<template>
  <div class="container">
    <Draggable v-model="items" :animation="500" @input="emitChange">
      <template v-for="item in items">
          <div :key="item.key" class="draggable-item">
            <BaseIcon :icon="item.icon" />
            <div class="title"> {{ item.name }} </div>
          </div>
      </template>
    </Draggable>
  </div>
</template>

<script>

import Draggable from 'vuedraggable'
export default {
    components:{Draggable},
    props:{
        items:{
            type:Array,
            default:()=>([])
        }
    }
}
</script>
<style lang="less">
.draggable-item{
  width:calc(100% - 20px);
  margin:10px;
  border:1px solid var(--border);
  display: flex;
  align-items: center;
  padding:0 10px;
  border-radius:3px;

  .title{
    margin-left:5px;
  }
}

.draggable-item:hover{
  border-color:var(--primary);
  transition: all 0.3s;
  .title{
    color:var(--primary);
  }
}

.draggable-item:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}
</style>