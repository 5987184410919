<template>
  <div :style="`background:${color};border-radius:5px;padding:3px 8px;color:var(--hover-text);font-size:12px;${dot?'width:15px;height:5px;padding:0;':''};${light?`box-shadow:0 0 3px 1px ${color};`:''}`">
    {{dot?'':text}}
  </div>
</template>

<script>
  export default {
    props:{
      value:{
        type:Number | String,
        default:0
      },
      states:{
        type:Array | Object,
        default:()=>(['准备中','进行中','已竣工','已结束','已中止','审计结束'])
      },
      light:{
        type:Boolean,
        default:false
      },
      dot:{
        type:Boolean,
        default:false
      },
      colors:{
        type:Array|Object,
        default:()=>({
          '准备中':'#333',
          '进行中':'var(--primary)',
          '已竣工':'var(--success)',
          '已结束':'darkgreen',
          '已中止':'var(--error)',
          '审计结束':'var(--warning)'
        })
      }
    },
    computed:{
      text(){
        if(isNaN(this.value)){
          return this.value
        }else{
          return this.states[this.value]
        }
      },
      color(){
        if(isNaN(this.value)){
          return this.colors[this.value]
        }else{
          return this.colors[this.states[this.value]]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>