<template>
  <div class="container flex-wrap flex-center">
    宁波高专建设监理有限公司(c)2023-2028 <Divider type="verticle" /> 联系电话 15991913205
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>