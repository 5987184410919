import util from '@/utils'
import moment from 'moment'
let out = {}

out.renderIndex = () => {
  return (h, params) => h("span", params.index + 1)
}


/** @method renderLink
 *  @description render a link with innerObject to trigger given callback eventHandler
 *  @params
 *      [innerRender] inner render function(h,param)
 *      [event] event type (default:open)
 *      [eventHandler] eventHandler callback function
 *      [option] column options
 */
out.renderLink = (innerRender, event = 'open', eventHandler, option = {}) => {
  return (h, params) => h("a", {
    style: {
      color: 'var(--primary)',
      fontSize:'12px',
    },
    on: {
      click(e) {

        if (typeof eventHandler === 'function')
          eventHandler({
            type: event,
            data: params.row,
            option
          })
        e.stopPropagation()
      }
    }
  }, [innerRender(h, params)])

}

out.renderText = (key, option = {}, onEvent) => {
  return (h, params) => {
    let value = (option.valmapper ? option.valmapper(params, key) : params.row[key]) !== undefined ? (option.valmapper ? option.valmapper(params, key) : params.row[key]) : option.defaultValue



    if (option.editable == true && onEvent) {
      let button = h('Icon', {
        class: "small-icon-button",
        style: {
          marginLeft: "5px"
        },
        props: {
          type: 'md-create',
          size: "14"
        },
        on: {
          click: () => {
            onEvent('edit-text', params.row, key)
          }
        }
      })

      value = [value, button]
      if (option.options) {
        let DropDownMenu = h("DropdownMenu", {
          slot: "list"
        }, option.options.map((v, i) =>
          h("DropdownItem", {
            props: {
              name: v
            }
          }, [v])
        ))

        let drop = h("Dropdown", {
          style: {
            right: '15px'
          },
          props: {
            trigger: "click",
            transfer: true
          },
          on: {
            "on-click": (e) => onEvent('change-state', {
              id: params.row.id,
              [key]: e
            })
          }
        }, [DropDownMenu, h('span', {
          style: {
            fontSize: '10px',
            marginLeft: "5px",
            color: "lightblue"
          }
        }, '[选择]')])


        value.push(drop)
      }

    }

    return h("span", {
      style: {
        float: option.align ? "auto" : "left",
        textAlign: option.align || "left",
        margin: "0 5px"
      },
    }, value)


  }

}

out.renderList = (key, option = {}, eventHandler) => {


  let store = option.store
  let getter = option.getters
  let storeData = null
  if (store && store.getters[getter])
    storeData = store.getters[getter]
  let getItem = (id) => id
  if (Array.isArray(storeData) && storeData.length > 0)
    getItem = (id) => {
      if (Array.isArray(id)) {
        if (storeData[0] && typeof storeData[0] == 'object')
          return id.map(v => storeData.find(item => item.id == v)).filter(v => v)
        else
          return id.map(v => storeData[v]).filter(v => v)
      } else {
        if (storeData[0] && typeof storeData[0] == 'object')
          return storeData.find(v => v.id == id)
        else
          return storeData[id]
      }
    }

  return (h, params) => {
    let list = params.row[key] || []
    if (list.length == 0)
      return out.renderEmpty(h)
    else
      return h('span', {},
        list.map(v => {
          let item = getItem(v)
          if (!item)
            return null
          return h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click(e) {
                  eventHandler({
                    type: 'clickItem:',
                    data: {
                      key,
                      value: v
                    }
                  })
                  e.stopPropagation()
                }
              }
            },
            item.name)
        }))

  }
}

out.renderUser = (key, option = {}, eventHandler) => {
  return (h, params) => {
    let id = (option.valmapper ? option.valmapper(params, key) : params.row[key])
    if(id){
      return h("div",{class:"flex-wrap flex-center",style:"padding-right:15px"},[h("BaseNameBoard",{props:{uid:id}})])
    } else {
      return h("span", "-")
    }

  }
}

out.renderUsers = (key, option = {}, eventHandler) => {
  return (h, params) => {
    let id = (option.valmapper ? option.valmapper(params, key) : params.row[key])
    if(!Array.isArray(id) && id)
      id = [id]

    if(id){
      return h("div",{class:"flex-wrap flex-center",style:"padding-right:15px"},[h("BaseNameBoards",{props:{uids:id}})])
    } else {
      return h("span", "-")
    }

  }
}
out.renderBool = (key, option = {}, onEvent) => {
  let texts = option.texts || ["否", "是"]
  let colors = option.colors || ["red", "green"]
  return (h, params) => {
    let v = (option.valmapper ? option.valmapper(params, key) : params.row[key]) || 0





    v = v ? 1 : 0

    if (option.type == "star") {
      return h("Icon", {
        props: {
          type: v ? "ios-star" : "ios-star-outline",
          size: 20
        },
        class: 'star-icon-button',
        on: {
          click: option.editable ? () => {
            onEvent('edit-bool', params.row, key)
          } : undefined
        }
      })
    }

    if (option.tooltipTo) {
      return h("Tooltip", {
        props: {
          transfer: true,
          theme: 'light'
        },
        style: {
          color: v ? 'green' : '#aaa'
        }
      }, [v ? '查看' : '无',
        h('div', {
          slot: "content"
        }, [
          (params.row[option.tooltipTo]) && typeof (params.row[option.tooltipTo]) == "object" ?
          params.row[option.tooltipTo].map(v => h("p", v)) : h("p", params.row[option.tooltipTo])
        ])
      ])
    }

    return h("span", {
      style: {
        color: colors[v]
      }
    }, texts[v])
  }
}

out.renderTool = (buttons, eventHandler, option, authorize, userinfo) => {
  const predefined = {
    edit: "编辑",
    delete: "删除"
  }
  return (h, params) => h("div", buttons.map(v => {
    let b = v
    if (typeof v == "string" && predefined[v]) {
      b = {
        event: v,
        label: predefined[v]
      }
    }


    return h(option.type == 'text' ? "a" : "Button", {
      props: {
        size: "small",
        disabled: authorize ? (!authorize(b.event, params.row, userinfo)) : false
      },
      style: {
        marginRight: "10px"
      },
      on: {
        click: () => {
          if (eventHandler)
            eventHandler({
              type: b.event,
              data: params.row,
              option
            })
        }
      }
    }, b.label)

  }))
}

out.renderTime = (key, option = {}) => {
  return (h, params) => {
    let t = option.type || "smart"
    let time = (option.valmapper ? option.valmapper(params, key) : params.row[key])
    if (!time || !moment(time).isValid())
      return h("span", "-")
    let m = moment(time)
    let o = ""
    if (t == "smart") {
      o = m.fromNow()
    } else if (t == "date") {
      o = m.format("L")
    } else {
      o = m.format("LL")
    }
    return h("span", {
      style: {
        color: "var(--secondary)"
      }
    }, o)
  }
}

out.renderState = (key, option = {}, onEvent) => {
  let colors = option.colors || ["grey", "orange", "green", "darkgreen", "red", "purple", "blue", "yellowgreen", "black", "pink"]

  let states = option.states


  return (h, params) => {
    let startIndex = option.startIndex || 0
    let stateIndex = (option.valmapper ? option.valmapper(params, key) : params.row[key]) || 0
    let stateStatic = h("span", {
      style: {
        padding: "3px 8px",
        backgroundColor: colors[stateIndex - startIndex],
        color: "#fff",
        borderRadius: "5px",
        fontSize: "10px",
        whiteSpace: 'nowrap'
      }
    }, states ? states[stateIndex - startIndex] : stateIndex)

    if (option.type == "star") {
      stateStatic = h("Icon", {
        props: {
          type: "md-star",
          size: 20
        },
        style: {
          color: colors[stateIndex]
        },
        class: 'star-icon-button'
      })
    }



    if (option.editable) {

      let DropDownMenu = h("DropdownMenu", {
        slot: "list"
      }, states.map((v, i) =>
        h("DropdownItem", {
          props: {
            name: i + startIndex,
            selected: i == stateIndex - startIndex
          }
        }, [h("Icon", {
          props: {
            type: "md-star",
            size: 14
          },
          style: {
            color: colors[i]
          },
          class: 'star-icon-button'
        }), v])
      ))



      return h("Dropdown", {
        props: {
          trigger: "click",
          transfer: true
        },
        on: {
          "on-click": (e) => onEvent('change-state', {
            id: params.row.id,
            [key]: e
          })
        }
      }, [DropDownMenu, stateStatic])
    } else {
      return stateStatic
    }
  }




}

function mapColor(ch, base = 10000) {
  let d = ch.charCodeAt(0)

  const dRange = 40896 - 19968
  d = d - 19968
  if (d < 0)
    d = 10000
  let baseColor = base || 180 * 180 * 180
  let colorRange = 256 * 256 * 256 - baseColor
  let mapped = baseColor + parseInt((d * colorRange / dRange))

  mapped = mapped.toString(16)
  while (mapped.length < 6)
    mapped = '0' + mapped

  return '#' + mapped
}

out.renderType = (key, option = {}) => {
  return (h, params) => {
    let type = (option.valmapper ? option.valmapper(params, key) : params.row[key])
    if (option.store && option.getters) {
      let types = option.store.getters[option.getters] || []
      if (option.getters_key)
        types = types(option.getters_key)

      if (option.multiple && params.row[key] != undefined) {
        let res = params.row[key].map(v => types.find(t => t.value != undefined ? t.value == v : t.id == v)).filter(v => v).map(v => v.name)
        if (res.length > 0)
          return h("div", {
            style: {
              padding: "2px 10px",
              display: "flex",
              justifyContent: "flex-start"
            }
          }, res.map(v => h("a", {
            style: {
              margin: '5px',
              whiteSpace: 'nowrap',
              fontSize: "10px"
            }
          }, v)))
        else
          return h("span", "-")
      }



      type = types.find(v => v.value != undefined ? v.value == type : v.id == type)
      if (type)
        return h("div", {

          style: {
            padding: "2px 8px",
            backgroundColor: type.color || mapColor(type.name, option.base),
            color: "#fff",
            lineHeight: "auto",
            display:'inline-block',
            textShadow: "1px 1px 1px var(--border)",
            borderRadius: "5px",
            fontSize: "10px"
          }
        }, type.name)
      else
        return h("span", "-")

    } else {
      if (type)
        return h("span", {
          style: {
            color: mapColor(type, option.base)
          }
        }, type)
      else
        return h("span", "-")
    }

  }
}

function renderSize(value) {
  if (null == value || value == '') {
    return "0 Bytes";
  }
  var unitArr = new Array("Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
  var index = 0;
  var srcsize = parseFloat(value);
  index = Math.floor(Math.log(srcsize) / Math.log(1024));
  var size = srcsize / Math.pow(1024, index);
  size = size.toFixed(2); //保留的小数位数
  return size + unitArr[index];
}

out.renderFileSize = (key, option = {}) => {
  return (h, params) => {
    let size = (option.valmapper ? option.valmapper(params, key) : params.row[key])
    return h('span', renderSize(size))
  }

}

out.renderHTML = (h, html) => {
  return h('span', {
    style: {
      textAlign: 'left',
      float: 'left',
      marginLeft: '0.5rem'
    },
    domProps: {
      innerHTML: html
    },
  })
}
out.moment = (date, format) => {
  if (!date)
    return moment()
  if (date.includes(':') && !format) {
    return moment(date)
  } else if (format) {
    return moment(date, format)
  } else {
    return moment(date, 'YYYY-MM-DD')
  }
}

out.durationOfDate = (d1, d2) => {
  if (d1 && d2) {
    let md1 = d1.includes(':') ? moment(d1) : moment(d1, 'YYYY-MM-DD')
    let md2 = d2.includes(':') ? moment(d2) : moment(d2, 'YYYY-MM-DD')
    return moment.duration(md2 - md1)
  }
}

out.isBeforeToday = (d, format, vnow) => {
  let md = moment(d, format)
  let today = moment(vnow).startOf('day')
  if (md < today) {
    return true
  } else
    return false
}

out.dateAddDays = (d, days) => {
  if (d && days) {
    let m = d.includes(':') ? moment(d) : moment(d, 'YYYY-MM-DD')
    return m.add(days, 'days')
  } else {
    return moment(d)
  }
}

out.days2months = (d) => {
  let days = parseInt(d)
  return (days / 30.4)
}

out.dateOffset = (d1, d2) => {
  return moment.duration(moment(d2) - moment(d1))
}


out.renderDateSimple = (h, d, hl) => {
  if (typeof d == 'string' && d != "null" && d != "") {
    let m = d.includes('T') ? moment(d) : moment(d, 'YYYY-MM-DD')
    let dateString = m.startOf("day").format('YYYY-MM-DD')
    return h('div', {
      style: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'

      }
    }, [h('div', {
      style: {
        color: hl ? 'blue' : '#777',
        textAlign: 'cener',
        fontSize: '0.7rem',
        lineHeight: '1rem'
      }
    }, dateString)])
  } else {
    return h('span', {}, '-')
  }

}

out.renderDate = (h, d, highlight) => {
  if (typeof d == 'string' && d != "null" && d != "") {
    let m = d.includes('T') ? moment(d) : moment(d, 'YYYY-MM-DD')
    let dateString = m.startOf("day").format('YYYY/MM/DD')
    let dateSmart = m.fromNow()
    if (dateSmart.includes('小时'))
      dateSmart = '1天内'
    return h('div', {
      style: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'

      }
    }, [h('div', {
      style: {
        color: highlight ? 'blue' : '#777',
        textAlign: 'cener',
        fontSize: '0.7rem',
        lineHeight: '0.9rem'
      }
    }, dateString), h('div', {
      style: {
        color: '#aaa',
        fontSize: '0.5rem',

        lineHeight: '0.6rem',
        textAlign: 'center'
      }
    }, dateSmart)])
  } else {
    return h('span', {}, '-')
  }

}

out.renderCompareMark = (h, d, r) => {

  return h('Icon', {
    props: {
      type: d >= 0 ? 'md-arrow-dropup' : 'md-arrow-dropdown',
      size: 14
    },
    style: {
      color: !r ? ((d > 0) ? 'red' : 'green') : (d >= 0 ? 'green' : 'red')
    }
  })
}

out.renderMark = (h, d, r) => {
  return h('Icon', {
    props: {
      type: 'ios-checkmark-circle-outline',
      size: 16
    },
    style: {
      color: 'green',
      marginLeft: '0.25rem',
      marginRight: '0.25rem'
    }
  })
}

out.renderCompare = (h, d, offset, colorReverse, renderFunc, inline) => {
  if (d || offset) {
    const styleInline = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }

    const styleNotInline = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }

    let renderItems = [h('div', {
      style: {
        color: '#777',
        textAlign: 'center',
        fontSize: '0.7rem',
        lineHeight: '1rem',

      }
    }, [renderFunc ? renderFunc(h, d) : d])]
    if (offset != 0 && (Math.abs(offset) > 0.01))
      renderItems.push(h('div', {
        style: {
          color: colorReverse ? (offset >= 0 ? 'green' : 'red') : (offset > 0 ? 'red' : 'green'),
          fontSize: '10px',

          lineHeight: inline ? '' : '0.6rem',
          textAlign: 'center',
          display: "flex",
          alignItems: "center"
        }
      }, [h('span', {
        style: {
          marginLeft: inline ? '0.5rem' : '',
          marginRight: '0.25rem'
        }
      }, offset > 0 ? '+' : '-'), renderFunc ? renderFunc(h, Math.abs(offset)) : offset.toFixed(2)]))
    return h('div', {
      style: inline ? styleInline : styleNotInline
    }, renderItems)
  } else {
    return h('span', {}, '-')
  }

}


out.renderComparePercent = (h, d, percent, colorReverse, renderFunc, inline) => {
  if (d || percent) {
    const styleInline = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }

    const styleNotInline = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }

    let renderItems = [h('div', {
      style: {
        color: '#777',
        textAlign: 'center',
        fontSize: '0.7rem',
        lineHeight: '1rem',

      }
    }, [renderFunc ? renderFunc(h, d) : d])]
    renderItems.push(h('div', {
      style: {
        color: colorReverse ? (percent <= 0 ? 'green' : 'red') : (percent > 0 ? 'red' : 'green'),
        fontSize: '10px',

        lineHeight: inline ? '' : '0.6rem',
        textAlign: 'center',
        display: "flex",
        alignItems: "center"
      }
    }, [out.renderCompareMark(h, percent, colorReverse), Math.abs(percent * 100).toFixed(1) + '%']))
    return h('div', {
      style: inline ? styleInline : styleNotInline
    }, renderItems)
  } else {
    return h('span', {}, '-')
  }

}


// d1 > d2, return 0
out.offsetMonth = (d1, d2) => {
  let dm1 = moment(d1)
  let dm2 = moment(d2)
  let y1 = dm1.year()
  let y2 = dm2.year()
  let m1 = dm1.month() + 1
  let m2 = dm2.month() + 1

  let offset = 0
  if (y2 > y1) {
    offset = (y2 - y1 - 1) * 12 + (12 - m1 + 1) + m2
  } else {
    offset = m2 - m1 + 1
  }
  return offset
}

out.offsetYear = (d1, d2) => {
  return parseInt(d2) - parseInt(d1)
}

out.calcInDuration = (inStart, inEnd, outStart, outEnd) => {
  let start, end
  if (inStart.isBefore(outStart)) {
    if (inEnd.isBefore(outStart))
      return 0
    else {
      start = outStart
      end = inEnd.isBefore(outEnd) ? inEnd : outEnd
    }
  } else {
    if (inStart.isAfter(outEnd)) {
      return 0
    } else {
      start = inStart
      end = inEnd.isBefore(outEnd) ? inEnd : outEnd
    }
  }
  if (end < start)
    return 0

  return moment.duration(end - start).as('month')
}

out.renderProgressValue = (h, percent, value, color = '#cef', bgcolor = '#eee') => {
  value = value || (percent * 100).toFixed(1) + '%'
  let percentItems = []
  if (typeof percent != 'undefined')
    percentItems.push(h("div", {
      style: {
        position: 'absolute',
        left: 0,
        height: "1.8rem",
        zIndex: '0',

        background: color,
        width: (percent && percent > 100 ? 100 : (percent || 0)) + '%'
      }
    }))
  else
    return

  percentItems.push(h('div', {
    style: {
      zIndex: 1,
      color: '#333',
      position: 'relative',
      width: "100%",
      textAligh: "right"
    }
  }, [value]))

  return h("div", {
    style: {
      position: "relative",
      height: "2rem",
      display: 'flex',
      alignItems: 'center',

      background: bgcolor,
      justifyContent: 'center',
      margin: '0 0.5rem'
    }
  }, percentItems)
}


out.renderCompareProgress = (h, percent) => {
  let percentItems = []
  if (typeof percent != 'undefined')
    percentItems.push(h("div", {
      style: {
        position: 'absolute',
        left: 0,
        height: "2rem",
        zIndex: '0',

        background: percent > 100 ? '#5cb515' : '#fa3833',
        width: (percent && percent > 100 ? 100 : (percent || 0)) + '%'
      }
    }))
  else
    return
  percentItems.push(h('span', {
    style: {
      zIndex: 1,
      color: 'white',
      position: 'relative'
    }
  }, (percent || 0) + '%'))

  return h("div", {
    style: {
      position: "relative",
      height: "2rem",
      display: 'flex',
      alignItems: 'center',

      background: '#ddd',
      justifyContent: 'center',
      margin: '0 0.5rem'
    }
  }, percentItems)
}


out.renderProgress = (h, percent, percent2, color1, color2,) => {

  let percentItems = []
  if (typeof percent != 'undefined')
    percentItems.push(h("div", {
      style: {
        position: 'absolute',
        left: 0,
        height: "1rem",
        zIndex: '0',
        transition: 'all 0.3s',
        background: color1 || '#5fabf1',
        width: (percent && percent > 100 ? 100 : (percent || 0)) + '%'
      }
    }))
  else
    return
  if (percent2) {
    percentItems.push(h("div", {
      style: {
        position: 'absolute',
        left: 0,
        height: "2rem",
        zIndex: '1.1',
        color: "#eee",
        background: color2 || 'green',
        textAlign: 'center',
        
        width: (percent2 && percent2 > 100 ? 100 : (percent2 || 0)) + '%'
      }
    }, (percent2 && percent2 > 100 ? 100 : (percent2 ? percent2.toFixed(2) : 0)) + '%'))
  }

  if (typeof percent == 'string')
    percent = parseFloat(percent)

  if (typeof percent != 'number')
    percent = 0
  if (percent > 100)
    percent = 100
  else
    percent = percent.toFixed(2)

  percentItems.push(h('span', {
    style: {
      zIndex: 1,
      color:"#fff",
      textShadow:"1px 1px 1px var(--text3)",
      lineHeight: '1.2rem',
      position: 'relative'
    }
  }, (percent + '%')))

  return h("div", {
    style: {
      position: "relative",
      height: "1.1rem",
      borderRadius:"1.1rem",
      border:"1px solid var(--border)",
      overflow:"hidden",
      display: 'flex',
      alignItems: 'center',
      background: '#eee',
      justifyContent: 'center',
      margin: '0 10px'
    }
  }, percentItems)
}

const renderFullAmount = (h, amount, highlight, center, percentTo,fix) => {
  if (amount == 'undefined')
    return renderEmpty(h)

  if (amount) {
    if (typeof highlight == 'boolean')
      highlight = "var(--primary)"

    let pt = null
    let percent = 0
    if (percentTo) {
      percent = parseInt(amount / percentTo * 100)
      pt = h('Progress', {
        props: {
          percent,
          strokeWidth: 3,
        },
        style: {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          color: "darkred"
        }
      })
    }

    let value = [h("span", {
      style: {
        color: "var(--subtext3)",
        position: "relative",
        top: "-3px",

      }
    }, "¥"), h("span", {
      style: {
        position: "relative",
        top: "-3px",
        color: amount <= 0.0001 ? 'var(--subtext3)' : (highlight ? highlight : "var(--text3)"),
        marginLeft: "0.2rem",

        marginRight: center ? '' : "1rem"
      }
    }, `${util.formatSalarySemicolon(amount,fix)}`)]

    if (percentTo)
      value.push(pt)

    return h("div", {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: center ? "center" : "flex-end",
        whiteSpace: "nowrap",
        position: "relative",
        height: "40px"
      }
    }, value)
  }

  return out.renderEmpty(h)
}

out.renderEmpty = (h) => {
  return h('span', {}, '-')
}

out.renderAmountNoFormat = (h,amount)=>{
  return h("div", {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      whiteSpace: "nowrap"
    }
  }, [h("span", {
    style: {
      color: "var(--subtext3)",
    }
  }, "¥"), h("span", {
    style: {
      color: "var(--text3)",
      marginLeft: "0.2rem",
      marginRight: "0.5rem"
    }
  }, amount),h('span',{style:'font-size:12px;color:var(--subtext3)'},'万元')])
}

const renderAmount = (h, amount, highlight, center, percentTo) => {
  amount = amount || 0
  if (typeof highlight == 'boolean' && highlight)
    highlight = "var(--primary)"

  let pt = undefined
  if (percentTo) {
    let percent = amount / percentTo
    pt = h('Progress', {
      props: {
        percent
      }
    })
  }


  return h("div", {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: center ? "center" : "flex-end",
      whiteSpace: "nowrap"
    }
  }, [h("span", {
    style: {
      color: "var(--subtext3)",
    }
  }, "¥"), h("span", {
    style: {
      color: amount <= 0.0001 ? 'var(--subtext3)':(highlight ? highlight : "var(--text3)"),
      marginLeft: "0.2rem",
      marginRight: center ? '' : "1rem"
    }
  }, util.formatSalary(amount)), pt])

}


out.renderNumber = (key, option = {}) => {

  return (h, params) => {
    let n = (option.valmapper ? option.valmapper(params, key) : params.row[key]) || 0
    if (option.hideZero && n == 0)
      return out.renderEmpty(h)

    if (option.type == 'amount') {
      let percentTo = undefined
      if (option.percentToValue)
        percentTo = option.percentToValue

      return renderAmount(h, n, option.highlight, option.center, percentTo)
    } else if (option.type == 'fullAmount') {
      let percentTo = undefined
      if (option.percentTo)
        percentTo = params[option.percentTo]
      return renderFullAmount(h, n, option.highlight, option.center, percentTo,option.fix)
    } else if (option.type == 'percent') {
      let percentTo = 1
      if (option.percentToValue)
        percentTo = option.percentToValue
      if (option.percentTo)
        percentTo = params.row[option.percentTo]
      
      let percent = n * 100 / percentTo

      let percent2 = 0
      let percentTo2 = 0
      if (option.percentToValue2)
        percentTo2 = option.percentToValue2
      if (option.percentTo2)
        percentTo2 = params.row[option.percentTo]
      if (percentTo2) {
        percent2 = n / percentTo2 * 100
      }
      return out.renderProgress(h, percent, percent2, option.highlight || "var(--primary)")
    } else if (option.type == 'progress') {
      let percentTo = 1
      if (option.percentToValue)
        percentTo = option.percentToValue
      if (option.percentTo)
        percentTo = params.row[option.percentTo]
      let percent = n / percentTo * 100
      return out.renderProgressValue(h, percent, n, option.highlight || "var(--primary)")
    } else {
      if (option.formatter)
        return h('span', {
          style: {
            whiteSpace: "nowrap",
            color:Math.abs(n)<=0.001?"var(--subtext3)":""
          }
        }, option.formatter(n))
      else
        return h('span', {
          style: {
            whiteSpace: "nowrap"
          }
        }, n)
    }
  }

}

out.renderFileType = (key, option = {}) => {
  return (h, params) => {
    let type = (option.valmapper ? option.valmapper(params, key) : params.row[key])
    const colors = {
      png: 'purple',
      image: 'purple',
      gif: 'purple',
      jpg: 'purple',
      jpeg: 'purple',
      xls: 'green',
      xlsx: 'green',
      ppt: 'orange',
      pptx: 'orange',
      doc: 'blue',
      docx: 'blue'

    }
    return h('span', {
      style: {
        padding: "0px 5px",
        background: colors[type] || "#333",
        color: "#fff",
        filter: "drop-shadow(1px 1px 1px #333)",
        borderRadius: "2px"
      }
    }, type)
  }
}


out.renderTree = (innerRender)=>{
  return (h,params)=>{
    let innerDom = innerRender(h, params)
    let treeNode = h("BaseIcon",{props:{icon:"md-add"},style:"margin-right:3px;background:var(--primary);color:#fff;padding:2px;border-radius:50%;"})
    if(params.row.children && params.row.children.length > 0)
      return h('div',{class:'flex-wrap'},[treeNode,innerDom])
    else
      return innerDom
  } 
}

out.render = (columnsDefinition, onEvent, auth, attachedDataSet, store) => {
  return columnsDefinition.map((v, i) => {
    // binding vuex/store for data transition
    if(v.children && v.children.length > 0){
      v.children = out.render(v.children,onEvent,auth,attachedDataSet,store)
      return v
    }

    if (v.option)
      v.option.store = store

    let compare = (a,b)=>a==b?0:(a>b?1:-1)
    if(v.type == 'time')
      compare = (a,b)=>{
        return moment(a).isSame(moment(b))?1:(moment(a).isAfter(moment(b))?1:-1)
      }
    else if(v.type == 'number'){
      compare = (a, b) => {
        return (a > b ? 1 : -1)
      }
    }

    // create iview table column definition
    let col = {
      key: v.key,
      title: v.title,
      align: v.align || "center",
      width: v.width,
      fixed: v.fixed,
      ellipse: true,
      tree: v.tree,
      type:v.type,

      maxWidth: v.maxWidth,
      className: v.className,
      minWidth: v.minWidth || 60,
      sortable: v.sortable != undefined ? v.sortable : true,
      sortMethod: (type) => {
       
        if (type == 'asc') {
          return (a, b) => {


            if (a[v.key] === null || a[v.key] === undefined){
              

              if (b[v.key] === null || b[v.key] === undefined)
                return 1
              else
                return -1
            }else{
              if(b[v.key] == null || b[v.key] == undefined)
                return 1
            }


            return compare(a[v.key],b[v.key])
          }
        } else if(type == 'desc') {
          return (a, b) => {
            if (b[v.key] === null || b[v.key] === undefined){
              

            if (a[v.key] === null || a[v.key] === undefined)
              return 1
            else
              return -1
            } else {
              if (a[v.key] == null || a[v.key] == undefined)
                return 1
            }

            return compare(b[v.key], a[v.key])
          }
        }
        return ()=>0
      }
    }

    if (v.renderHeader)
      col.renderHeader = v.renderHeader

    if (v.render) {
      col.render = v.render
      return col
    }



    // pre-defined type settings
    if (v.type == "index") {
      col.type = 'index'
      col.sortable = false
      col.width = 40
    } else if (v.type == "selection") {
      col.type = 'selection',
        col.sortable = false
    } else if (v.type == "text") {
      col.render = out.renderText(v.key, v.option, onEvent)
    } else if (v.type == "tool") {
      col.render = out.renderTool(v.buttons, onEvent, v.option, auth, attachedDataSet.userinfo)
    } else if (v.type == "time") {
      col.render = out.renderTime(v.key, v.option)
      col.minWidth = 100
      col.sortMethod = (type) => {
        if (type == 'asc') {
          return (a, b) => {
            if (a[v.key] == null || a[v.key] == undefined)
              return 1

            if (b[v.key] == null || b[v.key] == undefined)
              return -1

            if (a[v.key] == b[v.key])
              return 0

            return moment(a[v.key]).isAfter(b[v.key]) ? 1 : -1
          }
        } else {
          return (a, b) => {
            if (b[v.key] == null || b[v.key] == undefined)
              return -1

            if (a[v.key] == null || a[v.key] == undefined)
              return 1

            if (a[v.key] == b[v.key])
              return 0

            return moment(a[v.key]).isAfter(b[v.key]) ? -1 : 1
          }
        }
      }
    } else if (v.type == 'list') {
      col.render = out.renderList(v.key, v.option, onEvent)
    } else if (v.type == "user") {
      col.render = out.renderUser(v.key, v.option, onEvent)
      
    }else if (v.type == "users") {
      col.render = out.renderUsers(v.key, v.option, onEvent)
      
    }else if (v.type == "state") {
      col.render = out.renderState(v.key, v.option)
    } else if (v.type == "type") {
      col.render = out.renderType(v.key, v.option)
    } else if (v.type == "bool") {
      col.render = out.renderBool(v.key, v.option)
    } else if (v.type == 'filetype') {
      col.render = out.renderFileType(v.key, v.option)
    } else if (v.type == 'filesize') {
      col.render = out.renderFileSize(v.key, v.option)
    } else if (v.type == 'number') {
      col.render = out.renderNumber(v.key, v.option)
      col.width = 120
    }

    // defined link event to render it as a link <a>
    // click will trigger given linkEvent type
    if (v.linkEvent) {
      col.render = out.renderLink(
        col.render,
        v.linkEvent === true ? 'open' : v.linkEvent,
        onEvent,
        v.option
      )
    }

    if(v.tree){
      col.render = out.renderTree(col.render)
    }

    if (v.modify)
      v.modify(col)

    return col
  })
}



export default out
