<template>
  <FormItem :label="label">
    <template v-if="editable">
    <CheckboxGroup v-model="value" @input="$emit('input',$event)" style="margin-left:20px">
      <template v-for="o in options">
        <div :key="o.key">
          <Checkbox :label="o.name" :key="o.key" :disabled="!editable">
            <Icon v-if="o.icon" :type="o.icon"></Icon>
            <span>{{o.name}}</span>
        </Checkbox>
        </div>
      </template>
    </CheckboxGroup>
    </template>
    <template v-else>
      <div>{{val_objects}}</div>
    </template>
 </FormItem>
</template>

<script>
export default {
  name:"多选框",
  icon:'-duoxuan',
  properties:[{
    label:"选项",
    key:'options',
    control:"SelectOption"
  }],
 props:['fkey','config','value','editable','label'],
 initModel(e){
  e.config = {
    options:['选项1','选项2']
  }
 },
  computed:{
    val_objects(){
      return Array.isArray(this.value)?
        this.value.map(v=>this.options.find(t=>t.key == v).name).join(','):"-"
    },
    options(){
      let options = this.config.options
      if(Array.isArray(options)){
        if(options && typeof options[0] != 'object')
          options = options.map((v,i)=>({
            key:i,
            name:v
          }))
        
      }
      return options
    }
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>