<template>
  <div class="training-myproject">
    <img src="https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/misc/px.jpg" style="width:300px;height:170px;margin-bottom:10px;" />
    <div class="flex-wrap flex-between" style="width:100%">
      <div>
      <div class="tc-title">{{value.name}}</div>
      <div class="flex-wrap" style="flex-shrink:0"><BaseNameBoard :uid="value.teacher_id"  /></div>
      <div class="tc-date">{{value.created_at}}</div>
    </div>
     <Button>已报名</Button>
    </div>
    
  </div>
</template>

<script>
  export default {
    data(){
      return {
      }
    },
    props:{
      value:{
        type:Object,
        default:()=>({})
      }
    }
  }
</script>

<style lang="less">
.training-myproject{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  background:var(--bg2);
  padding:10px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 10px;
  .tc-title{
    font-size:16px;
    font-weight: bold;
  }

  .tc-date{
    font-size:12px;
  }

}

.training-project:hover{
  background:var(--primary);
  color:var(--hover-text);
}
</style>