<template>
  <Container>
    <Permission
      mod="business"
      :permissions="permission"
     />
  </Container>
</template>

<script>
import Container from '@/components/app/container'
import Permission from '@/components/app/permission'
export default {
  components:{Container,Permission},
  computed:{
    permissions(){
      return [{
        name: "数据总览",
        key: "dashboard",
        icon: "md-speedometer"
      },{
          name:"统计报表",
          key:"report",
          icon:"table"
        },{
        name: "项目库",
        key: "projects",
        icon: "xiangmu2"
      },{
        name: "分类报表",
        key: "datagrim",
        icon: "md-speedometer"
      },  {
        name: "数据导出",
        key: "report",
        icon: "folder"
      }, {
        name:"看板",
        key:"boards",
        icon:"chart"

      },]
    }
  }
}
</script>

<style>

</style>