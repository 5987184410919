<template>
    <App>
        <AppBar>
          <div class="flex-wrap" style="font-size:16px;"><GroupMenu /> {{ready?group.name:'加载中...'}} {{isLogined?'':'未登录'}}</div>
          <div class="flex-wrap">
            <IconTabs :items="views" :current="view" @on-select="handleTabEvent" />
           
            <IconButton title="更多模块" style="margin-left:3px" icon="ios-arrow-down"   @on-click="showMenu = !showMenu" v-if="mods.length > 4" />
          </div>
          <div class="flex-wrap split5">
            <AppAvatar />
            <Divider type="vertical" />
            <Button :type="isConcerned?'':'primary'" @click="switchConcerned">{{isConcerned?"已关注":"关注"}}</Button>
          </div>
        </AppBar>
        <AppClient>
          <AppMask v-if="!ready" />
          <router-view></router-view>
          </AppClient>
    </App>
</template>

<script>
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppRight from '@/components/app/right'
import AppClient from '@/components/app/client'
import AppAvatar from '@/components/app/avatar'
import IconButton from '@/components/app/IconButton'
import IconTabs from '@/components/app/IconTabs'
import AppLeft from '@/components/app/left'
import AppMask from '@/components/app/mask'
import GroupMenu from '@/components/group/GroupMenu'
import {mapGetters} from "vuex"
  export default {
    data(){
      return {
        ready:false,
        mods:[{
          key:"cm",
          name:"资料归档",
          icon:"archive"
        }]
      }
    },
    name:"groups",
    routerOption:{
      as_frame:true,
      as_object:true
    },
    mounted(){
      this.ready = false
      let path = this.isLogined?"group/load":"group/load_public"
      this.$store.dispatch(path,this.id).then(res=>{
        this.ready = true
      })
    },
    components: { App, AppBar,AppLeft,AppRight,AppClient,AppAvatar, IconButton,IconTabs, AppMask,GroupMenu },
    watch:{
      $route:{
        handler(){
          if(!this.id || (this.group && this.group.id == this.id))
            return
          this.ready = false
          let path = this.isLogined?"group/load":"group/load_public"
          this.$store.dispatch(path,this.id).then(res=>{
            this.ready = true
          })
        },
        deep:true,
        immediate:true
        
      }
    },
    computed:{
      ...mapGetters('session',['session','isLogined']),
      ...mapGetters('group',['group']),
      isConcerned(){
        return this.session.concerned.includes(this.id)
      },
      id(){
        return this.$route.params.id
      },
      view(){
        let path = this.$route.path
          return path.slice(path.lastIndexOf('/')+1)
      },
      views() {
      const _views = [{
        key: 'dashboard',
        name: "总览",
        icon: "desktop",
      },
      // {
      //   key: 'members',
      //   name: "人员管理",
      //   icon: "md-people",
      // }, {
      //   key: 'tasks',
      //   name: "进度管理",
      //   icon: "xiangmu",
      // },  {
      //   key: "files",
      //   name: "资料管理",
      //   icon: "folder",
      // },
      ...this.mods]
      return _views
    },
     },
     methods:{
      handleTabEvent(e){
        this.RouteTo(e)
      }
     }
  }
</script>

<style lang="scss" scoped>

</style>