<template>
  <App>
    <AppBar>
      <div class="flex-wrap">
        <BaseIcon icon="lianjieliu" size="18" style="margin-right:5px" />
        <FlowFileName v-model="flow" /> <span style="font-size:12px;color:var(--subtext2);margin-left:5px;">({{ id }})</span>
        <Button icon="md-refresh" @click="getData()" size="small" style="margin-left:10px" />
      </div>
      <div class="flex-wrap">
        <IconTabs :items="views" :current="view" @on-select="view=$event" />
       
      </div>
      <div class="flex-wrap split5">
        <AppAvatar />
        <Divider type="vertical" />
        <Button type="primary" @click="handleCreate">创建流程</Button>
      </div>
    </AppBar>
    
    <AppMask title="工作流程" icon-right="lianjieliu"  :loading="loading" />
    
    
    <AppClient>
     
      <transition name="fade">
        <template v-if="view == 'create'">
          <FlowCreate 
            ref="create"
            :flow="flow"
            @event="handleEvent"
          />
        </template>
        <template v-else-if="view == 'graph'">
          <FlowGraph :flow="flow"/>
        </template>
        <template v-else-if="view == 'history'">
          <div>
            File
          </div>
        </template>
      </transition>

    </AppClient>

    <Modal v-model="creating" footer-hide>
      正在为您创建流程
    </Modal>

  </App>
</template>

<script>
import responsableMixin from '@/mixins/responsable'
import { mapGetters } from 'vuex'
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppMask from '@/components/app/mask-loading'
import AppLeft from '@/components/app/left'
import AppRight from '@/components/app/right'
import AppClient from '@/components/app/client'
import AppAvatar from '@/components/app/avatar'
import IconButton from '@/components/app/IconButton'
import IconTabs from '@/components/app/IconTabs'
import FlowMenu from '@/components/flow/app/menu'
import FlowFileName from '@/components/flow/app/filename'
import FlowCreate from '@/components/flow/app/views/create'
import FlowGraph from '@/components/flow/app/views/graph'
export default {
  components: { App, AppBar,AppLeft,AppRight,AppClient,AppAvatar, IconButton,IconTabs, FlowMenu, FlowFileName,FlowCreate,FlowGraph,AppMask },
  routerOption:{
    as_default:true
  },
  data() {
    return {
      loading: false,
      selected: {},
      ready: false,
      showMenu: true,
      view: 'create',
      showHelper: true,
      creating:false,
      flow: {
       
      },
    }
  },
  mixins: [responsableMixin],
  props: {
    'panels': {
      type: Array,
      default: () => []
    }, 'padding': {
      type: Number,
      default: 0
    }, 'gutter': {
      type: Number,
      default: 10
    }, 'env': {
      type: Object,
      default: () => ({})
    }, 'flex': {
      type: Boolean,
      default: false
    }, 'panel-styles': {
      type: String
    }
  },
  watch:{
    $route:{
        handler(r,o){
            if(r.params.id !== o.params.id)
                this.getData()
        },
        deep:true,
        immediately:true
    }
  },
  mounted(){
    this.getData()
  },
  computed: {
    ...mapGetters("session", ['session']),
    id(){
        return this.$route.params.id
    },
    nodes() {
      return this.panels
    },
    views() {
      const _views = [{
        key: 'create',
        name: "创建",
        icon: "edit",
      }, {
        key: "graph",
        name: "图示",
        icon: "lianjieliu",
      }]
      let o = _views
      return o
    },
   
    admin() {
      return this.role == 'admin'
    },
    permissions() {
      if (this.role == 'admin') {
        return true
      } else return {

      }
    }
  },
  methods: {
    handleCreate(){
      let createView = this.$refs.create
      if(createView){
        if(this.view !== 'create'){
          this.view = create
        }
        createView.submit()
      }
    },
    getData(){
        this.loading = true
        this.$api.get(`/oa/flows/${this.id}?q=create`).then(res=>{
            this.flow = res.data.data
        }).catch(e=>{
            this.Error(e)
        }).finally(()=>{
            this.loading = false
        })   
    },
    handleEvent({type,value}) {
      if(type == 'submit'){
        this.creating = true
        this.$api.post(`/oa/flows/${this.id}/instances`,value).then(res=>{
          let {id:instance_id} = res.data.data
          setTimeout(()=>{
            this.creating = false
            this.$store.commit('session/removeByPath',this.$route.path)
            this.$store.commit('session/push_flow_instance',{
              id:instance_id,
              name:instance_id,
            })
            this.RouteTo(`/core/flows/${instance_id}/instance`)
          },3000)
        }).catch(e=>{
          this.Error(e)
          this.creating = false
        })
      }      
    },
    
  }
}
</script>

<style lang="less">
.pm-panel-flex {
  display: flex;
}
.pm-panel-editing-wrap:hover {
  filter: brightness(1.2);
  cursor: pointer;
}

.pm-panel-selected {
  background: #fbbd2162 !important;
}
</style>