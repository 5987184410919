<style lang="less" scoped>
.pm-panel{
  
  border:1px dotted #33aaff;
  transition:none !important;
  overflow: visible;
}
.pm-panel:hover{
  background:#ddeeffaa !important;
  border:1px solid #33aaff !important;
     cursor: grab;
 
 
}
.pm-panel:active{
  filter:brightness(0.9);
      cursor:grabbing;
    
}

.pm-panel-selected,.pm-panel-selected:hover{
   background:#f8e9cdaa !important;
  border:1px solid #ffb515 !important;
}

</style>

<template>
<div class="container">
  <div class="flex-wrap flex-between" style="height:50px;background:#fff;border-bottom:1px solid #eee;padding:0 10px;">
    <div class="flex-wrap">
       <Dropdown>
    <a><BaseIcon icon="md-menu" size="22" style="margin-right:10px" /></a>
    <DropdownMenu slot="list" placement="bottom left">
      <DropdownItem>返回工作台</DropdownItem>
    </DropdownMenu>
    </Dropdown>
    <Dropdown>
    <a style="font-size:0.9rem">我的工作台</a> 
    <DropdownMenu slot="list" placement="bottom left">
      <DropdownItem>123</DropdownItem>
    </DropdownMenu>
    </Dropdown>
    <span style="margin:0 5px">/</span> 
    <Input size="small" style="padding:5px;min-width:30px;width:auto" v-model="board.name" />
    <Divider type="vertical" />
    <Select v-model="zoom" style="width:80px;margin-left:10px;" size="small">
      <template v-for="o in zoomOptions">
        <Option :key="o.value" :value="o.value">{{o.label}}</Option>
      </template>
      
    </Select>
  </div>
  <div class="flex-wrap"></div>
    <div class="flex-wrap split5">
      <Avatar :src="session.avatar" size="small"></Avatar>
      <Button>分享</Button>
       <Divider type="vertical" />
        <BaseIcon icon="ios-play" size="25" color="#333" />
        <Button type="primary">保存并应用</Button>
    </div>
  </div>
  <div style="position:absolute;right:0;top:50px;bottom:0;width:220px;background:#fff;">
    <div class="flex-wrap">
      <div>padding</div>
      <Input number v-model="padding" />
    </div>
    <div><Button @click="resetLayout">Render</Button></div>
  </div>
  <div style="position:absolute;left:0;top:50px;bottom:0;width:220px;background:#fff;">
    <div class="flex-wrap" style="height:30px">
      Tools
    </div>
    <div style="height:calc(100% - 30px);border-top:1px solid #eee;">
      <BaseTree :data="[board]" manu style="height:100%" :nodeRenderer="renderTreeNode" />
    </div>
  </div>
  <div class="flex-wrap flex-center" style="position:absolute;right:220px;top:50px;bottom:0px;left:220px;background:#ddd;overflow:scroll;" :style="`cursor:${draggingCanvas?'grabbing':''}`" @click.self="selected=null" @mousedown.middle="onDragCanvas" @mousewheel="onZoomCanvas">
     <BaseRuler :length="w" placement="top" />
    <BaseRuler :length="w-300" placement="left" />
    <BaseRuler :length="w-300" placement="right" />
  <div class="pm-page" ref="page" :style="`zoom:${zoom};background:#def;width:${board.width}px;height:${board.height}px;position:relative;overflow:visible;border:${selected==board?'1px solid #3af':''};`">
    <div style="position:absolute;cursor:pointer;top:-20px;left:0;height:20px;font-size:0.8rem;" @click="selected=board" :style="selected==board?'color:#3af;font-weight:bold;':''">
      Desktop
    </div>
   
    <svg :style="`position:absolute;width:100%;height:100%;left:0;top:0;bottom:0;right:0;zoom:${(w-300)/w};`">
      <template v-for="(a,i) in new Array(24 * maxRows)">
        <rect 
          :key="i" 
          :x="padding+ parseInt(i%24)*(unitx+gutter)" 
          :y="padding+ parseInt(i/24)*(unity+gutter)"
          :width="unitx"
          :height="unity"
          style="fill:#eeeeeeaa;"
        ></rect>
      </template>

      <rect v-if="selecting" :x="originMouse[0] - startPos[0]" :y="originMouse[1] - startPos[1]" :width="Math.abs(curMouse[0] - originMouse[0])" :height="Math.abs(curMouse[1] - originMouse[1])" style="stroke:#3af;stroke-width:2px;fill:none;"></rect>
     
    </svg>

    <div class="pm-layout pm-layout-config" style="position:relaive;z-index:10">
      <BaseEmpty  v-if="loading">
        <BaseLoading></BaseLoading>
      </BaseEmpty>
      <div class="pm-fix" v-if="!loading">
        <template v-for="p in panels.filter(p=>p.option && p.option.fixed)">
          <div class="pm-panel" :style="`
            left:${p.x * (unitx+gutter) }px;
            top:${p.y * (unity+gutter)}px;
            width:${p.w * (unitx+gutter) - gutter}px;
            height:${p.h * (unity+gutter) - gutter}px;
            opacity:1;
            overflow:hidden;z-index:10;
          `" :key="p.id">
              <keep-alive>
              <component :is="p.control" @event="handleEvent" :data="p.data" :env="env" :option="p.option"  />
              </keep-alive>
          </div>
        </template>
      </div>
      <div class="pm-content"  ref="canvas" @mousedown.prevent="onMouseDown"  :class="flex?'pm-content-flex':''"  v-if="!loading"> 
       
        <template v-for="(p,i) in panels.filter(p=>!p.option || !p.option.fixed)">
          <div class="pm-panel" 
          :data-index="i+1"
          :class="{'pm-panel-selected':selected == p}"
          :style="(!flex?`left:${padding + p.x * (unitx+gutter) }px;
            top:${padding + p.y * (unity+gutter)}px;
            width:${p.w * (unitx+gutter) - gutter}px;
            height:${p.h * (unity+gutter) - gutter}px;
            opacity:1;z-index:10;
             
              zoom:${(w-300)/w};
            overflow:hidden;display:${loading?'none':'block'};`:`position:relative;
          z-index:10;
       
            width:calc(${(p.w *100/24)}% - ${gutter}px);
            min-height:${p.h * unity}px;
            zoom:${(w-300)/w};
            overflow:hidden;display:${loading?'none':'block'};
          margin:${gutter/2}px
          `) + ';'+panelStyles
          " :key="p.id"
          >
              <div style="position:absolute;top:-2px;height:5px;left:0;right:0;cursor:n-resize;"></div>
               <div style="position:absolute;right:-2px;width:5px;top:0;bottom:0;cursor:e-resize;"></div>
               <div style="position:absolute;bottom:-2px;height:5px;left:0;right:0;cursor:n-resize;"></div>
               <div style="position:absolute;left:-2px;width:5px;top:0;bottom:0;cursor:e-resize;"></div>
              <div style="position:absolute;left:0;top:0;background:#33333333;color:#fff;padding:2px 10px;">{{p.control}} {{p.x}} {{p.y}}</div>
              <keep-alive>
              <component :is="p.control" @event="handleEvent" :data="p.data" :env="env" :option="p.option"  />
              </keep-alive>
          </div>
        </template>
      </div>
      </div>
      <slot></slot>
    </div>
  </div>
  </div>
</template>

<script>
import responsableMixin from '@/mixins/responsable'
import {mapGetters} from 'vuex'
export default {
  data(){
    return {
      loading:false,
      selected:{},
      selecting:false,
      clientWidth:0,
      draggingCanvas:false,
      clientHeight:0,
      unitx:0,
      unity:0,
      unit:100,
      maxColumns:24,
      maxRows:11, 
      dragging:false,
      zoom:0.5,
      board:{
        id:5,
        width:1920,
        height:1080,
        name:"布局方案",
        subs:[{
          id:51,
          name:"通用(Default)",
          subs:[{
            id:111,
          x:0,y:0,w:6,h:3,control:"PanelWeather"
        },
        {
          id:112,
          x:0,y:3,w:5,h:3,control:"PanelWeather"
        },
        {
          id:113,
          x:0,y:6,w:4,h:3,control:"PanelWeather"
        },
        {
          id:114,
          x:6,y:0,w:6,h:2,control:"PanelWeather"
        },
        {
          id:115,
          x:6,y:3,w:6,h:2,control:"PanelWeather"
        },
        {
          id:116,
          x:6,y:6,w:6,h:1,control:"PanelWeather"
        },
        {
          id:117,
          x:6,y:7,w:6,h:0.5,control:"PanelWeather"
        },
        {
          id:118,
          x:6,y:8,w:6,h:0.3,control:"PanelWeather"
        },
        {
          id:119,
          x:12,y:0,w:6,h:2,control:"PanelWeather"
        },
        {
          id:120,
          x:12,y:3,w:6,h:2,control:"PanelWeather"
        },
        {
          id:121,
          x:12,y:6,w:6,h:2,control:"PanelWeather"
        },
        ]
        }]
       
      },
      originMouse:[0,0],
      startPos:[0,0],
      endPos:[0,0],
      curMouse:[0,0]
    }
  },
  mixins:[responsableMixin],
  props:{
    'panels':{
      type:Array,
      default:()=>[]
    },'padding':{
      type:Number,
      default:0
    },'gutter':{
      type:Number,
      default:10
    },'env':{
      type:Object,
      default:()=>({})
    },'flex':{
      type:Boolean,
      default:false
    },'panel-styles':{
      type:String
    }
  },
  mounted(){
    window.addEventListener('resize',this.resetLayout)
  
      this.calcLayout()
      this.resetLayout()
    window.removeEventListener('mousemove',this.onMouseMove)
    window.addEventListener('mousemove',this.onMouseMove)
    window.removeEventListener('mouseup',this.onMouseUp)
      window.addEventListener('mouseup',this.onMouseUp)
      window.addEventListener('keyup',this.onKeyUp)
  },
  activated(){
     this.$nextTick(e=>{
       this.calcLayout()
      this.resetLayout()
    })
  },
  computed:{
    ...mapGetters("session",['session']),
    nodes(){
      return this.panels
    },
    zoomOptions(){
      const zooms = [0.5,0.6,0.7,0.8,0.9,1,1.1,1.2,2,4]
      let o = []
      let index = zooms.findIndex(v=>v >= this.zoom)
      if(index == -1)
        index = this.zooms.length
      
      if(zooms[index] == this.zoom){
        o = zooms
      }else{
        o = [...zooms]
        o.splice(index,0,this.zoom)
      }
    
      return o.map(value=>({
            value,
            label:parseInt(value*100) + '%'
          }))
    }
  },
  methods:{
    onZoomCanvas(e){
      if(e.altKey){
      if(e.wheelDelta > 0 ){
        this.zoom += 0.07
      }else  if(this.zoom >= 0.3){
       
        this.zoom -= 0.07
      }
      }

      
    },
    resetLayout(){
     this.loading=true
       this.clientWidth = this.$el.clientWidth
    this.clientHeight = this.$el.clientHeight

    
    let ratio = this.clientHeight / this.clientWidth 
    this.maxColumns = ''
    this.unitx = (this.clientWidth + this.gutter- 2 * this.padding) / 24 - this.gutter 
     //(this.clientHeight + this.gutter ) / 11 - this.gutter 
     
    let unity = this.layoutScrollable?(this.clientHeight) / this.clientWidth * this.unitx - this.gutter:(this.clientHeight + this.gutter - 2 * this.padding)  / parseInt(24 * ratio) - this.gutter

    this.maxRows = Math.ceil(this.h / unity)
   

    if(this.clientHeight - 11 * unity <= 250){
       unity = (this.clientHeight + this.gutter - 2* this.padding ) / 11 - this.gutter 
      this.maxRows = 11
    }
    this.unity = unity
   
    setTimeout(()=>{
       this.loading =false
       this.$forceUpdate()
    },500)
   },
     handleEvent(e){
        this.$emit('event',e)
      },
      onResize(){
        this.resetLayout()
      },
      onDragCanvas(e){
          this.draggingCanvas = true
          this.originMouse = [e.pageX,e.pageY]
          this.startPos =  [parseInt(this.$refs.page.style.left) || 0 , parseInt(this.$refs.page.style.top) || 0]
   
      },
      onKeyDown(){

      },
      onKeyUp(e){
        if(e.code == 'Delete' && this.selected && this.selected.control){
         
          let index = this.panels.findIndex(v=>v.id == this.selected.id)
          
          this.panels.splice(index,1)
          this.$forceUpdate()
          this.selected = null
        }
      },
      onMouseDown(e){
        // middle mouse drag canvas
        
        if(e && e.target && e.target.className && e.target.className.includes('pm-panel')){
       
          this.selected = this.panels[parseInt(e.target.dataset.index) - 1]
          
           
          this.originMouse = [e.pageX,e.pageY]
          this.startPos = [this.selected.x,this.selected.y]
           this.dragging = true
        }else{
          
           
          if(e.button == 0){
              this.selected = null
          this.selecting = true
          this.originMouse = [e.offsetX,e.offsetY]
          
          }
       
        }
       
      },
      onMouseMove(e){
        if(this.draggingCanvas && e.button == 0){
          this.$refs.page.style.left = this.startPos[0] +  e.pageX - this.originMouse[0] + 'px'
          this.$refs.page.style.top = this.startPos[1] + e.pageY - this.originMouse[1] + 'px'
          return
        }
        if(this.dragging && e && e.buttons == 1 && this.selected){
          if(this.selected && this.originMouse[0] !=0 && this.originMouse[1] !== 0){
              let nx = this.startPos[0] + ( e.pageX - this.originMouse[0]) /this.zoom/this.unitx
            
              if(nx <= 0)
                nx = 0
              else if(nx >= 24 - this.selected.w){
                nx = 24- this.selected.w
              }

              nx = parseInt(nx * 10) / 10
              
              
              
           
              let ny = this.startPos[1] +( e.pageY - this.originMouse[1] ) /this.zoom /this.unity

              if(ny <= 0)
                ny = 0
              else if(ny >= this.maxRows - this.selected.h){
                ny = this.maxRows - this.selected.h
              }

               ny = parseInt(ny * 10) / 10
               if(isNaN(nx)) nx = 0
               if(isNaN(ny)) ny = 0
                this.endPos = [nx,ny]
                this.curMouse = [e.pageX,e.pageY]
                this.$set(this.selected,'x',nx)
               this.$set(this.selected,'y',ny)
          }
        }else if(this.selecting){
           this.curMouse = [e.pageX,e.pageY]
        }

        e.preventDefault()
      },
      onMouseUp(e){
        if(this.draggingCanvas){
          this.draggingCanvas = false 
          return
        }
        if(this.dragging && this.selected){
          this.startPos = [this.selected.x,this.selected.y]
          this.dragging = false
        }else{
           this.selecting = false
        this.originMouse = [0,0]
          this.curMouse = [0,0]
          this.startPos = [0,0]
        }
       
      },
      renderTreeNode(h,node){
        let tools = node.control?[]:[h('BaseIcon',{props:{icon:'md-add',size:"15",color:"#aaa"},style:"position:absolute;right:0;"})]
        return h('div',{class:"flex-wrap flex-between",style:"height:25px;"},[node.name || node.control, tools])
      }
  }
}
</script>

<style lang="less">
.pm-panel-flex{
  display: flex;
}
.pm-panel-editing-wrap:hover{
  filter:brightness(1.2);
  cursor: pointer;
}

.pm-panel-selected{
  background:#fbbd2162 !important;
}
</style>