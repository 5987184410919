
<style lang="less">
.u-group-name {
  font-weight: bold;
  color: #333;
  padding: 5px 10px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 2px;
}

.u-group-name:hover {
  background: #39f;
  color: #fff;
  i {
    color: #fff !important;
  }
}
.u-group-item {
  padding: 5px;
  white-space: nowrap;
  cursor: pointer;
  margin: 2px;
  .count {
    font-size: 8px;
    padding: 0 5px;
    background: #aaa;
    color: #fff;
    margin-left: 5px;
    font-family: system;
  }
}

.u-group-item:hover,
.u-group-item-selected {
  color: var(--primary) !important;
  background: var(--bg2);
  font-weight: bold;
  .count {
    background: rgb(15, 105, 183);
    color: rgb(247, 247, 247);
  }
}

.edit-icon {
  cursor: pointer;
}

.edit-icon:hover {
  color: #39f;
}

.ivu-dropdown-menu {
  > div {
    max-height: 800px !important;
    max-width: 800px !important;
  }
}

.cm-table{
  .ivu-table{
    font-family: system !important;
    .ivu-table-body::-webkit-scrollbar{
      width:7px;
      height:7px;
    }
  }
}
</style>
<template>
  <div class="hs-container cm-table" style="height:calc(100% - 15px);margin-top:10px;">
    <div class="flex-wrap flex-between" style="margin-bottom:10px;">
      <div class="flex-wrap split5">
        <Input search style="width:220px" v-model="searchText" clearable />
        <!-- <Button icon="md-add" type="primary">新建</Button> -->
        <Dropdown trigger="click" transfer placement="bottom-start">
          <Button icon="ios-funnel" :type="Object.values(selected_filters).filter(v=>v).length>0?'primary':'default'">筛选
            <Icon type="md-arrow-dropdown" /></Button>
          <DropdownMenu slot="list">
            <div style="padding:0 10px;max-width:700px;">
              <div class="flex-wrap">
                <div class="u-group-name">全部</div>
                <div class="u-group-item" @click.stop="selected_filters={}">清除</div>
              </div>
              <template v-for="f in filters">
                <div :key="f.title" class="flex-wrap align-start">
                  <div class="u-group-name">{{f.title}}</div>
                  <div class="u-group-item" :class="{
                    'u-group-item-selected':inArray(f.values,selected_filters[f.key])
                  }" style="color:#3af" @click.stop="selected_filters[f.key] = f.values.map(v=>v.value)">全选</div>
                  <div class="u-group-item" style="color:#3af" @click.stop="selected_filters[f.key] = []">清除</div>
                  <div class="flex-wrap" style="overflow:hidden;flex-wrap:wrap;align-content:flex-start">
                    <template v-for="item in f.values">
                      <div :key="item.key" class="u-group-item" :class="{
                      'u-group-item-selected':selected_filters[f.key] && selected_filters[f.key].includes(item.value)
                    }" @click.stop="handleSelectFilter(f.key,item.value)" :style="item.count > 0?'#333':'#aaa'">{{item.name}}</div>

                    </template>

                  </div>
                </div>
              </template>
            </div>
          </DropdownMenu>
        </Dropdown>
        <Dropdown trigger="click" transfer placement="bottom-start" v-if="!selected_notice">
          <Button icon="md-checkbox-outline">列组合
            <Icon type="md-arrow-dropdown" /></Button>
          <DropdownMenu slot="list">
            <div style="padding:0 10px;max-width:700px;">
              <div class="flex-wrap">
                <div class="u-group-name">全部</div>
                <div class="u-group-item" style="color:#3af" @click.stop="handleClearGroup()">清除</div>
              </div>
              <template v-for="g in groups">
                <div :key="g.name" class="flex-wrap align-start">
                  <div class="u-group-name">{{g.name}}</div>
                  <div class="u-group-item" :class="{
                    'u-group-item-selected':inArray(selected_cols,g.items.map(v=>v.key))

                  }" @click.stop="handleSelectAllGroup(g)" style="color:#3af">全选</div>
                  <div class="u-group-item" @click.stop="handleClearGroup(g)" style="color:#3af">清除</div>
                  <div class="flex-wrap" style="overflow:hidden;flex-wrap:wrap;align-content:flex-start">
                    <template v-for="item in g.items">
                      <div :key="item.key" class="u-group-item" :class="{
                      'u-group-item-selected':selected_cols.includes(item.key)
                      }" @click.stop="handleSelectColumn(item.key)">{{item.title}}</div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </DropdownMenu>
        </Dropdown>
        <Dropdown trigger="click" transfer placement="bottom-start" v-if="!selected_notice">
          <Button icon="md-list" :type="mappedTmpl?'info':'default'">
            {{mappedTmpl?mappedTmpl.name:'未选择模板'}}
            <Icon type="md-arrow-dropdown" />
          </Button>
          <DropdownMenu slot="list">
            <div style="max-width:700px;">
              <template v-for="t in tmpls">
                <div class="flex-wrap u-group-name" @click="selected_cols=[...t.fields];selected_filters={...t.filters||{}};localSaveTmpl();SaveState()" style="min-width:150px;cursor:pointer;font-weight:normal;" :key="t.name">
                  <BaseIcon :icon="t.icon || 'overview'" style="margin-right:5px" color="#aaa" /> {{t.name}}</div>
                <Divider style="margin:3px 0" v-if="t.name=='管理视图'" :key="'_div'+t.name"></Divider>
              </template>
            </div>
          </DropdownMenu>
        </Dropdown>
        <Button icon="md-settings" @click="handleEditTmpl" v-if="!selected_notice">编辑模板</Button>
        <Button type="primary" v-if="!mappedTmpl && !selected_notice" @click="showModalTmpl=true">保存为自定义模板</Button>
        <Button type="error" icon="md-trash" v-if="mappedTmpl && my_tmpls.includes(mappedTmpl) && !selected_notice" @click="handleDeleteTmpl(mappedTmpl)">删除</Button>

        <Dropdown trigger="click" transfer placement="bottom-start">
          <ButtonGroup>
            <Button type="warning" :disabled="notices.length == 0">
              <div class="flex-wrap">{{selected_notice?selected_notice.name:'待处理'}} <div class="u-count" style="margin-left:5px;margin-right:5px;background:#33333333;" v-if="selected_notice"> {{selected_notice.count}}</div>
                <Icon type="md-arrow-dropdown" style="margin-left:5px" />
              </div>
            </Button>
            <Button icon="md-close" style="border-left:1px solid var(--border)" type="warning" v-if="selected_notice" @click.stop="selected_notice=null"></Button>
          </ButtonGroup>
          <DropdownMenu slot="list">
            <div style="padding:0 10px;max-width:700px;">
              <template v-for="(t,i) in notices">
                <div class="flex-wrap flex-between u-group-name" @click="selected_notice = t" style="min-width:180px;height:25px;margin:2px;cursor:pointer;font-weight:normal;" :key="t.name"> {{t.name}} <div class="u-count" style="margin-left:5px"> {{t.count}}</div>
                </div>

              </template>
            </div>
          </DropdownMenu>
        </Dropdown>

      </div>
      <div class="flex-wrap split5">
        <Button icon="md-refresh" @click="getData(true)" type="success">刷新</Button><Button @click="exportExcel">导出 Excel</Button></div>
    </div>
    <div style="height:calc(100% - 45px);margin:5px;position:relative;">
      <BaseTable ref="table" style="font-family:PingFang-SC !important" :columns="computedComlumns" :page-size="50" :data="filteredData" :selectable="false" @event="handleTableEvent" :option="{
          summary:true
        }"></BaseTable>
      <Spin fix v-if="loading">
        <div style="padding:20px;min-width:200px">
          
            <Progress status="active" :percent="loading.percent" style="margin-bottom:10px" />
            {{loading.status}}
        </div>
      </Spin>
    </div>

    <Modal v-model="showModalCharger" title="修改负责人" width="300" footer-hide :mask-closable="false">
      <div style="padding:10px">
        <BaseUserSelectGrouped v-model="current.charger_id" editable :option="{users}" />
        <div class="flex-wrap flex-right split10" style="margin-top:10px">
          <Button type="primary" @click="SaveCharger">提交</Button>
          <Button @click="showModalCharger=false">取消</Button>
        </div>
      </div>
    </Modal>

    <Modal width="800" v-model="showModalEditTmpl" title="编辑模板" footer-hide>
      <Row :gutter="10">
        <Col :span="12">
        <div>
          <Input search placeholder="搜索" v-model="edit_tmpl_search" style="width:calc(100% - 40px);margin-bottom:10px;" />
        </div>
        <div style="width:100%;height:500px;overflow-y:auto;background:var(--bg3);padding:5px;">
        
          <template v-for="g in edit_groups">
            <div :key="'_group'+g.name">
              <div style="color:var(--subtext3)">{{g.name}}</div>
              <div>
                  <Draggable :value="g.items" :group="{
                      name:'edit_tmpls',
                      pull:'clone',
                      put:false
                    }"  
                     dataIdAttr="key"
                    :animation="500" 
                    :clone="handleCloneEditColumn">
                      <template v-for="col in g.items">
                        <div class="edit-column" v-if="col" :key="'_col'+col.key">
                          {{col.title}}<template v-if="col.desc">:<span style="color:var(--subtext3)">{{col.desc}}</span></template>
                        </div>
                      </template>
                  </Draggable>
              </div>
            </div>
          </template>
        </div>
        </Col>
        <Col :span="12">
        <div class="flex-wrap">
          <Button>全部取消</Button>
        </div>
        <div style="width:100%;height:500px;overflow-y:auto;background:var(--bg3);padding:5px;margin-top:10px;">
          <div class="flex-wrap flex-between edit-column">
            <div class="flex-wrap"><div class="flex-wrap flex-center" style="background:var(--bg1);padding:1px 3px;width:25px;color:var(--text1);">定</div> <div style="width:80px;margin-left:5px;">{{cols[0].title}}</div> <template v-if="cols[0].desc"><span style="color:var(--subtext3)">{{cols[0].desc}}</span></template> </div>
                <div>
                </div>
          </div>
          <Draggable v-model="edit_tmpls"  
            style="padding-bottom:20px"
          :group="{
            name:'edit_tmpls'
            }" :animation="500">
            <template v-for="(col,index) in edit_tmpls.map(v=>cols.find(t=>t.key == v)).filter(v=>v)">
              <div class="flex-wrap flex-between edit-column" :key="'_col'+col.key">
                <div class="flex-wrap"><div class="flex-wrap flex-center" style="background:var(--bg1);padding:1px 3px;width:25px;color:var(--text1);">{{index+1}}</div> <div style="width:80px;margin-left:5px;">{{col.title}}</div> <template v-if="col.desc"><span style="color:var(--subtext3)">{{col.desc}}</span></template> </div>
                <div>
                  <BaseIcon class="edit-close" icon="md-close" color="var(--subtext3)" @click="edit_tmpls.splice(index,1)" />
                </div>
              </div>
            </template>
            <div class="flex-wrap flex-center">
            <BaseAlert style="color:var(--primary);margin-top:5px;">可拖拽至此处</BaseAlert>
            </div>
          </Draggable>
        </div>
        </Col>
      </Row>
      <div class="flex-wrap flex-right" style="margin-top:10px">
        <Button type="primary" @click="handleApplyColumn">应用</Button>
      </div>
    </Modal>

    <Modal v-model="showModalAssistants" title="修改协助人" width="300" footer-hide :mask-closable="false">
      <div style="padding:10px">

        <BaseUserSelectGrouped v-model="current.assistants" editable :option="{users,multiple:true}" />
        <div class="flex-wrap flex-right split10" style="margin-top:10px">
          <Button type="primary" @click="SaveAssistants">提交</Button>
          <Button @click="showModalAssistants=false">取消</Button>
        </div>
      </div>
    </Modal>

    <Modal v-model="showModalFocus" title="关注原因" width="400" footer-hide>
      <div class="flex-wrap split10" style="padding:10px">
        <BaseMark v-model="formData.focused" editable></BaseMark>
        <Input v-model="formData.focused_reason" style="width:calc(100% - 100px)" />
        <Button type="primary" @click="handlePatchFocused">提交</Button>
      </div>
    </Modal>

    <Modal v-model="showContract" title="查看合约内容" width="800" footer-hide>
      <div style="padding:10px">
        <template v-for="c in current.contracts">
          <div :key="c.id">
            <div style="font-weight:bold;margin:5px 0;">{{c.name}}</div>
            <div class="flex-wrap">
              <div class="u-icon" style="width:auto;padding:0 5px;font-size:10px;">{{c.contract_type}}</div>
              <div class="u-icon" style="width:auto;padding:0 5px;font-size:10px;">{{c.business_type}}</div>
            </div>
            <div style="padding:10px;background:#fff;margin-top:5px;">
              <template v-for="(t,i) in c.conditions.filter(v=>v.type == '收费节点')">
                <Row class="flex-wrap" style="margin-bottom:2px" :key="t.id">
                  <Col :span="1"><span class="u-icon">{{i+1}}</span></Col>
                  <Col :span="3"><span style="color:#39f;margin-right:10px;">{{t.type}}</span>
                  </Col>
                  <Col :span="14">{{t.detail}}</Col>
                  <Col :span="2" align="right">{{t.percent?t.percent.toFixed(2):'-'}} %</Col>
                  <Col :span="4" align="right">¥ {{formatSalarySemicolon(t.amount)}}</Col>
                </Row>
              </template>
              <Row style="border-top:1px solid #ddd;padding-top:5px;margin-top:5px;">
                <Col :span="24" align="right">合计：¥ {{formatSalarySemicolon(c.amount)}}</Col>
              </Row>
              <div style="margin:10px 0;padding:10px;background:#fff;" v-if="c.conditions.filter(v=>v.type == '特殊条款').length>0" />
              <template v-for="(t,i) in c.conditions.filter(v=>v.type == '特殊条款')">
                <div class="flex-wrap" style="margin-bottom:2px" :key="t.id"><span class="u-icon">{{i+1}}</span> <span style="color:#39f;margin-right:10px;">{{t.type}}</span> {{t.detail}}</div>
              </template>
            </div>
          </div>
        </template>

      </div>
    </Modal>

    <Modal v-model="showModalTmpl" title="保存为模板" footer-hide>
      <div class="flex-wrap split10" style="margin:10px;flex-wrap:nowrap;">
        <Input v-model="tmpl_name" auto-focus placeholder="输入模板名称" style="flex-shrink:1;flex-grow:1;width:auto;" />
        <Button type="primary" @click="handleSaveTmpl">提交</Button>
      </div>
    </Modal>

    <Modal v-model="showModalDeps" title="修改归属部门" footer-hide :mask-closable="false">
      <div style="padding:20px">
        <CheckboxGroup v-model="current.deps">
          <div class="flex-col" style="align-items:flex-start">

            <template v-for="d in deps">
              <div :key="d.id">
                <Checkbox :label="d.id">{{d.name}}</Checkbox>
              </div>
            </template>
          </div>
        </CheckboxGroup>
        <div class="flex-wrap flex-between" style="margin-top:10px">
          <Button @click="current.deps = []">清除</Button>
          <div class="flex-wrap flex-right split10">
            <Button type="primary" @click="SaveDeps">提交</Button>
            <Button @click="showModalDeps=false">取消</Button>
          </div>
        </div>

      </div>
    </Modal>

    <Modal v-model="showModalAdjust" title="一键调整" footer-hide width="300">
      <div style="padding:20px">
        <div style="margin-bottom:10px">项目：{{formData.name}}</div>
        <div class="flex-wrap flex-between">
          <Input v-model="formData.adjust_amount" number style="width:calc(100% - 80px)" />
          <Button type="primary" @click="handlePatchAdjustAmount">提交</Button>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Draggable from 'vuedraggable'
import * as XLSX from 'xlsx'
import { intersection } from 'lodash'
import moment from 'moment'
import UTIL from '@/utils'
export default {
  data() {
    return {
      tmpl_name: "",
      showModalFocus: false,
      edit_tmpl_search: "",
      showModalEditTmpl: false,
      edit_tmpls: [],
      showContract: false,
      showModalTmpl: false,
      showModalDeps: false,
      showModalAssistants: false,
      showModalCharger: false,
      modified: null,
      current: {},
      selected_key: null,
      selected_id: null,
      selected_notice: null,
      searchText: "",
      status: ['准备中', '进行中', '已竣工', '已结束', '已中止'],
      states: ['待处理', '处理中', '已办结', '已关闭'],
      business_types: [
				"全过程咨询",
				"项目管理",
				"房建监理",
				"市政监理",
        "装修工程",
				"造价咨询",
				"BIM咨询",
				"招标代理",
				"对外合作",
				"其他",
			],
			business_type_colors: [
				"orange",
				"#369",
				"green",
				"darkred",
        "#388",
				"purple",
				"#3af",
				"grown",
				"pink",
				"#333",
			],
      state_colors: ['var(--subtext3)', 'var(--primary)', 'var(--success)', 'var(--error)'],
      selected_cols: [],
      selected_filters: {
        status:["准备中","进行中"]
      },
      my_tmpls: [],
      showModalAdjust: false,
      formData: {}
    }
  },
  mounted() {
    setTimeout(() => {
      this.getData()
      this.getTmpls()
      this.$store.dispatch("session/getUsers")
      this.$store.dispatch("session/getDeps")
      this.RestoreState()
    }, 500)

  },
    watch:{
    $route:{
      handler(v){
        let query =  this.$route.query.q
        if(query){
          let tmpl = this.tmpls.find(v=>v.name==query)
          if(tmpl){
           
            this.selected_cols = [...tmpl.fields]
            this.selected_filter = {status:["准备中","进行中"]} 
          }
        }
      },
      immediate:true,
      deep:true
    }
  },
  components: { Draggable },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs && this.$refs.table && this.$refs.table.calcTableHeight)
        this.$refs.table.calcTableHeight()
    })

  },
  computed: {
    ...mapGetters('session', ['session', 'users']),
    deps(){
      return this.$store.getters['session/deps'].filter(v=>v.in_business)
    },
    ...mapGetters('cm', ['projects', 'loading']),
    mappedTmpl() {
      return (this.tmpls.find(v => [...v.fields].sort().join('-') == [...this.selected_cols].sort().join('-') && JSON.stringify(v.filters || {}) == JSON.stringify(this.selected_filters)))
    },
    tmpls() {
      return [{
        name: "项目总体报表",
        icon: "contract",
        fields: ['code', 'type', 'name','charger_id','status','total_production_percent','remained_amount','year_plan_bill','year_plan_production','year_plan_hrv','year_actual_hra_bill','year_actual_hra_production','completed_at'],
        filters:{status:["准备中","进行中"]} 
      }, {
        name: "合同收费报表",
        icon: "money",
        fields:['code', 'type', 'name','charger_id','status','signed_amount','adjust_amount','amount','billed_amount','billed_percent','transfer_percent','remained_amount','plan_billed_amount_thisyear','billed_amount_thisyear','remianed_billed_amount_thisyear','bill_plans','season_actual_bill','next_month_plan_bill_1','next_month_plan_bill_2','next_month_plan_bill_3','contractCount'],
        filters:{status:["准备中","进行中"]} 

      }, {
        name: "人员报表",
        icon: "supervisor",
        fields: ['code', 'type', 'name','charger_id','status','hr_count','_hr_users','total_origin_plan_hrv','total_actual_hrv','total_plan_hr','year_origin_plan_hrv','year_actual_hrv','year_plan_hrv'],
        filters:{status:["准备中","进行中"]} 
      }, {
        name: "项目进度报表",
        icon: "Building-",
        fields: ['code', 'type', 'name','charger_id','status','operated_at','completed_at','total_plan_duration','total_remain_duration','year_end_progress','total_plan_production','total_production_percent','year_plan_production','year_predict_production','year_actual_hra_production','building_production','pb_percent','year_actual_bp','year_actual_hra_bp'],
        filters:{status:["准备中","进行中"]} 
      }, {
        name: "人均产值报表",
        icon: "user",
        fields: ['code', 'type', 'name','charger_id','status', ...this.cols.filter(v => v.group == '人均产值').map(v => v.key)],
        filters:{status:["准备中","进行中"]} 
      },  {
        name: "管理视图",
        icon: "md-create",
        fields:['code','type','name','status',...this.cols.filter(v => v.group == '管理').map(v => v.key)],

      }, ...this.my_tmpls]
    },
    notices() {
      return [ {
        name: '未绑定人员的项目',
        filter: v => !v.charger_id,
        fields: ['code', 'type','name', 'charger_id'],
        count: this.projects.filter(v => !v.charger_id).length
      }, {
        name: '无归属部门的项目',
        filter: v => !v.deps || v.deps.length == 0,
        fields: ['code', 'type', 'name','deps', 'charger_id'],
        count: this.projects.filter(v => !v.deps || v.deps.length == 0).length
      }, {
        name: "收费大于合同总额（需要调整）",
        filter: v => v.amount < v.billed_amount,
        fields: ['code', 'type', 'name','amount', 'signed_amount', 'adjust_amount', 'billed_amount', 'mod_adjust'],
        count: this.projects.filter(v => v.amount < v.billed_amount).length
      }, {
        name: "提醒关注的项目",
        filter: v => v.focused && v.focused > 0,
        fields: ['code', 'type', 'name','amount', 'status', 'charger_id', 'focused', 'focused_reason'],
        count: this.projects.filter(v => v.focused && v.focused > 0).length
      }, {
        name: "已竣工但状态未调整的项目",
        filter: v => (v.finished_at && moment(v.finished_at).isBefore(moment())) ? (v.status != '已结束') : (v.complted_at && moment(v.complted_at).isBefore(moment()) && v.status != '已竣工'),
        fields: ['code', 'type','name', 'amount', 'status', 'started_at', 'completed_at', 'finished_at', 'charger_id', 'focused', 'focused_reason'],
        count: this.projects.filter(v => (v.finished_at && moment(v.finished_at).isBefore(moment())) ? (v.status != '已结束') : (v.complted_at && moment(v.complted_at).isBefore(moment()) && v.status != '已竣工')).length
      }]
    },
    filteredData() {
      return this.projects.filter(v => {
        if (this.searchText && this.searchText.trim()) {
          let st = this.searchText.trim()
          if (!v.name || !v.name.includes(st))
            return false
        }

        if (this.selected_filters) {
          for (let key in this.selected_filters) {
            let filter = this.filters.find(v => v.key == key)
            if (Array.isArray(this.selected_filters[key]) && this.selected_filters[key].length > 0) {

              let value = filter.formatValue ? filter.formatValue(v[key]) : (v[key] || [])
              if (filter.array) {
                if (intersection(this.selected_filters[key], value).length == 0)
                  return false
              } else if (this.selected_filters[key].length > 0 && !this.selected_filters[key].includes(value))
                return false
            }
          }
        }

        if (this.selected_notice && this.selected_notice.filter) {
          return this.selected_notice.filter(v)
        }



        return true
      })
    },
    filters() {
      var that = this
      let filterTypes = [{
        title: "归属部门",
        key: "deps",
        values: [],
        array: true,

        formatter: v => (that.deps.find(t => t.id == v) || { name: (!v || v === undefined) ? "未分类" : v }).name
      }, {
        title: "项目类型",
        key: 'type',
        values: []
      }, {
        title: "项目状态",
        key: "status",
        values: [],
         sorter: (a, b) => {
          const items = ["准备中", "进行中", "已竣工", "已结束", "已关闭"]
          return items.findIndex(t => t == a.name) > items.findIndex(t => t === b.name) ? 1 : -1
        },
      }, {
        title: "签约时间",
        key: "signed_at",
        formatValue: e => moment(e).isValid() ? moment(e).year().toString() : '其他',
        values: []
      }, {
        title: "竣工时间",
        key: "completed_at",
        formatValue: e => moment(e).isValid() ? moment(e).year().toString() : '其他',
        values: []
      }, {
        title: "收费进度",
        key: "billed_percent",
        formatValue: e => {
          e = e * 100
          if (e < 20) {
            return "20%以下"
          } else if (e < 50) {
            return "20%"
          } else if (e < 70) {
            return "50%"
          } else if (e < 100) {
            return "70%"
          } else {
            return "100%"
          }
        },
        sorter: (a, b) => {
          const items = ["20%以下", "20%", "50%", "70%", "100%"]
          return items.findIndex(t => t == a.name) > items.findIndex(t => t === b.name) ? 1 : -1
        },
        values: []
      },{
        title:"签约计划",
        key:"has_plan",
        formatValue:e=>{
          if(e)
            return '有'
          else
            return '无'
        }
      }, {
        title: "项目规模",
        key: "amount",
        formatValue: e => {
          if (e < 1000000) {
            return "100万以下"
          } else if (e < 5000000) {
            return "100万"
          } else if (e < 10000000) {
            return "500万"
          } else if (e < 20000000) {
            return "1000万"
          } else {
            return "2000万以上"
          }
        },
        sorter: (a, b) => {
          const items = ["100万以下", "100万", "500万", "1000万", "2000万以上"]
          return items.findIndex(t => t == a.name) > items.findIndex(t => t === b.name) ? 1 : -1
        },
        values: []
      }]

      let map = {}
      filterTypes.forEach(t => {
        map = {}
        this.projects.forEach(v => {
          let value = t.formatValue ? t.formatValue(v[t.key]) : v[t.key]
          if (t.array && Array.isArray(value)) {
            value.forEach(k => {
              if (!map[k])
                map[k] = 1
              else map[k++]++
            })
          } else {
            if (!map[value])
              map[value] = 1
            else map[value]++
          }
        })

        t.values = Object.keys(map).map(key => {
          return {
            name: t.formatter ? t.formatter(key) : key,
            value: key,
            count: map[key]
          }
        }).filter(v => v.name != "0")

        t.values = t.values.sort(t.sorter ? t.sorter : ((a, b) => a.name > b.name ? 1 : -1))
      })

      return filterTypes
    },
    cols() {
      var that = this
      return [{
        type: 'index',
        title: "序",
        key: "index",
        width: 80,
        desc:"项目顺序号",
        option: {

          align: "center",
        }
      }, {
        type: 'text',
        key: "code",
        title: "项目编码",
        desc:"项目编码",
        width: 80,
        group: '项目',
        option: {

          align: "center",
        }
      }, {
					type: "text",
					title: "业务类型",
					key: "type",
					group: "项目",
					width: 100,
					option: {
						align: "center",
					},
					render: (h, { row }) => {
            let iconArrow = h("BaseIcon",{props:{icon:"ios-arrow-down"},style:"margin-left:3px;margin-right:5px;"})
						let domContent =  h(
							"div",
							{ class: "flex-wrap flex-right flex-line", style: { height: "40px;padding:0 3px;" } },
							[
								h(
									"div",
									{
										style: {
											width: "86px",
											borderRadius: "5px",
											textAlign: "right",
											background:
												this.business_type_colors[
													this.business_types.findIndex((v) => v == row.type)
												],
											color: "#fff",
										},
									},
									[row.type, iconArrow]
								),
							]
						);
            const types = ['全过程咨询','项目管理', '市政监理','房建监理','造价咨询','装修工程',  'BIM咨询', '对外合作',   '其他',]
            	let domListItems = types.map((v, i) =>
							h(
								"DropdownItem",
								{ props: { name: v, selected: row.status == v } },
								v
							)
						);
						let domList = h("DropdownMenu", { slot: "list" }, domListItems);
						let domDropDown = h(
							"Dropdown",
							{
								props: { trigger: "click", size: "small" },
								on: {
									"on-click": (type) => {
										this.$api
											.patch("/projects/" + row.id, { type,business_type:type })
											.then((res) => {
												let updateInfo = res.data.data;
												updateInfo.type = type;
												updateInfo.id = row.id;
												this.updateItem(updateInfo);
												this.$Notice.success({
													title: "修改成功",
													content: type,
												});
											});
									},
								},
							},
							[domContent, domList]
						);
						return domDropDown;
					
					},
				}, {
        type: 'text',
        title: "项目名称",
        desc:'项目名称',
        group: '项目',
        linkEvent: "open",
        key: "name",
        width: 280,
      }, {
        type: 'number',
        title: "签约金额",
        desc:"合同签订时的金额",
        key: "signed_amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          highlight:true,
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "合同金额",
        desc:"经过各类调整和附加合同之后计算总额",
        key: "amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "调整金额",
        desc:"相对于签约金额的调整差额",
        key: "adjust_amount",
        group: '合约',
        width: 120,
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },{
        type:"number",
        title:"总产值",
        key:"total_plan_production",
        width:140,
        group:"产值",
        option:{
          type:"amount"
        }
      },{
        type:"number",
        title:"已完成产值比例",
        width:140,
        key:"total_production_percent",
        group:"产值",
        option:{
          type:"percent",
          percentToValue:100,
          formatted_value:true
        }
      },{
        type:"number",
        title:"年计划产值",
        key:"year_plan_production",
        width:140,
        group:"产值",
        option:{
          type:"amount"
        }
      },{
        type:"number",
        title:"年预测产值",
        key:"year_predict_production",
        width:140,
        group:"产值",
        option:{
          type:"amount"
        }
      },{
        type:"number",
        title:"年人均产值",
        key:"year_hra_production",
        width:140,
        group:"产值",
        option:{
          type:"amount"
        }
      }, {
        type: "text",
        title: "状态",
        desc:"项目的进行状态",
        key: "status",
        group: "项目",
        width: 80,
        option: {
          align: "center",
        },
        render: (h, { row }) => {
          const status_colors = {
            "准备中": "#aaa",
            "进行中": "var(--primary)",
            "已竣工": "var(--active)",
            "已结束": "var(--success)",
            "已中止": "var(--error)"
          }
          let iconArrow = h("BaseIcon", { props: { icon: "ios-arrow-down" }, style: "margin-left:3px;" })
          let domContent = h(
            "div",
            { class: "flex-wrap flex-center", style: { height: "40px" } },
            [
              h(
                "div",
                {
                  style: {
                    width: "65px",
                    borderRadius: "50px",
                    textAlign: "center",
                    background: status_colors[row.status],
                    color: "#fff",
                  },
                },
                [row.status, iconArrow]
              ),
            ]
          );

          let domListItems = this.status.map((v, i) =>
            h(
              "DropdownItem",
              { props: { name: v, selected: row.status == v, value: v } },
              v
            )
          );
          let domList = h("DropdownMenu", { slot: "list" }, domListItems);
          let domDropDown = h(
            "Dropdown",
            {
              props: { trigger: "click", size: "small" },
              on: {
                "on-click": (status) => {
                  this.$api
                    .patch("/projects/" + row.id, { status })
                    .then((res) => {
                      let updateInfo = res.data.data;
                      updateInfo.status = status;
                      updateInfo.id = row.id;
                      this.updateItem(updateInfo);
                      this.$Notice.success({
                        title: "修改成功",
                        content: status,
                      });
                    });
                },
              },
            },
            [domContent, domList]
          );
          return domDropDown;
        },
      }, {
        key: "partA",
        group: "合约",
        title: "业主单位",
        desc:"合同的甲方单位",
        type: "text",
        width: 200
      }, {
        type: 'time',
        group: '合约',
        desc:"合同的中标日期",
        title: "中标日期",
        key: "bid_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'time',
        group: '合约',
        title: "签约日期",
         desc:"合同的签约日期",
        key: "signed_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        group: '合约',
        title: "合约条款",
        key: "contractCount",
        width: 120,
        render(h, { row }) {
          return h('a', {
            style: "color:#39f;cursor:pointer;",
            on: {
              click() {
                that.showContract = true
                that.current = row
              }
            }
          }, `查看 (${row.conditions.length})`)
        }
      }, {
        type: 'time',
        group: '项目',
        title: "开始日期",
        key: "started_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'time',
        title: "开工日期",
        group: "项目",
        key: "operated_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'time',
        title: "竣工日期",
        group: "项目",
        key: "completed_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'time',
        title: "结束日期",
        group: "项目",
        key: "finished_at",
        width: 120,
        option: {
          type: "date"
        }
      }, {
        type: 'text',
        group: "管理",
        title: "关联项目",
        key: "zzl_id",
        width: 220
      }, {
        group: "管理",
        title: "归属事业部",
        type: "text",
        key: "deps",
        width: 220,
        render(h, { row }) {
          let groups = row.deps.map(v => that.deps.find(g => g.id == v) || {
            name: v,
            error: true
          })

          let domGroups = groups.map(v => v.error ? h('span', { style: "color:red;" }, v.name) : h('span', { style: "color:#39f;margin:0 5px;" }, v.name))

          let domEdit = h("BaseIcon", {
            props: {
              icon: "md-create"
            },
            class: "edit-icon",
            on: {
              click() {
                that.EditDeps(row)
              }
            },
            style: {
              marginLeft: "5px"
            }          })
          return h("div",
            {              class: "flex-wrap flex-center", style: {
                flexWrap: "wrap"
              }            },
            [...domGroups, domEdit])
        }
      },
      {
        key: "billed_amount",
        group: "收费",
        title: "开票金额",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "billed_percent",
        group: "收费",
        title: "开票占比",
        type: "number",
        option: {
          type: "percent"
        },
      },
      {
        key: "transfer_amount",
        group: "收费",
        title: "到账金额",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "transfer_percent",
        group: "收费",
        title: "到账占比",
        type: "number",
        option: {
          type: "percent"
        }
      },
      {
        key: "remained_amount",
        group: "收费",
        title: "合同余额",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },

      //　收费计划部分
      {
        key: "plan_billed_amount_thisyear",
        group: "收费计划",
        title: "今年计划收款",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "billed_amount_thisyear",
        group: "收费计划",
        title: "今年已开票",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "remianed_billed_amount_thisyear",
        group: "收费计划",
        title: "今年尚可开",
        type: "number",
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      },
      {
        key: "bill_plans",
        group: "收费计划",
        title: "收费计划",
        width: 250,
        render(h, { row }) {
          if(!row.bill_plans || row.bill_plans.length == 0)
            return h("span","-")
          let doms = row.bill_plans.map((v, i) => h('div', { class: "flex-wrap split5", style: `padding:0 5px;${v.finished_at ? 'color:green;' : (moment(v.plan_finished_at).isBefore(moment()) ? 'color:red' : '')}` }, [i + 1, " ", UTIL.formatSalary(v.amount), " ", v.plan_finished_at ? moment(v.plan_finished_at).format("YYYY-MM-DD") : "无日期", " ", v.name]))
          let content = h('div', { style: "text-align:left;" }, doms)
          let icon = h('BaseIcon',{props:{icon:'ios-eye'},style:"margin-left:3px"})
          let trigger = h('a',{style:"padding:2px 5px;background:var(--primary);color:var(--hover-text);border-radius:3px;"},[`查看(${doms.length})`])
          let contentWrap = h("DropdownMenu",{slot:"list"},[content])
          return h("Dropdown",[trigger,contentWrap])
        }
      },
      {
        type:"season_actual_bill",
        title:"本季度已开票",
        group: "收费计划",
        width:140,
        option:{
          type:"fullAmount"
        }
      },
      {
        type:"number",
        key:"next_month_plan_bill_1",
        title:moment().add(1,'months').format("MM月计划开票"),
        group: "收费计划",
        width:140,
        option:{
          type:"fullAmount"
        }
      },
      {
        type:"number",
        key:"next_month_plan_bill_2",
        title:moment().add(2,'months').format("MM月计划开票"),
        group: "收费计划",
        width:140,
        option:{
          type:"fullAmount"
        }
      },
      {
         type:"number",
        key:"next_month_plan_bill_3",
        title:moment().add(3,'months').format("MM月计划开票"),
        group: "收费计划",
        width:140,
        option:{
          type:"fullAmount"
        }
      },
      
      {
        key: "progress_plans",
        group: "签约计划",
        title: "签约计划",
        width: 250,
        render(h, { row }) {
          if(!row.origin_plan)
            return h('span','-')
          let doms = row.origin_plan.nodes.map((v, i) => h('div', { class: "flex-wrap split5", style: `padding:0 5px;${v.finished_at ? 'color:green;' : (moment(v.plan_started_at).isBefore(moment()) ? 'color:red' : '')}` }, [i + 1, " ", v.percent ? (v.percent + '%') : '-', " ", v.plan_started_at ? moment(v.plan_started_at).format("YYYY-MM-DD") : "-", " ", v.name]))
          return h('div', { style: "text-align:left;" }, doms)
        }
      },
      {
        key: "adjust_progress_plans",
        group: "签约计划",
        title: "调整计划",
        width: 250,
        render(h, { row }) {
          if(!row.current_plan || row.current_plan.version == 'origin')
            return h('span','-')
          let doms = row.current_plan.nodes.map((v, i) => h('div', { class: "flex-wrap split5", style: `padding:0 5px;${v.finished_at ? 'color:green;' : (moment(v.plan_started_at).isBefore(moment()) ? 'color:red' : '')}` }, [i + 1, " ", v.percent ? (v.percent + '%') : '-', " ", v.plan_started_at ? moment(v.plan_started_at).format("YYYY-MM-DD") : "-", " ", v.name]))
          return h('div', { style: "text-align:left;" }, doms)
        }
      },
      {
        key: "charger_id",
        group: "管理",
        type:'user',
        title: "负责人",
        width: 120,
        render(h, { row }) {
          let domEdit = h("BaseIcon", {
            props: {
              icon: "md-create"
            },
            class: "edit-icon",
            on: {
              click() {
                that.EditCharger(row)
              }
            },
            style: {
              marginLeft: "5px"
            }          })
          let domName = h("BaseNameBoard", {            props: {
              uid: row.charger_id
            }          })
          let domEmpty = h('span', { style: { color: "#aaa" } }, "未指定")
          return h('div', { class: 'flex-wrap flex-center', style: { padding: "0 10%" } }, [row.charger_id ? domName : domEmpty, domEdit])

        }
      }, {
        key: "assistants",
        group: "管理",
        title: "协助人",
        width: 120,
        render(h, { row }) {
          let domEdit = h("BaseIcon", {
            props: {
              icon: "md-create"
            },
            class: "edit-icon",
            on: {
              click() {
                that.EditAssistants(row)
              }
            },
            style: {
              marginLeft: "5px"
            }          })
          let domNames = h("BaseNameBoards", {            props: {
              uids: row.assistants, maxShow: 2
            }          })
          let domEmpty = h('span', { style: { color: "#aaa" } }, "未指定")
          return h('div', { class: 'flex-wrap flex-center', style: { padding: "0 10%" } }, [row.assistants && row.assistants.length > 0 ? domNames : domEmpty, domEdit])

        }
      }, {
        key: "buildingAddress",
        group: "项目详情",
        title: "项目地址",
        width: 200,
        type: "text"
      },
      {
        key: "buildingArea",
        group: "项目详情",
        title: "建筑面积",
        width: 120,
        type: "number"
      },
      {
        key: "buildingHeight",
        group: "项目详情",
        title: "建筑高度",
        width: 120,
        type: "number"
      },
      {
        key: "basementLevels",
        group: "项目详情",
        title: "地下室层数",
        width: 120,
        type: "number"
      },
      {
        key: "buildingCategory",
        group: "项目详情",
        title: "建筑类型",
        width: 200,
        type: "text"
      },
      {
        key: "buildingCount",
        group: "项目详情",
        title: "单体数量",
        width: 120,
        type: "number"
      },
      // ...
      {
        type: 'text',
        title: "当前阶段",
        key: "current_node",
        width: 120,
        group: '项目',
      },
      {
        type: 'text',
        title: "下一阶段",
        key: "next_node",
        width: 120,
        group: '项目',
      },
      {
        type: 'text',
        title: "签约计划",
        key: "signed_plan",
        width: 120,
        group: '项目',
      }, {
        type: 'text',
        title: "调整计划",
        key: "adjust_plan",
        width: 120,
        group: '项目',
      },
      {
        type: 'number',
        title: "建安工程量",
        key: "building_production",
        width: 120,
        group: '工程量',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "当前工程量",
        key: "current_bp",
        width: 120,
        group: '工程量',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),highlight:true,
          type: "fullAmount"
        },
      }, {
        key: "pb_percent",
        group: "工程量",
        title: "工程量占比",
        type: "number",
        option: {
          type: "percent"
        },
      },
      {
        key: "total_origin_plan_hrv",
        group: "人力投入",
        width: 140,
        title: "总人力投入(计划)",
        type: "number",option:{
          
        formatter:v=>v.toFixed(2), 
        sumable:true,
        },
      },
      {
        key: "total_actual_hrv",
        group: "人力投入",
        width: 140,
        title: "总人力投入(实际)",
        sumable:true,
        type: "number",option:{
          
        formatter:v=>v.toFixed(2), 
        sumable:true,
        },
      },
      {
        key: "total_plan_hr",
        group: "人力投入",
        width: 140,
        title: "总人力投入(预测)",
        type: "number",option:{
          
        formatter:v=>v.toFixed(2), 
        sumable:true,
        },
      },
      {
        key: "year_origin_plan_hrv",
        group: "人力投入",
        width: 140,
        title: "年人力投入(计划)",
        type: "number",option:{
          
        formatter:v=>v.toFixed(2), 
        sumable:true,
        },
      },
      {
        key: "year_actual_hrv",
        group: "人力投入",
        width: 140,
        title: "年人力投入(实际)",
        type: "number",option:{
          
        formatter:v=>v.toFixed(2), 
        sumable:true,
        },
      },
      {
        key: "year_plan_hrv",
        group: "人力投入",
        width: 140,option:{
        formatter:v=>v.toFixed(2),        
        sumable:true,
        },
  
        title: "年人力投入(预测)",
        type: "number"
      },
      {
        key: "hr_count",
        group: "人力投入",
        width: 120,
        title: "在建人员数量",
        type: "number",
      },
      {
        key: "_hr_users",
        group: "人力投入",
        width: 240,
        sortable:false,
        title: "在建人员名单",
        type: "text",
        render:(h,{row})=>{
          if(row.hr_count && Array.isArray(row.hr_users))
            return h("div",{class:"flex-wrap",style:"flex-wrap:wrap"},row.hr_users.map(v=>h("BaseNameBoard",{props:{uid:v.user_id},style:"flex-shrink:0;padding-left:5px;margin:2px 5px;flex-grow:1;max-width:calc(50% - 5px);min-width:100px;"+(v.position=="总监" || v.position == "项目经理"?"border-left:2px solid var(--primary)":"")},[v.position])))
          else
            return h("span",'-')
        }
      },
      {
        key: "total_origin_plan_hra_production",
        group: "人均产值",
        width: 120,
        title: "总人均产值(计划)",
        type: "number",
        option:{
          type:"amount",
          highlight:true
        }
      },
      {
        key: "total_plan_hra_production",
        group: "人均产值",
        width: 120,
        title: "总人均产值(预测)",
        type: "number",
        option:{
          type:"amount",
          highlight:true
        }
      },
      {
        key: "total_actual_hra_production",
        group: "人均产值",
        width: 120,
        title: "总人均产值(实际)",
        type: "number",
        option:{
          type:"amount",
          highlight:true
        }
      },
      {
        key: "total_actual_hra_bill",
        group: "人均产值",
        width: 120,
        title: "总人均开票(实际)",
        type: "number",
        option:{
          type:"amount",
          highlight:true
        }
      },
      {
        key: "year_origin_plan_hra_production",
        group: "人均产值",
        width: 120,
        title: "年人均产值(计划)",
        type: "number",
        option:{
          type:"amount",
          highlight:true
        }
      },
      {
        key: "year_plan_hra_production",
        group: "人均产值",
        width: 120,
        title: "年人均产值(预测)",
        type: "number",
        option:{
          type:"amount",
          highlight:true
        }
      },
      {
        key: "year_actual_hra_production",
        group: "人均产值",
        width: 120,
        title: "年人均产值(实际)",
        type: "number",
        option:{
          type:"amount",
          highlight:true
        }
      },
      {
        key: "year_actual_hra_bill",
        group: "人均产值",
        width: 120,
        title: "年人均开票(实际)",
        type: "number",
        option:{
          type:"amount",
          highlight:true
        }
      },
      {
        key: "error",
        group: "人均产值",
        width: 200,
        title: "计算错误",
        type: "text"
      },
      {
        key: "total_actual_hra_bp",
        group: "工程量",
        width: 120,
        title: "总人均工程量",
        type: "number",
        option:{
          type:"amount"
        }
      },
      {
        key: "year_actual_hra_bp",
        group: "工程量",
        width: 120,
        title: "年度人均工程量",
        type: "number",
        option:{
          type:"amount"
        }
      },
      {
        key:"total_plan_duration",
        width:120,
        
        type:"number",
        group:"进度",
        title:"总工期(月)",
        option:{
          formatter:e=>parseInt(e*10/30.4)/10
        }
      },
      {
        key:"total_remain_duration",
        width:120,
        type:"number",
        group:"进度",
        title:"后续工期(月)",
        option:{
          formatter:e=>parseInt(e*10/30.4)/10
        }
      },
       {
        key:"year_end_progress",
        type:"text",
        width:120,
        group:"进度",
        title:"年末形象进度"
      },
      {
        key: "focused_reason",
        group: "管理",
        title: "关注原因",
        width: 300,
        render: (h, { row }) => {
          let mark = h('BaseMark', {
            style: "display:inline;margin-right:5px;",
            props: {
              value: row.focused
            }
          })
          let edit = h('Icon', {
            props: {
              type: "md-create"
            },
            style: {
              style: "margin-left:5px;cursor:pointer;"
            },
            on: {
              click: () => {
                this.showModalFocus = true
                this.formData = {
                  id: row.id,
                  focused: row.focused,
                  focused_reason: row.focused_reason
                }
              }
            }
          })

          return h('div', { class: "split10", style: "padding:0 10px;text-align:left;" }, [mark, row.focused_reason || '请输入关注原因', edit])
        }
      }, {
        key: "updated_at",
        title: "最近更新",
        group: "管理",
        width: 80,
        type: 'time'
      }, {
        key: "updated_by",
        title: "操作人员",
        group: "管理",
        width: 120,
        type: "user",
        option: {
          getters: "session/users"
        }
      }, {
        key: "mod_adjust",
        title: "快速调整",
        group: "管理",
        width: 120,
        render: (h, { row }) => {
          let amount = row.amount
          let adjust_amount = row.adjust_amount
          let signed_amount = row.signed_amount
          let billed_amount = row.billed_amount
          if (billed_amount > amount) {
            let icon = h('BaseIcon', {              props: { icon: "md-create" }, style: {
                marginLeft: "5px"
              }            })
            return h('a', {
              on: {
                click: () => {
                  this.formData = {
                    id: row.id,
                    name: row.name,
                    signed_amount: row.signed_amount,
                    adjust_amount: billed_amount - signed_amount
                  }
                  this.showModalAdjust = true
                }
              }
            }, ['一键调整', icon])
          } else {
            return h('span', { style: "color:#aaa" }, '无需调整')
          }

        }
      }]
    },
    computedComlumns() {
      let cols = [this.cols[0]]
      if (this.selected_notice && Array.isArray(this.selected_notice.fields))
        cols = cols.concat(this.selected_notice.fields.map(key => this.cols.find(t => t.key == key)))
      else
        cols = cols.concat(this.selected_cols.map(key => this.cols.find(t => t.key == key)))

      return cols.filter(v=>v)
    },
    edit_groups() {
      let g = []
      this.cols.forEach(c => {
        if (!c.group || this.edit_tmpl_search && (!c.title || !c.title.includes(this.edit_tmpl_search)) || this.edit_tmpls.includes(c.key))
          return
        let group = g.find(v => v.name == c.group)
        if (group)
          group.items.push(c)
        else
          g.push({
            name: c.group,
            items: [c]
          })
      })

      return g
    },
    groups() {
      let g = []
      this.cols.forEach(c => {
        if (!c.group)
          return
        let group = g.find(v => v.name == c.group)
        if (group)
          group.items.push(c)
        else
          g.push({
            name: c.group,
            items: [c]
          })
      })

      return g
    }
  },
  methods: {
    formatSalarySemicolon(t) {
      return UTIL.formatSalarySemicolon(t)
    },
    localSaveTmpl() {
      let data = {
        selected_cols: this.selected_cols,
        selected_filters: this.selected_filters
      }
      localStorage.setItem('local_cm_tmpl' + this.session.id, JSON.stringify(data))

    },
    localLoadTmpl() {
      try {
        let { selected_cols, selected_filters } = localStorage.getItem('local_cm_tmpl' + this.session.id)
        if (selected_cols)
          this.selected_cols = selected_cols
        if (selected_filters)
          this.selected_filters = selected_filters
      } catch (e) {
        // just catch 
      }


    },
    handleSaveTmpl() {
      if (!this.tmpl_name) {
        this.Error("清输入名称")
        return
      } else if (this.tmpls.find(v => v.name == this.tmpl_name)) {
        this.Error("此名称已存在")
        return
      }

      let tmpl = {
        name: this.tmpl_name,
        fields: this.selected_cols,
        filters: this.selected_filters
      }
      this.my_tmpls.push(tmpl)

      this.$api.post(`users/${this.session.id}/oa_tmpls`, this.my_tmpls).then(res => {
        this.$Notice.success({ title: "保存成功" })
        this.showModalTmpl = false

        this.tmpl_name = ""
      }).catch(e => {
        this.Error(e)
      })
    },
    getTmpls() {
      this.$api.get(`users/${this.session.id}/oa_tmpls`).then(res => {

        this.my_tmpls = Array.isArray(res.data.data) ? res.data.data : []
        this.localLoadTmpl()
      })
    },
    fix() {
      if (this.$refs && this.$refs.table)
        this.$refs.table.calcTableHeight()
    },
    exportExcel() {
      const FixData = v => {
        let res = []
        this.computedComlumns.filter(v => !v.unexport).forEach(c => {
          if (c.formatter) {
            res.push(c.formatter(v[c.key]))
            return
          }

          if (c.type == 'state') {
            if (c.option && c.option.states) {
              res.push(c.option.states[v[c.key]])
            }
          } else if (c.type == 'number' && c.option && c.option.type == 'percent') {
            res.push(parseInt(v[c.key] * 100) / 100 + '%')
          } else if (c.type == 'time') {
            if (v[c.key] && !v[c.key].includes('1900') && !v[c.key].includes('0000'))
              res.push(moment(res[c.key]).format('YYYY/MM/DD'))
            else
              res.push("")
          }else if(c.type == 'user'){
            let id = v[c.key]
            res.push((this.$store.getters['session/users'].find(v=>v.id == id) || {name:id}).name)
          } else {
            res.push(v[c.key])
          }
        })

        return res
      }
      let data = this.filteredData.map((v, i) => {
        v.index = i + 1

        return FixData(v)
      })
      let date = moment().format("YYYYMMDD")
      const filename = `合约明细表${date}.xlsx`;

      const workbook = XLSX.utils.book_new()

      let Caption = [filename]
      let title = this.computedComlumns.filter(v => !v.unexport).map(v => v.title)

      let worksheet = XLSX.utils.aoa_to_sheet([Caption, title, ...data])


      XLSX.utils.book_append_sheet(workbook, worksheet, '明细表');

      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      // save file
      let link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      setTimeout(function () {
        // 延时释放掉obj
        URL.revokeObjectURL(link.href);
        link.remove();
      }, 500);

    },
    updateItem(item) {
      this.$store.commit('cm/update_project', item)
    },
    handleDeleteTmpl(tmpl) {
      let index = this.my_tmpls.findIndex(v => v == tmpl)
      if (index == -1)
        return
      this.Confirm("删除此模板?", e => {
        this.my_tmpls.splice(index, 1)
        this.$api.post(`users/${this.session.id}/oa_tmpls`, this.my_tmpls).then(res => {
          this.$Notice.success({ title: "删除成功" })
          this.showModalTmpl = false

          this.tmpl_name = ""
        }).catch(e => {
          this.Error(e)
        })
      })
    },
    selectNotice(t) {

    },
    handlePatchFocused() {
      this.$api.patch(`projects/${this.formData.id}`, {
        focused: this.formData.focused,
        focused_reason: this.formData.focused_reason
      }).then(res => {
        this.showModalFocus = false

        let updateInfo = {
          id: this.formData.id,
          focused: this.formData.focused,
          focused_reason: this.formData.focused_reason
        }

        this.$store.commit('cm/update_project', updateInfo)
        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
    },
    handlePatchAdjustAmount() {
      this.$api.patch(`projects/${this.formData.id}`, {
        adjust_amount: this.formData.adjust_amount
      }).then(res => {
        this.showModalAdjust = false

        let updateInfo = {
          id: this.formData.id,
          adjust_amount: this.formData.adjust_amount,
          amount: this.formData.signed_amount + this.formData.adjust_amount
        }

        this.$store.commit('cm/update_project', updateInfo)
        this.formData = {}
        this.$Notice.success({ title: "修改成功" })
      })
    },
    handleSelectFilter(f, v) {
      if (Array.isArray(this.selected_filters[f])) {
        let index = this.selected_filters[f].findIndex(t => t == v)
        if (index != -1)
          this.selected_filters[f].splice(index, 1)
        else
          this.selected_filters[f].push(v)
      } else
        this.$set(this.selected_filters, f, [v])
      this.SaveState()
    },
    handleApplyColumn() {
      this.selected_cols = [...this.edit_tmpls]
      this.showModalEditTmpl = false
    },
    inArray(array, sub) {
      if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0 && sub.length <= array.length) {
        for (let i = 0; i < sub.length; i++)
          if (!array.includes(sub[i]))
            return
        return true
      }
    },
    outArray(array, sub) {
      if (Array.isArray(array) && Array.isArray(sub) && array.length > 0 && sub.length > 0) {
        for (let i = 0; i < sub.length; i++)
          if (array.includes(sub[i]))
            return false
        return true
      }
    },

    handleClearGroup(g) {
      if (!g)
        this.selected_cols = []
      else
        this.selected_cols = this.selected_cols.filter(v => !g.items.map(t => t.key).includes(v))
      this.SaveState()
    },
    handleSelectAllGroup(g) {
      g.items.forEach(v => {
        if (!this.selected_cols.includes(v.key))
          this.selected_cols.push(v.key)
      })
      this.SaveState()
    },
    handleSelectColumn(key) {
      let index = this.selected_cols.findIndex(v => v == key)
      if (index == -1)
        this.selected_cols.push(key)
      else
        this.selected_cols.splice(index, 1)
      this.SaveState()
    },
    handleTableEvent(e) {
      if (e && e.type == 'open') {
        let baseurl = '/core/projects/' + e.data.id
        // this.$store.commit('session/pushProcess', { name: e.data.name, custom: 'jianzhu', url: baseurl + '/cm', baseurl })
        this.$store.commit("session/push_project_cm", e.data)
        this.RouteTo(baseurl + '/cm')

      }
    },
    EditCharger(item) {
      this.current = {
        id: item.id,
        charger_id: item.charger_id
      }
      this.showModalCharger = true
    },
    EditAssistants(item) {
      this.current = {
        id: item.id,
        assistants: item.assistants
      }
      this.showModalAssistants = true
    },
    RestoreState() {
      let query =  this.$route.query.q
      if(query){
        let tmpl = this.tmpls.find(v=>v.name==query)
        console.log(tmpl)
        if(tmpl){
          this.selected_cols = [...tmpl.fields]
           this.selected_filters = {...tmpl.filters}
           console.log("ok")
           return
        }
      }
      let state = localStorage.getItem("OA_PROJECTS_STATE_" + this.session.id)
      if (state) {
        state = JSON.parse(state)

        this.selected_key = state.selected_key
        this.selected_cols = state.selected_cols
        this.selected_filters = state.selected_filters
        this.selected_id = state.selected_id
        this.selected_Text = state.selected_Text
      }else{
        this.selected_key = ['code', 'type', 'amount', 'signed_at', 'status', 'charger_id', 'deps', 'adjust_amount', 'partA', 'contractCount', 'updated_at', 'updated_by']
      }
    },
    SaveState() {
      let state = {
        selected_key: this.selected_key,
        selected_id: this.selected_id,
        selected_notice: this.selected_notice,
        selected_Text: this.selected_Text,
        selected_cols: this.selected_cols,
        selected_filters: this.selected_filters,
      }
      localStorage.setItem("OA_PROJECTS_STATE_" + this.session.id, JSON.stringify(state))
    },
    SaveCharger() {
      let item = this.current
      this.$api.patch('projects/' + item.id, { charger_id: item.charger_id }).then(res => {
        this.$Notice.success({
          title: "修改成功"
        })
        let updateInfo = res.data.data
        updateInfo.id = item.id
        updateInfo.charger_id = item.charger_id
        this.$store.commit('cm/update_project', updateInfo)
        this.showModalCharger = false
        this.current = {}
      })
    },
    handleCloneEditColumn(e){
      return e.key
    },
    handleEditTmpl() {
      this.edit_tmpls = [...this.selected_cols]
      this.showModalEditTmpl = true
    },
    SaveAssistants() {
      let item = this.current
      this.$api.patch('projects/' + item.id, { assistants: item.assistants }).then(res => {
        this.$Notice.success({
          title: "修改成功"
        })
        let updateInfo = res.data.data
        updateInfo.id = item.id
        updateInfo.assistants = item.assistants
        this.$store.commit('cm/update_project', updateInfo)
        this.showModalAssistants = false
        this.current = {}
      })
    },
    EditDeps(item) {
      this.showModalDeps = true
      this.current = {
        id: item.id,
        deps: item.deps
      }
    },
    SaveDeps() {
      let item = this.current
      let deps = item.deps.map(group_id=>{
        return {
          group_id
        }
      })
      this.$api.patch('projects/' + item.id, { deps }).then(res => {
        this.$Notice.success({
          title: "修改成功"
        })
        let updateInfo = res.data.data
        Object.assign(item, updateInfo)
        this.$store.commit('cm/update_project', item)
        this.showModalDeps = false
        this.current = {}
      })
    },
    getData(forced) {
      this.$store.dispatch('cm/getData', forced)
    },
    handleMigrate() {
      //migrate-projects
      this.$api.post('/tools/migrate-fix').then(res => {
        this.Success(res.data.data)
        this.getData()
      })
    }
  }
}
</script>
<style lang="less">
.edit-column {
  padding: 2px 5px;
  background: var(--bg2);
  margin: 2px;
  border-left: 5px solid var(--border);
}

.edit-column:hover {
  border-color: var(--primary);
  cursor: pointer;
}

.edit-close:hover {
  color: var(--primary) !important;
}
</style>
