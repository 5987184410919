<template>
  <router-view></router-view>
</template>

<script>
  export default {
    routerOption:{
      as_frame:true
    }
  }
</script>

<style lang="scss" scoped>

</style>