

<template>
  <BaseSegment title="重要事项及风险提示" desc="合同中需要重点注意的事项" style="margin:0">
  <div class="flex-wrap split10" slot="extra">
				<BaseBoardButton icon="md-create" v-if="!editing" name="编辑" :desc="moment().fromNow()" @on-click="startEdit" />
        <template v-else>
          <BaseBoardButton icon="save" :loading="loading" name="保存" type="primary" @on-click="handleSave()" />

          <BaseBoardButton icon="save" name="取消" @on-click="editing=null" />
        </template>
  </div>

    <div style="padding:0 15px;padding-bottom:10px;">
      <template v-if="editing">
        <EditorMemos v-model="formData" />
      </template>
      <template v-else>
    <BaseEmpty v-if="items.length == 0" />
    <template v-else>
        <CmProjectMemos :value="data" />
    </template>
    </template>
    </div>
  </BaseSegment>
</template>

<script>
import UTIL from "@/utils"
import CmProjectMemos from '@/components/cm/Memos'
import EditorMemos  from '@/components/cm/EditorMemos.vue'
import { pick, cloneDeep } from "lodash"
import moment from 'moment'
export default {
  data(){
    return {
      modalEdit:false,
      loading:false,
      editing:false,
      formData:{},
      warnings:[{
        color:"darkgreen",
        name:'普通'
      },{
        color:"orange",
        name:'重要'
      },{
        color:"red",
        name:'紧急'
      }]
    }
  },
  components:{CmProjectMemos,EditorMemos},
  props:{
    data:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    id(){
      return this.$route.params.id
    },
    items(){
      return this.data.memos || []
    },
  },
  methods:{
    startEdit(){
       this.formData = cloneDeep(pick(this.data, ['id', 'amount', 'business_type', 'splited', 'memos']))
      this.editing = true
    },
    handleSave(){
      let data = {}
      console.log("handleSave:",this.formData.memos.map(v=>v.order + '-'+v.detail),this.data.memos.map(v=>v.order+ '-'+v.detail))
      let patched = UTIL.compare_patched(this.data.memos,this.formData.memos,['detail','note','warning','order'])
      
      if(patched.empty){
        return
      }
   
      this.loading = true
      this.$api.patch(`projects/${this.id}?q=memos`,{memos:patched}).then(res=>{
        let updateInfo = res.data.data
        Object.assign(this.data,updateInfo)
        this.$Notice.success({title:"更新成功"})
        this.editing = false
        this.formData = {}
      }).catch(e=>{
        this.$Notice.error({title:"更新失败",desc:e?e.message:'未知原因'})
      }).finally(e=>{
         this.loading = false
      })
    }
  }
}
</script>

<style>

</style>