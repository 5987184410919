<template>
  <div class="hs-container" style="height:300px;position:relative;">
    <div ref="map" class="map-container" style="width:100%;height:300px;">

    </div>

  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    option: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {

    this.$nextTick(() => {

      this.init()
    })
  },
  watch: {
    data: {
      handler(v) {
        if (Array.isArray(v) && v.length > 0)
          this.changeMap()
      },
      immediate: true,
      deep: true
    },
    theme: {
      handler(v) {
        this.$nextTick(() => {

          this.init()
        })
      },
      immediate: true,
    }
  },

  computed: {
    theme() {
      return this.$store.getters['session/session'].theme
    },
    projects() {
      if (Array.isArray(this.data)) {
        return this.data.filter(v => v.lat && v.lng).map(v => {
          return {
            id: v.id,
            lat: v.lat,
            lng: v.lng,
            poiname: v.poiname,
            name: v.name,
            address: v.buildingAddress
          }
        })
      }
      return []
    }
  },
  methods: {
    init() {
      if (this.initTimer) {
        clearTimeout(this.initTimer)
        this.initTimer = null
      }
      this.initTimer = setTimeout(() => {
        if (!TMap)
          return
        if (this.$refs.map)
          this.$refs.map.innerText = ""
        let centerLat = 29.83
        let centerLng = 121.53
        if (!this.option.center && this.projects.length > 0) {
          centerLat = this.projects[0].lat
          centerLng = this.projects[0].lng
        }
        var center = new TMap.LatLng(centerLat, centerLng)
        //定义map变量，调用TMap.Map构造函数创建地图
        var map = new TMap.Map(this.$refs.map, {
          center: center,//设置地图中心点坐标
          zoom: this.option.level || 12,   //设置地图缩放级别,
          pitch: 0,  //设置俯仰角
          rotation: 0,    //设置地图旋转角度
          mapStyleId: this.option.style || (this.$store.getters['session/session'].theme == 'tech' ? 'style1' : 'style3'), //个性化地图样式设置
          renderOptions: {
            enableBloom: true, //泛光
          },
        });
        this.map = map
        var dot = new TMap.visualization.Dot({
          faceTo: 'screen', // 设置散点朝向
          selectOptions: { //拾取配置
            action: 'hover', //拾取动作
            style: {
              type: "image",
              width: 30,
              height: 30,
              "anchor": { x: 15, y: 28 },
              "src": 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/location-active.png',
            },
            enableHighlight: false //是否使用高亮效果
          },
          styles: {
            redCircle: {  // 定义一个红色圆形样式
              type: "image",
              width: 30,
              height: 30,
              "anchor": { x: 15, y: 28 },
              "src": 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/location.png',
              // type: 'circle',
              // fillColor: '#3399ff',
              // strokeColor:"#333",
              // strokeWidth:2,
              // radius:5
            }
          }
        }).addTo(map); // 通过addTo()添加到指定地图实例
        var radiationCircle = new TMap.visualization.Radiation({
          styles: {
            style2: {
              fillColor: "rgba(33,99,255,0.9)", //设置辐射圈填充颜色
            },
          },
          processAnimation: {
            duration: 3000, //辐射圈半径从0动态过渡到最大半径的时长，单位为ms
          },
        }).addTo(map);
        this.dot = dot
        this.radiationCircle = radiationCircle

        dot.on('click', evt => {
          if (evt.detail.dot) {
            console.log(evt.detail); // 用户所点击的图形所对应的数据
          }
        })

        dot.on('hover', evt => {

        })




        this.label = new TMap.MultiLabel({
          id: 'label-layer',
          map, //设置折线图层显示到哪个地图实例中
          //文字标记样式
          styles: {
            'label': new TMap.LabelStyle({
              'color': '#3399ff', //颜色属性
              'size': 10, //文字大小属性
              'offset': { x: 0, y: 10 }, //文字偏移属性单位为像素
              'angle': 0, //文字旋转属性
              'alignment': 'center', //文字水平对齐属性
              'verticalAlignment': 'middle' //文字垂直对齐属性
            })
          },
          //文字标记数据
          geometries: []
        });

        this.changeMap()
      }, 500)



    },
    changeMap() {
      if (this.changeMapTimer) {
        clearTimeout(this.changeMapTimer)
        this.changeMapTimer = null
      }
      this.changeMapTimer = setTimeout(() => {
        if (this.projects.length == 0 || !this.projects[0].lat || !this.projects[0].lng)
          return
        if (this.label) {
          this.label.setGeometries(this.projects.map(v => ({
            'id': v.id, //点图形数据的标志信息
            'styleId': 'label', //样式id
            'position': new TMap.LatLng(v.lat, v.lng), //标注点位置
            'content': v.name
          })));

        }

        const radiationData =
          this.projects.map(v => ({
            center: new TMap.LatLng(v.lat, v.lng),
            radius: this.option.level ? 2000 / (this.option.level - 10) : 2000,
            styleId: "style2" //设置辐射圈样式配置id
          }))

        if (this.radiationCircle)
          this.radiationCircle.setData(radiationData)

        if (this.dot)
          this.dot.setData(this.projects.map(v => ({
            lat: v.lat,
            lng: v.lng,
            styleId: "redCircle",
            properties: {
              pid: v.id
            }
          })));

      }, 500)

    },
  }
}
</script>

<style lang="scss" scoped>
</style>