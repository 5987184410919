<template>
  <div class="container flex-wrap flex-center hover-lighter" style="background:var(--bg3);font-size:20px;background:url('https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/1673927964765.jpg');background-size:100% 100%;cursor:pointer;color:#fff;" @click="RouteTo(`https://b.inbgz.com/cm/${id}?key=${key}`,{},true)">
    数据看板 <BaseIcon icon="ios-forward" :size="20" />
  </div>
</template>

<script>
  export default {
    props:{
      data:{
        type:Object,
        default:()=>({})
      }
    },
    computed:{
      id(){
        return this.data.id
      },
      key(){
        return this.data.key
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>