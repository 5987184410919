<template>
  <div style="padding:10px">
    <BaseBoardSubTitle :name="title" />
    <div style="border:1px solid var(--border);padding:10px 5px">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:""
        }
    }
}
</script>

<style>

</style>