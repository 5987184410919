<style lang="less">
.gz-image {
   
   
	background: none;
	.ivu-tooltip-rel {
		height: 100%;
	}

	.gz-images-item {
		width: 100%;
		height: 40px;
		margin-right: 5px;
		margin-bottom: 5px;
    overflow:hidden;
		img {
			width: 35px;
			height: 35px;
			margin: 5px;
			margin-bottom: 0;
			border-radius: 2px;
			position: relative;
			border: 1px solid #333;
		}

		.file-icon {
			width: 60px;
			height: 30px;
			margin: 5px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				border: none;
				position: relative;
				bottom: 0;
				color: #fff;
				z-index: 10;
				line-height: auto;
				box-shadow: none;
			}
		}

		i {
			font-size: 20px;
		}
		.name {
			width: 100%;
			text-align: left;
		}

		.download {
			margin-right: 10px;
			color: rgb(29, 157, 248);
			display: flex;
			justify-content: flex-end;
			span:hover {
				color: rgb(96, 180, 240);
				cursor: pointer;
			}
			width: 120px;
		}
	}
}

.gz-images:hover {
	background: none;
}
</style>
<template>
	<FormItem :label="label" :requried="required"
		
	>
		<div class="gz-image-wrapper">
				<template v-for="(f, i) in files">
					<div class="gz-image" :key="i" 	@click="modalPreview=true;previewIndex=i;">
						<Tooltip
							style="width: 100%; height: 100%;"
							:content="getFileName(f.name)"
							placement="bottom"
							transfer
						>
							<template v-if="isImage(getFileExt(f.url))">
								<img
									:src="f.url"
                  style=""
								/>
							</template>
							<template v-else>
								<div
									class="file-icon"
									@click="s_download(f.url, f.name)"
									:style="`background:${getFileExtColor(f.name)}`"
								>
									<Icon type="ios-document-outline" />
									<span>{{ getFileExt(f.url) }}</span>
								</div>
							</template>
						</Tooltip>
						<div
							v-if="f.loading"
							style="
								color: red;
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
                                bottom:0;
								background: rgba(33, 33, 33, 0.5);
								text-align: center;
                                display:flex;
                                align-items:center;
                                justify-content:center;
                                color:yellowgreen;
                                z-index:100;
							"
							>{{ f.percent || "待上传" }}</div
						>
						<Spin fix v-if="f.loading" />
						<Icon
							type="md-close"
							v-if="editable"
							class="gz-button l-close-btn"
							@click="remove(i)"
						/>
					</div>
				</template>
			<div
				v-if="!editable && files.length == 0"
				style="margin: 10px 20px; color: #aaa"
			>
				无
			</div>
			<div class="gz-image-add" v-if="canAddMore">
				<Icon type="md-add" />

				<input
					type="file"
					:multiple="isMultiple"
					:accept="acceptType"
					ref="file"
					@change="handleUpload"
					:index="index"
					style="width: 100%; height: 100%"
				/>
			</div>
         
  
		</div>

    <Modal v-model="modalPreview" footer-hide width="1200">
      <div class="flex-col flex-center" style="position:relative;">
      <template v-if="modalPreview">
        
        <img :src="files[previewIndex].url" style="max-width:800px" /> 
        <div class="flex-wrap flex-around" style="position:fixed;z-index:10;bottom:30px;left:300px;right:300px;z-index:999999;" v-transfer>
          <div style="background:#33333333;border-radius:50%;padding:3px;"><Icon type="md-arrow-back" size="40" /></div>
          <div class="flex-wrap">
          <template v-for="(f, i) in files">
        	<div class="gz-image" :key="i" 	@click="previewIndex=i;">
            
            	<template v-if="isImage(getFileExt(f.url))">
								<img
									:src="f.url"
                  style=""
								/>
							</template>
              
          </div>
          </template>
          </div>
          <div style="background:#33333333;border-radius:50%;padding:3px;" @click="previewIndex=i+1;"><Icon type="md-arrow-forward"  size="40" /></div>
        </div>
        	
      </template>
      </div>
    </Modal>
	</FormItem>
</template>
<script>
/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {cb}
 */
function getBlob(url, cb) {
	var xhr = new XMLHttpRequest();
	xhr.open("GET", url, true);
	xhr.responseType = "blob";
	xhr.onload = function () {
		if (xhr.status === 200) {
			cb(xhr.response);
		}
	};
	xhr.send();
}

/**
 * 保存
 * @param  {Blob} blob
 * @param  {String} filename 想要保存的文件名称
 */
function saveAs(blob, filename) {
	if (window.navigator.msSaveOrOpenBlob) {
		navigator.msSaveBlob(blob, filename);
	} else {
		var link = document.createElement("a");
		var body = document.querySelector("body");

		link.href = window.URL.createObjectURL(blob);
		link.download = filename;

		// fix Firefox
		link.style.display = "none";
		body.appendChild(link);

		link.click();
		body.removeChild(link);

		window.URL.revokeObjectURL(link.href);
	}
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
function download(url, filename) {
	getBlob(url, function (blob) {
		saveAs(blob, filename);
	});
}

import axios from 'axios'
var CancelToken = axios.CancelToken
import {mapGetters} from 'vuex'
export default {
	data() {
		return {
			files: [],
      modalPreview:false,
      previewIndex:0,
			percents: [],
		};
	},
	mounted() {
		this.parseValue()
	},
  name:"图片",
  icon:'xiazai40',
	props: {
		index: {
			type: [Number, String],
		},
		label: {
			type: String,
		},
		value: {
			type: String,
		},
    required:{
      type:Boolean
    },
		env: {
			type: Object,
			default: () => {},
		},
		parent: {
			type: Object,
		},
		editable: {
			type: Boolean,
		},
		option: {
			type: Object,
			default: {},
		},
		axiosServer: {
			type: Object,
		},
	},
	watch: {
		value: {
			handler(v) {
			
					this.parseValue()
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
    ...mapGetters('session',['session']),
        canAddMore(){
            if(!this.editable)
                return false

            if(this.isMultiple)
                return (this.files.length < this.option.max) || true
            else
                return this.files.length == 0
        },
		isMultiple() {
			return !this.option.single
		},
		acceptType() {
			return "image/*"
		},
	},

	methods: {
    parseValue(){
				try {
						if (typeof this.value == "string") {
						let images =  JSON.parse(this.value)
						this.files = images.map((v,i)=>({
						name:"图片"+(i+1)+'.jpg',
						url:v
						}))
				
						} else if(typeof this.value == "object"){
							this.files = this.value.map((v,i)=>({
								name:"图片"+(i+1)+'.jpg',
								url:v
								}))
					} else {
							this.files = [];
					}
				}catch(e) {
						console.error("格式错误:", e);
						this.files = [];
					}
		},
		emitValue(){
			this.$emit('input',JSON.stringify(this.files.map(v=>v.url)))
			this.$emit('change',JSON.stringify(this.files.map(v=>v.url)))
		},
		s_download(url, name) {
			
			download(url, name);
		},
		onUploadProgressList(progressEvent,index) {
            
            var complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            
            this.$set(this.files[this.baseIndex+index],'percent',complete + "%");
            console.log("onUploadProgressList:",this.files[this.baseIndex+index],this.baseIndex+index,complete)
            
        },
         getObjectURL(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
		handleUpload(e) {
			let that = this;
			let files = e.srcElement.files;

            if (!files) return;
         
         
            this.baseIndex = this.files.length
			      let fileObjects = Object.values(files).map((f) => ({
                name: f.name,
                size: f.size,
                ext: that.getFileExt(f.name),
                url:this.getObjectURL(f),
                loading:true,
				        vdisk: this.option.vdisk || "ref",
            }));
            let oldfiles = this.files
            this.files = this.files.concat(fileObjects)
         
 if (this.option.uploader) {
			
				if (this.option.uploaderSource == "env") {
					let uploader = this.env[this.option.uploader];
					uploader(Object.values(files), this.onUploadProgressList).then(
						(urls) => {
							
							files.forEach((v, i) => {
								fileObjects[i].url = urls[i];
							});
							let values = this.files.map((v, i) => v.url).join(';');
								this.emitValue()
						}
					);
				}else{
					this.$store.dispatch(this.option.uploader,{files:Object.values(files),onProgress:this.onUploadProgressList}).then(res=>{
						if(!Array.isArray(res))
							throw("文件上传失败")
						files.forEach((v,i)=>{
							fileObjects[i].url = res[i].url
						})
						this.emitValue()
					})
				}
			
			}else{
         // 1 - upload file info to server
			   this.$api.post('files',fileObjects).then(res=>{
			       let resFiles = res.data.data
             // 2 - upload each file to cos server
			        return Promise.all(fileObjects.map((v,i,a)=>{
			            a[i].loading = true
			            a[i].id = resFiles[i].id
			            let file =  files[i]
			            let filekey = "files/"+resFiles[i].url

			             file.loading = true

			             var formdata = new FormData()
			                formdata.append('key',filekey)
			                formdata.append('file', files[i])

			                fileObjects[i].source = CancelToken.source()
			                return this.$cos.post('',formdata,{
			                    'content-type': 'multipart/form-data',headers:{Authorization:that.session.coskey
			                    },
			                    cancelToken:fileObjects[i].source.token,
			                    onUploadProgress: progressEvent => {
			                        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
			                        that.$set(fileObjects[i],'percent',complete)
			                    }
			                }).then(res=>{
			                    a[i].url = this.$cos.defaults.baseURL + filekey
			                    delete a[i].loading
			                    delete fileObjects[i].source
                          
			                    that.$forceUpdate()

			                }).catch(e=>{
			                    if(e && e.message == "取消上传"){
			                        that.cancelUploading(that.files.findIndex(f=>f==fileObjects[i]))
			                        return
			                    }
			                    return Promise.reject({e,file})
			                })
			        })).then(res=>{
                 that.$api.post('files/success',resFiles.map(v=>v.id)).then(res=>{
                  
                 }).catch(e=>{
                  this.Error(e)
                  this.$api.post('files/cancel',resFiles.map(v=>v.id))
                 })
			        })

			   }).catch((e)=>{
          // 3 - if failed, rollback from the file server
			        that.Error("上传失败:",e)
			        that.files = oldfiles
			    })
      }
		},
		getFileName(url) {
			if (url) {
				let fileName = url.replace(/(.*\/)*([^.]+)/i, "$2");
				return fileName;
			}
		},
		getFileExt(url) {
			if (url) {
				let ext = url.substring(url.lastIndexOf(".") + 1);
				return ext;
			}
		},
		isImage(ext) {
			const imgexts = ["jpg", "jpeg", "png", "gif","ico"];
			return imgexts.includes(ext);
		},
		getFileExtColor(url) {
			let ext = this.getFileExt(url);
			const colors = {
				doc: "blue",
				docx: "blue",
				rar: "purple",
				pptx: "orange",
				ppt: "orange",
				xls: "green",
				xlsx: "green",
			};

			return colors[ext] || "#333";
		},
		onAddSelectedFiles(files) {
			this.files = this.files.concat(
				files.filter((f) => this.files.findIndex((v) => v.id == f.id) == -1)
			);

			this.modalFileSelector = false;
		},
		push(u) {
			this.files.push(u);

			return this.files.length - 1;
		},
		cancelUploading(index) {
			if (index == -1) return;
			if (this.files[index] && this.files[index].source) {
				this.files[index].source.cancel("取消上传");
			}
      // remove from file server
			this.files.splice(index, 1);
		},
		remove(index) {
			if (this.files[index] && this.files[index].source) {
				this.files[index].source.cancel("取消上传");
			}
			this.files.splice(index, 1);
				this.emitValue()
		},
	},
};
</script>
<style lang="less">
.gz-image-wrapper {
	display: flex;
	flex-wrap: wrap;
  margin-top:0;
}
.gz-image {
	position: relative;
	display: inline-block;
	background: #fff;
	height: 60px;
	color: #ddd;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #dfdfdf;
	margin-left: 10px;
	margin-top: 10px;
	width: 60px;
	height: 60px;
	overflow: visible;

	cursor: pointer;

	img {
		width: 90%;
		height: 90%;
		margin: 5%;
	}
}

.gz-image-add {
	.gz-image;
	input {
		position: absolute;
		cursor: pointer !important;
		font-size: 100px;
		right: 0;
		top: 0;
		opacity: 0;
		font-size: 0;
	}

	i {
		font-size: 30px;
		cursor: pointer !important;
		color: #aaa;
	}
}

.gz-image-add:hover {
	cursor: pointer;
	background: yellow;
}

.l-close-btn {
	position: absolute;
	font-size: 14px;
	right: -5px;
	top: -5px;
	width: 20px;
	height: 20px;
	background: darkred;
	border: 1px solid #333;
	color: white;
	border-radius: 50%;
	padding: 2px;
	display: none;
	z-index: 10;
}

.l-close-btn:hover {
	filter: brightness(1.2);
	cursor: pointer;
}

.gz-image:hover {
	background: rgb(246, 255, 208);
	color: #eee;

	.l-close-btn {
		display: block;
	}
}

.file-icon {
	color: #fff;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	text-shadow: 1px 1px 1px #333;
	i {
		position: absolute;
		top: 2px;
		left: 2px;
		bottom: 2px;
		right: 2px;
		font-size: 56px;
	}

	span {
		position: relative;
		box-shadow: 1px 1px 1px #333;
		bottom: -10px;
		font-size: 10px;
		background: inherit;
		width: 40px;
		text-align: center;
		text-transform: uppercase;
		height: 18px;
		line-height: 16px;
		border: 2px solid #fff;
	}
}
</style>

