<template>
  <div id="login_wechat">

  </div>
</template>

<script>
  export default {
    activated(){
      var that = this
      const appid = 'wx215ebbf66af92517'
      const host = "https://a.inbgz.com/oauth2"

      var redirect_uri = encodeURIComponent(`${host}`)
      this.$nextTick(()=>{
        var obj = new WxLogin({
        self_redirect: false,
        id: "login_wechat",
        appid,
        scope: "snsapi_login",
        redirect_uri,
        style:"width:300px;height:300px;",
        state:'WECHAT'
      });
      })

      setTimeout(()=>{
         if(this.$el){
           this.$el.querySelector('iframe').sandbox = "allow-top-navigation"
         }
           
      },1000)

       
    }
  }
</script>

<style lang="scss" scoped>

</style>