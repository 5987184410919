<template>
  <div class="container">
    <div class="flex-wrap" style="padding:10px;">
      <!-- <Button type="primary"><BaseIcon icon="md-add" /> 新建报表</Button> -->
      <Input search placeholder="搜索" clearable style="width:220px;" />
    </div>
    <div style="height:calc(100% - 70px);margin:0 10px;border:1px solid var(--border)">
      <BaseTable :columns="columns" :data="filteredItems" />
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data(){
      return {
        showWizard:false,
        projects:[],
        items:[{
          id:'project-list',
          name:"在建项目进度成本合约情况表",
          code:"202301",
          tmpl_url:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/temp/%E6%9C%88%E5%BA%A6%E9%A1%B9%E7%9B%AE%E6%B1%87%E6%8A%A5%E6%A8%A1%E6%9D%BF.docx",
          description:"生成月度项目",
          fields:['项目情况','成本合约','在岗人员']
        },{
          id:'project-basic',
          name:"项目总体报表（当年）",
          code:"202302",
          tmpl_url:"https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/tmpls/%E9%A1%B9%E7%9B%AE%E6%80%BB%E4%BD%93%E6%83%85%E5%86%B5%E7%BB%9F%E8%AE%A1%E8%A1%A8.docx",
          description:"生成月度项目",
          fields:['在建数量','合同余额','收费情况','人力投入','人均产值']
        }]
      }
    },
    methods:{
      handleWizard(item){
        this.$api.post("projects/tmpls",{id:item.id}).then(res=>{
          this.DownloadWithName(res.data.data,moment().format("YYYY-MM")+`${item.name}.docx`)
        })
      }
    },
    computed:{
      filteredItems(){
        return this.items
      },
      columns(){
        return [{
        type: 'index',
        title: "序",
        key: "index",
        width: 80,
        option: {

          align: "center",
        }
      }, {
        type: 'text',
        key: "code",
        title: "编号",
        width: 80,
        group: '项目',
        option: {

          align: "center",
        },
        render:(h,{row})=>{
          let items = [h('span',row.code)]
          items.push(h('BaseIcon',{props:{icon:'wancheng',color:'var(--primary)',size:12},style:"margin-left:5px;"}))
          return h('div',{class:"flex-wrap"},items)
        }
      },{
        type:'text',
        title: "名称",
        linkEvent: "open",
        key: "name",
        minWidth: 200,
      },{
        type:'text',
        title: "说明",
        linkEvent: "open",
        key: "description",
        minWidth: 200,
      },{
        type:'text',
        title: "包含字段",
        linkEvent: "open",
        key: "fields",
        minWidth: 200,
        render:(h,{row})=>{
          return h("span",row.fields.join(','))
        }
      },{
        type:'text',
        title: "模板",
        linkEvent: "open",
        key: "desc",
        minWidth: 200,
        render:(h,{row})=>{
          return h("span",'有')
        }
      },{
        type: 'number',
        title: "操作",
        width:120,
        key: "partA",
        render:(h,{row})=>{
          let buttons = [
            h('Button',{props:{icon:"md-arrow-forward",type:"primary",size:"small"},on:{click:()=>{
              this.handleWizard(row)
            }}},'生成'),
          ]
          return h('div',{class:"flex-wrap split5 flex-center"},buttons)
        }
      },{
        type: 'number',
        title: "管理",
        width:200,
        key: "partA",
        render:(h,{row})=>{
          let buttons = [
            h('Button',{props:{icon:"md-create",size:"small"},on:{click:()=>{
              this.handleEdit(row.id)
            }}},'修改'),
            h('Button',{props:{icon:"md-trash",size:"small",loading:this.deleting == row.id},on:{click:()=>{
              this.handleDelete(row.id)
            }}},'删除')
          ]
          return h('div',{class:"flex-wrap split5 flex-center"},[])
        }
      }]
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>