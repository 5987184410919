<template>
  <Form ref="form" :model="formData" :rules="rules">
    <div style="color:var(--subtext2)">正在{{toUpdate?'编辑':'新建'}}表单</div>
    <BaseSegment :title="s.name" :disabled="true" v-for="s in segments" :key="s.id">
        <Row v-for="r in s.rows" :key="r.id" :gutter="view.option.gutter">
           
            <Col :span="c.span" v-for="c in r.cols" :key="c.id" >
            
                <FormItem
                    :label="c.name?(c.name):''"
                    :required="editables[c.key]?c.option.required:false"
                    v-if="viewables[c.key]"
                    :prop="editables[c.key]?c.key:undefined"
                    :style="`padding:10px;margin:5px 2px;border-radius:5px;border:1px solid var(${editables[c.key]?'--subtext3':'--border'});`"
                    >
                <component 
                    :is="view.type+'_'+c.control"
                    :value="formData[c.key]"
                    :ref="c.key"
                    :label="c.name"
                    :editable="editables[c.key]?true:false"
                    :option="c.option"
                    :db="formData"
                    @input="handleInput(c,$event)"
                    @on-event="handleEvent"
                ></component>
            </FormItem>
            </Col>
            
        </Row>
    </BaseSegment>
    <div class="flex-wrap flex-center">
        <slot name="buttons"></slot>
        <Button type="primary" size="large" @click="handleSubmit">
            <BaseIcon :icon="submitText.icon" :size="20"  />
            <Divider type="vertical" />
            {{ submitText.name }}
            <BaseIcon icon="ios-arrow-forward" style="margin-left:5px;" />
        </Button>
    </div>
    
    
  </Form>
</template>

<script>
import dynamic_Input from '@/components/form/dynamic_controls/Input'
import dynamic_FlowSettings from '@/components/form/dynamic_controls/FlowSettings'
import dynamic_Textarea from '@/components/form/dynamic_controls/Textarea'
import dynamic_Select from '@/components/form/dynamic_controls/Select'
import dynamic_UserSelect from '@/components/form/dynamic_controls/UserSelect'
import dynamic_CheckBox from '@/components/form/dynamic_controls/CheckBox'
import {cloneDeep} from 'lodash'
import UTIL from '@/utils'
export default {
    components:{
        dynamic_Input,
        dynamic_FlowSettings,
        dynamic_Textarea,
        dynamic_Select,
        dynamic_UserSelect,
        dynamic_CheckBox
    },
    data(){
        return {
            formData:{},
            rules:{}
        }
    },
    props:{
        value:{
            type:Object,
            default:()=>({})
        },
        fields:{
            type:Array,
            default:()=>([])
        },
        view:{
            type:Object,
            default:()=>({})
        },
        submit:{
            type:String | Object,
            default:"提交"
        },
        toUpdate:{
            type:Boolean,
        },
        filter:{
            type:Array,
            default:()=>([])
        }
    },
    watch:{
        value:{
            handler(e){
                this.formData = cloneDeep(e)
            },
            deep:true,
            immediate:true,
        }, 
        fields:{
            handler(fields){
                if(fields && Array.isArray(fields))
                    this.formData = this.defaultValue
                let rules = {}
                fields.forEach(f=>{
                    if(f.option.required){
                        rules[f.key] = [{
                            required:true,
                            message:`${f.name}是必填项`,
                            trigger:"blur"
                        }]
                    }else{
                        rules[f.key] = []
                    }

                    if(Array.isArray(f.option.rule)){
                        rules[f.key] = rules[f.key].concat(f.option.rule)
                    }else if(f.option.rule)
                        rules[f.key].push(f.option.rule)

                    
                })
                this.rules = rules
            },
            immediate:true
        }
    },  
    methods:{
        handleInput(field,data){
            this.$set(this.formData,field.key,data)
        },  
        handleSubmit(){
            const currentFormData = { ...this.formData };

            // 重置表单以清除所有错误信息
            this.$refs.form.fields.forEach(field => field.validateState = '');
            // 重新设置表单数据
            this.$nextTick(() => {
                this.formData = { ...currentFormData };
                    this.$refs.form.validate((valid,fields)=>{
                        
                        if(!valid){
                            // 获取第一个错误的字段并聚焦
                            const errorFields = this.$refs.form.fields.filter(field => field.validateState === 'error');
                            if (errorFields.length > 0) {
                                this.$nextTick(() => {
                                    const firstErrorField = errorFields[0];
                                    const errorElement = firstErrorField.$el.querySelector('input, textarea, select'); // 根据实际情况调整选择器
                                    if (errorElement) {
                                        errorElement.focus();
                                    }
                                });
                            }
                        }else{
                            let updateData = this.formData
                            console.log(updateData,this.value)
                            if(this.toUpdate){
                                updateData = UTIL.compare_patch_object(this.value,this.formData,this.dynamic_filter)
                                console.log('updated:',updateData)
                            }
                            this.$emit('on-submit',updateData)
                        }
                        
                    })
                })
        },
        handleEvent({type,value}){
            if(type == 'focus'){
                console.log(value,this.$refs[value])
                if(this.$refs[value])
                    this.$refs[value][0].focus()
                
            }
        }
    },
    mounted(){
        this.formData = cloneDeep(this.value)
    },
    computed:{
        dynamic_filter(){
            return this.filter || []
        },
        editables(){
            let o = {
                'FlowSetting':true
            }
            if(this.view && this.view.option && Array.isArray(this.view.option.editable)){
                this.view.option.editable.forEach(e=>{
                    o[e] = true
                })
            }
            return o
        },
        viewables(){
            let o = {
                'FlowSetting':true
            }
            if(this.view && this.view.option && Array.isArray(this.view.option.viewable)){
                this.view.option.viewable.forEach(e=>{
                    o[e] = true
                })
            }
            return o
        },
        submitText(){
            if(typeof this.submit == 'object'){
                return this.submit
            }else{
                return {
                    icon:'md-checkmark',
                    name:this.submit
                }
            }
        },
        defaultValue(){
            let defaultValue = {}
            this.fields.forEach(f=>{
                if(f.option && f.option.defaultValue){
                    defaultValue[f.key] = f.option.defaultValue
                }
            })
            return defaultValue
        },
        segments(){
           return this.view.segments
        }
    }
}
</script>

<style>

</style>