<template>
  <Modal class-name="modal-loading" :value="value" footer-hide @input="$emit('input',$event)" :width="10" :closable="false">
    <div style="padding:10px;text-align:center;">
      <div class="loader"></div>
      <div style="margin-top:10px;font-size:12px;">{{title}}</div>
    </div>
  </Modal>
</template>
<script>
export default {
  props:{
    value:{
      type:Boolean,
      default:false
    },
    title:{
      type:String,
      default:'加载中'
    }
  }
}
</script>
<style lang="less">
/* HTML: <div class="loader"></div> */
.modal-loading{
  .ivu-modal{
    top:300px;
  }
}
.loader {
  width: 100%;
  height: 20px;
  border-radius: 20px;
  color: var(--active);
  border: 2px solid;
  position: relative;
}
.loader::before {
  content: "";
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 2s infinite;
}
@keyframes l6 {
    100% {inset:0}
}
</style>