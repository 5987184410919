<template>
  <FlowGraph />
</template>

<script>
import FlowGraph from '@/components/gojs/AppraisalEditor'
export default {
    components:{FlowGraph}
}
</script>

<style>

</style>