<template>
  <div class="container flex-line" style="padding:20px">
    <div style="width:200px;flex-shrink:0;height:100%;border:1px solid var(--border);">
       <BaseMenu :menus="menus" :actived="actived" @on-click="handleOpen"></BaseMenu>
    </div>
    <div style="margin-left:20px;border:1px solid var(--border);height:100%;width:100%;padding:10px;position:relative;background:var(--bg3);">
      <div class="flex-wrap">
      <Button type="primary" icon="md-add" @click="handleCreate">发起</Button>
      <Input search clerable v-model="searchText" style="margin-left:10px;width:200px" />
      <i-switch style="margin:0 10px" v-model="showHistory"></i-switch> 显示已完成的
      <i-switch style="margin:0 10px" v-model="showMine"></i-switch> 只显示我的
      </div>
      <div style="height:calc(100% - 80px);width:100%;position:relative;margin-top:10px;">
        <BaseTable :columns="columns" :data="filteredItems" @event="handleTableEvent"></BaseTable>
      </div>

    </div>

    <Modal v-model="showEditor" title="新建反馈问题" footer-hide draggable :width="600">
        <Form ref="formCreate" :model="formData" >
          <Row :gutter="10">
            <Col :span="8"><FormItem label="类别" prop="type" required>
            <Select type="tab" clearable v-model="formData.type">
              <Option v-for="t in types" :value="t" :key="t">{{t}}</Option>
            </Select>
          </FormItem></Col>
            <Col :span="16"> <FormItem label="标题" prop="title" required>
            <Input clearable v-model="formData.title" />
          </FormItem></Col>
          </Row>
         
          
          <FormItem label="内容" prop="content">
            <Input type="textarea" v-model="formData.content" :rows="8" clearable />
          </FormItem>
          <FormItem label="附件" prop="name">
            <BaseImageUpload type="textarea" v-model="formData.files" :rows="8" clearable />
          </FormItem>
          <div class="flex-wrap flex-right">
            <Button @click="showEditor=false" style="margin-right:10px">取消</Button>
            <Button type="primary" @click="handleSubmit">提交</Button>
          </div>
        </Form>
    </Modal>

    <Modal v-model="showDetail" title="查看联系单" footer-hide draggable :width="700">
      <div style="padding:0 10px">
        
        
        <div  style="padding:10px;border:1px solid var(--border);margin-top:10px;background:var(--bg3);box-shadow:1px 1px 2px 1px var(--border);">
          <div class="flex-wrap flex-between" style="margin-bottom:10px;border-bottom:1px dashed var(--border);padding-bottom:10px;">
        <div class="flex-wrap" style="font-size:16px;font-weight:bold;"><Tag color="var(--primary)" style="margin-right:10px">{{current.type}}</Tag> {{current.title}}</div>
        <div :style="`background:${state_colors[current.state]};color:#fff;padding:2px 5px;font-size:10px;border-radius:3px;`">{{states[current.state]}}</div>
        </div>
        <div v-html="current.content" style="padding:10px;color:var(--subtext3);">
            </div>

            <div class="flex-wrap flex-between" style="padding-top:5px;margin-top:20px;border-top:1px dashed var(--border)" >
          <div class="flex-wrap split10"><BaseNameBoard :uid="current.created_by" ></BaseNameBoard> {{moment(current.created_at).fromNow()}} 发布</div>
          <div class="flex-wrap split10 flex-right">
          评论 {{current.comment_count}}
        </div>
        </div>
        </div>
        
       
        <div style="min-height:100px;padding:10px 0;">
          <div class="flex-wrap flex-between" style="margin:5px 0;color:var(--text3);font-weight:bold;">
          问题处理及内容回复
          <div></div>
          </div>
          <template v-if="current.state < 2">
          <div class="flex-wrap">
            <Input type="textarea" v-model="comment" :rows="4" placeholder="请输入回复内容" />
           
          </div>
          <div class="flex-wrap flex-right" style="margin-top:10px;">
            <Button type="success" style="margin-right:10px" icon="md-checkmark" @click="ConfirmToFinish" :loading="submittingFinish">结单</Button>
             <Button type="primary" @click="handleSubmitComment" :loading="submitting" icon="md-chatboxes">回复</Button>
          </div>
          </template>
          <BaseLoading v-if="loadingTopic"></BaseLoading>
          <template v-for="c in current.comments">
            <div :key="c.id" style="margin-top:15px;border-top:1px dashed var(--border);padding-top:10px;">
              <div class="flex-wrap split10"><BaseNameBoard :uid="c.created_by" ></BaseNameBoard> {{moment(c.created_at).fromNow()}} 评论 <a v-if="c.created_by == session.id" @click="handleDeleteComment(c.id)">删除</a></div>
              <div style="background:var(--bg3);margin-left:40px;border-radius:5px;padding:10px;border:1px solid var(--border);margin-top:10px;">{{c.content}}</div>
              
            </div>
          </template>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    data(){
      return {
        showFinish:false,
        items:[],
        showEditor:false,
        searchText:"",
        showHistory:false,
        showMine:false,
        loadingTopic:false,
        submittingFinish:false,
        current:{comments:[]},
        comment:"",
        showDetail:false,
        loading:false,
        submitting:false,
        states:['待处理','处理中','已办结','已关闭'],
        state_colors:['var(--subtext3)','var(--primary)','var(--success)','var(--error)'],
        actived:'业务问题',
        formData:{
          type:"业务问题"
        }
      }
    },
    mounted(){
      this.getData()
    },
    computed:{
      ...mapGetters("session",['session']),
      filteredItems(){
        let result = []
        if(this.actived == "created_by"){
          result = this.items.filter(v=>v.created_by == this.session.id)
        }
        else if(this.actived == "replyed_by"){
          result = this.items.filter(v=>v.replyed)
        }else{
          
          result = this.items.filter(v=>v.type == this.actived)
        }

        return result.filter(v=>{
          if(this.searchText && (!v.title || !v.title.includes(this.searchText)))
            return false
          return true
        }).filter(v=>this.showHistory || v.state < 2).filter(v=>!this.showMine || v.created_by == this.session.id)

      },
      types(){
        return ['业务问题','系统问题','需求及建议']
      },
      menus(){
        return [{
          name:"业务问题",
          key:"业务问题",
          icon:"iconset0118"
        },{
          name:"系统问题",
          key:"系统问题",
          icon:"iconset0118"
        },{
          name:"需求及建议",
          key:"需求及建议",
          icon:"iconset0118"
        },{
          name:"我发起的",
          key:"created_by",
          icon:"user"
        },{
          name:"我参与的",
          key:"replyed_by",
          icon:"user"
        }]
      },
      columns(){
        var that =this
        return [{
          type:"index",
          title:"序",
          width:60
        },{
          type:"state",
          title:"状态",
          key:"state",
          option:{
            states:['未处理','处理中','已处理','已关闭'],
            colors:["#aaa","var(--primary)","var(--success)","var(--error)"]
          },
          width:100
          
        },{
          type:"text",
          title:"项目",
          key:"project_id",
          width:200,
          render:(h,{row})=>{
            return h("BaseProjectName",{props:{pid:row.project_id}})
          }
          
        },{
          type:"text",
          key:"title",
          align:"left",
          linkEvent:"open",
          title:"标题",
          render:(h,{row})=>{
            let doms = [h('span',row.title)]
            if(row.files)
              doms.push(h(BaseIcon,{props:{icon:'md-photos'},style:"margin-left:3px"}))
            return h("a",{on:{
              click:()=>this.handleTableEvent({type:'open',data:row})
            }},doms)
          }
        },{
          type:"number",
          key:"comment_count",
          title:"回复数",
          width:120
        },{
          type:"user",
          key:"created_by",
          title:"创建者",
          width:120
        },{
          type:"time",
          key:"created_at",
          title:"创建时间",
          width:120
        },{
          type:"user",
          key:"replyed_by",
          title:"最后回复",
          width:120
        },{
          type:"time",
          key:"replyed_at",
          title:"回复时间",
          width:120
        },{
          type:"tool",
          title:"操作",
          width:200,
          render:(h,{row})=>{
            let buttonReset = h("Button",{props:{size:"small"},on:{click:()=>{
              that.handleResetTopic(row)
            }}},'重办')
            let buttonClose = h("Button",{props:{size:"small"},on:{click:()=>{
              that.handleCloseTopic(row)
            }}},'关闭')
             let buttonDelete = h("Button",{props:{size:"small"},on:{
              click:()=>{
                that.handleDeleteTopic(row)
              }
             }},'删除')
            return h("div",{class:"flex-wrap split5 flex-center"},[row.state < 2?buttonClose:buttonReset,buttonDelete])
          }
        }]
      }
    },
    methods:{
      handleResetTopic(t){
         this.$api.patch("/topics/"+t.id,{state:1}).then(res=>{
            this.$Notice.success({title:'操作完成'})
            let index = this.items.findIndex(v=>v.id == t.id)
            this.items.splice(index,1,Object.assign({},t,{state:1}))
          }).catch(this.Error)
      },
      handleCloseTopic(t){
        this.Confirm(`确定关闭此主题 "${t.title}"(关闭后不可再回复）`,()=>{
          this.$api.patch("/topics/"+t.id,{state:3}).then(res=>{
            this.$Notice.success({title:'操作完成'})
            let index = this.items.findIndex(v=>v.id == t.id)
            this.items.splice(index,1,Object.assign({},t,{state:3}))
          }).catch(this.Error)
        })
      },
      handleDeleteTopic(t){
        this.Confirm(`确定删除此主题"${t.title}"`,()=>{
          this.$api.delete("/topics/"+t.id).then(res=>{
            this.$Notice.success({title:'删除成功'})
            let index = this.items.findIndex(v=>v.id == t.id)
            this.items.splice(index,1)
          }).catch(this.Error)
        })
      },
      ConfirmToFinish(){
        this.Confirm("确定结束此问题?",()=>{
          
          this.submittingFinish = true
          this.$api.patch("topics/"+this.current.id+'?a=finish').then(res=>{
            this.current.state = 2
          }).catch(this.Error).finally(()=>{
            this.submittingFinish = false
          })
        })
      },
      getData(){
        this.$api.get("topics?n=cm").then(res=>{
          this.items = res.data.data
        })
      },
      handleCreate(){
        let index = this.menus.findIndex(v=>v.key == this.actived)
        if(index < 3)
          this.formData.type = this.actived
        this.showEditor = true
      },
      handleSubmit(){
        this.$refs.formCreate.validate(valid=>{
          if(valid){
            this.formData.namespace = "cm"
            this.$api.post("topics",this.formData).then(res=>{
              let updateInfo = res.data.data
              let item = Object.assign({},this.formData,updateInfo)
              this.items.splice(0,0,item)
              this.showEditor = false
            })
          }
        })
      },
      handleOpen(e){
        this.actived = e
      },
      handleDeleteComment(e){
        this.Confirm("确定删除这条评论",()=>{
          this.$api.delete(`/topics/${this.current.id}/comments/${e}`).then(res=>{
            let index = this.current.comments.findIndex(v=>v.id == e)
            this.current.comments.splice(index,1)
            this.current.comment_count--
            this.$Notice.success({title:"删除成功"})
          })
        })
      },
      handleTableEvent(e){
        if(e.type == 'open'){
          this.loadingTopic = true
          
          this.current = e.data
          
          this.showDetail = true
          this.$api.get("/topics/"+e.data.id).then(res=>{
            this.current = res.data.data
          }).finally(()=>{
            this.loadingTopic = false
          })
          
        }
      },
      handleSubmitComment(){
        this.submitting = true
        this.$api.post("/topics/"+this.current.id+"/comments",{content:this.comment}).then(res=>{
          let item = Object.assign({content:this.comment},res.data.data)
          this.current.comments.splice(0,0,item)
          this.current.comment_count++
          this.comment = ""
        }).catch(this.Error).finally(()=>{
          this.submitting = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>