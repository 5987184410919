<style lang="less">
.pm-count{
  color:var(--primary);
  padding:0px 15px;
  cursor: pointer;

  .l-label{
    font-size:0.8rem;
    line-height:0.8rem;
    filter:brightness(0.9);
  }
  .l-count{
    font-size:1.5rem;
    font-weight: bold;
  }
}

.pm-count:hover{
  filter:brightness(1.1);
}

.pm-count-empty{
  color:var(--disabled);
}

.pm-group{
  margin-right:20px;
  .l-name{
    width:100%;
    padding:0 15px;
    color:#aaa;
    margin-bottom:5px;
  }
}
.pm-group:last-child{
  margin:0;
  border:none;
}

</style>

<template>
  <div class="flex-wrap flex-between" style="border-bottom:1px solid #eeeeee;padding-bottom:9px;width:100%;">
    <template v-for="g in groups">
      <div :key="g.name" class="pm-group">
          <div v-if="g.name" class="l-name">{{g.name}}</div>
          <div class="flex-wrap flex-line flex-between">
            <template v-for="c in g.counts">
              <div class="pm-count flex-col align-center flex-center" :class="{
                'pm-count-empty':!c.count
              }" :key="c.name"
                @click="RouteTo(c.path)"
              >
              
                <div class="l-count">{{c.count}}</div>
                  <div class="l-label">{{c.name}}</div>
              </div>
            </template>
          </div>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props:{
      data:{
        type:Array,
        default:()=>([])
      }
    },
    computed:{
      groups(){
        if(Array.isArray(this.data) && this.data.length > 0 && this.data[0].counts){
          return this.data
        }else{
          return {
            counts:Array.isArray(this.data)?this.data:[]
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>