const ProductServer = 'https://api.inbgz.com'
const LocalServer = 'http://192.168.15.212:6005'
const Home = 'http://192.168.15.212:6005' // 'http://192.168.0.112:6005' //'http://localhost:6005' //
const ProductAIServer = 'https://chat.inbgz.com/api'
const LocalAIServer = 'http://192.168.15.212:6018'
const HomeAIServer =null// 'http://localhost:6018'
module.exports = {
  version: 1006,
  server: process.env.VUE_APP_MOCK ? '/' : (process.env.NODE_ENV == 'production' ? ProductServer : (Home || LocalServer)),
  ai_server: process.env.VUE_APP_MOCK ? '/' : (process.env.NODE_ENV == 'production' ? ProductAIServer : (HomeAIServer || LocalAIServer)),
  cosServer: 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/'
}
