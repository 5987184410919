<template>
  <div class="l-container">
    <div style="font-size:12px;margin:10px 0;text-align:center;color:var(--text2);font-weight:bold;">{{ name }}</div>
    <div class ref="chart" style="height:300px;width:200px;position:relative;"></div>
  </div>
</template>
  
  <script>
import * as echarts from "echarts/core";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);
export default {
    props:{
        name:{
            type:String
        },
        data:{
            type:Array,
            default:()=>([])
        }
    },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: "item",
          
        },
        legend: {
          top: "0%",
          left: "center"
        },
        series: [
          {
            type: "pie",
            radius: ["20%", "60%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: "var(--border)",
              borderWidth: 1
            },
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data:this.data 
          }
        ]
      };
    }
  },
  mounted() {
    setTimeout(() => {
      var chartDom = this.$refs.chart;
      console.log(chartDom);
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption(this.option);
    },500);
  }
};
</script>