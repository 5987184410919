<template>
  <div class="flex-col flex-center container login">
    <!-- <img src="@/assets/bg.png" class="absolute-full" /> -->
    <img src="https://inbgz-1257839135.cos.ap-shanghai.myqcloud.com/inbgz-portal/2.0.0/img/light.89ce8b1d.jpg" class="absolute-full" height="100%" style="left:-600px;filter:blur(15px) brightness(0.7)" />
     
    <div class="flex-wrap login-wrap m-light">
       <div class="login-header"> 
        高专企业信息平台 
        
      </div>
      <div class="square-divider">
        <div></div>
        <div></div>
        <div></div>
        <div style="margin-right:20px"></div>
        <span class="login-version">inbgz-OA v2.0 beta</span>
        <div style="margin-left:20px"></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="flex-wrap">
     
      <Form class="login-form" :label-width="40">
        <div class="flex-wrap" style="color:#fff">
          <BaseIcon :icon="current_mode.icon" size="16" style="margin-right:5px" />
          {{current_mode.title}}
        </div>
        <transition name="fade">
          <keep-alive>
          <PasswordForm v-if="mode == 'pass'" v-model="formData" @on-login="handleLogin" />
          <DingForm v-else-if="mode == 'ding'" @on-login="handleLogin" />
          <WechatForm v-else-if="mode == 'wechat'" @on-login="handleLogin" />
          <SMSForm v-else-if="mode == 'sms'"  v-model="formData" @on-login="handleLogin" />
          </keep-alive>
        </transition>
        <ModeSelect 
          :modes="modes" 
          v-model="mode" 
          style="margin-top:15px" 
          @input="handleChangeMode" 
        />
        <div class="mode-prompt">
          新用户可通过短信/扫码方式注册
        </div>
      </Form>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import LoginScene from '@components/scene/occean'
import BaseIcon from '@components/base/Icon'
import Copyright from '@components/login/copyright'
import ModeSelect from '@components/login/ModeSelect'
import WechatForm from '@components/login/Wechat'
import DingForm from '@components/login/Ding'
import PasswordForm from '@components/login/PasswordForm'
import SMSForm from '@components/login/SMS'
export default {
  routerOption: {
    as_index: true
  },
  data() {
    return {
      mode: "pass",
      formData:{}
    }
  },
  metaInfo: {
    title: "用户登录"
  },
  mounted() {
    this.$nextTick(() => {
      this.LoginInWechat()
      if (this.$refs.user)
        this.$refs.user.focus()
      let mode = localStorage.getItem('LOGIN_MODE')
      if(mode)
        this.mode = mode
       document.getElementsByTagName('body')[0].className = ""

    })
  },
  components: { LoginScene, BaseIcon, Copyright, ModeSelect,PasswordForm,SMSForm, WechatForm,DingForm },
  computed: {
    current_mode() {
      return this.modes.find(v => v.key == this.mode)
    },
    modes() {
      return [{
        key: 'pass',
        icon: 'md-lock',
        title: "账号 / 密码登陆",
        size: 21,
        color: "#39f"
      }, 
      // {
      //   key: 'wechat',
      //   icon: 'wechat',
      //   title: "微信扫码登录",
      //   size: 21,
      //   color: "yellowgreen"
      // },
       {
        key: "ding",
        icon: 'dingding-o',
        title: "钉钉扫码登录",
        color: "#39f"
      },
      {
        key: "sms",
        icon: 'phone',
        title: '短信验证登录',
        size: 14,
        color: "#39f"
      }
      ]
    }
  },
  methods: {
    LoginInWechat(){
      // var ua = navigator.userAgent.toLowerCase()
      // if(ua.match(/MicroMessenger/i) == "micromessenger"){
      //   const REDIRECT_URI = encodeURIComponent("https://a.inbgz.com/oauth2")
      //   window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.$store.getters['session/wechatAppID']}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_userinfo&state=WECHAT`
      // }
    },
    handleChangeMode(mode) {
      localStorage.setItem('LOGIN_MODE',mode)
    },
    handleLogin(){
      if(this.$store.getters['session/mobile'] == true){
        this.RouteTo("/core/ai")
      }else
        this.RouteTo(this.$store.getters['session/login-url'])
    }
  }
}
</script>

<style lang="less" scoped>
</style>