<template>
  <BaseLayout :panels="panels"  :gutter="10" 
    :padding="40"  />
</template>

<script>
  export default {
    computed:{
      panels(){
        return [{
          x:0,y:0,w:2,h:1.5,control:"PanelBillBoard",data:{name:"用户",value:124,unit:"名"}
        },
        {
          x:2,y:0,w:2,h:1.5,control:"PanelBillBoard",data:{name:"企业",value:2,unit:"家"}
        },{
          x:4,y:0,w:2,h:1.5,control:"PanelBillBoard",data:{name:"应用",value:12,unit:"个"}
        },{
          x:6,y:0,w:2,h:1.5,control:"PanelBillBoard",data:{name:"文件",value:12.8,unit:"TB"}
        },{
          x:0,y:2,w:8,h:4,control:"PanelEmpty",data:"用户增长曲线"
        },{
          x:0,y:7,w:8,h:4,control:"PanelEmpty",data:"用户增长曲线"
        },{
          x:9,y:0,w:3,h:1.5,control:"PanelBillBoard",data:{name:"服务器负载",value:87.3,unit:"%"}
        },{
          x:12,y:0,w:3,h:1.5,control:"PanelBillBoard",data:{name:"数据库负载",value:87.3,unit:"%"}
        },{
          x:15,y:0,w:3,h:1.5,control:"PanelBillBoard",data:{name:"存储容量",value:87.3,unit:"%"}
        },{
          x:9,y:2,w:8,h:4,control:"PanelEmpty",data:"负载状态曲线"
        },{
          x:9,y:7,w:8,h:4,control:"PanelEmpty",data:"用户增长曲线"
        },{
          x:18,y:0,w:7,h:8,control:"PanelEmpty",data:"负载状态曲线"
        },]
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>