<style lang="less">
.ivu-poptip,.ivu-poptip-rel{
  display: block;
}
</style>

<template>
<div class="flex-wrap">
  <template v-if="uid || _userinfo.id">
    <Poptip
    padding="0"
    placement="top"
    style="display:block;padding-top:3px;"
    :disabled="disabled"
    popper-class=""
    transfer
    trigger="hover">
       <div class="flex-wrap" style="height:100%" >
           <Avatar v-if="!hideAvatar"  style="background:var(--bg1);color:var(--text1);font-weight:normal;"  :src="_userinfo.avatar" size="small" :style="`line-height:${size}px;marginRight:8px;${size?('height:'+size+'px;width:'+size+'px'):''}`">{{_userinfo && _userinfo.name?_userinfo.name[0]:(uid||'-')}}</Avatar>
          <div class="flex-col" style="align-items:flex-start;height:100%;" v-if="!hideInfo">
              <span v-if="!hideName" :style="`font-weight:bold;color:${_userinfo.id?'var(--success)':'red'}`">{{_userinfo.name || uid.slice(0,6)}}</span>
              <slot></slot>
          </div>
      </div>
      <div slot="content" style="background:var(--bg3);padding:10px;padding-right:20px;">
      <div class="flex-wrap" style="align-items:flex-start;" >
        <Avatar :src="_userinfo.avatar" :style="{width:'30px',height:'30px',borderRadius:'50%',marginRight:'8px',lignHeight:size+'px'}">{{_userinfo && _userinfo.name?_userinfo.name[0]:(uid||"-")}}</Avatar>
        <div style="margin-left:10px;">
          <div style="font-size:16px;text-align:left;">{{_userinfo.id?_userinfo.name:uid}}</div>
          <div style="font-size:14px;font-weight:bold;">{{_userinfo.phone}}</div>
          <div style="font-size:12px;text-align:left;">{{['男','女'][_userinfo.gender]}}</div>
          <div><a href="#" @click="RouteTo('/core/persons/'+uid)" style="font-size:12px;">查看动态</a></div>
        </div>
      </div>
  </div>
    </Poptip>
  </template>
  <template v-else>
    <span>无</span>
  </template>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name:"BaseNameBoard",
  computed:{
    ...mapGetters('session',['users']),
     _userinfo(){
      if(this.userinfo)
        return this.userinfo
      else if(this.uid)
        return this.users.find(v=>v.id == this.uid) || {}
      else
        return {}
    },
  },
  mounted(){
    setTimeout(()=>{
      if(this.users && this.users.length == 0)
        this.$store.dispatch("session/getUsers")
    },1000)
  },
  props:{
    'uid':{
      type:String
    },
    'userinfo':{
      type:Object,
      default:null
    },
    'size':{
      type:Number | String,
      default:20
    },
    'hideInfo':{
      type:Boolean
    },
    'hideName':{
      type:Boolean
    },
    'hideAvatar':{
      type:Boolean
    },
    "disabled":{
      type:Boolean
    }
  }
}
</script>

<style>

</style>
