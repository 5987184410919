<template>
<div class="flex-wrap" style="flex-wrap:wrap">
  <template v-for="uid in maxShow?uids.slice(0,maxShow):uids">
    <BaseNameBoard style="width:15px;overflow:visible;" :style="`width:${showName?80:15}px`" :key="uid" :hide-name="!showName" :uid="uid" />
    
  </template>
  <Tooltip>
    <Avatar size="small"  v-if="uids.length > maxShow">+{{uids.length - maxShow}}</Avatar>
    <div class="flex-wrap" slot="content" style="padding:10px">
       <template v-for="uid in maxShow?uids.slice(maxShow):uids">
        <BaseNameBoard  :key="uid" hide-name :uid="uid" />
      </template>
    </div>
  </Tooltip>
  
</div>
</template>

<script>
export default {
  props:{
    uids:{
      type:Array,
      default:()=>([])
    },
    maxShow:{
      type:Number,
      default:3
    },
    showName:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    users(){
      return this.$store.getters["session/users"]
    }
  }
}
</script>

<style lang="less">
.name-board{
  
}
</style>