<template>
  <Modal 
    :value="value"
    footer-hide
    :title="(data.id?'编辑':'新建') + '投标项目'"
    @input="$emit('input',$event)"
    :width="960"
    >  
    <Form 
        :model="formData"
        :rules="rules"
        :label-width="110"
        ref="form"
    >
    <div class="flex-wrap" style="align-items:flex-start">
        <div style="width:50%;flex-shrink:0;">
    <BaseFormSection title="项目信息"> 
        <Row>
            <Col :span='22'>
            <FormItem 
                label="项目名称"
                prop="name"
                required
            >
                <Input 
                    v-model="formData.name"
                    clearable
                    placeholder="请输入" 
                />
            </FormItem>
        </Col>
        </Row>

        <Row>
            <FormItem 
                label="服务范围"
                prop="services"
            >
                <CheckboxGroup v-model="formData.services">
                    <template v-for="s in services">
                        <Checkbox 
                            :key="s.id"
                            :label="s.id"
                            border
                            style="margin-bottom:8px"
                        >{{s.name}}</Checkbox>
                    </template>
                   
                </CheckboxGroup>
            </FormItem>
        </Row>

        <Row>
            <FormItem 
                label="涉及部门"
                prop="f9"
            >
                <CheckboxGroup v-model="formData.f9">
                    <template v-for="s in deps">
                        <Checkbox 
                            :key="s.id"
                            :label="s.id"
                            border
                            style="margin-bottom:8px"
                        >{{s.name}}</Checkbox>
                    </template>
                   
                </CheckboxGroup>
            </FormItem>
        </Row>
        
        <Row>
            <FormItem 
                label="工程类别"
                prop="building_categories"
            >
                <CheckboxGroup v-model="formData.building_categories">
                    <template v-for="s in building_categories">
                        <Checkbox 
                            :key="s.id"
                            :label="s.id"
                            border
                            style="margin-bottom:8px"
                        >{{s.name}}</Checkbox>
                    </template>
                    
                </CheckboxGroup>
            </FormItem>
        </Row>
        <Row>
            <FormItem 
                label="招标控制价"
                prop="controling_price"
            >
            <div class="flex-wrap">
                <Input 
                    v-model="formData.controling_price"
                    clearable
                    number
                    style="width:100px;margin-right:10px;"
                    placeholder="请输入" 
                />
                 万元
                </div>
            </FormItem>
           
        </Row>
        
        <Row>
            <FormItem 
                label="报价区间"
                prop="max_price"
            >
            <div class="flex-wrap">
                <Input 
                    v-model="formData.max_price"
                    clearable
                    number
                    style="width:100px;margin-right:10px;"
                    placeholder="最低" 
                />
                至
                <Input 
                    v-model="formData.min_price"
                    clearable
                    number
                    style="width:100px;;margin-left:10px;margin-right:10px;"
                    placeholder="最高" 
                />
                 万元
                </div>
            </FormItem>
           
        </Row>
        
        
        <Row>
            <Col :span="24">
            <FormItem 
                label="评标办法"
                prop="bid_eval_method"
            >
            
                <RadioGroup v-model="formData.bid_eval_method">
                    <template v-for="s in bid_eval_methods">
                        <Radio 
                            :key="s.name"
                            :label="s.name"
                            border
                            style="margin-bottom:8px"
                        >{{s.name}}</Radio>
                    </template>
                </RadioGroup>
            
            </FormItem>
        </Col>
           
        </Row>
        <Row>
            <Col :span="22">
            <FormItem 
                label="备注"
                prop="note"
            >
            
            <Input 
                    v-model="formData.note"
                    clearable
                    :rows="3"
                    type="textarea"
                    placeholder="请输入" 
                />
            
            </FormItem>
        </Col>
           
        </Row>
    </BaseFormSection>

   
       
       </div>
       <div> 
        
    <BaseFormSection title="发布信息"> 
        <Row>
            <Col :span="24">
            <FormItem 
                label="网址"
                prop="link"
            >
            <div class="flex-wrap">
                <Input 
                    v-model="formData.link"
                    clearable
                    placeholder="请输入" 
                />
                </div>
            </FormItem>
            </Col>
        </Row>
        <Row>
            <FormItem 
                label="发布时间"
                prop="published_at"
            >
                <BaseDate 
                    v-model="formData.published_at"
                    clearable
                />
            </FormItem>
        </Row>
        <Row>
            <FormItem 
                label="发布平台"
                prop="published_platform"
            >
                 <RadioGroup v-model="formData.published_platform">
                    <template v-for="s in platforms">
                        <Radio 
                            :key="s.name"
                            :label="s.name"
                            border
                            style="margin-bottom:8px"
                        >{{s.name}}</Radio>
                    </template>
                </RadioGroup>
            </FormItem>
        </Row>
    </BaseFormSection>
        <BaseFormSection title="招标人"> 
        <Row>
            <Col :span="24">
            <FormItem 
                label="招标单位"
                prop="bid_unit"
            >
                <Input 
                    v-model="formData.bid_unit"
                    clearable
                    placeholder="请输入" 
                />
            </FormItem>
            </Col>
            <Col :span="10">
            <FormItem 
                label="联系人"
                prop="contact_name"
            >
                <Input 
                    v-model="formData.contact_name"
                    clearable
                    placeholder="请输入" 
                />
            </FormItem>
        </Col>
            <Col :span="12">
            <FormItem 
                label="联系电话"
                prop="contact_phone"
            >
                <Input 
                    v-model="formData.contact_phone"
                    clearable
                    placeholder="请输入" 
                />
            </FormItem>
            </Col>
        </Row>
    </BaseFormSection>
    <BaseFormSection title="流程配置"> 
        <FormItem label="发起审批流">
            
        <i-switch v-model="formData.use_flow"></i-switch>
        </FormItem>
        <FlowSettings
            style="padding:10px 20px;border:1px dashed var(--border);"
            v-if="formData.use_flow"
            :value="flow.data?flow.data.FlowSetting:{}"
            :option="flow && flow.activeVersion?flow.activeVersion.option:{}"
            :editable="true"
            :db="{...formData,...flow.data}"
            :current="flow.state"
        />
    </BaseFormSection>
</div>
    
    </div>
    <div class="flex-wrap flex-right" style="margin-right:10px">
        <Button 
            type="primary"
            style="margin-right:10px"
            icon="md-checkmark"
            @click="handleSubmit"
            :loading="loading"
        >
            提交
        </Button>
        <Button @click="handleCancel">取消</Button>
       </div>
    </Form>
  </Modal>
</template>

<script>
import UTIL from '@/utils'
import {cloneDeep} from "lodash"
import moment from 'moment'
import FlowSettings from '@/components/form/dynamic_controls/FlowSettings'
export default {
    components:{FlowSettings},
    props:{
        value:{
            type:Boolean
        },
        data:{
            type:Object,
            default:()=>({})
        }
    },
    mounted(){
        this.getFlow()
        this.getDeps()
        if(this.data && this.data.id)
            this.formData = cloneDeep(this.data)
        else
            this.formData = this.initFormData()
    }, 
    data(){
        return {
            flow:{},
            formData:{},
            deps:[],
            loading:false,
            building_categories:[{
                id:1,
                name:"土建"
            },{
                id:2,
                name:"学校"
            },{
                id:3,
                name:"改造"
            },{id:4,
            name:"其他"}],
            bid_eval_methods:[{
                name:'综一',

            },{name:'综二'},
            {name:'评定分离:票决'},{name:'评定分离:价格'}],
            platforms:[{name:"市内"},{name:"市外"},{name:"省外"},{name:"其他"}],
            services:[{
                id:1,
                name:"全过程咨询",
                color:'#369',
                icon:'md-apps'
            },{
                id:2,
                name:"项目管理",
                color:'#369',
                icon:'md-apps'
            },{
                id:3,
                name:"市政监理",
                color:'#369',
                icon:'md-apps'
            },{
                id:4,
                name:"房建监理",
                color:'#369',
                icon:'md-apps'
            },{
                id:5,
                name:"其他",
                color:'#369',
                icon:'md-apps'
            }]
        }
    },
    computed:{
        rules(){
            return {
                name:{
                    required:true,
                    message:"必须填写项目名称"
                }
            }
        }
    },
    methods:{
        getDeps(){
            this.$api.get(`/deps?q=subject`).then(res=>{
                this.deps = res.data.data
            }).catch(e=>{
                this.Error(e)
            })
        },
        getFlow(){
            this.$api.get(`/oa/flows/contract-approval?q=create`).then(res=>{
                this.flow = res.data.data
            }).catch(e=>{
                this.Error(e)
            }).finally(()=>{
                this.loading = false
            })   
        },
        initFormData(){
            return {
                services:[1],
                published_at:moment().format()
            }
        },
        stopLoading(){
            this.loading = false
        },
        handleSubmit(){
            this.$refs.form.validate((valid)=>{
                if(valid){
                    this.loading = true
                    if(this.data.id){
                        let updateData = UTIL.compare_patch_object(this.data,this.formData,)
                        this.$emit('on-event',{type:'update',value:updateData})
                    }else{
                        this.$emit('on-event',{type:'create',value:this.formData})
                    }
                    
                }
            })
        },
        handleCancel(){
            this.$emit('on-event',{type:'cancel'})
        }
    }
}
</script>

<style>

</style>