<template>
  <Row :gutter="config.gutter" style="width:100%">
   
    <template v-for="(p,i) in cols">
      <Col :key="p" :span="span" >
      <draggable tag="div" :key="p"  :list="list[i]" :group="{animation: 300,
        group: 'control',
        disabled: false,
        ghostClass: 'ghost'
      }"  style="background:#ffffffee;min-height:150px;width:100%;display: block;padding:10px 0;border:1px dashed #aaa;">
        <div v-if="!list || list[i].length == 0">
          拖拽至此处
        </div>
        <template v-else>
          <template v-for="p in list[i]">
             <component 
                    :is="p.control" 
                    :required="p.required"
                    :label="p.name"
                    :key="p,key"
                    :config="p.config" 
                    :editable="editable" 
                  
                  />
          </template>
            </template>
          </draggable>
      </Col>
       
    </template>
   
  </Row>
</template>

<script>
  export default {
    name:"分栏",
    icon:"biaoge",
    props:{
      list:{
        type:Object,
        default:()=>({})
      },
      model:{
        type:Object,
        default:()=>({})
      },
      old:{
        type:Object,
        default:()=>({})
      },
      editable:{
        type:Boolean
      },
      config:{
        type:Object,
        default:()=>({})
      }

    },
    computed:{
      cols(){
        return this.config.cols || 2
      },
      span(){
        return 24 / this.cols
      }
    },
    properties:[
      {
        label:'分栏',
        key:'col',
        control:'Text',
      },
      {
        label:'间距',
        key:'gutter',
        control:'Text'
      }

    ],
    methods:{
      
    },
    initModel(obj){
        let arr =[]
        obj.config = {
          cols:2
        }
        for(let i=0;i<2;i++){
           arr.push({
            index:"slot"+(i+1),subs:[]
           })
        }
        obj.subs = arr
      }
  }
</script>

<style lang="scss" scoped>

</style>