<template>
  <div class="training-class" @click="$emit('on-open','video')">
    <img :src="value.avatar || (value.project && value.project.avatar) || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/misc/px.jpg'"   />
    <div class="flex-wrap flex-between" style="width:100%">
      <div>
      <div class="tc-title" v-if="value.project">{{ value.project.name }}</div>
      <div class="tc-project">{{value.name}}</div>
      <div class="tc-date">{{value.created_at}}</div>
    </div>
     <div class="flex-wrap" style="flex-shrink:0">
        <BaseNameBoard :uid="value.project.charger"  />
     </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
      }
    },
    props:{
      value:{
        type:Object,
        default:()=>({})
      }
    }
  }
</script>

<style lang="less">
.training-class{
  display:flex;
  flex-direction: column;
  width:calc(25% - 10px);
  align-items: flex-start;
  background:var(--bg2);
  margin:5px;
  padding:10px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 10px;
  img{
    width:100%;
    height:140px;
    margin-bottom:10px;
    filter:grayscale(0.75);
  }
  .tc-title{
    font-size:16px;
    font-weight: bold;
  }

  .tc-date{
    font-size:12px;
  }

}

.training-class:hover{
  color:var(--primary);
  img{
    
  filter:none;
  } 
}

.training-class:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}
</style>