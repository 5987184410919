<template>
  <div>
    <Row :gutter="10" class-name="l-bill">
      <Col :span="2">序号</Col>
      <Col :span="3">到账日期</Col>
       <Col :span="4" style="text-align:right" :pull="1">到账金额</Col>
      <Col :span="5">操作</Col>
     
      <Col :span="4">
      操作</Col>
    </Row>
    
    <BaseEmpty v-if="items.length == 0" style="padding:20px;margin:0 -5px;width:calc(100% + 10px);background:var(--bg3);" msg="暂无条目" />
    <template v-for="(data,index) in value">
      <Row :key="data.id" :gutter="10" :class-name="`l-bill ${selected==data?'l-bill-selected':''}`" @click.native="$emit('event',{type:'select',data});selected=data">
      <Col :span="2">{{index+1}}</Col>
      <Col :span="3" style="color:var(--primary)">{{moment(data.transferred_at).format("YYYY-MM-DD")}}</Col>
       <Col :span="4"  :pull="1"><BaseAmount :value="data.amount"></BaseAmount></Col>
      <Col :span="5"></Col>
     
      <Col :span="4">
      <div style="text-align:right">
        <a style="" @click="$emit('event',{type:'edit',data})"><BaseIcon icon='md-create' /></a>
        <a style="margin-left:10px;" @click="$emit('event',{type:'delete',data})"><BaseIcon icon='md-trash' /></a></div> </Col>
    </Row>
    </template>
    <Row :gutter="10" class-name="l-bill">
      <Col :span="2">合计</Col>
      <Col :span="12">
      <BaseAmount :value="amount"></BaseAmount>
      </Col>
    </Row>

    

    <Modal v-model="showEditor" title="编辑开票" footer-hide>
      <div style="padding:10px">

      </div>
    </Modal>
  </div>
</template>

<script>
// A Component to display/edit bills for project
export default {
  name: 'CmBills',
  data(){
    return {
      showEditor:false,
      selected:{},
      current:{}
    }
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    amount() {
      return this.value.reduce((c,item) => {
        return c + item.amount
      }, 0)
    },
    transferAmount(){
      return 0
    }
  }

}
</script>
