<template>
    <div class="container flex-col" style="gap:5px;">
      <div class="flex-wrap" style="width:100%;height:30px;font-weight:bold;">
      <BaseIcon icon="md-apps" style="margin-right:5px" />常用板块 </div>
      <div class="flex-wrap flex-between" style="width:100%;flex-wrap:wrap;gap:5px">
        <template v-for="d in deps.slice(0,3)">
        <div class="flex-wrap flex-between hover-lighter m-light l-link-card" style="flex-shrink:0.5;width:calc(50% - 10px);" :style="`background:var(${d.color || 'var(--bg1)'})`" :key="d.id" @click="openDep(d)">    
          <div class="flex-wrap">
            <BaseIcon :icon="d.icon || 'organization'" size="30" />
            <div class="l-title">
              {{ d.name }}
                <div class="l-sub">部门入口</div>
            </div>
          </div>
          <div class="l-status">
          </div>
        </div>

      </template>
      </div>
      <div style="height:5px;background:var(--bg1);width:100%;" v-if="deps && deps.length > 0"></div>
      <div class="flex-wrap flex-between" style="width:100%;">
        <div class="flex-wrap flex-between hover-lighter m-light l-link-card" style="" :style="`background:var(--error)`" @click="openApp">    
          <div class="flex-wrap">
            <BaseIcon icon="chart" size="30" />
            <div class="l-title">
                人才盘点
                <div class="l-sub">评价入口</div>
            </div>
          </div>
          <div class="l-status">
            <div>
              进行中
            </div>
            <div class="l-sub">截止8月31日</div>
          </div>
        </div>
      </div>
      <div class="flex-wrap flex-between" style="width:100%;">
        <div class="flex-wrap flex-between hover-lighter m-light l-link-card" style="" :style="`background:var(--primary)`" @click="RouteTo(`http://zzlatm.gicp.net:10000/acl/crossLogin.html?uid=${session.zzl_id}&phone=${session.phone}&platform=pmis&href=/v11/index.html`,true)">    
          <div class="flex-wrap">
            <BaseIcon icon="nbgz" size="30" />
            <div class="l-title">
                项目管理平台
                <div class="l-sub">免登陆可直接进入</div>
            </div>
          </div>
          <div class="l-status">
          </div>
        </div>

        <div class="flex-wrap flex-between hover-lighter m-light l-link-card" style="margin-left:5px" :style="`background:var(--warning)`" @click="RouteTo(`http://zzlatm.gicp.net:10020/acl/crossLogin.html?uid=${session.zzl_id}&phone=${session.phone}&platform=jlis&href=/v2/index.html`,true)">    
          <div class="flex-wrap">
            <BaseIcon icon="nbgz" size="30" />
            <div class="l-title">
                监理信息平台
                <div class="l-sub">免登陆可直接进入</div>
            </div>
          </div>
          <div class="l-status">
          </div>
        </div>
      </div>

      <div class="flex-wrap flex-between hover-lighter m-light l-link-card"  @click="RouteTo('/core/photowall')" style="background:var(--success)">    
          <div class="flex-wrap">
            <BaseIcon icon="md-photos" size="30" />
            <div class="l-title">
              照片墙
                <div class="l-sub">找回曾经的记忆，欢迎的大家上传照片</div>
            </div>
          </div>
          <div class="l-status">
          </div>
        </div>

        

      </div>
        
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import {uniqBy} from 'lodash'
    export default {
      data(){
        return {
            appraisalState:'点击进入',
      appraisalStateColor:'var(--primary)',
          item:{
            id:99,
            name:'青苗二期 《现场监理工作规定》解读1',
            teacher:'林密',
            date:'2023-04-21',
            address:"公司总部14楼多功能厅"
          }
        }
      },
      mounted(){
        this.checkAppraisal()
        this.checkOTR()
      },    
      computed:{
        ...mapGetters('session', ['session']),
        deps(){
          return uniqBy(this.session.groups.filter(v=>v.type=='dep' && v.namespaced == 1),a=>a.id)
        }
      },    
      methods:{
        checkOTR(){
          
        },
        checkAppraisal(){
      this.$api.get("/oa/appraisal_instances/self?appraisal_id=2023&simple=1").then(res=>{
        let appraisal = res.data.data
        if(appraisal && appraisal.id){
          if(appraisal.state < 2){
            this.appraisalStateColor = `var(--warning)`
            this.appraisalState = '继续填写'
          }
          else{
            this.appraisalState = '已完成'
            this.appraisalStateColor = `var(--success)`
          }
        }else{
          this.appraisalStateColor = `var(--primary)`
          this.appraisalState = '点击进入'
        }
      })
    },
    openDep(d){
      this.Error("部门模块还在开发中，请稍候再试")
      reuturn
      let base_url = "/core/groups/${d.id}"
          this.$store.commit('session/push',{
            url:base_url+'/dashboard',
            base_url,
            icon:'organization',
            name:d.name
          })
          this.RouteTo(url)
    },
    openApp(){
      let url = "/core/appraisals/otr"
          this.$store.commit('session/push',{
            url,
            base_url:url,
            icon:'supervisor',
            name:'人才盘点'
          })
          this.RouteTo(url)
    },
        handleOpen(){
          let url = "/core/training/list"
          this.$store.commit('session/push',{
            url,
            base_url:url,
            icon:'huiyi',
            name:'学习培训'
          })
          this.RouteTo(url)
        },
        handleOpenVideo(){
          let {id,name} = this.item
          let type = 'video'
          let path = `/core/training/${id}/${type}`
          this.$store.commit('session/push',{
            icon: "logo-youtube",
            type: "video",
            name,
            size: 24,
            path,
            base_url: `/core/training/${id}`
          })
          this.RouteTo(path)
        }
      }
    }
  </script>
  
  <style lang="less">
  .l-activity{
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  .l-activity:hover{
    filter:brightness(1.1);

  }

  .l-activity:active{
    position: relative;
    bottom:-1px;
    right:-1px;
  }

  .l-link-card{
    max-height:70px;
    overflow:hidden;
    padding:10px;flex-grow:1;width:100%;font-size:16px;border-radius:3px;background:var(--primary);color:var(--hover-text);border:1px solid var(--border);box-shadow: 1px 1px 3px 1px #33333322;cursor:pointer;

    .l-title{
      margin-left:10px;font-size:14px;
      
    }

    .l-sub{
        font-size:12px;color:var(--hover-text);font-weight:normal;
      }

    .l-status{
      font-size:16px;text-align:center;filter:brightness(1);
    }

    
  }

  </style>