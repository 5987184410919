
<template>
  <div  class="pm-embed-control" style="width:100%;height:100%;position:relative;">
    <div class="flex-wrap pm-embed-value-wrap" style="width:calc(100% - 32px);overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-size:12px;" :style="{color:value?'#39f':'#39f'}" @click="modalSelector = true">
      {{value || '编辑文档'}}
    </div>
    <div class="flex-wrap flex-center" style="position:absolute;right:0;top:0;bottom:0;width:30px;color:#aaa;" @click="$emit(input,'')">
      <Icon type="md-trash" size="16" />
    </div>

    <Modal v-model="modalSelector" width="1200" title="要点提示" footer-hide>
      <div style="padding:20px">
        <BaseQEditor type="textarea" :rows="10" v-model="value" @input="$emit('input',$event)" />
      </div>
    </Modal>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        form:{},
        modalSelector:false
      }
    },
    props:['value','editable']
  }
</script>

<style lang="scss" scoped>

</style>