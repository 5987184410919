<template>
  <div class="container" style='position:relative;border:5px solid var(--border);'>
    <template v-if='!items || items.length == 0' >
      {{error || "没有内容"}}
    </template>
    <template v-else>
      <Carousel autoplay v-model="value2" loop style='width:100%;'  autoplay-speed='10000' :height="350">
        <template v-for='d in items'>
          <CarouselItem :key='d.title' >
            <a :href="d.link" target="_blank"><div :style='`background:url("${d.image}");background-size:100% 100%;height:350px;display:flex;align-items:flex-end;justify-content:center;color:#fff;text-shadow:1px 1px 1px #333;padding:20px 5px;`' >{{d.content}}</div></a>
        </CarouselItem>
        </template>
    </Carousel>
    </template>
  </div>

</template>

<script>
  export default {
    props:{
      data:{
        type:Object,
        default:()=>({})
      }
    },
    computed:{
      items(){
        if(this.data.raw)
          return this.data.raw
        else
          return []
      }
    }
  }
</script>

<style>

</style>