<template>
  <App>
    <AppBar>
        <AppTitle name="流程管理" icon="lianjieliu" />
        <div></div>
    </AppBar>
    <AppMaskLoading 
        :loading="loading" 
        iconRight="lianjieliu"
        title="流程管理"
    />
    <AppLeft>
        <BaseTree 
            ref="tree"
            :data="nodes" 
            :nodeRenderer="renderNode"
            @event="handleTreeEvent"
        />
    </AppLeft>
    <AppRight style="padding:20px;">
        <div>
        <a  @click="handleCreate"><BaseIcon icon="md-add" /> 创建新流程</a>
        </div>
        <template v-if="selected">
        <Divider />
        <div>
            <div><a  @click="handleCreate"><BaseIcon icon="md-create" /> 编辑流程</a></div>
            <div><a  @click="handleCreate"><BaseIcon icon="md-switch" /> 禁用流程</a></div>
            <div><a  @click="handleCreate"><BaseIcon icon="md-trash" /> 删除流程</a></div>
           
        </div>
        <Divider />
        <div>
            <div><a  @click="handleCreateInstance"><BaseIcon icon="md-add" /> 发起流程</a></div>
        </div>
        </template>
    </AppRight>
    <AppClient 
        :collapse-left="true" 
        :collapse-right="true" 
        style="background:#fff;border-left:1px solid var(--border);border-right:1px solid var(--border);"
    >
        <BaseEmpty v-if="!selected"></BaseEmpty>
        <div class="full-container" style="padding:0 20px;height:100%;overflow:hidden;" v-else>
            <h2 style="margin-top:20px;">{{ selected.name }} <BaseVersion value="v202413" :menus="selected.versions"><span  style="margin-right:5px;color:var(--subtext2)">启用版本</span> </BaseVersion></h2>
            <div class="flex-wrap" style="height:50px"><Input search style="width:200px" /><Button icon="md-refresh" style="margin-left:10px;" @click="getFlow(selected.id)" /></div>
            <div style="position:relative;height:calc(100% - 120px);">
                
                <BaseTable ref="table" full :columns="cols" :data="flow.instances" @event="handleTableEvent" />
            </div>
        </div>
    </AppClient>
  </App>
</template>

<script>
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppClient from '@/components/app/client'
import AppTitle from '@/components/app/title'
import AppMaskLoading from '@/components/app/mask-loading'
import AppLeft from '@/components/app/left'
import AppRight from '@/components/app/right'
import PageStateMixin from '@/mixins/page_state'
import {pick} from 'lodash'
import { mapGetters } from 'vuex'
import UTIL from '@/utils'
export default {
  components:{App,AppBar,AppClient,AppTitle,AppMaskLoading,AppLeft,AppRight},
    routerOption:{
        as_index:true
    },
    mixins:[PageStateMixin],
    data(){
        return {
            loading:false,
            selected:null,
            flows:[],
            nodes:[],
            flow:{},
            pageStateFields:['selected']
        }
    },
    mounted(){
        this.getData()
    },
    computed:{
        cols(){
            return [{
                type:'index',
                width:60,
                title:"序号"
            },{
                type:"text",
                key:"name",
                width:200,
                title:"名称",
                linkEvent:"open"
            },{
                type:"state",
                title:"状态",
                width:120,
                key:'state',
                option:{
                    states:['准备中','审核中','已通过','已退回','已闭合']
                }
            },{
                type:"time",
                key:"created_at",
                width:120,
                title:"创建时间"
            },{
                type:"user",
                key:"created_by",
                width:120,
                title:"创建人"
            },{
                title:"操作"
            }]
        }
    },
    methods:{
        handleCreate(){
            this.$store.commit("session/push", {
                name: "流程编辑器",
                icon: "lianjieliu",
                path: "/core/flows/create/editor",
                base_url: "/core/flows/create"
            });
            this.RouteTo("/core/flows/create/editor");
        },
        handleTableEvent({type,data}){
            if(type == 'open'){
                this.handleOpenInstance(data)
            }
        },
        handleCreateInstance(){
            let flow = this.selected
          let url = `/core/flows/${flow.id}/create`
          this.$store.commit("session/push_flow_create",{
            id:flow.id,
            name:'流程创建-'+flow.name,
          })
          this.RouteTo(url)
        },
        handleOpenInstance(inst){
          let url = `/core/flows/${inst.id}/instance`
          this.$store.commit("session/push_flow_create",{
            id:inst.id,
            name:inst.name,
          })
          this.RouteTo(url)
        },
        renderNode(h,node){
            let domName = h('span',{},node.name)
            let domCount = h('span',{style:'margin-left:3px'},`(${node.count || 0})`)
            return h('div',{
                class:"flex-wrap",
                style:{color:node.count > 0?'var(--text2)':'var(--subtext2)'}
            },[domName,domCount])
        },
        handleTreeEvent({type,data}){
            if(type == 'select'){
                this.selected = data
                this.getFlow(data.id)
                 this.$refs.table.calcTableHeight()
            }
        },
        getData(){
            this.loading = true
            this.$api.get("oa/flows").then(res=>{
                let flows = res.data.data
                let nodes = [{
                    id:'gzcloud',
                    name:'系统流程',
                    count:0,
                    icon:'nbgz'
                },{
                    id:'dd',
                    name:'钉钉流程',
                    count:0,
                    icon:'ding'
                }]
                
                nodes = nodes.concat(flows.map(v=>{
                    return {
                        id:v.id,
                        name:v.name,
                        parent_id:v.parent_id || 'gzcloud'
                    }
                }))
                this.flows = flows
                this.nodes = nodes
                if(this.$refs.tree && this.selected && this.selected.id){
                    this.$refs.tree.select(nodes.find(n=>n.id == this.selected.id))
                     this.getFlow(this.selected.id)
                       this.$refs.table.calcTableHeight()
                }
            }).finally(()=>{
                this.loading = false
            })
        },
        getFlow(id){
            this.loadingData = true
            this.$api.get(`oa/flows/${id}?q=instances`).then(res=>{
                let flow = res.data.data
                //flow.instances = flow.versions[0].instances
                this.flow = flow
            }).catch(e=>{
                this.Error(e)
            }).finally(()=>{
                this.loadingData = false
            })
        },
        getDingFlow(){
            this.$api.get("flows?q=ding").then(res=>{
                this.ding_flows=  res.data.data
                let nodes = nodes.concat(this.ding_flows.map(v=>{
                    return {
                        id:v.id,
                        name:v.name,
                        parent_id:v.parent_id || 'dd'
                    }
                }))
                this.nodes =  this.nodes.concat(nodes)
            })
        }
    }

}
</script>

<style>

</style>