

<template>
<App class="pm-editor">
   <AppBar>
      <div class="flex-wrap">
        <BaseIcon icon="lianjieliu" style="margin-right:5px" :size="20" color="var(--primary)" />
        {{ flowData.name }}
      </div>
      <div class="flex-wrap">
        
      </div>
      <div class="flex-wrap split5">
        <AppAvatar />
        <Divider type="vertical" />
        <Button type="primary" @click="handleSave">保存并上传</Button>
      </div>
    </AppBar>
   <AppMaskLoading :loading="loading" />
    
    <AppClient style="bottom:30px">
      <FlowContent ref='fl' :flow="flowData" @on-submit="Submit" />

    </AppClient>
    <AppStatusBar>
      <div class="flex-wrap split10" style="font-size:12px">
      <div>正在编辑</div>
      <div>节点:{{flowData.nodes.length}}</div>
      <div>连接:{{flowData.edges.length}}</div>
      </div>
      <div></div>
    </AppstatusBar>
  </App>
</template>

<script>
import responsableMixin from '@/mixins/responsable'
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppMaskLoading from '@/components/app/mask-loading'
import FlowLeft from '@/components/flow/editor/left'
import AppRight from '@/components/app/right'
import AppAvatar from '@/components/app/avatar'
import AppClient from '@/components/app/client'
import IconButton from '@/components/app/IconButton'
import IconTabs from '@/components/app/IconTabs'
import TaskMenu from '@/components/task/app/bar/TaskMenu'
import TaskFileName from '@/components/task/app/bar/TaskFileName'
import FlowContent from '@/components/flow/editor/content'
import AppStatusBar from '@/components/app/status-bar'
import cloneDeep from 'lodash'
import UTIL from '@/utils'
export default {
  components: { App, AppStatusBar,AppBar,AppMaskLoading,FlowLeft,AppRight,AppClient,AppAvatar, IconButton,IconTabs, TaskMenu, TaskFileName, FlowContent},
  data(){
    
    return {
      loading: false,
      showMenu: true,
      showDrawerInput:false,
      view: 'task',
      flow:{},
      flowData:{
        nodes:[],
        edges:[]
      }
    }
  },
  mixins:[responsableMixin],
  watch:{
    $route:{
      handler(v){
        this.getData()
      },
      immediate:true,
      deep:true
    }
  },
  activated(){
     this.$nextTick(e=>{
       this.calcLayout()
    })
  },
  computed:{
    showHelper(){
      return this.selected && this.selected.id
    },
     views() {
      const _views = [{
        key: 'task',
        name: "执行",
        icon: "md-create",
      }, {
        key: "flow",
        name: "流程",
        icon: "lianjieliu",
      }, {
        key: "gantte",
        name: "管理",
        icon: "xiangmu",
      }, {
        key: "file",
        name: "资料",
        icon: "folder",
      }, {
        key: "share",
        name: "分享",
        icon: "share1",
      }]
      let o = _views
      if (!this.selected.flow) {
        o = o.filter(v => v.key != 'flow')
        if (!this.subs || this.subs.length == 0)
          o = o.filter(v => v.key != 'gantte')
      }

      return o
    },
   
    admin() {
      return this.role == 'admin'
    },
    permissions() {
      if (this.role == 'admin') {
        return true
      } else return {

      }
    }
  },
  methods:{
    getData(){
      let id = this.$route.params.id
      if(id == 'create'){
        this.Init()
      }else{
        // get
        //  this.flow = 
        // this.flowData = cloneDeep()
      }
    },
    Init(){
      this.flowData = {
        name:'新流程',
        nodes:[],
        edges:[]
      }
    },
    Update(model){
      this.flow = model
    },
    handleSave(){
      let fl = this.$refs.fl
      if(fl){
        fl.Save()
      }
    },
    Submit(model){
      this.$refs.fl.setLoading(true)
      let {id} = this.$route.params
      if(id == 'create'){
        this.$api.post('oa/flows',model).then(res=>{
          let {id} = res.data.data
          this.$route.replace(this.$route.path.replace('create',id))
        }).catch(e=>{
          this.Error("错误:"+e)
        }).finally(()=>{
          this.$refs.fl.setLoading(false)
        })
      }else{
        
        let updateData = UTIL.compare_patch_object(this.flow,model)
        this.$api.patch(`oa/flows/${id}`,updateData).then(res=>{
          
        }).catch(e=>{
          this.Error("错误:"+e)
        }).finally(()=>{
          this.$refs.fl.setLoading(false)
        })
      }
    }
  }
}
</script>

<style lang="less">
.pm-panel-flex{
  display: flex;
}
.pm-panel-editing-wrap:hover{
  filter:brightness(1.2);
  cursor: pointer;
}

.pm-panel-selected{
  background:#fbbd2162 !important;
}

.itask-action-button{
  width:60px;height:60px;background:var(--bg2);margin:5px;border-radius:10px;box-shadow:1px 1px 2px 1px var(--border);padding-top:5px;
  font-size:12px;
  font-weight: bold;
  color:var(--subtext2);
  cursor: pointer;
  transition:all 0.3s;
  border:1px solid var(--border);
}

.itask-action-button:hover{
  filter:brightness(1.05);
}

.itask-action-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}
</style>