
<template>
  <div style="position:relative;height:100%;">
    <div style="width:100%;height:30px;font-weight:bold;">
      <BaseIcon icon="md-apps" style="margin-right:5px" />快捷入口 </div>
    <div class="flex-wrap" style="background:var(--border);flex-wrap:wrap;height:calc(100% - 40px);width:calc(100%);align-items:flex-start;justify-content:space-between;align-content:flex-start;padding:5px;border:1px solid var(--border);">
    <template v-for="a in def_actions">
      <div class="acc-item" :key="a.id" @click="execute(a)" :class="`acc-item-${a.type || 'mod'} acc-item-${a.private?'private':''}`" :style="`width:${col_width}px;height:${row_height}px;font-size:10px;`">
        <Badge :offset="[10,-10]" :dot="a.option && a.option.once && !a.created_at">
        <BaseIcon :icon="a.icon" :size="w<300?(w/300*25):25"  style="font-weight:normal;" :color="a.color || ''" />
        </Badge>
        <div class="aci-name">{{a.name}}</div>
      </div>
     
    </template>
    <template v-for="(a,i) in slots">
       <div class="acc-item acc-item-slot" :key="('slot'+i)" :style="`width:${col_width}px;height:${row_height}px;`" >
          空
       </div>
    </template>
    
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mixinResponsable from '@/mixins/responsable'
import Draggable from 'vuedraggable'
import CreateProjectWizard from '@/components/wizards/project'
import AIPanel from '@/components/panel/Memo'
import FlowInstance from '@/components/flow/instance'
  export default {
    name:"Accelerator",
    panelInfo:{
      name:'快捷按钮面板',
      minWidthSpan:2,
      required:"project"
    },
    mounted(){
      
    },
    components:{Draggable,CreateProjectWizard,AIPanel,FlowInstance},
    data(){
      return {
        modalFlow:false,
        useImage:false,
        saving:false,
        currentAction:{},
        showStore:false,
        search_text:"",
        showCreateProject:false,
          q:"",
        loading:false,
        items:[],
        showChat:false,
        
        edit_actions:[]
      }
    },
    mixins:[mixinResponsable],
    computed:{
      ...mapGetters('session',['session','modules','accelerators']),
      def_actions(){
        return [
        //   {
        // id:1,
        // name:'合同起草',
        // icon:"edit",
        // group:"个性化",
        //  type:"action",
        // size:29,
        // url:"/core/contracts/create/editor"
        // },
        {
        id:2,
        name:'合同审定',
         group:"日常工作",
        icon:"checkreport2",
        type:"flow",
        flow:'contract-approval'
        },{
        id:2,
        name:'用印申请',
         group:"日常工作",
        icon:"renzhengchenggong",
        type:"flow",
        },{
        id:3,
        name:'履约跟踪',
        group:"日常工作",
         type:"action",
        icon:"checkreport3",
        option:{
          namespace:this.id
        },
        action:"upload"
        },{
        id:4,
        name:'付款申请',
         type:"flow",
         group:"行政管理",
         width:700,
        icon:"fenzuguanli",
        flow:"add-notice"
        },{
        id:5,
        name:'开票申请',
        group:"日常工作",
         type:"flow",
        icon:"fenzuguanli",
        action:"project"
        },{
        id:'l-121',
        name:'合同变更',
         type:"link",
         group:"日常工作",
          icon:"checkreport2",
        },{
        id:'l-122',
        name:'纠纷上报',
         type:"flow",
         group:"日常工作",
         flow:'dispute-report',
          icon:"fenzuguanli",
         color:"orange",
        }]
      }, 
      slots(){
        let n = 3
        return new Array(n<=0?0:n)
      },
      show_actions(){
        return this.actions.slice(0,this.slot_count-1)
      },
      slot_count(){
        return this.row_count * this.col_count
      },
      offset(){
        return 5
      },
      col_count(){
        let t = Math.floor(this.w / 50)
        return t > 5?5:t
      },
      row_count(){
        let t = Math.floor(this.h / 60)
        return t> 4?4:t
      },
      row_height(){
        return (this.h - 45) / this.row_count - this.offset - 2
      },
      col_width(){
        return (this.w) / this.col_count - this.offset - 2
      },
      edit_slots(){
        let n = this.slot_count - 1 - this.edit_actions.length
        if(n > 0)
          return new Array(n)
        else
          return []
      },
      colors(){
        return {
          action:"green",
          flow:"#346",
          mod:"var(--bg1)"
        }
      },
      types(){
        return {
          action:"快捷操作",
          mod:"模块",
          create:"新建"
        }
      },
      actions(){
        if(!this.accelerators)
          return []
        return this.accelerators.map(id=>{
          let a = this.modules.find(m=>m.id == id)
          if(!a){
            a = this.def_actions.find(m=>m.id == id) 
          }
          return a
        }).filter(v=>v)
      },
      groups(){
        let g = {}
        
        this.modules.forEach(m=>{
          if(m.group){
            if( g[m.group])
              g[m.group].mods.push(m)
            else
              g[m.group] = {id:m.group,name:m.group,mods:[m]}
           
          }
        })
        this.def_actions.forEach(m=>{
          if(m.group){
            if( g[m.group])
              g[m.group].mods.push(m)
            else
              g[m.group] = {id:m.group,name:m.group,mods:[m]}
          }
        })
        return Object.values(g)
      },
      namespace(){
        if(this.$route.params)
          return this.$route.params.id
      }
    },
    methods:{
       submit(){
        let index = this.items.length
        let q = this.q
        this.q = ""
        this.loading = true
        this.items.push({q})
         this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
         let url = '/ai'
         if(this.useImage)
          url+='?q=image'
        this.$api.post(url,{prompt:q},{timeout:300000}).then(res=>{
          let {id,a,image} = res.data.data
          a = a.replaceAll('\n\n','\n').replace(/^\n/,'')
          this.items.splice(index,1,{id,q,a,image})
          this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight

        }).catch(e=>{
          this.Error(e)
        }).finally(e=>{
          this.loading =false
        })
      },
      action(a){
        if(a == 'chat')
          this.showChat = true
        else if(a == 'project'){
          this.showCreateProject = true
        }
      },
      
      execute(a){
        if(a.url){
          this.$store.commit("session/push",{...a,path:a.url,base_url:a.base_url || a.url})
          this.RouteTo(a.url)
        }
        else if(a.flow){
          let url = `/core/flows/${a.flow}/create`
          this.$store.commit("session/push_flow",{...a})
          this.RouteTo(url)
        }else if(a.action){
          this.action(a.action)
        }
      },
      clear(){
        this.items = []
      },
      handleEdit(){
        this.edit_actions = [...this.actions]
        this.showStore=true
      },
      handleDelete(a){
        let index = this.edit_actions.findIndex(v=>v.id == a.id)
        if(index != -1)
          this.edit_actions.splice(index,1)
      },
      handleSave(){
        this.saving = true
        this.$store.dispatch('session/saveAccelerators',this.edit_actions.map(v=>v.id)).then(()=>{
          this.edit_actions = []
          this.showStore = false
            this.saving = false
        }).catch(()=>{
           this.saving = false
        }).finally(()=>{
          this.saving = false
        })
        
      },
      select(a){
        
        if(a.dev)
          return
        let index = this.edit_actions.findIndex(v=>v.id == a.id)
        if(index == -1)
          this.edit_actions.push({...a})
        else
          this.edit_actions.splice(index,1)
      }
    }
  }
</script>

<style lang="less" scoped>
.acc-item{
  min-width:57px;
  min-height:64px;
  padding:5px;
  margin-bottom:7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor:pointer;
  position: relative;
  overflow: visible;
  border-radius: 3px;
  background:var(--bg3);

 

  .ivu-icon,.aci-name{
   
    color:var(--text2);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:100%;
    margin-top:5px;
    font-size:12px;
  }

  

}



  .acc-item-action{

  .ivu-icon,.aci-name{
      color:var(--success);
      
     }
}

.acc-item-flow{
    .ivu-icon,.aci-name{
    color:var(--primary);
     }
  
}

.acc-item-mod{
  
}

.acc-item-selected {
  overflow:hidden;
  border: 2px solid  var(--primary) !important;
}





.acc-item-selected:after {
  content: "";
  position: absolute;
  right: -12px;
  bottom: -14px;
  width: 26px;
  height: 34px;
  transform: rotateZ(45deg);
  background: var(--primary);
}

.acc-item-selected:before {
  content: "\F375";
  font-family: Ionicons;
  position: absolute;
  right: 1px;
  bottom: 5px;
  width: 11px;
  height: 11px;
  color: #fff;
  z-index: 10;
}

.acc-item-add{
   background:#33333322;
   box-shadow: none;
   .ivu-icon,.aci-name{
   
    color:#aaa;
  }

}

.acc-item-slot{
  background:#33333311;
  color:#aaa;
   box-shadow: none;
   
}

.aci-status{
  font-size:12px;
  color:var(--primary);
  line-height:12px;
}
.acc-item:nth-child(5n){
  border-right:none;
}

.acc-item:nth-child(n+6){
  border-bottom:none;
}

 .acc-item:hover{
   background:var(--primary);
   .ivu-icon,.aci-name{
     color:var(--hover-text);
    }
   
   
  }
  .acc-item:active{
    position: relative;
    right:-1px;
    bottom:-1px;
  }

  .acc-item-add:hover,.acc-item-slot:hover{
    background:inherit;
    .ivu-icon,.aci-name{
      color: var(--primary);
    }
  }

  .h5-modal{
    .ivu-modal{
      .ivu-modal-content{
        
    .ivu-modal-body{
      padding:0 !important;
    }
      }
    }
  }

  .acc-item-private:before{
     
        content:'P';
        position: absolute;
        right:6px;
        top:2px;
        font-weight: bold;
        color:var(--text3);
      
    
  }

  .acc-item-dev,.acc-item-dev:hover{
  background:url('https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/300.png');
  background-size:100%;
  box-shadow: -1px -1px 3px 1px #333333aa;
  cursor: not-allowed;
 .ivu-icon,.aci-name{
 color:#fff;
 text-shadow: 1px 1px 1px #333;

 }
}


</style>