<template>
  <AutoComplete v-model="value" :size="size" :data="sdata" transfer capture :filter-method="handleFilter" clearable :placeholder="placeholder || '请输入'"/>
</template>

<script>
  export default {
    data(){
      return {
        sdata:[]
      }
    },
    props:{
      value:{
        type:String,
        default:""
      },
      url:{
        type:String
      },
      size:{
        type:String
      },
      placeholder:{
        type:String
      },
      data:{
        type:Array,
        default:()=>([])
      }
    },
    mounted(){
      this.sdata = this.data
      if(this.url){
        setTimeout(()=>{
            
          this.$api.get(this.url).then(res=>{
            this.sdata = res.data.data
          })
        },500)
      }
    },
    methods:{
      handleFilter(value,option){
        return option.includes(value)
          
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>