<template>
  <div class="flex-wrap split5" style="position:relative">
    <template v-for="m in menus">
      <div 
      class="app-menu-item flex-wrap" 
      :key="m.key"
      :class="{
        'app-menu-item-actived':activedKey == m.key
      }"
      @click="handleClick(m.key)"
      >
        <Divider type="vertical" v-if="m.divider" style="margin-right:20px" />
        <BaseIcon :icon="m.icon" :size="18" style="margin-right:5px;margin-bottom:2px;"></BaseIcon>
        <div><Badge :dot="m.new_count">{{m.name}}</Badge></div>
      </div>
    </template>
    <div class="actived-spin" :style="{left:activedLeft+'px'}"></div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        activedLeft:0
      }
    },
    props:{
      menus:{
      type:Array,
      default:()=>([])
      },
      activedKey:{
        type:String
      },
      baseRoute:{
        type:String
      },
      custom:{
        type:Boolean,
        default:false
      }
    },
    mounted(){
      this.$nextTick(()=>{
        setTimeout(this.updateActivedLeft,300)
      })
    },
    watch:{
      activedKey:{
        handler(v){
           this.$nextTick(()=>{
            setTimeout(this.updateActivedLeft,300)
          })
        },
        immediate:true,
        deep:true
      }
    },
    methods:{
      updateActivedLeft(){
        let activeItem = this.menus.find(t=>t.key ==  this.activedKey)
        if(!activeItem)
          return 0
        let activedDom = document.querySelector('.app-menu-item-actived')
        if(activedDom)
          this.activedLeft = activedDom.offsetLeft + activedDom.clientWidth / 2 - 15 + (activeItem.divider?15:0)
      },
      handleClick(key){
        if(this.custom){
          this.$emit('on-click',key)
          return
        }
        let path = key
        if(this.baseRoute)
          path = this.baseRoute + key
        this.RouteTo(path)
        setTimeout(this.updateActivedLeft,500)
      }
    }
  }
</script>

<style lang="less">
.app-menu-item{
  font-size:15px;
  margin:0 10px;
  cursor: pointer;
}
.app-menu-item:hover,.app-menu-item-actived{
  color:var(--primary);
}

.actived-spin{
  position: absolute;
  left:0;
  bottom:-4px;
  height:4px;
  width:28px;
  border-radius:5px;
  background:var(--primary);
  transition:all 0.2s ease-out;
}
</style>