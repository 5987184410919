<template>
  <Select :size="size" :value="value" @input="$emit('input',$event)" transfer clearable placeholder="请选择" filterable>
    <template v-if="valueType == 'object'">
        <Option v-for="(o) in options" :key="'vop_'+o.id" :label="o.name" :value="o.id">
      {{o.name}}
    </Option>
    </template>
    <template v-else>
    <Option v-for="(o,index) in options" :key="'vop_'+o" :label="o" :value="valueType=='index'?index:o">
      {{o}}
    </Option>
    </template>
  </Select>
</template>

<script>
  export default {
    props:{
      value:{
        type:String | Array
      },
      options:{
        type:Array
      },
      size:{
        type:String
      },
      valueType:{
        type:String,
        default:"index"
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>