<template>
  <div class="flex-wrap split5 icon-tabs">
    <div class="animate-spin" :style="`left:${items.findIndex(v=>v.key == current) * 37}px`">
    </div>
    <template v-for="(v) in items">
      <Tooltip :key="v.name" :content="v.name">
        <BaseIcon :icon="v.icon" size="15" @click="$emit('on-select',v.key)" :color="current===v.key?'#fff':'#888'" style="border-radius:7px;border:2px solid #fff;padding:4px;position:relative;cursor:pointer;z-index:5;transition:all 0.5s;" :style="{'border-color':current===v.key?'#fff':'#888'}"></BaseIcon>
      </Tooltip>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    current: {
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.in-app {
  .icon-tabs {
    position: relative;
    padding-left:1px;
  }

  .animate-spin {
    padding: 2px;
    background: var(--primary);
    border-radius: 8px;
    position: absolute;
    left: 0;
    width: 31px;
    height: 31px;
    transition: all 0.1s;
  }
}
</style>