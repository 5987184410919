<template>
	<div class="container" style="padding: 10px;background:#33333311">
		<div class="tool-box">
			<Input search style="width:220px" v-model="filter.searchText" placeholder="请输入合同名称查询" clearable />
      <Select placeholder="业务类型" v-model="filter.business_type" style="margin-left:10px;width:140px"  clearable >
        <template v-for="(r,i) in business_types">
          <Option :value="r" :key="i">{{r}}</Option>
        </template>
      </Select>
      <Select placeholder="归属部门" v-model="filter.dep" style="margin-left:10px;width:140px"  clearable >
        <template v-for="(r,i) in deps">
          <Option :value="r.id" :key="i">{{r.name}}</Option>
        </template>
      </Select>
      <Select placeholder="履约状态" v-model="filter.status" style="margin-left:10px;width:140px"  clearable >
        <template v-for="(r,i) in status">
          <Option :value="r" :key="i">{{r}}</Option>
        </template>
      </Select>
      <Select placeholder="合同金额" v-model="filter.amount_region"  style="margin-left:10px;width:140px"  clearable >
        <template v-for="(r,i) in amount_regions">
          <Option :value="i" :key="i">{{r.name}}</Option>
        </template>
      </Select>
		</div>
		<div
			style="height: calc(100% - 40px); position: relative; margin-top: 10px;background:#fff;"
		>
			<BaseTable
				:columns="columns"
				:loading="loading"
				:data="filteredData"
				:option="{ summary: true, sort_key:'updated_at'}"
				@event="handleTableEvent"
			/>
		</div>

		<Modal
			v-model="showModalDeps"
			title="归属部门及产值划分"
			footer-hide
			:mask-closable="false"
		>
			<div style="padding: 0px">
				<div
					class="flex-wrap flex-right"
					style="
						padding: 5px 3px;
						border-bottom: 1px dashed var(--border);
						margin-bottom: 5px;
					"
				>
					合同总额
					<BaseAmount style="margin-left: 10px" full :value="current.amount" />
				</div>
				<CheckboxGroup v-model="current_deps">
					<div class="flex-col" style="align-items: flex-start">
						<template v-for="d in deps">
							<div
								class="flex-wrap flex-between flex-line"
								style="width: 100%; padding: 3px"
								:key="d.id"
							>
								<Checkbox :label="d.id">{{ d.name }}</Checkbox>
								<div class="flex-wrap" v-if="current_deps.includes(d.id)">
									<Input
										style="width: 70px; margin-right: 5px"
										size="small"
										v-model="current_dep_values[d.id].percent"
										number
									>
										<template #append>
											<span>%</span>
										</template>
									</Input>
									<Button
										size="small"
										icon="ios-arrow-forward"
										@click="handleCalcAmount(d.id)"
									/>
									<Input
										style="width: 100px; margin-right: 5px; margin-left: 5px"
										size="small"
										@on-change="handleChangePercent(d.id)"
										v-model="current_dep_values[d.id].amount"
										number
									/>
									<Button
										size="small"
										icon="md-build"
										style="margin-right: 10px"
										@click="handleFix(d.id)"
										v-if="
											group_acc_amount < current.amount - 0.1 ||
											group_acc_amount > current.amount + 0.1
										"
									/>
									<BaseAmount
										full
										style="width: 100px"
										:value="current_dep_values[d.id].amount"
									/>
								</div>
							</div>
						</template>
					</div>
				</CheckboxGroup>
				<div
					class="flex-wrap flex-right"
					style="
						padding: 0 3px;
						border-top: 1px dashed var(--border);
						margin-top: 5px;
						padding-top: 5px;
					"
				>
					合计
					<BaseAmount
						full
						style="margin-left: 10px"
						:value="group_acc_amount"
					/>
					<span style="margin-left: 20px"
						>占比
						<span
							style="color: var(--primary)"
							:style="
								group_acc_percent < 99.9 || group_acc_percent > 100.1
									? 'color:var(--error)'
									: ''
							"
							>{{ group_acc_percent }}%</span
						></span
					>
				</div>
				<div class="flex-wrap flex-between" style="margin-top: 10px">
					<Button @click="current.deps = []">清除</Button>
					<div class="flex-wrap flex-right split10">
						<Button type="primary" @click="SaveDeps">提交</Button>
						<Button @click="showModalDeps = false">取消</Button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-model="showModalAdjust" title="一键调整" footer-hide width="300">
			<div style="padding: 20px">
				<div style="margin-bottom: 10px">项目：{{ formData.name }}</div>
				<div class="flex-wrap flex-between">
					<Input
						v-model="formData.adjust_amount"
						number
						style="width: calc(100% - 80px)"
					/>
					<Button type="primary" @click="handlePatchAdjustAmount">提交</Button>
				</div>
			</div>
		</Modal>

		<Modal
			v-model="showModalCharger"
			title="修改负责人"
			width="300"
			footer-hide
			:mask-closable="false"
		>
			<div>
				<Form>
					<FormItem label="负责人">
						<BaseUserSelectGrouped
							v-model="current.charger_id"
							editable
							:option="{ users }"
						/>
					</FormItem>
					<FormItem label="协助人">
						<BaseUserSelectGrouped
							v-model="current.assistants"
							editable
							:option="{ users, multiple: true }"
						/>
					</FormItem>
				</Form>

				<div class="flex-wrap flex-right split10" style="margin-top: 10px">
					<Button type="primary" @click="SaveCharger">提交</Button>
					<Button @click="showModalCharger = false">取消</Button>
				</div>
			</div>
		</Modal>

	

		<Modal v-model="showZZLProjects" title="关联平台" width="400" footer-hide>
			<div class="flex-wrap flex-line">
				<Select
					style="background: #fff; border-radius: 8px; max-width: 500px"
					v-model="formData.zzl_id"
					filterable
					clearable
					transfer
				>
					<Option v-for="p in zzl_projects" :key="p.id" :value="p.id">
						{{ p.name }}
					</Option>
				</Select>
				<Button
					icon="md-checkmark"
					type="primary"
					@click="saveZZL"
					style="margin-left: 10px; flex-shrink: 0"
				/>
			</div>
		</Modal>

    <Modal v-model="showMap" title="地图定位" width="400" footer-hide>
          <div class="flex-wrap">
            <div style="width:60px;margin-right:10px;flex-shrink:0;">地图定位</div>
           <BaseMapSelect :data="{
                poiname:formData.poiname,
                address:formData.address,
                }" @input="handleAssignLocation" />
          </div>
          <div class="flex-wrap" style="margin-top:10px"><div style="width:60px;margin-right:10px;flex-shrink:0;">详细地址</div><Input v-model="formData.buildingAddress" placeholder="输入详细地址" clearable />
          </div>
        	<div class="flex-wrap flex-right split10" style="margin-top: 10px">
					<Button type="primary" @click="saveMap">提交</Button>
					<Button @click="showMap = false">取消</Button>
				</div>
    </Modal>

		<Modal v-model="showDebugModel" title="问题反馈" footer-hide>
			<template v-if="debug_item">
				<div style="margin-bottom:10px">项目<span style="color:var(--primary);margin-left:10px;">{{debug_item.name}}</span></div>
				<div class="flex-wrap">错误 <AutoComplete style="width:200px;margin-left:10px" :data="debug_texts" v-model="debug_form.name" /></div>
				<div class="flex-wrap align-start" style="margin-top:10px">描述
					<Input type='textarea' style="width:calc(100% - 40px);margin-left:10px;" placeholder="问题描述" v-model="debug_form.desc" />
					</div>
				<div style="margin-top:10px" class="flex-wrap flex-right">
					<Button type="primary" @click="handleCreateDebug">提交</Button>
					<Button @click="showDebugModel=false" style="margin-left:10px">取消</Button>
				</div>
			</template>
		</Modal>

		<Modal v-model="showDetail" title="查看联系单" footer-hide draggable :width="700">
      <div style="padding:0 10px">
        
        
        <div  style="padding:10px;border:1px solid var(--border);margin-top:10px;background:var(--bg3);box-shadow:1px 1px 2px 1px var(--border);">
          <div class="flex-wrap flex-between" style="margin-bottom:10px;border-bottom:1px dashed var(--border);padding-bottom:10px;">
        <div class="flex-wrap" style="font-size:16px;font-weight:bold;"><Tag color="var(--primary)" style="margin-right:10px">{{current.type}}</Tag> {{current.title}}</div>
        <div :style="`background:${state_colors[current.state]};color:#fff;padding:2px 5px;font-size:10px;border-radius:3px;`">{{states[current.state]}}</div>
        </div>
        <div v-html="current.content" style="padding:10px;color:var(--subtext3);">
            </div>

            <div class="flex-wrap flex-between" style="padding-top:5px;margin-top:20px;border-top:1px dashed var(--border)" >
          <div class="flex-wrap split10"><BaseNameBoard :uid="current.created_by" ></BaseNameBoard> {{moment(current.created_at).fromNow()}} 发布</div>
          <div class="flex-wrap split10 flex-right">
          评论 {{current.comment_count}}
        </div>
        </div>
        </div>
        
       
        <div style="min-height:100px;padding:10px 0;">
          <div class="flex-wrap flex-between" style="margin:5px 0;color:var(--text3);font-weight:bold;">
          问题处理及内容回复
          <div></div>
          </div>
          <template v-if="current.state < 2">
          <div class="flex-wrap">
            <Input type="textarea" v-model="comment" :rows="4" placeholder="请输入回复内容" />
           
          </div>
          <div class="flex-wrap flex-right" style="margin-top:10px;">
            <Button type="success" style="margin-right:10px" icon="md-checkmark" @click="ConfirmToFinish" :loading="submittingFinish">结单</Button>
             <Button type="primary" @click="handleSubmitComment" :loading="submitting" icon="md-chatboxes">回复</Button>
          </div>
          </template>
          <BaseLoading v-if="loadingTopic"></BaseLoading>
          <template v-for="c in current.comments">
            <div :key="c.id" style="margin-top:15px;border-top:1px dashed var(--border);padding-top:10px;">
              <div class="flex-wrap split10"><BaseNameBoard :uid="c.created_by" ></BaseNameBoard> {{moment(c.created_at).fromNow()}} 评论 <a v-if="c.created_by == session.id" @click="handleDeleteComment(c.id)">删除</a></div>
              <div style="background:var(--bg3);margin-left:40px;border-radius:5px;padding:10px;border:1px solid var(--border);margin-top:10px;">{{c.content}}</div>
              
            </div>
          </template>
        </div>
      </div>
    </Modal>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import { intersection } from "lodash";
import UTIL from "@/utils"
import CMR from "../render"
export default {
	data() {
		return {
      showMap:false,
			items: [],
			debug_form:{
				name:"项目归属错误"
			},
			debug_texts:['项目归属错误','合同金额错误'],
      filter:{ searchText:"",},
	showDebugModel:false,
	debug_item:null,
			loading: false,
			  loadingTopic:false,
        submittingFinish:false,
        current:{comments:[]},
        comment:"",
        showDetail:false,
			current_deps: [],
			current_dep_values: {},
			current: {},
      status:['准备中','进行中','已竣工','已结束','已中止'],
			states:['待处理','处理中','已办结','已关闭'],
        state_colors:['var(--subtext3)','var(--primary)','var(--success)','var(--error)'],
			zzl_projects: [],
			showModalFocus: false,
			showDetail:false,
			showZZLProjects: false,
			zzl_search_text: "",
			showContract: false,
			showModalTmpl: false,
			showModalDeps: false,
			showModalAssistants: false,
			showModalCharger: false,
			modified: null,
			showModalAdjust: false,
			formData: {},
			business_types: [
				"全过程咨询",
				"项目管理",
				"房建监理",
				"市政监理",
				"造价咨询",
				"BIM咨询",
				"招标代理",
				"对外合作",
				"装修工程",
				"其他",
			],
			business_type_colors: [
				"orange",
				"#369",
				"green",
				"darkred",
				"purple",
				"#3af",
				"grown",
				"pink",
				"#387",
				"#333",
			],
		};
	},
	mounted() {
		this.getData();
		this.$store.dispatch("session/getUsers");
		this.$store.dispatch("session/getDeps")
		this.getZZLProjectList();
	},
	methods: {
      saveMap(){
        this.$api.patch("projects/"+this.current.id,this.formData).then(res=>{
          this.$Notice.success({title:"保存成功"})
          this.updateItem(Object.assign({id:this.current.id},this.formData,res.data.data))
          this.formData = {}
          this.showMap = false

        })
      },
      handleAssignLocation(e){
        Object.assign(this.formData,e);
        if(!this.formData.buildingAddress)
          this.$set(this.formData,'buildingAddress',e.address)
      },
		getZZLProjectList() {
			this.$api.get("cm/projects?q=zzl").then((res) => {
				this.zzl_projects = res.data.data;
			});
		},
		getData() {
			this.loading = true;
			this.$api
				.get("cm/projects")
				.then((res) => {
				
					let items = res.data.data;
					items.forEach((v) => {
						v.adjust_amount = v.adjust_amount || 0;
						v.full_amount = v.signed_amount + v.adjust_amount
						v.children = items.filter((v) => v.parent_id == v.id);
						console.log('bill_amount',v.bill_amount)
						v.bill_percent = v.bill_amount / v.full_amount * 100
					});

					this.items = items.filter((v) => !v.parent_id);
					
				})
				.finally(() => {
					this.loading = false;
				});
		},
		handleTableEvent(e) {
			if (e.type == "open") {
				this.RouteTo("/core/projects/" + e.data.id + "/cm");
			}
		},
		handlePatchFocused() {
			this.$api
				.patch(`projects/${this.formData.id}`, {
					focused: this.formData.focused,
					focused_reason: this.formData.focused_reason,
				})
				.then((res) => {
					this.showModalFocus = false;

					let updateInfo = {
						id: this.formData.id,
						focused: this.formData.focused,
						focused_reason: this.formData.focused_reason,
					};

					this.$store.commit("cm/update_project", updateInfo);
					this.formData = {};
					this.$Notice.success({ title: "修改成功" });
				});
		},
		handlePatchAdjustAmount() {
			this.$api
				.patch(`projects/${this.formData.id}`, {
					adjust_amount: this.formData.adjust_amount,
				})
				.then((res) => {
					this.showModalAdjust = false;
						this.getData()
					this.formData = {};
					this.$Notice.success({ title: "修改成功" });
				});
		},
		handleSelectFilter(f, v) {
			if (Array.isArray(this.selected_filters[f])) {
				let index = this.selected_filters[f].findIndex((t) => t == v);
				if (index != -1) this.selected_filters[f].splice(index, 1);
				else this.selected_filters[f].push(v);
			} else this.$set(this.selected_filters, f, [v]);
		},
		inArray(array, sub) {
			if (
				Array.isArray(array) &&
				Array.isArray(sub) &&
				array.length > 0 &&
				sub.length > 0 &&
				sub.length <= array.length
			) {
				for (let i = 0; i < sub.length; i++)
					if (!array.includes(sub[i])) return;
				return true;
			}
		},
		outArray(array, sub) {
			if (
				Array.isArray(array) &&
				Array.isArray(sub) &&
				array.length > 0 &&
				sub.length > 0
			) {
				for (let i = 0; i < sub.length; i++)
					if (array.includes(sub[i])) return false;
				return true;
			}
		},
		handleOpen({id:e}) {
		 let project = this.items.find(v=>v.id == e)
      
        this.$store.commit("session/push_project_cm",{
          id:e,
          name:project.name
        })
			this.RouteTo("/core/projects/" + e + "/cm");
		},
		handleClearGroup(g) {
			if (!g) this.selected_cols = [];
			else
				this.selected_cols = this.selected_cols.filter(
					(v) => !g.items.map((t) => t.key).includes(v)
				);
		},
		handleSelectAllGroup(g) {
			g.items.forEach((v) => {
				if (!this.selected_cols.includes(v.key)) this.selected_cols.push(v.key);
			});
		},
		handleSelectColumn(key) {
			let index = this.selected_cols.findIndex((v) => v == key);
			if (index == -1) this.selected_cols.push(key);
			else this.selected_cols.splice(index, 1);
		},
		handleTableEvent(e) {
			if (e && e.type == "open") {
				let baseurl = "/core/projects/" + e.data.id;
				this.RouteTo(baseurl + "/cm");
			}
		},
		EditCharger(item) {
			this.current = {
				id: item.id,
				charger_id: item.charger_id,
				assistants: item.assistants ? [...item.assistants] : [],
			};
			this.showModalCharger = true;
		},
		SaveCharger() {
			let item = this.current;
			this.$api
				.patch("projects/" + item.id, {
					charger_id: item.charger_id,
					assistants: item.assistants,
				})
				.then((res) => {
					this.$Notice.success({
						title: "修改成功",
					});
					let updateInfo = res.data.data;
					updateInfo.id = item.id;
					updateInfo.charger_id = item.charger_id;
					updateInfo.assistants = item.assistants;
					let index = this.items.findIndex((v) => v.id == item.id);
					if (index != -1)
						this.items.splice(
							index,
							1,
							Object.assign(this.items[index], updateInfo)
						);
					this.showModalCharger = false;
					this.current = {};
				});
		},
		saveZZL() {
			let item = this.current;
			this.$api
				.patch("projects/" + item.id, { zzl_id: this.formData.zzl_id })
				.then((res) => {
					this.$Notice.success({
						title: "修改成功",
					});
					let updateInfo = res.data.data;
					updateInfo.id = item.index;
					updateInfo.zzl_id = this.formData.zzl_id;
					let index = this.items.findIndex((v) => v.id == item.id);
					if (index != -1)
						this.items.splice(
							index,
							1,
							Object.assign(this.items[index], updateInfo)
						);
					this.showZZLProjects = false;
					this.current = {};
					this.formData = {};
				});
		},
		EditDeps(item) {
			this.current = {
				id: item.id,
				amount: item.amount,
				deps: item.deps,
			};
			this.current_deps = item.deps.map((v) => v.group_id);
			let v = {};
			this.deps.forEach((d) => {
				v[d.id] = {
					amount: 0,
					percent: 0,
				};
			});
			item.deps.forEach((d) => {
				v[d.group_id] = {
					amount: d.amount,
					percent: d.percent,
				};
			});
			this.current_dep_values = v;

			this.showModalDeps = true;
		},
		handleChangePercent(id) {
			if (this.current.amount) {
				let amount = this.current_dep_values[id].amount;
				if (amount > this.current.amount)
					this.$set(this.current_dep_values[id], "amount", this.current.amount);
				this.current_dep_values[id].percent =
					parseInt((amount * 10000) / this.current.amount) / 100;
			}
		},
		handleCalcAmount(id) {
			if (this.current.amount) {
				let percent = this.current_dep_values[id].percent;
				this.current_dep_values[id].amount =
					parseInt(percent * this.current.amount) / 100;
			}
		},
		handleFix(id) {
			if (this.current.amount) {
				this.current_dep_values[id].amount = 0;
				this.current_dep_values[id].amount =
					this.current.amount - this.group_acc_amount;
				this.handleChangePercent(id);
			}
		},
		updateItem(item) {
			let index = this.items.findIndex((v) => v.id == item.id);
			if (index != -1)
				this.items.splice(index, 1, Object.assign(this.items[index], item));
			else this.items.splice(0, 0, item);
		},
		handleCreateDebug(){
			let data = {
				title:this.debug_form.name,
				content:this.debug_form.desc,
				project_id:this.debug_item.id,
				namespace:"cm",
				type:"业务问题"
			}
			this.$api.post("topics",data).then(res=>{
				let updateInfo = res.data.data
				Object.assign(data,updateInfo)
				this.$Notice.success({title:"创建成功",content:"您的反馈已提交"})
				this.debug_form = {name:"项目分配错误"}
				this.$set(this.debug_item,'debugs',[data])
				this.showDebugModel = false
			})
		},
		SaveDeps() {
			let item = this.current;

			// validation

			let deps = this.current_deps.map((v) => {
				return {
					group_id: v,
					...this.current_dep_values[v],
				};
			});
			this.$api.patch("projects/" + item.id, { deps }).then((res) => {
				this.$Notice.success({
					title: "修改成功",
				});
				let updateInfo = res.data.data;
				updateInfo.deps = deps;
				Object.assign(item, updateInfo);
				this.updateItem(item);
				this.showModalDeps = false;
				this.current = {};
			});
		},
	},
	computed: {
		...mapGetters("session", ["session", "users"]),
		deps(){
			return this.$store.getters["session/deps"].filter(v=>v.in_business)
		},
    amount_regions(){
        return [{
          name:"50万元以下",
          check:v=>v < 500000
        },{
          name:"50万以上",
          check:v=>v >= 500000
        },{
          name:"100万以上",
          check:v=>v >= 1000000
        },{
          name:"200万以上",
          check:v=>v >= 2000000
        },{
          name:"500万以上",
          check:v=>v >= 5000000
        }]
      },
    filteredData(){
        return this.items.filter(v=>{
          let searchText = this.filter.searchText.trim()
          if(searchText && !v.name.includes(searchText)){
            return false
          }

          if(this.filter.business_type && v.type != this.filter.business_type)
            return false

          if(this.filter.amount_region != undefined && !this.amount_regions[this.filter.amount_region].check(v.amount))
            return false
          
          if(this.filter.status && v.status != this.filter.status)
            return false
          
          if(this.filter.dep && !v.deps.find(v=>v.group_id == this.filter.dep))
            return false


          return true
        })
      },
		group_acc_amount() {
			let amount = 0;
			Object.keys(this.current_dep_values)
				.filter((v) => this.current_deps.includes(v))
				.forEach((d) => {
					let v = this.current_dep_values[d];
					amount += v.amount || 0;
				});
			return amount;
		},
		group_acc_percent() {
			if (this.current.amount)
				return (
					parseInt((this.group_acc_amount * 10000) / this.current.amount) / 100
				);
			else return 0;
		},
		columns() {
			var that = this;
			function renderEdit(h, row, onclick) {
				return h("BaseIcon", {
					props: {
						icon: "md-create",
					},
					class: "edit-icon",
					on: {
						click() {
							onclick(row);
						},
					},
					style: {
						marginLeft: "5px",
					},
				});
			}

			const states = ["准备中", "进行中", "已竣工", "已结束", "已中止"];
			return [
				{
					type: "text",
					key: "code",
					title: "编号",
					width: 70,
					group: "项目",
					fixed:"left",
					option: {
						align: "center",
					},
					render:CMR.renderCode
				},
				{
					type: "text",
					title: "业务类型",
					key: "type",
					group: "项目",
					width: 100,
					option: {
						align: "center",
					},
					render: (h, { row }) => {
            let iconArrow = h("BaseIcon",{props:{icon:"ios-arrow-down"},style:"margin-left:3px;margin-right:5px;"})
						let domContent =  h(
							"div",
							{ class: "flex-wrap flex-right flex-line", style: { height: "40px;padding:0 3px;" } },
							[
								h(
									"div",
									{
										style: {
											width: "86px",
											borderRadius: "5px",
											textAlign: "right",
											background:
												this.business_type_colors[
													this.business_types.findIndex((v) => v == row.type)
												],
											color: "#fff",
										},
									},
									[row.type, iconArrow]
								),
							]
						);
            const types = ['全过程咨询','项目管理', '市政监理','房建监理','造价咨询','装修工程',  'BIM咨询', '对外合作',   '其他',]
            	let domListItems = types.map((v, i) =>
							h(
								"DropdownItem",
								{ props: { name: v, selected: row.status == v } },
								v
							)
						);
						let domList = h("DropdownMenu", { slot: "list" }, domListItems);
						let domDropDown = h(
							"Dropdown",
							{
								props: { trigger: "click", size: "small",transfer:true },
								on: {
									"on-click": (type) => {
										this.$api
											.patch("/projects/" + row.id, { type,business_type:type })
											.then((res) => {
												let updateInfo = res.data.data;
												updateInfo.type = type;
												updateInfo.id = row.id;
												this.updateItem(updateInfo);
												this.$Notice.success({
													title: "修改成功",
													content: type,
												});
											});
									},
								},
							},
							[domContent, domList]
						);
						return domDropDown;
					
					},
				},
				{
					type: "text",
					title: "名称",
					tree: true,
					fixed:"left",
					key: "name",
					minWidth: 240,
					render: CMR.renderName(this.handleOpen)
				},
				{
					type: "number",
					title: "签约金额",
					key: "signed_amount",
					width: 120,
					group: "合约",
					option: {
						sumable: true,
						formatter: (e) => UTIL.formatSalary(e),
						type: "fullAmount",
            sum_method:v=>{
              if(this.filter.dep){
                if(v.deps.length == 1){
                  return v.signed_amount
                }
                let dep = v.deps.find(d=>d.group_id == this.filter.dep)
                if(dep)
                  return dep.percent * v.signed_amount / 100
              }else{
                
                return v.signed_amount
              }
            }
					},
				},
				{
					type: "number",
					title: "调整金额",
					key: "adjust_amount",
					width: 120,
					group: "合约",
					option: {
						sumable: true,
						formatter: (e) => UTIL.formatSalary(e),
						type: "fullAmount",
					},
          render:(h,{row})=>{
            let domEdit = renderEdit(h,row,()=>{
              this.formData.id = row.id
              this.formData.adjust_amount = row.adjust_amount
              this.formData.name = row.name
              this.showModalAdjust = true
            })
            let domAmount = h("BaseAmount",{props:{value:row.adjust_amount,full:true,signed:true}})
            return h("div",{class:"flex-wrap flex-right",style:"padding-right:5px"},[domAmount,domEdit])
          }
				}, {
					type: "number",
					title: "合同总额",
					key: "full_amount",
					width: 120,
					group: "合约",
					option: {
						sumable: true,
						formatter: (e) => e<0?("-"+UTIL.formatSalary(-e)):UTIL.formatSalary(e),
						type: "fullAmount",
            sum_method:v=>{
              if(this.filter.dep){
                if(v.deps.length == 1){
                  return v.amount
                }
                let dep = v.deps.find(d=>d.group_id == this.filter.dep)
                if(dep)
                  return dep.percent * v.amount / 100
              }else{
                
                return v.amount
              }
            }
					},
				},{
        type: 'number',
        title: "开票比例",
        key: "bill_percent",
        width: 80,
        group: '合约',
        option: {
          type: "percent",
          percentToValue:100,
        },
      },{
					type: "text",
					title: "状态",
					key: "status",
					width: 80,
					option: {
						align: "center",
					},
					render: (h, { row }) => {
            const status_colors = {
              "准备中":"#aaa",
              "进行中":"var(--primary)",
              "已竣工":"var(--active)",
              "已结束":"var(--success)",
              "已中止":"var(--error)"
            }
              let iconArrow = h("BaseIcon",{props:{icon:"ios-arrow-down"},style:"margin-left:3px;"})
						let domContent = h(
							"div",
							{ class: "flex-wrap flex-center", style: { height: "40px" } },
							[
								h(
									"div",
									{
										style: {
											width: "65px",
											borderRadius: "50px",
											textAlign: "center",
											background:status_colors[row.status],
											color: "#fff",
										},
									},
									[row.status,iconArrow]
								),
							]
						);

						let domListItems = states.map((v, i) =>
							h(
								"DropdownItem",
								{ props: { name: v, selected: row.status == v } },
								v
							)
						);
						let domList = h("DropdownMenu", { slot: "list" }, domListItems);
						let domDropDown = h(
							"Dropdown",
							{
								props: { trigger: "click", size: "small" },
								on: {
									"on-click": (status) => {
										console.log(status);
										this.$api
											.patch("/projects/" + row.id, { status })
											.then((res) => {
												let updateInfo = res.data.data;
												updateInfo.status = status;
												updateInfo.id = row.id;
												this.updateItem(updateInfo);
												this.$Notice.success({
													title: "修改成功",
													content: status,
												});
											});
									},
								},
							},
							[domContent, domList]
						);
						return domDropDown;
					},
				},	{
					group: "管理",
					title: "归属事业部",
					type: "text",
					key: "deps",
					minWidth: 100,
					render(h, { row }) {
						let groups = row.deps.map((v) => {
							let group = that.deps.find((g) => g.id == v.group_id);
							if (!group) {
								v.error = true;
								v.name = v.group_id;
							} else {
								v.name = group.name;
							}
							return v;
						});

						let domGroups = groups.map((v) =>
							v.error
								? h("span", { style: "color:red;" }, v.name)
								: h(
										"div",
										{
											class: "flex-wrap",
											style:
												"width:120px,color:#39f;color:var(--primary);margin:0 5px;",
										},
										[
											h("span", v.name ? v.name.slice(0, 2) : v.id),
											v.percent
												? h(
														"span",
														{ style: ";margin-left:5px;" },
														v.percent + "%"
												  )
												: "",
										]
								  )
						);
						let domGroupsWrap = h(
							"div",
							{ class: "flex-col align-start" },
							domGroups.length > 0 ? domGroups : "未关联"
						);
						let domEdit = renderEdit(h, row, () => {
							that.EditDeps(row);
						});
						return h(
							"div",
							{
								class: "flex-wrap flex-center",
								style: {
									flexWrap: "wrap",
								},
							},
							[domGroupsWrap, domEdit]
						);
					},
				},
				{
					key: "charger_id",
					group: "管理",
					title: "负责/协助人",
					width: 240,
					render(h, { row }) {
						let domEdit = h("BaseIcon", {
							props: {
								icon: "md-create",
							},
							class: "edit-icon",
							on: {
								click() {
									that.EditCharger(row);
								},
							},
							style: {
								marginLeft: "5px",
							},
						});
						let domName = h("BaseNameBoard", {
							props: {
								uid: row.charger_id,
							},
						});
						let domAssistants = h("BaseNameBoards", {
							props: { uids: row.assistants, maxShow: 2 },
						});
						let Divider = h("Divider", {
							props: { type: "vertical" },
							style: "",
						});
						let domEmpty = h(
							"div",
							{ style: "color:var(--subtext3);width:30px;text-align:center;" },
							"无"
						);
						return h(
							"div",
							{ class: "flex-wrap flex-left", style: { padding: "0 10%" } },
							[
								row.charger_id ? domName : domEmpty,
								Divider,
								row.assistants && row.assistants.length > 0
									? domAssistants
									: domEmpty,
								domEdit,
							]
						);
					},
				},
				{
					type: "text",
					group: "管理",
					title: "关联项目",
					key: "zzl_id",
					width: 80,
					render: (h, { row }) => {
						let domLink = h(
							"a",
							{
								on: {
									click: () => {
										this.RouteTo("http://zzlatm.gicp.net:10000", {}, true);
									},
								},
							},
							"已关联"
						);
						let domEdit = renderEdit(h, row, () => {
							this.current = row;
							this.formData.zzl_id = row.zzl_id;
							this.showZZLProjects = true;
						});
						return h("span", [row.zzl_id ? domLink : "未关联", domEdit]);
					},
				},{
        type: 'text',
        group: "管理",
        title: "地图定位",
        key: "lnt",
        width: 80,
        render: (h, { row }) => {
          let editDom = renderEdit(h,row,()=>{
            this.showMap = true
            this.formData = {
              address:row.address,
              poiname:row.poiname,
              buildingAddress:row.buildingAddress
            }
						this.current = row
          })
          return h("div",{class:"flex-wrap flex-center"},[h("span",{style:{color:row.poiname?'var(--primary)':''}}, row.poiname ? '已关联' : '未创建'), editDom])
        }
      },  
			{
				width:80,
				title:"报错",
				sortable:false,
				render:(h,{row})=>{
					if(row.debugs && row.debugs.length > 0)
						return h("span",{style:"margin:0 auto;padding:0;cursor:pointer;",on:{
							click:()=>{
								this.showDebugView = true
								this.debug_view_id = row.debugs[0]
							}
						}},'处理中')
					return h("Button",{props:{size:"small",icon:"ios-alert",type:"warning"},on:{
						click:()=>{
							this.showDebugModel = true
							this.debug_item = row
						}
					}})
				}
			},
				
				{
					key: "updated_at",
					title: "最近更新",
					group: "管理",
					width: 80,
					type: "time",
				},
				{
					key: "updated_by",
					title: "操作人员",
					group: "管理",
					width: 120,
					type: "user",
					option: {
						getters: "session/users",
					},
				},
			];
		},
	},
};
</script>
