<template>
  <div class="ivu-form-item" style="width:100%;position:relative;">
   
    <div class="flex-wrap flex-between" style="margin-bottom:10px">
       <div class="flex-wrap ivu-form-item-label" style="padding-left:15px;padding-right:15px;"><span style="color:red;" v-if="required">*</span> {{label}}  <slot name="title"></slot></div>
       <div class="flex-wrap split5">
      <Button  icon="md-add"  v-if="editable" @click="add">新增</Button>
      <slot name="buttons"></slot></div>
      </div>
    <div style="height:calc(100% - 30px);width:100%;position:relative;">
     <Table 
      ref="table"
      class="ugo-table"
      :columns="cols" 
      :data="items" 
      size="small"
      :border="true"
      disabled-hover
      :span-method="handleSpan" />

      <template v-if="config.sample">
      <a href='#' @click="showExample=!showExample"><Icon type="md-help-circle"></Icon> 显示样表</a>

      <Table 
      v-if="showExample"
      class="ugo-table"
      :columns="sampleCols" 
      :data="config.sample" 
      size="small"
      width="100%"
      :border="true"
      disabled-hover
      :span-method="handleSpan" />
      </template>
    </div>
  </div>
  
</template>

<script>
import UgoMark from './Mark.vue'
import render from './render.js'
export default {
  name:"数据表",
  icon:'biaoge',
  components:{UgoMark},
  initModel(e){
    e.config = {
      cols:[{
        label:'第一列',
        control:'Text',
        key:"col1",
        width:100
      },{
        label:'第二列',
        control:'Text',
        key:"col2",
        width:100
      }]
    }
  },
  properties:[{
    label:"列",
    key:'cols',
    control:"ColumnDesign"
  },{
    label:"样例",
    key:'data',
    control:'TableSample'
  }],
   data(){
     return {
      showExample:false,
       items:[],
       selected:{},
       selectedKey:null,
       editingItem:{}
     }
   },
   props:['config','value','editable','label','required'],
   watch:{
     value:{
       handler(v){
        this.$set(this,'items',v || [])
              this.$forceUpdate()
      
       },
       deeply:true,
       immediate:true
     }
   },
   computed:{
    sampleCols(){
       let index = {
         title:'序号',
         type:'index',
         align:'center',
         width:65
       }
       var that = this
       let fieldColumns = this.config.cols.map(v=>{
         return {
           title:v.label,
           type:v.type,
           key:v.key || v.label,
           align:v.align || "center",
           rowspan:v.rowspan,
           width:v.width=='auto'?undefined:(v.width|| 150),
           render:(h,params)=>{
             let item = params.row
           
             if(v.computed)
              v.disabled = true

           

            let value = item[v.key]
            if(v.computed){
              let caculator = createCaculator(v.computed)
              value = caculator(item,this.items)
            }


            let domContent = value
         
             return h('div',{
               class:`flex-wrap control-wrapper control-wrapper-disabled`,
             },[domContent])
           }
         }
       })
      
       let columns = [index,...fieldColumns]
       let full = false
       columns.forEach(v=>{
        if(!v.width || v.width == 'auto')
          full = true
       })
       if(!full){
        columns.push({title:' '})
       }
       return columns
     },
     cols(){
       let index = {
         title:'序号',
         type:'index',
         align:'center',
         width:65
       }
       var that = this
       let fieldColumns = this.config.cols.map(v=>{
         return {
           title:v.label,
           type:v.type,
           key:v.key || v.label,
           align:v.align || "center",
           rowspan:v.rowspan,
           width:v.width=='auto'?undefined:(v.width|| 150),
           render:(h,params)=>{
            let item = params.row
            let key = v.key || v.label
            let value = item[key]
            if(v.computed){
              v.disabled = true
              value = render.computeValue(v.computed,item,items)
            }

            let editable = this.editingItem == item&&this.selectedKey==v.key&& this.editable && !v.disabled
            
          
            let domContent = render.renderContent(h,v,value,editable,e=>{
            
              that.$set(that.items[params.index],key,e)
              
              that.handleChange()
              that.$forceUpdate()
            })
            let isSelected = this.selected == item && this.selectedKey == key
             return h('div',{
               class:`control-wrapper ${ isSelected? "control-wrapper-selected":""} ${!v.disabled?'':'control-wrapper-disabled'} `,
               on:{
                 click:()=>{
                   if(!this.editable || v.disabled)
                    return
                   if(isSelected){
                     this.editingItem = item
                   }else{
                    this.selected = item
                    this.selectedKey = key
                   }
                 }
               }
             },[domContent,editable])
           }
         }
       })
       let tool = {
         title:'操作',
         align:"center",
         width:200,
         render:(h,params)=>{
           let btnRemove = h('Button',{props:{icon:'md-trash',size:'small'},on:{
             click:()=>{
               this.items.splice(params.index,1)

             }
           }})
           let btnMoveUp = h('Button',{props:{icon:'md-arrow-up',size:'small',
           disabled:params.index==0},on:{
             click:()=>{
               console.log(params.index)
               if(params.index < 1)
                return
               this.swap(params.index,params.index-1)

             }
           }})
           let btnMoveDown = h('Button',{props:{icon:'md-arrow-down',size:'small',
           disabled:params.index==this.items.length-1},on:{
             click:()=>{
               console.log(params.index)
               if(params.index > this.items.length-2)
                return
               this.swap(params.index,params.index+1)

             }
           }})
           return h('div',{class:'flex-wrap flex-center split5'},[btnMoveUp,btnMoveDown,btnRemove])
         }
       }
       let columns = [index,...fieldColumns]
       let full = false
       columns.forEach(v=>{
        if(!v.width || v.width == 'auto')
          full = true
       })
       if(!full){
        columns.push({title:' ',disabled:true})
       }
       if(this.editable)
        columns.push(tool)
       return columns
     }
   },
   mounted(){
     this.items = this.value || []
   },
   methods:{
     swap(a,b){
       if(a < 0 || b < 0 || a == b || a > this.items.length - 1 || b > this.items.length - 1)
        return
       let t = this.items[a]
       this.items[a] = this.items[b]
        this.items.splice(b,1,t)
       this.handleChange()
     },
     add(){
       let item = {}
       this.config.cols.forEach(v=>{
       
         if(v.defaultValue !== undefined)
          item[v.key] = v.defaultValue
         console.log(v,item)
       })
       
       this.items.push(item)
       this.handleChange()
     },
     handleSpan({row,column,rowIndex,columnIndex}){
       
       if(column.rowspan)
        if(rowIndex == 0){
          return {
            rowspan:column.rowspan?this.items.length:(column.rowspan || 1),
            colspan:1
          }
        }else{
          return [0,0]
        }
     },handleChange(e){
      console.log('change')
      this.$emit('input',[...this.items])
    }
   }
}
</script>

<style lang="less">
.ugo-table{
  .ivu-table-cell{
    height:100%;
    width:100%;
    position: relative;
    padding:0;
    display: flex;
    align-items: center;
    justify-content: center;
    .ivu-input-wrapper{
      height:100%;
    }
    .ivu-input{
      width:100%;
      height:100%;
      border:none;
      background:none;
      margin:0;
      border-radius: 0;
      padding:0 10px;
    }
  }

  
}

.control-wrapper{
  background:#fff;
  width:100%;
  height:100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-wrapper-selected{
  background:#ffd;
}

.control-wrapper-disabled{
  background:rgb(255, 254, 254);
  
}
</style>