<style lang="less">
.gz-image {
	background: none;
	.ivu-tooltip-rel {
		height: 100%;
	}

	.gz-images-item {
		width: 100%;
		height: 40px;
		margin-right: 5px;
		margin-bottom: 5px;
		overflow: hidden;
		img {
			width: 35px;
			height: 35px;
			margin: 5px;
			margin-bottom: 0;
			border-radius: 2px;
			position: relative;
			border: 1px solid #333;
		}

		.file-icon {
			width: 60px;
			height: 30px;
			margin: 5px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				border: none;
				position: relative;
				bottom: 0;
				color: #fff;
				z-index: 10;
				line-height: auto;
				box-shadow: none;
			}
		}

		i {
			font-size: 20px;
		}
		.name {
			width: 100%;
			text-align: left;
		}

		.download {
			margin-right: 10px;
			color: rgb(29, 157, 248);
			display: flex;
			justify-content: flex-end;
			span:hover {
				color: rgb(96, 180, 240);
				cursor: pointer;
			}
			width: 120px;
		}
	}
}

.gz-images:hover {
	background: none;
}

.file-loading {
	color: red;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(33, 33, 33, 0.5);
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: yellowgreen;
	z-index: 100;
}
</style>
<template>
	<div>
		<div class="gz-image-wrapper">
			<template v-for="(f, i) in files">
				<div
					class="gz-image"
					:key="i"
					@click="
						modalPreview = true;
						previewIndex = i;
					"
				>
					<Tooltip
						style="width: 100%; height: 100%"
						:content="getFileName(f.name)"
						placement="bottom"
						transfer
					>
						<template v-if="isImage(getFileExt(f.url))">
							<img :src="f.url" style="" />
						</template>
						<template v-else>
							<div
								class="file-icon"
								@click="DownloadFile(f.url, f.name)"
								:style="`background:${getFileExtColor(f.name)}`"
							>
								<Icon type="ios-document-outline" />
								<span>{{ getFileExt(f.url) }}</span>
							</div>
						</template>
						<Icon
						type="md-close"
						v-if="!disabled"
						class="gz-button l-close-btn"
						@click.stop="remove(i)"
					/>
					</Tooltip>
				</div>
			</template>
			<template v-for="(f, i) in upload_files">
				<div
					class="gz-image"
					:key="'u'+i"
					@click="
						modalPreview = true;
						previewIndex = i;
					"
				>
					<Tooltip
						style="width: 100%; height: 100%"
						:content="getFileName(f.name)"
						placement="bottom"
						transfer
					>
						<template v-if="isImage(getFileExt(f.url))">
							<img :src="f.url" style="" />
						</template>
						<template v-else>
							<div
								class="file-icon"
								@click="DownloadFile(f.url, f.name)"
								:style="`background:${getFileExtColor(f.name)}`"
							>
								<Icon type="ios-document-outline" />
								<span>{{ getFileExt(f.url) }}</span>
							</div>
						</template>
					</Tooltip>
					<div v-if="f.loading" class="file-loading">
						{{ f.percent || "待上传" }}
					</div>
					<Spin fix v-if="f.loading" />
					<Icon
						type="md-close"
						v-if="!disabled"
						class="gz-button l-close-btn"
						@click.stop="remove_upload(i)"
					/>
				</div>
			</template>
			<div
				v-if="disabled && files.length == 0"
				style="margin: 10px 20px; color: #aaa"
			>
				无
			</div>
			<div class="gz-image-add" v-if="canAddMore">
				<Icon type="md-add" />

				<input
					type="file"
					:multiple="isMultiple"
					:accept="acceptType"
					ref="file"
					@change="handleUpload"
					style="width: 100%; height: 100%"
				/>
			</div>
		</div>

		<Modal v-model="modalPreview" footer-hide width="1200">
			<div class="flex-col flex-center" style="position: relative">
				<template v-if="modalPreview">
					<img :src="files[previewIndex].url" style="max-width: 800px" />
					<div
						class="flex-wrap flex-around"
						style="
							position: fixed;
							z-index: 10;
							bottom: 30px;
							left: 300px;
							right: 300px;
							z-index: 999999;
						"
						v-transfer
					>
						<div
							style="background: #33333333; border-radius: 50%; padding: 3px"
						>
							<Icon type="md-arrow-back" size="40" />
						</div>
						<div class="flex-wrap">
							<template v-for="(f, i) in files">
								<div class="gz-image" :key="i" @click="previewIndex = i">
									<template v-if="isImage(getFileExt(f.url))">
										<img :src="f.url" style="" />
									</template>
								</div>
							</template>
						</div>
						<div
							style="background: #33333333; border-radius: 50%; padding: 3px"
							@click="previewIndex = i + 1"
						>
							<Icon type="md-arrow-forward" size="40" />
						</div>
					</div>
				</template>
			</div>
		</Modal>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			files: [],
			upload_files:[],
			modalPreview: false,
			previewIndex: 0,
			percents: [],
		};
	},
	mounted() {
		this.parseValue();
	},
	name: "ImageUpload",
	icon: "xiazai40",
	props: {
		value: {
			type: String | Array,
		},
		option: {
			type: Object,
			default: () => ({}),
		},
		disabled: {
			type: Boolean,
		},
	},
	watch: {
		value: {
			handler(v) {
				this.parseValue();
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		...mapGetters("session", ["session"]),
		canAddMore() {
			if (this.disabled) return false;

			if (this.isMultiple) return this.files.length < this.option.max || true;
			else return this.files.length == 0;
		},
		isMultiple() {
			return !this.option.single;
		},
		acceptType() {
			return "image/*";
		},
	},

	methods: {
		parseValue() {
			try {
				if (typeof this.value == "string") {
					let images = JSON.parse(this.value);
					this.files = images.map((v, i) => ({
						name: "图片" + (i + 1) + ".jpg",
						url: v,
					}));
				} else if (typeof this.value == "object") {
					this.files = this.value.map((v, i) => ({
						name: "图片" + (i + 1) + ".jpg",
						url: v,
					}));
				} else {
					this.files = [];
				}
			} catch (e) {
				this.files = [];
			}
		},
		emitValue() {
			this.$emit("input", JSON.stringify(this.files.map((v) => v.url)));
			this.$emit("change", JSON.stringify(this.files.map((v) => v.url)));
		},
		DownloadFile(url, name) {
			download(url, name);
		},
		onUploadProgressList(progressEvent, index) {
			var complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
			this.$set(this.upload_files[index], "percent", complete + "%");
		},
		handleUpload(e) {
			let files = Array.from(e.srcElement.files)
			console.log('files:',files)
			this.$cos.upload(
				files,
				{
					onPreload: this.onPreloadFiles,
					onProgressFile: this.onUploadProgressList,
					onSuccess: this.onUploadSuccess,
				},
				{ coskey: this.session.coskey }
			);
		},
		onPreloadFiles(files) {
			this.upload_files = files;
		},
		onUploadSuccess(files) {
			
			this.files = this.files.concat(files)
			this.upload_files = []
			this.emitValue();
		},
		getFileName(url) {
			if (url) {
				let fileName = url.replace(/(.*\/)*([^.]+)/i, "$2");
				return fileName;
			}
		},
		getFileExt(url) {
			if (url) {
				let ext = url.substring(url.lastIndexOf(".") + 1);
				return ext;
			}
		},
		isImage(ext) {
			const imgexts = ["jpg", "jpeg", "png", "gif", "ico"];
			return imgexts.includes(ext);
		},
		getFileExtColor(url) {
			let ext = this.getFileExt(url);
			const colors = {
				doc: "blue",
				docx: "blue",
				rar: "purple",
				pptx: "orange",
				ppt: "orange",
				xls: "green",
				xlsx: "green",
			};

			return colors[ext] || "#333";
		},
		onAddSelectedFiles(files) {
			this.files = this.files.concat(
				files.filter((f) => this.files.findIndex((v) => v.id == f.id) == -1)
			);

			this.modalFileSelector = false;
		},
		cancelUploading(index) {
			if (index == -1) return;
			if (this.upload_files[index] && this.upload_files[index].source) {
				this.upload_files[index].source.cancel("取消上传");
			}
			// remove from file server
			this.files.splice(index, 1);
		},
		remove_upload(index){
			if (this.upload_files[index] && this.upload_files[index].source) {
				this.upload_files[index].source.cancel("取消上传");
			}
			this.upload_files.splice(index, 1);
			this.emitValue();
		},
		remove(index) {
			this.files.splice(index, 1);
			this.emitValue();
		},
	},
};
</script>
<style lang="less">
.gz-image-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-top: 0;
}
.gz-image {
	position: relative;
	display: inline-block;
	background: #fff;
	height: 60px;
	color: #ddd;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #dfdfdf;
	margin-right: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	width: 60px;
	height: 60px;
	overflow: visible;

	cursor: pointer;

	img {
		width: 90%;
		height: 90%;
		margin: 5%;
	}
}

.gz-image-add {
	.gz-image;
	input {
		position: absolute;
		cursor: pointer !important;
		font-size: 100px;
		right: 0;
		top: 0;
		opacity: 0;
		font-size: 0;
	}

	i {
		font-size: 30px;
		cursor: pointer !important;
		color: #aaa;
	}
}

.gz-image-add:hover {
	cursor: pointer;
	background: yellow;
}

.l-close-btn {
	position: absolute;
	font-size: 14px;
	right: -5px;
	top: -5px;
	width: 20px;
	height: 20px;
	background: darkred;
	border: 1px solid #333;
	color: white;
	border-radius: 50%;
	padding: 2px;
	display: none;
	z-index: 10;
}

.l-close-btn:hover {
	filter: brightness(1.2);
	cursor: pointer;
}

.gz-image:hover {
	background: rgb(246, 255, 208);
	color: #eee;

	.l-close-btn {
		display: block;
	}
}

.file-icon {
	color: #fff;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	text-shadow: 1px 1px 1px #333;
	i {
		position: absolute;
		top: 2px;
		left: 2px;
		bottom: 2px;
		right: 2px;
		font-size: 56px;
	}

	span {
		position: relative;
		box-shadow: 1px 1px 1px #333;
		bottom: -10px;
		font-size: 10px;
		background: inherit;
		width: 40px;
		text-align: center;
		text-transform: uppercase;
		height: 18px;
		line-height: 16px;
		border: 2px solid #fff;
	}
}
</style>

