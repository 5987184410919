<template>
  <Dropdown 
    :visible="showDropdown" 
    trigger="custom" 
    v-if="role.id == 'apr-dep'"
    stop-propagation 
    @on-clickoutside="handleClickOut"
    transfer
>
    <a 
        @click="showDropdown=true" 
        :class="{
            'rd-value':true,
            'rd-value-empty':items.length==0,}"
    >
    {{selected.length > 0 ? selected.join(','):'请选择'}} 
    <BaseIcon icon="down" style="margin-left:5px" />
        <div class="flex-wrap flex-right" style="margin:5px;" v-if="formattedData != value && formattedData">
            <Button type="primary" size="small" icon="md-checkmark" @click.stop="handleSubmit"></Button>
            <Button style="margin-left:5px" size="small" icon="md-close" @click.stop="handleCancel" ></Button>
        </div>
    </a>
    <DropdownMenu slot="list">
        <div v-for="(d,i) in deps" :name="i" :key="d" :class="{
            'rd-item':true,
            'rd-item-selected':items.includes(i)
        }">
        <div @click="handleSelect(i)" style="text-align:center;cursor:pointer;">
            {{ d }} 
            <BaseIcon 
                icon="md-checkmark" color="var(--primary)" v-if="items.includes(i)"></BaseIcon>
        </div>
        </div>
        
    </DropdownMenu>
  </Dropdown>
</template>

<script>
const deps = ['行政','房建','市政','管理','装修', '造价', 'BIM','总师']
export default {
    data(){
        return {
            items:[],
            showDropdown:false,
        }
    },
    props:{
        role:{
            type:Object,
            default:()=>({})
        },
        user_id:{
            type:String,
            default:""
        },
        value:{
            type:String
        }
    },
    computed:{
        deps(){
            return deps
        },
        selected(){
            return this.items.map(v=>deps[v])
        },
        formattedData(){
            return this.items.length > 0? this.items.join(','):""
        }
    },
    mounted(){
        if(this.value)
            this.items = this.value.split(',').map(v=>parseInt(v))
        else
            this.items = []
    },
    methods:{
        handleSelect(i){
            if(this.items.includes(i)){
                this.items = this.items.filter(v=>v!==i)
            }else{
                this.items.push(i)
            }
        },
        handleSubmit(){
            this.$api.patch(`oa/appraisal_permissions/${this.role.id}/users/${this.user_id}`,{extra:this.items.join(',')}).then(res=>{
                this.showDropdown = false
                this.$emit('input',this.formattedData)
                this.Success({title:"操作成功",desc:"已修改权限"})
            })
        },
        handleClickOut(e){
            if(e && e.target && e.target.parentElement && e.target.parentElement._prevClass && e.target.parentElement._prevClass.includes('rd-item'))
                return
            this.showDropdown = false
        },
        handleCancel(){
            if(this.value)
                this.items = this.value.split(',').map(v=>parseInt(v))
            else
                this.items = []
            this.showDropdown=false;

        }
    }
}
</script>

<style lang="less">
.rd-value{
    color:var(--primary);
    font-weight: bold;
}

.rd-value-empty{
    color:var(--subtext3);
    font-weight: normal;
}

.rd-item-selected{
    font-weight: bold;
    color:var(--primary);
}
</style>