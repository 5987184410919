<template>
    <Container class="flex-col align-start">
      <h2 style="margin:10px 20px">销毁信息登记
        <div style="font-size:12px;color:var(--subtext3);font-weight:bold;">
            本记录均有由系统自动生成
        </div>
      </h2>
      <div class="flex-wrap" style="margin:10px 20px">
        <Input search placeholder="输入名称搜索" /> <Button icon="md-refresh" style="margin-left:10px" @click="getData()"></Button>
      </div>
      <div style="padding:0 20px;height:calc(100% - 40px);width:100%;">
        <BaseTable ref="table" :columns="cols" :data="items" :loading="loading" />

      </div>
      
    </Container>
  </template>
  <script>
    import Container from '@/components/app/container'
    import Toolbar from '@/components/app/toolbar'
    import moment from 'moment'
  export default {
    routerOption: {
      as_default: true,
    },
    components:{Container,Toolbar},
    data(){
      return {
        filter_mode:0,
        filter_type:null,
        page:1,
        pagesize:20,
        loading:false,
        types:[{
          name:'合同审阅',
          color:'var(--text3)',
          count:2,
        },{
          name:'合同变更',
          count:1,
          color:'var(--warning)'
        },{
          name:'合同结算',
          count:1,
          color:'var(--success)'
        },{
          name:"用印申请",
          color:"var(--error)",
          count:4
        }],
        states:[{
          name:'全部',
          new_count:3,
          color:'var(--error)',
          count:7
        },{
          name:'待处理',
          new_count:3,
          color:'var(--error)',
          count:4
        },{
          name:"已处理",
          count:123,
          new_count:2
        },{
          name:"与我相关",
          count:243
        }],
        items:[]
      }
    },
    mounted(){
     
    },
    computed: {
      filteredItems(){
        if(this.filter_type !== null)
          return this.items.filter(v=>v.type == this.filter_type)
        else
          return this.items
      },
      cols(){
        return [{
          title:"序号",
          width:60,
          type:"index"
        },{
          title:"归档日期",
          width:120,
          render:(h,{row})=>{
            return h('div',{style:"color:var(--success)"},row.signed_at?moment(row.signed_at).format("L"):'-')
          }
        },{
          title:"合同名称",
          minWidth:140,
          key:"name",
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:'contract'},style:"margin-right:3px;color:var(--primary)"})
            let name = h('a',row.name)
            return h('div',{class:"flex-wrap"},[icon,name])
          }
        },{
          title:"销毁日期",
          width:120,
          type:'user',
          key:"created_by"
        },{
          title:"负责人",
          width:120,
          type:'user',
          key:"created_by"
        },{
          title:"销毁方式",
          width:240,
          key:"name",
          render:(h,{row})=>{
            return h('div',{style:"color:var(--success)"},'下载PDF')
          }
        },]
      }
        
    },
    methods:{
        getData(){
        if(this.timer){
          clearTimeout(this.timer)
          this.loading = true
          
        }
        this.timer = setTimeout(()=>{
          const base_url = `contracts-m/seal_records`
          let url = base_url
        
          this.$api.get(url).then(res=>{
            this.items = res.data.data
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              
            this.loading= false
            },300)
          })
        },100)
       
       
      },
    }
  }
  </script>
  
  <style lang="less">
    .tab-card{
      padding:5px 10px;
      height:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background:var(--bg3);
      border-right:none;
      cursor: pointer;
      border-radius: 5px;
      .count{
        font-size:30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      
    }


    .tab-card:hover{
      background:var(--primary);
      color:var(--hover-text);
      .count,.name{
        color:var(--hover-text) !important;
      }
    }

    .tab-card-active{
      background:var(--primary);
      color:var(--hover-text);
      .count,.name{
        color:var(--hover-text) !important;
      }
    }
  </style>