<template>
  <BaseLayout :panels="panels" :padding="20" :gutter="20" border-radius style="background:var(--bg3)">
  
  </BaseLayout>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    routerOption:{
      as_default:true
    },
    computed:{
      ...mapGetters('group',['group']),
      panels(){
         return [{
            x:0,y:0,w:6,h:2,control:"PanelWeather"
          },{
            x:6,y:1,w:6,h:3,control:"PanelNotice"
          },{
            x:0,y:2,w:6,h:1.5,control:"PanelLinks"
          },{
            x:0,y:6.5,w:6,h:4.5,control:"PanelAccelerators"
          },{
            x:6,y:0,w:12,h:1,control:"PanelCaption",data:{
              title:this.group.name,
              icon:'Building-'
            }
          },{
            x:0,y:3.5,w:6,h:3,control:"PanelCNotices",data:{
              title:"事业部通知"
            }
          },{
            x:12,y:1,w:6,h:3,control:"PanelCNotices",data:{
              title:"重要通知",
              icon:"Building-"
            }
          },{
            x:6,y:4,w:12,h:3,control:"PanelCNotices",data:{
              title:"总控计划",
              icon:"Building-"
            }

          },{
            x:6,y:7,w:6,h:4,control:"PanelCNotices",data:{
              title:"形象进度",
              icon:"Building-"
            }
          },{
            x:12,y:7,w:6,h:4,control:"PanelCNotices",data:{
              title:"巡检记录",
              icon:"photos"
            }
          },{
            x:6,y:11,w:6,h:4,control:"PanelCNotices",data:{
              title:"抽查记录",
              icon:"meeting"
            }
          },{
            x:12,y:11,w:6,h:4,control:"PanelCNotices",data:{
              title:"会议记录",
              icon:"meeting"
            }
          },{
            x:18,y:0,w:6,h:4,control:"PanelCNotices",data:{
              title:"项目简介",
              icon:"user"
            }
          },{
            x:18,y:4,w:6,h:11,control:"PanelCNotices",data:{
              title:"在岗人员",
              icon:"user"
            }
          }]
      }
    }
  }
</script>
