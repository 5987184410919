<template>
  <div style="position:relative">
    <template v-for="m in menus">
      <div 
      class="app-vmenu-item flex-wrap" 
      :key="m.key"
      :class="{
        'app-vmenu-item-actived':activedKey == m.key
      }"
      @click="handleClick(m.key)"
      >
        <BaseIcon :icon="m.icon" :size="20" style="margin-right:5px;margin-bottom:2px;"></BaseIcon>
        <div>{{m.name}}</div>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        activedLeft:0
      }
    },
    props:{
      menus:{
      type:Array,
      default:()=>([])
      },
      activedKey:{
        type:String
      }
    },
    methods:{
      handleClick(key){
        this.RouteTo(key)
      }
    }
  }
</script>

<style lang="less">
.app-vmenu-item{
  font-size:16px;
  margin:0 10px;
  padding:5px;
  cursor: pointer;
}
.app-vmenu-item:hover,.app-vmenu-item-actived{
  color:var(--primary);
}

</style>