<template>
  <div style="padding:20px;">
    <BaseSegment :title="g.name" :key="g.id" v-for="g in groups">
      <div class="flex-wrap" style="flex-wrap:wrap;gap:10px;">
        <div class="flex-wrap l-tool-btn hover-lighter" v-for="t in g.tools" :key="t.id" @click="ExecuteTool(t.key)">
      <BaseIcon :icon="t.icon || 'md-switch'" color="var(--text1)" :size="25" style="margin:10px;" />
      <div style="padding:10px;border-left:1px solid var(--border)">
        <div class="label">{{t.name}}</div>
        <div class="sub">{{ t.desc }}</div>
      </div>
    </div>
      </div>
    
    </BaseSegment>

    <BaseLoadingModal v-model="executing" title="正在执行"/>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        executing:false
      }
    },
    computed:{
      groups(){
        return [{
          id:1,
          name:'钉钉管理',
          tools:[{
            id:11,
            name:'同步新员工',
            key:'migrate-newcome-ding',
            desc:'检测钉钉和平台员工，将新员工数据进行迁移'
          },{
            id:112,
            name:'同步在职员工',
            key:'check-ding-user',
            desc:'检测钉钉和平台员工，同步在职员工的部门信息'
            
          },{
            id:12,
            name:'同步员工部门',
            key:'async-deps',
            desc:'检测钉钉和平台员工，同步在职员工的部门信息'
          },{
            id:13,
            name:'同步离职员工',
            key:'async-dismmissed-users',
            desc:'调整离职员工的状态，并移除部门信息'
          }]
        }]
      }
    },
    methods:{
      ExecuteTool(key){
        this.executing = true
        this.$api.post(`tools/${key}`,{},{timeout:3000000}).then(res=>{
          this.Success(res.data.data)
        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          this.executing = false
        })
      }
    }
  }
</script>

<style lang="less">
.l-tool-btn{
  color:var(--text1);
  background:var(--bg1);
  width:fit-content;

  .label{
    font-size:16px;
    font-weight: bold;
    
  }

  .sub{
    font-size:12px;
  }
}
</style>