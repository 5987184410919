<template>
  <div class="container">
      <Row 
        :gutter="10" 
        class-name="flex-table-caption-line"
      >
         <template v-for="(c,index) in columns">
           <Col :span="c.span || 1" v-if="!c.if || model[c.if]" :key="'title'+vid+c.key+index">
           <div  class="flex-table-caption-field">
           {{c.name || ""}}</div></Col>
        </template>
          <Col :span="4"><div  class="flex-table-caption-field">操作</div></Col>
      </Row>

      
      <Draggable 
        v-model="items" 
        :animation="500" 
        handle=".draggable"
        @input="emitChange"
      >
        <template v-for="(item,index) in items">
          <Row :key="item.id" :gutter="10" class-name="l-bill">
            <template v-for="c in columns">
            <Col :span="c.span || 1" :key="'_col_item'+vid+c.key+index" v-if="!c.if || model[c.if]" style="text-align:center">
              <div v-if="c.control=='drag'" :key="'_coldrag'+vid+c.key+index" class="draggable" style="cursor:grab">
                <BaseIcon icon="md-menu" />
              </div>
              <span v-else-if="c.control=='index'"  :key="'_colc'+c.key+index">{{index+1}}</span>
              <component v-else :is="c.control" v-bind="c.option" :key="'_colc'+c.key+index" @click="handleClick(c,item,index)" @input="emitChange" v-model="item[c.key]" />
            </Col>
            </template>
             <Col :span="4">
                 <div class="flex-wrap split5"><Button size="small" style="flex-shrink:0" @click="copy(index)" icon="md-copy"></Button><Button  style="flex-shrink:0" size="small" @click="remove(index)" icon="md-remove"></Button></div>
             </Col>
          </Row>
        </template>
      </Draggable>
      
      <BaseEmpty v-if="items.length == 0" image style="padding:20px;">
        <div class="flex-wrap flex-center" style="margin-bottom:20px" >
         <Button icon="md-add" size="small" @click="add">新增一行</Button>
          <slot name="button"></slot>
        </div>
      </BaseEmpty>
      <div v-else class="flex-wrap flex-between" style="margin:5px 10px">
        <Alert v-if="error" type="error" size="small" style="padding:3px 5px">{{error}}</Alert>
        <div class="flex-wrap"  style="margin-bottom:20px;margin-top:5px;">

          <Button icon="md-add" size="small" @click="add">新增一行</Button>
          <slot name="button"></slot>
        </div>
      </div>
      <Row :gutter="10" class-name="l-bill" v-if="summary && items.length > 0">
        <template v-for="(c,i) in columns">
        <Col :span="c.span" :key="'_col_sum'+(c.key || i)" style="text-align:center">
         
          <span v-if="c.summarable"  :key="'_col_sum'+c.key">{{index+1}}</span>
        </Col>
        </template>
      </Row>
  </div>
</template>
<script>

import Draggable from 'vuedraggable'
import {cloneDeep} from 'lodash'
function evil(fn,params) {
    params.fix = (total,items,key,index)=>{
      console.log(total,items,key,index)
      return formatNumber(total - formatNumber(items.filter((v,i)=>i!=index).reduce((acc,item)=>{
        return acc + item[key]
      },0)))
    }
    var Fn = Function;  //一个变量指向Function，防止有些前端编译工具报错
    return new Fn('params','console.log(params);console.log('+fn+');return ' + fn)(params);
}
function formatNumber(e){
  if(isNaN(e))
    return 0
  else 
    return parseInt(e*100)/100
  
}
export default {
  components: { Draggable },
  data() {
    return {
      items: [],
      vid:0
    }
  },
  created(){
   
    this.vid = parseInt(Math.random()*10000)
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    columns:{
      type:Array,
      default:()=>([])
    },
    error:{
      type:String,
      default:()=>""
    },
    summary:{
      type:Boolean
    },
    model:{
      type:Object,
      default:()=>({})
    }
  },
  watch:{
    value:{
      handler(v){
         if (Array.isArray(this.value))
          this.items = cloneDeep(this.value)
        else{
          this.items = []
          this.emitChange()
        }
        
      },
      immediate:true,
      deep:true
    }
  },
  mounted() {
    if (Array.isArray(this.value))
      this.items = cloneDeep(this.value)
    else {
      this.items = []
      this.vid = parseInt(Math.random()*1000)
      this.emitChange()
    }
  },
  methods: {
    handleClick(c,item,index){
      let model = this.model
      if(c.control=='Button'){
        this.$set(item,c.to,formatNumber(evil(c.from,{model,item,index})))
      }
    },
    validate(){
      if (this.check_result.state == "success")
        return true
    },
    getError(){
      return this.check_result.message
    },
    emitChange() {
      this.items.forEach((v,i)=>v.order=(i+1))
      this.$emit('input',this.items)
    },
    add() {
      this.items.push({})
    },
    copy(i){
      this.items.splice(i,0,{...this.items[i],id:undefined})
      this.$emit('input',this.items)
    },
    remove(i) {
      this.items.splice(i, 1)
      this.$emit('input',this.items)
    }
  }
}
</script>

<style lang="less">
.flex-table-caption-line{
  
}

.flex-table-caption-field{
  background:var(--bg2);
  text-align: center;
}
</style>