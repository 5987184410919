<template>
  <div class="list-item">
    
    <div class="img-wrap">
      <div class="tag">docx</div>
    </div>
    <div class="list-item-name">
      {{name}}
    </div>
  </div>
</template>

<script>
export default {
  props:{
    name:{
      type:String,
      default:''
    }
  }
}
</script>

<style lang="less" scoped>
.list-item{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width:110px;
  height:180px;
  position: relative;
  .img-wrap{
    
  width:100px;
  height:130px;
  background:var(--bg2);
  flex-shrink: 0;
  position: relative;
  }

  .list-item-name{
    width:100%;
    text-align: center;
    white-space:pre-wrap;
    overflow: hidden;
    margin-top:10px;
  }

  .tag{
    position:absolute;
    left:0;
    top:0;
    background:var(--primary);
    color:var(--hover-text);
    font-size:10px;
    padding:1px 3px;
  }
  
}

.list-item:hover{
  cursor: pointer;
  .img-wrap{
    
  transition:all 0.3s;
  box-shadow: 1px 1px 5px 2px #33333322;
  }
  .list-item-name{
    color:var(--primary);
  }
}

.list-item:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

</style>