<template>
  <div class="app-bar-mobile">
    <div class="abm-spin" :style="`left:${spinLeft}`"></div>
    <template v-for="m in menus.slice(0,3)">
      <div class="app-menu-item" :class="{
        'app-menu-item-active':path.indexOf(m.path) == 0
      }" :key="m.path" @click="RouteTo(m.path)">
        <BaseIcon :icon="m.icon"  size="28"/>
        <div style="margin-top:5px;font-size:12px;font-family:宋体">{{m.name}}</div>
      </div>
    </template>
     <div class="app-menu-item" :class="{
        'app-menu-item-active':path.indexOf('/core/self') == 0
      }"  @click="RouteTo('/core/self')">
        <Avatar :src="session.avatar" />
        <div style="margin-top:5px;font-size:12px;font-family:宋体">我的</div>
      </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    computed:{
      ...mapGetters('session',['session','darkmode','theme','themes']),
      menus(){
        return [{
          icon:'md-desktop',
          path:'/core/dashboard',
          name:"工作台"
        },{
          icon:'date',
          path:'/core/tasks',
          name:"任务"
        },{
          icon:'nbgz',
          path:'/core/news',
          name:"动态"
        },{
          path:'/core/self'
        }]
      },
      path(){
        return this.$route.path
      },
      spinLeft(){
        let index = this.menus.findIndex(v=>this.path.indexOf(v.path) == 0)
        return `calc(${25 * (index+1)}% - 12.5% - 7.5px)`
      }
    },
    mounted(){
      if(!this.session.id){
        this.$store.dispatch('session/whoami').then(()=>{
          this.$bus.$emit('login')
        })
      }
    },
  }
</script>

<style lang="less">
.app-bar-mobile{
  height:80px;
  background:var(--bg1);
  color:var(--text1);
  display: flex;
  justify-content:space-around;
  align-items: center;
  overflow: visible;
  padding-bottom:10px;
  padding-top:5px;
  position: relative;
  box-shadow: 0 -2px 5px 1px #aaa;

  
.app-menu-item{
  width:100px;
  margin:10px;
  text-align: center;
}

.app-menu-item-active{
  i{
    color:var(--primary);
  }
  color:var(--primary);
}

.app-menu-item-center{
  position: relative;
  top:-25px;
  margin:0;
  background:var(--bg1);
  border-radius: 50%;

}

.abm-spin{
  position: absolute;
  top:2px;
  height:2px;
  width:15px;
  border-radius: 5px;
  background:var(--primary);
  transition:all 0.3s;
}

}

</style>