<style lang="less">
.ugo-item-user {
  width: 116px;
  height: 38px;
  padding: 0 10px;
  background: var(--bg3);
  cursor: pointer;
}

.ugo-item-user:hover {
  background: var(--primary);
  transition: all 0.3s;
}

.ugo-item-user-selected {
  background: var(--primary);
  color: var(--hover-text);
}

.ivu-select-dropdown {
  max-height: 400px;
}
</style>
<template>
  <div class="pm-embed-control flex-wrap" style="color:var(--text3);align-items:flex-start;width:100%;position:relative;height:100%;">

    <Dropdown trigger="custom" :visible="showDropdown" transfer placement="bottom-start" style="width:100%;display:block;height:100%;" :disabled="!editable" @on-clickoutside="handleClickOut">
      <div class="value-wrap flex-wrap" style="cursor:pointer;border-radius:3px;padding:0 5px;" @click="showDropdown=true;searchText=''">
        <template v-if="selected.length == 0">
          <Avatar shape="square" style="color:var(--hover-text)">
            <BaseIcon icon="md-add" />
          </Avatar>
        </template>
        <template v-else>
          <template v-for="user in selected_users.slice(0,3)">
            <div class="flex-wrap" :key="user.id">
              <Tooltip :content="user.name" transfer>
                <Avatar shape="square" style="margin-right:3px" :src="user.avatar">{{user.name?user.name[0]:'-'}}</Avatar>
              </Tooltip>
            </div>
          </template>
          <Avatar shape="square" v-if="selected_users.length > 3" :title="selected_users.slice(3).map(v=>v.name).join(',')" style="background:var(--primary);color:var(--hover-text);">
            +{{selected_users.length - 3}}
          </Avatar>
          <Avatar shape="square" v-if="multiple && editable" style="color:var(--hover-text);margin-left:5px;background:var(--bg3);color:var(--subtext3);border:1px solid var(--border);text-align:center">
            <BaseIcon icon="md-add" />
          </Avatar>
        </template>
      </div>
      <DropdownMenu slot="list">
        <div ref="panel" class="" style="max-width:600px;background:var(--bg2);position:relative;max-height:300px;overflow:hidden;">
          <template v-if="option.groups">
            <template v-if="prev">
              <div @click="current=prev;prev=null">Back</div>
            </template>
            <template v-if="current.groups">
              <template v-for="o in current.groups">
                <div :key="o.key">
                  <Avatar shape="rect" :src="o.avatar">{{o.name}}</Avatar>
                </div>
              </template>
            </template>
            <template v-if="current.users">
              <template v-for="o in current.groups">
                <div :key="o.id">
                  <Avatar shape="rect" :src="o.avatar">{{o.name}}</Avatar>
                </div>
              </template>
            </template>
          </template>
          <template v-else>
            <div class="flex-wrap split5" style="width:100%;padding:5px"><Input v-model="filterText" search clearable style="width:calc(100% - 70px)" /><Button @click="$emit('input',[]);selected=[];showDropdown=false;">清除</Button></div>

            <div class="flex-wrap" style="max-height:140px;overflow-y:auto;flex-wrap:wrap;overflow-x:hidden;">
              <template v-for="o in filteredUsers">
                <div class="flex-wrap ugo-item-user" :class="{
                  'ugo-item-user-selected':selected && selected.includes(o.id)
                }" :key="o.id" style="" @click="handleClick(o)">
                  <Avatar :src="o.avatar">{{o.name[0]}}</Avatar>
                  <div style="margin-left:5px">{{o.name}}</div>
                </div>

              </template>
            </div>
          </template>

        </div>
        <div class="flex-wrap flex-between" v-if="multiple" style="width:100%;height:40px;padding:0 10px;background:var(--bg3);border-top:1px solid #ddd;" @click.stop>
          <div class="flex-wrap split5">
            <div>已选择 {{selected.length}} <span v-if="option.limit">/ {{ option.limit }}</span></div>
            <Button size="small" @click.stop="handleSelectAll" v-if="option.limit">全选</Button>
            <Button size="small" @click.stop="handleClear">取消</Button>
          </div>
          <Button icon="md-checkmark" @click="handleConfirm" type="primary" size="small">确认</Button>
        </div>
      </DropdownMenu>
    </Dropdown>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "BaseUserSelect",
  icon: 'xialaliebiao',
  data() {
    return {
      prev: false,
      current: {},
      showDropdown: false,
      filterText: "",
      selected: []
    }
  },
  watch: {
    value: {
      handler(v) {
        this.filterText = ""
        if (Array.isArray(this.value)) {
          this.selected = this.value
        } else if (this.value) {
          this.selected = this.multiple ? [...this.value] : (this.value ? [this.value] : [])
        } else {
          this.selected = []
        }
      }
    },
  },
  mounted() {
    if(this.employees.length == 0)
      this.$store.dispatch('session/getGroupedEmployees')
    
    this.filterText = ""
    if (this.option.groups)
      this.current = { groups: this.option.groups }
    if (Array.isArray(this.value)) {
      this.selected = [...this.value]
    } else if (this.value) {
      this.selected = this.multiple ? [...this.value] : (this.value ? [this.value] : [])
    } else {
      this.selected = []
    }
  },
  props: {
    'option': {
      type: Object,
      default: () => ({})
    }, 'value': {
      type: String | Array,
      default: () => ([])
    }, 'editable': {
      type: Boolean
    }  },
  computed: {
    ...mapGetters("session", ["users","employees"]),
    filteredUsers() {
      let users = this.users
      if(this.option.ent){
        users = this.employees        
      }
      if (this.filterText) {
        return users.filter(v => v.name && v.name.includes(this.filterText))
      } else {
        return users.filter(v => v.name)
      }
    },
    multiple() {
      return this.option.multiple
    },
    user() {
      return this.users.find(v => v.id == this.value) || {}
    },
    selected_users() {
      return this.selected.map(v => this.users.find(u => u.id == v)).filter(v => v)
    }
  },
  methods: {
    handleClickOut(e) {
      if (!this.$refs.panel.contains(e.target)) {
        this.showDropdown = false
        if (Array.isArray(this.value)) {
          this.selected = this.value
        } else if (this.value) {
          this.selected = this.multiple ? [...this.value] : (this.value ? [this.value] : [])
        } else {
          this.selected = []
        }
      }
    },
    handleClear(e) {
      this.selected = []
    },
    handleSelectAll() {
      this.selected = this.filteredUsers.map(v => v.id)
    },
    handleClick(o) {
      if (this.multiple) {
        let index = this.selected.findIndex(v => v == o.id)
        if (index == -1){
          if(this.selected.length < this.option.limit)
            this.selected.push(o.id)
        }
        else
          this.selected.splice(index, 1)
      } else {
        this.selected = [o.id]
        this.$emit('input', o.id)
        this.showDropdown = false
      }
    },
    handleConfirm() {
      this.$emit('input', [...this.selected])
      this.showDropdown = false
    }
  }
}
</script>

<style lang="less">
.l-full {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>