<template>
    <div>
        <div v-if="editable">
            <Select 
                :value="value"
                @on-change="$emit('change',$event)"
                @input="$emit('input',$event)"
                placeholder="选择"
            >
                <Option v-for="o in options" :value="o.id" :key="o.id">
                    {{ o.name }}
                </Option>
            </Select>
        </div>
        <div v-else style="display:inline-block;width:100%;">
           
            <Static :value="readableValue" />
        </div>
    </div>
     
  </template>
  <script>
  import Static from './Static'
  export default {
    name:'DynamicUserSelect',
    components:{Static},
      props:{
        value:{
            type:String
        },
        option:{
            type:Object,
            default:()=>({})
        },
        editable:{
            type:Boolean,
            default:true
        }
      },
      computed:{
        options(){
            return this.$store.getters['session/users']
        },
        readableValue(){
            let user = this.options.find(v=>v.id == this.value)
            if(user)
                return user.name
            else
                return '未选择'
        }
      }
  }
  </script>
  
  <style>
  
  </style>