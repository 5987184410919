<template>
<BaseSegment title="人员投入" desc="项目的人员实际投入情况" style="margin:0">
<div slot="title-append" v-if="isProjectFinished" icon="md-checkmark" color="var(--success)"
                style="position:absolute;left:160px;top:-5px;padding:5px;border:3px solid var(--success);border-radius:50%;opacity:0.5;font-weight:bold;color:var(--success);transform:rotateZ(-25deg);padding:10px;filter:grayscale(1)" 
                :size="30"
              >项目已结束</div>
<div class="flex-wrap split10" slot="extra">
				<BaseBoardButton icon="md-create" name="添加人员" desc="单个新增人员" @on-click="showAdd=true" />

      

				<BaseBoardButton icon="md-create" name="快速调整" desc="每月系数批量调整" @on-click="showFactorEditorModal()" v-if="users.length > 0" />

      
				<BaseBoardButton icon="md-list" name="统计信息" desc="人力投入测算" @on-click="calcFilterStatsUsers();showStatistic=true"  v-if="users.length > 0" />

			</div>
    <div class="flex-wrap flex-left split10" style="background:var(--bg2);margin:0 10px;padding:10px;">
      <BaseNumboard name="在岗人数"  :value="stats.count_on_duty" unit="人" reverse />
       <BaseNumboard name="离岗人数"  :value="stats.count_off_duty" unit="人" reverse />
      <BaseBoardDivider />
      <template v-if="!stats.hrv_error">
        <BaseNumboard name="总体投入" style="margin-left:30px" align="flex-end" :value="stats.total_hrv" unit="人月" reverse />
      
<BaseBoardDivider />
      <BaseNumboard :name="`年度投入(${stats.current_year})`" align="flex-end" :value="stats.year_hrv" unit="人月" reverse />
<BaseBoardDivider />
      <BaseNumboard :name="`月度投入(${stats.current_month}月)`" align="flex-end" :value="stats.month_hrv" unit="人月" reverse />
      <BaseBoardDivider />
      <BaseNumboard name="项目开始" align="flex-start" :value="data.started_at
									? moment(data.started_at).format('YYYY/MM/DD')
									: '-'" reverse  />
					<BaseNumboard name="项目开工" align="flex-start" :value="data.operated_at
									? moment(data.operated_at).format('YYYY/MM/DD')
									: '-'" reverse  />
					<BaseNumboard name="项目竣工" align="flex-start" :value="data.completed_at
									? moment(data.completed_at).format('YYYY/MM/DD')
									: '-'" reverse />
					<BaseNumboard name="项目结束" align="flex-start" :value="data.finished_at
									? moment(data.finished_at).format('YYYY/MM/DD')
									: '-'" reverse  />

					<BaseBoardDivider />
      </template>
      <template v-else>
        <div>
        {{stats.hrv_error}}
        </div>
      </template>
      </div>
    
    <BaseEmpty v-if="!users || users.length == 0" />
    <template v-else>
     
      <div style="margin-top:20px;">
        <div class="flex-wrap">
        <BaseBoardSubTitle name="在岗人员" style="margin-left:150px" />
        <BaseBoardSubTitle name="离岗人员" style="margin-left:calc(50% - 300px)" />
        </div>
        <!-- <template v-for="h in users">
          <div class="flex-wrap flex-between l-hover" :style="h.finished_at?'filter:grayscale(1)':''" :key="h.id" style="width:calc(10% - 5px);min-width:100px;margin:10px;cursor:pointer"  @click="showUserModal(h)">
          <BaseNameBoard  size="33" disabled :uid="h.user_id" style="font-size:18px;font-weight:bold;color:#369">
          <div class="flex-wrap">
            <span style="font-size:10px;color:#888">{{h.position}}</span>
             <span class="u-icon" style="font-size:10px;background:var(--bg1);margin-left:5px;">{{h.factor}}</span></div>
          </BaseNameBoard> 
          
          </div>
        </template> -->

        <template v-for="g in positionGroups">
           <div class="flex-wrap hr-group" :key="'g'+g.name" style="padding:5px;flex-wrap:wrap !important;border-bottom:1px dashed #aaa;align-item:stretch;">
            
           <div class="flex-wrap" style="width:130px;flex-grow:0;flex-shrink:0;margin-right:20px;height:50px;padding:0 10px;"> <BaseNumboard style="width:120px;" :name="g.name"   align="flex-start" labelStyle="font-size:13px;margin-bottom:3px;var(--text3)" valueStyle="color:var(--subtext3);font-size:12px;font-weight:normal" reverse />
           <BaseIcon icon="ios-arrow-forward" style="margin-left:5px" size="20" color="var(--primary)" />
           </div>
           
           <div class="flex-wrap" :key="'group'+g.name" style="flex-shrink:1;width:calc(50% - 150px);flex-wrap:wrap;">
          <template v-for="h in g.users.filter(v=>!v.leaved)">
            <div class="flex-wrap flex-between l-hover" :style="h.leaved?'filter:grayscale(1)':''" :key="'u'+h.id" style="margin:5px;cursor:pointer;width:120px;"  @click="showUserModal(h)">
            <BaseNameBoard  size="33" disabled :uid="h.user_id">
              <div style="font-size:10px;background:var(--bg2);padding:0 2px;font-weight:normal;border-radius:5px;margin-top:2px;"> {{h.factor || 0}}{{h.intern?'-实习 ':''}} </div>
            </BaseNameBoard> 
            
            </div>
          </template>
           </div>
           <div class="flex-wrap" :key="g.name" style="flex-shrink:1;width:calc(50% - 150px);flex-wrap:wrap;">
          <template v-for="h in g.users.filter(v=>v.leaved)">
            <div class="flex-wrap flex-between l-hover" :style="h.leaved?'filter:grayscale(1)':''" :key="h.id" style="margin:5px;cursor:pointer;width:120px;"  @click="showUserModal(h)">
            <BaseNameBoard  size="33" disabled :uid="h.user_id">
              <div style="font-size:10px;background:var(--bg2);padding:0 2px;font-weight:normal;border-radius:5px;margin-top:2px;">{{h.factor || 0}}</div>
            </BaseNameBoard> 
            
            </div>
          </template>
           </div>
           </div>
        </template>
         
        <div style="font-size:10px;padding:10px 15px;color:var(--subtext3)"><BaseIcon icon="md-alert" /> 点击人员可以查看其详情进行单独调整</div>
      </div>
      

    </template>
    <Modal v-model="showAdd" footer-hide title="添加人员" :width="400">
     
        <Form ref="form_user" :model="formData" :label-width="60" style="background:var(--bg3);padding:15px 10px;">
          <FormItem label="员工" required>
            <BaseUserSelectGrouped v-model="formData.user_id" :config="{users:total_users}" />
          </FormItem>
         
          <FormItem label="岗位" required>
             <Select transfer  style="width:160px" searchable v-model="formData.position" clearable  >
                  <template v-for="(p,i) in positions">
                    <Option :key="i+'adp'+p" :value="p">{{p}}</Option>
                  </template>
                </Select>
                
           <div v-if="this.data.positions && this.data.positions.length > 0" style="font-size:10px;padding:5px;line-height:18px;color:var(--subtext3)"><BaseIcon icon="md-alert" /> 此项目已在签约计划中设置岗位，请按照预设岗位选择。若有新增，选择'其他'，并填写备注信息</div>
          </FormItem>
          <FormItem label="特殊">
            <Checkbox v-model="formData.intern" /> 实习
          </FormItem>
          <FormItem label="备注">
            <Input v-model="formData.note" number />
          </FormItem>
          
          <FormItem label="系数" required>
            <Input v-model="formData.factor" style="width:40px;" number />
          </FormItem>
          <FormItem label="开始" required>
            <BaseDate v-model="formData.started_at" />
          </FormItem>
          <FormItem label="结束">
            <BaseDate v-model="formData.finished_at" />
          </FormItem>
          <div class="flex-wrap flex-right split10">
            <Button type="primary" @click="handleAddUser">提交</Button>
            <Button @click="showAdd=false">取消</Button>
          </div>
        </Form>
    </Modal>

    <Modal v-model="showFactorEditor" footer-hide title="当月系数调整" width="600">
    
      <div v-if="showFactorEditor">
         
        <div style="padding:20px;background:var(--bg3);">
         
        <template v-for="u in users_editing">
          <div class="flex-wrap flex-between" :key="u.user_id" style="margin-bottom:10px;border-bottom:1px dashed var(--border);padding-bottom:10px;">
            
             <BaseNameBoard  size="33" disabled :uid="u.user_id" >
              
                <span style="font-size:10px;color:var(--subtext3)">上次调整 {{moment(u.started_at).format("YYYY/MM")}}</span>
              
             </BaseNameBoard>
             <div class="flex-wrap split10">
                
              
                <Select transfer  style="width:160px" searchable v-model="u.position"  v-if="!u.leaved" >
                    <template v-for="p in positions">
                      <Option :key="'p2'+p" :value="p">{{p}}</Option>
                    </template>
                </Select>
                  <Input v-model="u.factor" number style="width:60px" />
                 
              </div>
          </div>
        </template>
        
        </div>
        <div class="flex-wrap flex-between" style="margin-top:10px">
          <div></div>
          <div class="flex-wrap split10">
            <Button type="primary" @click="handleSaveFactor()">提交</Button>
            <Button @click="showFactorEditor=false">取消</Button></div>
        </div>
      </div>
    </Modal>
    <Modal v-model="showStatistic" footer-hide title="统计信息" width="900">
      <div style="padding:0px 20px">
        
      <div class="flex-wrap" >
        <template v-if="data.current_nodes && data.current_nodes.length > 0">
        服务阶段
         <Select v-model="selected_node" placeholder="请选择"  @on-change="handleSelectFilterNode" clearable style="width:240px;margin:0 10px;margin-right:20px;">
            <Option :key="n.id" v-for="(n,i) in data.current_nodes" :value="n.id">
              阶段{{i+1}} - {{n.name}}
            </Option>
          </Select>
        </template>
          服务月份
          <Select v-model="selected_month" @on-change="handleSelectFilterMonth"  placeholder="请选择"  style="width:200px;margin:0 10px;">
            <Option :key="i" v-for="(n,i) in months" :value="i">
              {{n}}
            </Option>
          </Select>
        </div>
        <div class="flex-wrap" style="margin:10px 0">
          

          起始时间
          <DatePicker v-model="filter_started_at"  style="width:140px;margin:0 10px;" clearable placeholder="开始日期" />

          结束时间
          <DatePicker v-model="filter_finished_at" style="width:140px;margin:0 10px;" clearable placeholder="结束日期" />

          <Button  @click="handleClear" style="margin-right:10px" v-if="filter_started_at || filter_finished_at">清除</Button>

          

          <Button type="primary" @click="calcFilterStatsUsers">计算</Button>
        </div>
        <BaseAlert type="primary" v-if="data.status == '已结束'">注意：此项目已结束于 {{moment(data.finished_at).format("YYYY/MM/DD")}}</BaseAlert>
        <div style="position:relative;height:400px;">
          <template  v-if="showStatistic">
          <BaseTable ref="table" :columns="cols_statistic" :option="{summary:true}" :data="filtered_stats_users" :paged="false" /></template>
        </div>
      </div>
    </Modal>

    <Modal v-model="showUser" footer-hide title="员工信息" width="900">
      <div v-if="user.id">
        <BaseSegment title="员工信息 / 本项目服务情况" >
          <div class="flex-wrap flex-between">
          <div class="flex-wrap split5">
           <BaseNameBoard style="flex-shrink:0"  size="33" :uid="user.user_id">
            <span style="font-size:10px">{{user.position}}</span>
          </BaseNameBoard> 
           <div class="u-icon" style="flex-shrink:0;background:var(--bg2);padding:1px 8px;font-weight:normal;border-radius:5px;margin-left:20px;">{{user.intern?'实习 ':''}}{{user.factor}}</div>
          <div style="padding:0px 5px;background:var(--primary);color:var(--hover-text);border-radius:5px;flex-shrink:0" :style="user.leaved?'filter:grayscale(1)':''">
              {{!user.leaved?'在岗':'已离岗'}}
            </div>
          </div>
          <BaseBoardDivider />
          <div class="flex-wrap split10">
                <Input v-model="userData.factor" style="width:60px" v-if="!user.leaved" />
                <Select transfer style="width:140px" clearable filterable searchable v-model="userData.position"  v-if="!user.leaved" >
                  <template v-for="p in positions">
                    <Option :key="'ud'+p" :value="p">{{p}}</Option>
                  </template>
                </Select>
                <DatePicker v-model="userData.finsihed_at" style="width:140px" v-if="!user.leaved" placeholder="离岗时间" />
                  <Button type="primary" icon="md-build" v-if="!user.leaved" @click="handlePatchUser">快速调整</Button> 

          
          </div>
         
        </div>
        <div style="border-top:1px dashed var(--border);
        background:var(--bg3);padding:0 10px;margin-top:30px;">
          
          <BaseControlTable style="margin-top:20px;margin-bottom:10px" v-model="userData.records" :editable="true" :config="recordTable">
                <BaseBoardSubTitle slot="title" name="服务记录详情" />
                
          <div class="flex-wrap flex-right" slot="buttons" style=""> <Button type="primary" style="margin-left:10px" @click="handleSaveUser" v-if="user_changed"><BaseIcon icon="save" :size="18" style="margin-right:5px" /> 保存</Button> <Button style="margin-left:10px" icon="md-trash" type="error" @click="handleDeleteUser(user.user_id)">删除此人员</Button></div>
          </BaseControlTable>
          <div style="color:var(--subtext3)" v-if="user_record_errors.length > 0">
            错误提醒
            <template v-for="e in user_record_errors">
              <BaseAlert type="error" :key="e">{{e}}</BaseAlert>
            </template>
          </div>
        </div>
         
        </BaseSegment>
        
        <BaseSegment title="员工当前所有在岗项目" disabled v-if="projects.length > 0">
          <div class="flex-wrap flex-line">
            <BaseNumboard style="flex-shirnk:0;margin-right:20px;min-width:100px;background:var(--bg2);padding:5px 0;" name="当前总系数" reverse :value="fix(projects.reduce((prev,cur)=>prev+(cur.factor?parseFloat(cur.factor):0),0))" />
            <div style="width:100%">
              <template v-for="(p,i) in projects">
              <div :key="p.id" class="flex-wrap flex-between">
                <div><span style="color:var(--text3)">{{i+1}} - {{p.name}}</span> 
                <span style="color:var(--subtext3);margin-left:10px;">{{p.started_at?moment(p.started_at).format('YYYY/MM/DD'):"-"}}</span>  </div>
                <div>{{p.position}} ({{p.factor || 0}})</div>
              </div>
            </template>
            </div>
          </div>
    
        
        </BaseSegment>
      </div>
    </Modal>


  </BaseSegment>
</template>

<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import {cloneDeep} from 'lodash'
import UTIL from '@/utils'
import {time_int_duration,time_duration,fix} from '@/utils/calc'


export default {
  data(){
    return {
      selected:[],
      showPositions:false,
      showAdd:false,
      formData:{
        factor:1,
        started_at:moment().format()
      },
      user:{},
      showEditor:false,
      showFactorEditor:false,
      showStatistic:false,
      showUser:false,
      new_position: "",
      userData:{

      },
      projects:[],
      users_editing:[],
      filterDate:"",

      selected_node:null,
      selected_month:null,
      filter_point:false,
      filter_started_at:null,
      filter_finished_at:null,
      filtered_stats_users:[]
      
    }
  },
  mounted(){
    this.selected = this.pm
  },
  props:{
    data:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    ...mapGetters('session',{
      "total_users":"users"
    }),
    user_changed(){
      let data = UTIL.compare_patched(this.user.records,this.userData.records,['factor','position','note','started_at','finished_at'])
      return !data.empty
    },
    user_record_errors(){
      // caculate records errors
      if(!this.showUser)
        return []
      let errors = []
      let prev = null
      this.userData.records.forEach((v,i)=>{
        
        
        if(v.finished_at && moment(v.finished_at).isBefore(v.started_at)){
          errors.push(`[记录${i+1}] 阶段时间无效:开始时间(${moment(v.started_at).format('YYYY-MM-DD')}) > 完成时间${moment(v.finished_at).format('YYYY-MM-DD')}`)
        }


        if(!prev){
          if(v.finished_at)
            prev = moment(v.finished_at)
        }else{
          if(moment(v.started_at).isBefore(prev)){
            errors.push(`[记录${i+1}] 重复的时间段`)
          }else if(v.finished_at){
            prev = moment(v.finished_at)
          }
        }
        
      })

      return errors
    },
    
    stats(){
      
      let d = {}
      let users = this.data.hr
      d.count_on_duty = this.users.filter(v=>!v.leaved).length 
      d.count_off_duty = this.users.filter(v=>v.leaved).length 
      if(!users)
        return d

      let total_started = moment(this.data.started_at).startOf('day')
      let total_finished = this.data.finished_at
      if(!total_started)
          d.hrv_error ="未录入开始时间和结束时间，无法测算当前数值"
      
      
      let now = moment().startOf('day')
      if(!total_finished)
        d.hrv_warning = "未录入项目结束时间，如果项目已结束，人员未结束时，数据会出现异常"
      else
        now = moment(total_finished).isAfter(now)?now:moment(total_finished)

      d.current_year = now.year()
      d.current_month = now.month()+1

      
      d.total_hrv = 0
      d.year_hrv = 0
      d.month_hrv = 0

      users.forEach(u=>{
        let user_started = u.started_at
        let user_finished = u.finished_at && moment(u.finished_at).isBefore(now)?u.finished_at:now
        let factor = u.factor || 0
        let total_duration = time_int_duration(total_started, now, user_started, user_finished,'months')
        let year_duration = time_int_duration(now.clone().startOf('year'),now,user_started,user_finished,'months')
        let month_duration = time_int_duration(now.clone().startOf('month'),now,user_started,user_finished,'months')

        d.total_hrv   += total_duration * factor
        d.year_hrv    += year_duration  * factor
        d.month_hrv   += month_duration * factor
      })

   

      d.total_hrv = fix(d.total_hrv,2)
      d.year_hrv = fix(d.year_hrv,2)
      d.month_hrv = fix(d.month_hrv,2)
      return d
    },
    id(){
      return this.$route.params.id
    },
    
    cols_statistic(){
      return [{
        type:"index",
        title:"序号",
        width:60
      },{
        type:"text",
        title:"姓名",
        key:"user_id",
        width:120,
        align:"left",
        render:(h,params)=>{
          return h("a",{
          on:{
            'click':()=>{
              this.showUserModal(params.row)
            }
          }},[h("BaseNameBoard",{style:"margin:0 10px;text-align:center;",
          props:{
            disabled:true,
            uid:params.row.user_id
          }})])
        }
      },{
        type:"text",
        key:"position",
         align:"left",
        title:"岗位",
      },{
        type:"time",
        key:"started_at",
         width:120,
        title:"开始",
        option:{
          type:"date"
        }
      },{
         type:"time",
        key:"finished_at",
           width:120,
        title:"结束",
        option:{
          type:"date"
        }
      },{
        type:"number",
           width:100,
        title:"平均系数",
        key:"avg_factor",
      },{
        type:"number",
           width:100,
        title:"服务天数",
        key:"duration",
        option:{
          formatter:e=>fix(e),
          sumable:true
        }
      },{
        type:"number",
        key:"hrv",   
        width:100,
        title:"人力(人月)",
        option:{
          formatter:e=>fix(e,2),
          sumable:true
        }
      }]
    },
    recordTable(){
      return {
        disable_moveupdown:true,
        cols:[{
          key:"position",
          label:"岗位",
          type:"select",
          defaultValue:this.user.position,
          config:{
            text:true,
            options:this.positions
          }
        },{
          key:"note",
          label:"备注",
          type:"text",
          defaultValue:""
        },{
          key:"factor",
          label:"系数",
          type:"text",
          width:80,
           defaultValue:1
        },{
          key:"started_at",
          label:"开始",
          type:"date",
          defaultValue:moment().format()
        },{
          key:"finished_at",
          label:"结束",
          type:"date"
        }]
      }
    },
    now(){
      return moment()
    },
    isProjectFinished() {
			return (
				this.data.status == "已结束"
			);
		},
    def_position_groups() {
      const sp =  [
					"总监",
					"总代",
					"土建工程师",
					"土建监理员",
          "安装监理员",
					"市政工程师",
					"市政监理员",
					"资料员",
					"安装监理员",
					"桩基监理员"
      ]
      const pm = [
					"项目经理",
					"项目经理助理",
					"前期工程师",
					"技术工程师",
          "安装工程师",
					"合约工程师",
					"机电工程师",
					"项目副经理",
					"造价工程师",
					"现场工程师"
				]

        let positions = []
        if(!this.data)
          positions= []
        if(this.data.business_type == '全过程咨询')
          positions= [...sp,...pm]
        else if(this.data.business_type == '项目管理')
          positions= [...pm]
        else if(this.data.business_type && this.data.business_type.includes('监理'))
          positions= [...sp]
        else
          positions= [...sp,...pm]
        positions.push('其他岗位')
        return positions
        
        
			},
    positions(){
      return !this.data.positions || this.data.positions.length == 0 ? this.def_position_groups : [...this.data.positions,'其他岗位']
    },
    positionGroups(){
      return this.positions.map(v=>{
        if(v == '其他岗位'){
          return {
            name:v,
            users:this.users.filter(u=>u.position == v || !this.positions.includes(u.position))
          }
        }
        return {
          name:v,
          users:this.users.filter(u=>u.position == v)
        }
      }).filter(v=>v.users.length > 0)
    },
    users(){
      let users = {}
      if(!this.data.hr)
        return []
      this.data.hr.forEach(v=>{
        if(users[v.user_id]){
          users[v.user_id].records.push(v)
        }else{
          users[v.user_id] = {records:[v]}
        }
      })
      let arr = Object.values(users)
      arr.forEach(item=>{
        item.records.sort((a,b)=>moment(a.started_at).isBefore(moment(b.started_at))?-1:1)
     
        Object.assign(item,item.records[item.records.length-1])
        item.record_id = item.id
        let last = item.records[item.records.length-1]
        if(last.finished_at && (moment(last.finished_at).isBefore(moment())))
          item.leaved = true
      })

      
      const sortWithPosition = (a,b)=>{
        return this.positions.findIndex(v=>v == a) >  this.positions.findIndex(v=>v == b)?1:-1
      }

     let a= arr.filter(v=>!v.leaved)
     a.sort(sortWithPosition)
     let b = arr.filter(v=>v.leaved)
     b.sort(sortWithPosition)

      return  [...a,...b]    
    },
    pm(){
      const positions = ['项目经理','项目经理助理']
      return this.users.filter(v=>positions.includes(v.position) || v.position && v.position.includes('工程师'))
    },
    sp(){
      const positions = ['总监']
      return this.users.filter(v=>positions.includes(v.position) || v.position && v.position.includes('监理员'))
    },
    current_users(){

    },
    months(){
      let r = []
      if(this.data.started_at){
          let start = moment(this.data.started_at)
          let finished_at = this.data.finished_at && moment().isAfter(this.data.finished_at)?this.data.finished_at : moment()
          for(let t=start.clone();t.isBefore(finished_at);t=t.add(1,'month')){
            r.push(t.startOf('month').format("YYYY年MM月"))
          }
      }

      return r
      
    }
  },
  methods:{
    fix(e = 0){
      return parseInt(e*100)/100    },
    calcFilterStatsUsers(){
      let users = {}
      if(!this.data.hr)
        return []
      this.data.hr.forEach(v=>{
        if(users[v.user_id]){
          users[v.user_id].records.push(v)
        }else{
          users[v.user_id] = {id:v.user_id,records:[v]}
        }
      })
      let arr = Object.values(users)

      arr.forEach(item=>{
        if(!item.records || item.records.length == 0){
          console.error('user_id has no records',item.id)
          return
        }
        item.records.sort((a,b)=>moment(a.started_at).isBefore(moment(b.started_at))?-1:1)
        let first_record = item.records[0]
        let last_record = item.records[item.records.length - 1]
        Object.assign(item,item.records[item.records.length-1])
        
        let now = this.data.finished_at && moment(this.data.finished_at).isBefore(moment().startOf('day'))?moment(this.data.finished_at):moment().startOf('day')
        let started_at = first_record.started_at
        let finished_at = last_record.finished_at && moment(last_record.finished_at).isBefore(now)?last_record.finished_at: now
        item.started_at = null
        item.finished_at = null
        item.duration = 0
        item.hrv = 0
        item.records.forEach(record=>{
          let duration = 0
          let duration_m = 0
          if(this.filter_started_at){
            let filter_finished_at = this.filter_finished_at ||now
            let [p, q] = UTIL.timeIntersection(started_at,finished_at, this.filter_started_at,filter_finished_at)
            let [x, y] = UTIL.timeIntersection(p,q,record.started_at,record.finished_at || now)

            if(x && (!item.started_at ||  x.isBefore(item.started_at)))
              item.started_at = x.format("YYYY/MM/DD")
            if( y && (!item.finished_at || y.isAfter(item.finished_at)))
              item.finished_at = y.format("YYYY/MM/DD")
            duration = time_duration(x, y)
            duration_m = time_duration(x, y,'months')
            
          }else{
            let [x, y] = UTIL.timeIntersection(started_at,finished_at,record.started_at,record.finished_at || now)
            if(x && (!item.started_at ||  x.isBefore(item.started_at)))
              item.started_at = x.format("YYYY/MM/DD")
            if( y && (!item.finished_at || y.isAfter(item.finished_at)))
              item.finished_at = y.format("YYYY/MM/DD")
                  duration = time_duration(x, y)
            duration_m = time_duration(x, y,'months')
          }


          item.duration += duration
          item.hrv += duration_m * (record.factor || 0)
        })

        
        if(last_record.finished_at)
          item.leaved = true

      })
      arr = arr.filter(v=>v.duration > 1)

      arr.forEach(item=>{
        if(item.duration > 0)
          item.avg_factor = fix(item.hrv / item.duration,3)
        item.duration = fix(item.duration)
        item.hrv = fix(item.hrv,2)
      })

      
      const sortWithPosition = (a,b)=>{
        return this.positions.findIndex(v=>v == a) >  this.positions.findIndex(v=>v == b)?1:-1
      }

     let a= arr.filter(v=>!v.leaved)
     a.sort(sortWithPosition)
     let b = arr.filter(v=>v.leaved)
     b.sort(sortWithPosition)
      this.filtered_stats_users =  [...a,...b]  
    },
    handleSaveFactor(){
      let patched = UTIL.compare_patched(this.users.filter(v=>!v.leaved),this.users_editing,['factor','position'])
      this.$api.post("/hrs?q=mod-hr",patched.update).then(res=>{
        this.data.hr = res.data.data
        this.showFactorEditor = false
      }).catch(e=>{
        this.Error('操作失败:'+e.message)
      })
    },
    handleClear(){
        this.filter_point = false
      this.selected_month = null
       this.selected_node = null
       this.filter_started_at = this.filter_finished_at = null
    },
    handleSelectFilterNode(e){
      this.filter_point = false
      this.selected_month = null
      let node = this.data.nodes.find(v=>v.id == e)
      if(node){
        this.filter_started_at = node.started_at
        this.filter_finished_at = node.finished_at
        
      this.calcFilterStatsUsers()
      }
      
    },
    handleSelectFilterMonth(e){
       this.filter_point = false
        this.selected_node = null
      this.filter_started_at = moment(this.data.started_at).add(e,'months').startOf('month').format("YYYY-MM-DD")
      this.filter_finished_at = moment(this.filter_started_at).clone().endOf("month").format("YYYY-MM-DD")
      this.calcFilterStatsUsers()
    },
    handlePatchUser(){
      let item = {
        factor:this.userData.factor,
        position:this.userData.position,
        finished_at:this.userData.finished_at
      }
      let current_month = moment().startOf('month')
      if(this.data.finished_at && current_month.isAfter(this.data.finished_at)){
        this.Error("调整失败,检测到此项目已结束")
        return
      }
      let data = {}
      let isUpdate = false
      if(!this.user.records || this.user.records.length == 0){
       data = {
          create:[{
            user_id:this.userData.user_id,
            factor:this.userData.factor,
            position:this.userData.position,
            started_at:current_month.format()
          }]
       }
      }else if(!moment(this.user.records[this.user.records.length - 1].started_at).isBefore(current_month)){
        isUpdate = true
        data = {
          update:[
            { 
              id:this.userData.record_id,
              factor:this.userData.factor,
            }
          ]
        }
      }else{
        data = {
          create:[{
            user_id:this.userData.user_id,
            factor:this.userData.factor,
            position:this.userData.position,
            started_at:current_month.format()
          }],
          update:[{
            id:this.userData.record_id,
            finished_at:current_month.clone().subtract(1,'days').format()
          }]
        }
      }

      this.Confirm(isUpdate?"您本月已提交过项目调整,本次提交将覆盖上次的调整,是否继续执行?":"本操作将快速调整员工的系数，请确认?",()=>{
        this.$api.post("/hrs?q=patch-hr",{
          project_id:this.id,
          data
        }).then(res=>{
          this.data.hr = res.data.data
          this.showUser = false
        })
      })
      
      
    },
    handleSaveUser(){
      //check_user_records
      if(this.user_record_errors.length >0){
        this.Error("数据存在错误，请按错误提示修改后再尝试保存")
        return
      }

      //
      let patched = UTIL.compare_patched(this.user.records,this.userData.records,['factor','position','started_at','finished_at'])
      patched.create.forEach(v=>{
        v.user_id = this.user.user_id
      })

      this.$api.post("/hrs?q=patch-hr",{
        project_id:this.id,
        data:patched
      }).then(res=>{
        this.data.hr = res.data.data
         this.user = this.users.find(v=>v.user_id == this.user.user_id)
         this.userData = cloneDeep(this.user)
        this.$forceUpdate()
        this.$Notice.success({title:"修改成功"})
      })
    },
    getUserProjects(user_id){
      this.user.projects = []
      this.$api.get("hrs?user="+user_id).then(res=>{
        let projects = res.data.data
        this.$set(this,'projects',projects)
        this.$forceUpdate()
      })
    },
    showFactorEditorModal(){
      let current_month = moment().startOf('month')
      if(this.data.finished_at && current_month.isAfter(this.data.finished_at)){
        this.Error("调整失败,检测到此项目已结束")
        return
      }
  
      this.users_editing = this.users.filter(v=>!v.leaved).map(v=>{
        return {
          id:v.id,
          user_id:v.user_id,
          factor:v.factor,
          position:v.position,
          started_at:v.started_at
        }
      })
      this.users_editing.sort((a,b)=>{
        let index = this.positions.findIndex(v=>v == a.position)
        let index2 = this.positions.findIndex(v=>v == b.positions)
        return index>index2?-1:1
      })
      this.showFactorEditor = true
    },
    showUserModal(u){
      this.user = u
      this.userData = cloneDeep(u)
      
      

      this.getUserProjects(u.user_id)
      this.showUser = true
     
    },
    handleDeleteUser(user_id){
      this.Confirm("将删除此用户在本项目的所有人力信息，是否继续？",()=>{
        this.$api.delete(`hrs/${user_id}?project=${this.id}&q=user`).then(res=>{
           this.$Notice.success({title:"删除成功"})
           this.data.hr = this.data.hr.filter(v=>v.user_id != user_id)
           this.showUser = false
        }).catch(e=>{
          this.$Notice.error({title:"删除失败"})
        })
      })
    },
    handleAddUser(){
      // if user is exist
      if(this.users.find(v=>v.user_id == this.formData.user_id)){
        this.Error("此用户已存在")
        return
      }

      this.$refs.form_user.validate(valid=>{
        if(valid){
          this.formData.project_id = this.id
          this.$api.post("/hrs?q=hr",this.formData).then(res=>{
            this.data.hr.push(res.data.data)
            delete this.formData.user_id
            this.$Notice.success({title:"添加成功"})
            this.showAdd = false
          }).catch(e=>{
          this.$Notice.error({title:"操作失败"})
        })
        }
      })
      
    }
  }
}
</script>
<style lang="less">
.hr-group:last-child{
  border:none !important;
}
</style>
