<template>
  <Container class="flex-col align-start">
    <h2 style="margin:10px 20px">合同审定
      <div style="font-size:12px;color:var(--subtext3);font-weight:bold;">
          本模块为【合同审定】流程模块
      </div>
    </h2>
    <div class="flex-wrap" 
      style="padding:10px 20px;height:100px;gap:10px;width:100%;">
      <div class="flex-wrap" style="padding:5px;gap:5px;background:var(--border);border-radius:5px;">
      <template v-for="(s,i) in states">
        <div 
          class="tab-card m-light"
          :class="{
            'tab-card-active':i == filter_mode
          }"
          @click="filter_mode = i"
          :key="s.name">
          <div class="name">{{s.name}}</div>
          <div class="count" :style="`color:${s.color}`">
              {{s.count}}
            
          </div>
          
        </div>
        
      </template>
      </div>
     
    </div>
    <div class="flex-wrap flex-between" style="margin:10px 20px;width:calc(100% - 40px);">
    <div class="flex-wrap">
      <Input search placeholder="输入名称搜索" /> <Button icon="md-refresh" style="margin-left:10px" @click="getData()"></Button>
    </div><div><Button icon="md-add" @click="handleCreate">发起流程</Button></div>
    </div>
    <div style="padding:0 20px;height:calc(100% - 40px);width:100%;">
      <BaseTable ref="table" :columns="cols" :data="items" :loading="loading" :count="312" />

    </div>
  </Container>
</template>
<script>
  import Container from '@/components/app/container'
  import Toolbar from '@/components/app/toolbar'
  import moment from 'moment'
export default {
  components:{Container,Toolbar},
  routerOption: {
    as_default:true
  },
  data(){
    return {
      filter_mode:1,
      filter_type:null,
      page:1,
      pagesize:20,
      loading:false,
      states:[{
        name:'所有流程',
        new_count:1,
        color:'var(--orange)',
        count:312
      },{
        name:'审批中',
        new_count:1,
        color:'var(--primary)',
        count:1
      },{
        name:"已通过",
        count:5,
        color:'var(--success)',
        new_count:2
      },{
        name:"与我相关",
        count:15
      }],
      items:[]
    }
  },
  mounted(){
    this.getData()
  },
  computed: {
    filteredItems(){
      if(this.filter_type !== null)
        return this.items.filter(v=>v.type == this.filter_type)
      else
        return this.items
    },
    cols(){
      return [{
        title:"序号",
        width:60,
        type:"index"
      },{
        title:"发起日期",
        width:120,
        render:(h,{row})=>{
          return h('div',{style:"color:var(--success)"},row.signed_at?moment(row.signed_at).format("L"):'-')
        }
      },{
          title:"合同类型",
          width:120,
          key:"type",
          render:(h,{row})=>{
            const colormap = {
              '全过程咨询':'var(--text3)',
              '市政监理':'var(--warning)',
              '房建监理':'var(--success)'
            }
            return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[row.business_type] || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row.business_type)
          }
        },{
        title:"合同名称",
        minWidth:140,
        key:"name",
        render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:'contract'},style:"margin-right:3px;color:var(--primary)"})
            let name = h('a',row.name)
            return h('div',{class:"flex-wrap",style:"text-align:left;white-space:nowrap;text-overflow:ellipsis;margin:0 10px;overflow:hidden;width:calc(100% - 20px);"},[icon,name])
          }
      },{
        title:"对方单位",
        width:240,
        key:"name",
        render:(h,{row})=>{
          let name = h('a',row.partA)
          return h('div',{class:"flex-wrap",style:"text-align:left"},[name])
        }
      },{
        title:"合同金额",
        width:140,
        key:"name",
        render:(h,{row})=>{
          return h('BaseAmount',{props:{value:row.amount}})
        }
      },{
        title:"合同文件",
        width:120,
        key:"name",
        sortable:false,
        render:(h,{row})=>{
          let icon = h("BaseIcon",{props:{icon:'md-download'},style:'margin-right:3px'})
          return h('a',[icon,'下载'])
        }
      },{
        title:"申请人",
        width:120,
        type:'user',
        key:"created_by"
      },{
        title:"专业部门审核",
        width:120,
        type:'users',
        key:"tech_by"
      },{
        title:"事业部审核",
        width:120,
        type:'user',
        key:"checked_by"
      },{
        title:"审核意见",
        width:120,
        type:'user',
        key:"result",
        render:(h,{row})=>{
          let color = row.passed?'var(--success)':'var(--primary)'
          let text = row.passed?'审核通过':'审核中'
          return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);border-color:${color || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},text)
        }
      },{
        title:"流程",
        width:120,
        render:(h,{row})=>{
          let icon = h("BaseIcon",{props:{icon:"lianjieliu"},style:"margin-right:3px"})
          let buttons = [h('Button',{props:{size:"small"}},[icon,"审批记录"])]
          return h('div',{class:"flex-wrap flex-center"},buttons)
        }
      }]
    }
      
  },
  methods:{
    handleCreate(){
      let url = `/core/flows/contract-approval/create`
          this.$store.commit("session/push_flow",{
        id:2,
        name:'合同审定',
         group:"日常工作",
        icon:"checkreport2",
        type:"flow",
        flow:'contract-approval'
        })
          this.RouteTo(url)
    },
      getData(){
      if(this.timer){
        clearTimeout(this.timer)
        this.loading = true
        
      }
      this.timer = setTimeout(()=>{
        const base_url = `contracts-m/contract_approval_flows`
        let url = base_url
      
        this.$api.get(url).then(res=>{
          this.items = res.data.data
        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          setTimeout(()=>{
            
          this.loading= false
          },300)
        })
      },100)
     
     
    },
  }
}
</script>

<style lang="less">
  .tab-card{
    padding:5px 10px;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background:var(--bg3);
    border-right:none;
    cursor: pointer;
    border-radius: 5px;
    .count{
      font-size:30px;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }
    
  }


  .tab-card:hover{
    background:var(--primary);
    color:var(--hover-text);
    .count,.name{
      color:var(--hover-text) !important;
    }
  }

  .tab-card-active{
    background:var(--primary);
    color:var(--hover-text);
    .count,.name{
      color:var(--hover-text) !important;
    }
  }
</style>