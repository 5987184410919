<template>
	<div class="flex-wrap flex-line" style="font-size: 14px; color: var(--text3)">
		<ProjectImage
			:data="value"
			style="
				width: 300px;
				height: 200px;
				position: relative;
				flex-shrink: 0;
				flex-grow: 0;
			"
		></ProjectImage>

		<div style="width: 50%; margin-left: 20px; flex-shrink: 0">
			<CellGroup>
				<Cell
					title="项目状态"
					style="
						background: var(--bg3);
						color: var(--text3);
						border-bottom: 1px solid var(--border);
					"
				>
					<div slot="extra">{{ value.status }}</div>
				</Cell>
				<Cell
					title="业务类型"
					style="background: var(--bg3); border-bottom: 1px solid var(--border)"
				>
					<div slot="extra" style="font-size: 14px">
						{{ value.type || "-" }}
					</div>
				</Cell>
				<Cell
					title="合同金额"
					label="调整后"
					style="background: var(--bg3); border-bottom: 1px solid var(--border)"
				>
					<BaseAmount
						style="font-size: 14px"
						slot="extra"
						:value="value.amount"
					></BaseAmount>
				</Cell>

				<Cell
					title="签订日期"
					style="background: var(--bg3); border-bottom: 1px solid var(--border)"
				>
					<div slot="extra" style="font-size: 14px">
						{{ value.signed_at ? moment(value.signed_at).format("LL") : "-" }}
					</div>
				</Cell>
				<Cell
					title="负责人"
					style="background: var(--bg3); border-bottom: 1px solid var(--border)"
				>
					<div slot="extra" style="font-size: 14px">
						<BaseNameBoard :uid="value.charger_id"></BaseNameBoard>
					</div>
				</Cell>
				<Cell
					title="合同原件"
					style="background: var(--bg3); border-bottom: 1px solid var(--border)"
				>
					<div slot="extra" style="font-size: 14px">
						<a v-if="value.file" :href="value.file">点击下载</a
						><template v-else>-</template>
					</div>
				</Cell>
			</CellGroup>
		</div>
	</div>
</template>

<script>
import ProjectImage from "./project/ProjectImage";
export default {
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
	},
	components: { ProjectImage },
};
</script>

<style lang="scss" scoped>
</style>