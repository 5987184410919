<template>
  <div class="ugo-page">
    objects:{{id}}
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    routerOption:{
      as_object:true,
      as_frame:true
    },
    computed:{
      id(){
        return this.$route.params.id
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>