<style lang="less">
.pd-embed-control{
  .ivu-select{
    background:none;
    width:100%;
    border:none;
    height:100%;
    margin:0;
    background:rgb(239, 239, 201);
    border-radius: 0;
    .ivu-select-selection{
       background:none;
    width:100%;
    border:none;
    height:100%;
    margin:0;
    
    border-radius: 0;
    }

   
  }

   .ivu-select-disabled{
      background:#ddd !important;
      color:#333;
      .ivu-select-selection{
      background:#ddd !important;
      color:var(--text1);
      }
    }
}
</style>

<template>
  <div class="pd-embed-control">
    <Select v-model="value" @input="$emit('input',$event)" transfer :disabled="!editable">
      <template v-for="o in options">
        <Option :key="o.key" :value="o.value">{{o.label}}</Option>
      </template>
    </Select>
  </div>
</template>

<script>
  export default {
    props:['value','editable','option'],
    mounted(){
      if(this.option.defaultTo  && this.value == undefined){
        this.value = this.option.defaultTo 
        this.$emit('input',this.option.defaultTo )
      }
    },
    computed:{
      options(){
        let option = this.option || {}
        if(option.getters)
        {
          let data = this.$store.getters[this.option.getters]
          if(Array.isArray(data)){
            return data.map(v=>{
              return {
                label:v[this.option.labelKey || 'name'],
                value:v[this.option.valueKey || 'id']
              }
            })
          }
        }else if(option.options){
          if(option.options.length >0 && typeof option.options[0] == 'object')
            return option.options
          else
            return option.options.map(v=>({
              label:v,
              value:v
            }))
        }
        return [{
          label:'test',
          value:1
        },{
          label:'test2',
          value:2
        }]
      },
      selected(){
        return this.options.find(v=>v.value == this.value) || {}
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>