<template>
 <FormItem :label="label" :required="required">
  <template v-if="editable">
      <DatePicker format="yyyy/MM/dd" v-model="formattedValue" transfer :placeholder="placeholder" />
  </template>
  <template v-else>
    <div style="color:#aaa;min-width:140px;text-align:center;"> 
      {{this.value?moment(this.value).format("YYYY/MM/DD"):"未填写"}}
    </div>
    
  </template>
  
  </FormItem>
</template>

<script>
 import moment from 'moment'
  export default {
    name:'日期',
    icon:'xuanzeriqi',
    props:['value','label','config','editable','required','placeholder'],
    computed:{
      formattedValue:{
        get(){
          return this.value?new Date(moment(this.value).valueOf()):''
        },
        set(v){
          this.$emit('input',v?moment(new Date(v)).format("YYYY-MM-DD HH:mm:ss"):'')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>