<template>
  <Form ref="form" label-position="top" class="ugo-form" :model="model" :rules="rules" inline>
        <BaseEmpty v-if="!form.id && fields.length == 0" msg="表单配置错误, 请联系管理员" />
        <template v-if="form.html">
          <BaseForm :form="{
              layout:form.html,
              def:formDef,
              option:{
                hideTools:true
              }
            }" 
            :editable="editable"  
            v-model="formData" 
            @submit="formData=$event" 
          ></BaseForm>
        </template>
        <template v-else>
          <template v-for="p in fields">
            <div class="form-item"  :key="p.id">
              <template v-if="isContainer(p.control)">
                <component 
                  :is="p.control"
                   v-bind="p.option || {}"
                />
              </template>
              <template v-else>
                <FormItem :label="p.name" :required="p.required" :prop="p.key">
                  <component 
                    :is="p.control" 
                    :editable="editable" 
                    :label="p.name"
                    v-bind="p.option || {}"
                    :value="model[p.key]==undefined?old[p.key]:undefined"
                    @input="model[p.key] = $event"
                  />
                  </FormItem>
              </template>  
            </div>
          </template>
        </template>
      </Form>
</template>

<script>
  import Field from './Fields/field' 
  export default {
    components:{
      Field
    },
     data(){
    return {
      old:{},
      model:{}
    }
  },
  props:{
    editable:{
      type:Boolean,
      default:true
    },
    form:{
      type:Object,
      default:()=>({})
    },
    value:{
      type:Object,
      default:()=>({})
    }
  },
  watch:{
    value:{
      handler(v){
        this.$set(this,'old',v)
        this.$forceUpdate()
      }, 
      deeply:true,
      immediate:true
    }
  },
  mounted(){
    this.model = {}
  },
  computed:{
    data(){
        return {...this.old,...this.model}
    },
    fields(){
      return this.form?(this.form.fields || []):[]
    },
    rules(){
      let rules = {}
      this.fields.forEach(f=>{
        if(f.required)
          rules[f.key] = [{required:true,message:'请填写'+f.name,trigger:"blur"}]
      })
      return rules
    },  
    formDef(){
        let o = {}
        // if(this.form.fields){
          
        // this.form.fields.forEach(v=>{
        //   if(v.key || v.name)
        //     o[v.key || v.name] = {
            
        //       control:'input',
        //       option:{}
        //     }
        // })
        // }
        return o
      }
  },
  methods:{
    isContainer(control){
      const containers = ['UgoRow']
      return containers.includes(control)
    },
    submit(on_success){
      return this.$refs.form.validate(valid=>{
        if(valid){
          on_success(this.model)
        }
      })
    }
  }
}

</script>

<style>

</style>