<template>
  <div class="container" style="padding:10px;background:#33333311;">
    <h2 style="margin-bottom:10px">履约保证金管理</h2>
    <div class="flex-wrap">
      
      <Input search style="width:220px" v-model="filter.searchText" placeholder="请输入合同名称查询" clearable @on-enter="getFullData" />
      <Select placeholder="业务类型" v-model="filter.business_type" style="margin-left:10px;width:140px"  clearable @on-change="getFullData" >
        <template v-for="(r,i) in business_types">
          <Option :value="r" :key="i">{{r}}</Option>
        </template>
      </Select>
      <div class="flex-wrap" style="margin-left:15px">
      
        隐藏小微项目  <i-switch v-model="filter.stype" style="margin-left:10px" />
      </div>
      <!-- <Select placeholder="合同金额" v-model="filter.amount_region"  style="margin-left:10px;width:140px"  clearable >
        <template v-for="(r,i) in amount_regions">
          <Option :value="i" :key="i">{{r.name}}</Option>
        </template>
      </Select> -->
      <Button type="primary"  @click="getFullData()" style="margin-left:10px" icon="md-search">搜索</Button>
    </div>
    <div class="content">
      <BaseTable 
        :columns="columns" 
        :loading="loading" 
        :data="items"
        :count="total" 
        paged
        :page-size="pagesize"
        :page="page"
        @event="handleTableEvent" 
      />
    </div>

    <Drawer 
      v-model="showEditor" 
      draggable 
      :class-name="className" 
      ref="modal" 
      :title="model.id?'编辑项目':'新建项目'" :width="800" footer-hide>
      <FormCreateContract 
        ref='form_create'
        v-model="model"
        :submitting="submitting" 
        @on-submit="handleSubmit" 
        @on-error="handleModelError" 
        @on-cancel="showEditor=false" />
     
    </Drawer>

    <Modal v-model="showShortNameEditor" :title="`修改简称 - ${model?model.name:''}`" footer-hide>
      <div class="flex-wrap">
      <Input v-model="formData.shortname" clearable />
      <Button type="primary" @click="handleSaveShortName" style="margin-left:10px">提交</Button>
      </div>
    </Modal>
  </div>
</template>

<script>

import moment from 'moment'
import UTIL from '@/utils'
import FormCreateContract from '@/components/forms/CreateContract'
import {cloneDeep,pick} from 'lodash'
import CMR from '../../cm/render'
import CMConstants from '@/components/cm/constants'
import {mapGetters} from 'vuex'

  export default {
    components:{FormCreateContract},
    data(){
      return {
        items:[],
        loading:false,
        deleting:false,
        submitting:false,
        showEditor:false,
        showShortNameEditor:false,
        formData:{},
        sortkey:{},
        pagesize:50,
        page:1,
        total:0,
        className:"",
        filter:{
          searchText:"",
          stype:false
        },
        order:{
          key:'created_at',
          order:'desc'
        },
        model:{}
      }
    },
    mounted(){
      this.loading = true
      this.$store.dispatch('cm/auth').then(()=>{
        setTimeout(()=>{
        this.getCount()
        this.getData()
      },500)
      })
     
    },
    methods:{
      getFullData(){
        this.getCount()
        this.getData()
      },
      getCount(){
        if(this.timerCount){
          clearTimeout(this.timerCount)
          
        }
        this.timerCount = setTimeout(()=>{
          const base_url = `cm/projects?q=count`
          let condition = this.getCondition()
          let url = base_url + condition
          this.$api.get(url).then(res=>{
            let {total} = res.data.data
            this.total = total
          }).catch(e=>{
            this.Error(e)
          })
        },100)
      },
      getCondition(){
        let condition = `&r=${this.my_role.id}`
        if(this.filter.searchText && this.filter.searchText.trim())
          condition += `&search=${this.filter.searchText}`
        if(this.filter.business_type)
          condition += `&business_type=${this.filter.business_type}`
        if(this.filter.stype)
          condition += `&stype=1`
        condition += `&status=已结束`
        return condition
      },
      getData(){
        if(this.timer){
          clearTimeout(this.timer)
          this.loading = true
          
        }
        this.timer = setTimeout(()=>{
          const url = `contracts-m/settlement_contracts`
         
          this.$api.get(url).then(res=>{
            this.items = res.data.data
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              
            this.loading= false
            },300)
          })
        },100)
       
       
      },
      handleTableEvent(e){
        if(e.type == 'page-change'){
          this.page = e.data
          this.getData()
        }else if(e.type == 'edit-name'){
          this.model = e.data
          this.formData = {shortname:e.data.shortname || ''}
          this.showShortNameEditor = true
        }else if(e.type == 'page-size-change'){
          this.pagesize = e.data
          this.getData()
        }else if(e.type == 'sort'){
          if(e.data && e.data.key){
            
            this.order = e.data
            this.page = 1
            this.getData()
          }else{
            this.order = {}
            this.getData()
          }
        }
      },
      handleSaveShortName(){
        if(this.model.shortname != this.formData.shortname){
          this.$api.patch(`/cm/projects/${this.model.id}`,{shortname:this.formData.shortname}).then(res=>{
            this.Success({title:"修改成功"})
            this.getData()
            this.showShortNameEditor = false
          })
        }else{
          this.showShortNameEditor = false
        }
      },
      handleOpen({id:e}){
        let project = this.items.find(v=>v.id == e)
        this.$store.commit("session/push_project_cm",{
          id:e,
          name:project.name
        })
        this.RouteTo('/core/projects/'+e+'/cm')
      },
      handleCreate(){
        if(this.model.id)
          this.model = {}
        this.showEditor = true
      },
      handleEdit(contract_id){
        this.loading = true
        this.$api.get(`/cm/contracts/${contract_id}`).then(res=>{
          this.model = res.data.data
          console.log(this.model)
          this.showEditor = true
        }).finally(()=>{
          this.loading = false
        })
      },
      handleDelete(id){
        this.Confirm("将删除此项目相关的所有信息，是否继续?",()=>{
          
          this.deleting = id
          this.$api.patch(`cm/projects/${id}`,{sys_flag:2}).then(()=>{
            let index =this.items.findIndex(v=>v.id == id)
            this.items.splice(index,1)
            this.$Notice.success({title:"操作成功",content:"项目已删除"})
          }).finally(()=>{
            this.deleting = false
          })
        })
      },
      handleSubmit(model){
        if(this.model.id){
            let shrinked_model = UTIL.compare_patch_object(this.model,model)
            console.log("shrinked:",shrinked_model)
            this.submitting = true
            this.$api.patch("cm/projects/"+this.model.id,shrinked_model).then(res=>{
            this.getData()
            this.formData = {}
            this.showEditor = false
            this.$Notice.success({title:"操作成功",content:"项目已修改"})
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.submitting = false
          })
        }else{
           this.submitting = true
          this.$api.post("cm/projects",model).then(res=>{
            this.getData()
            this.showEditor = false
            this.formData = {}
            this.$Notice.success({title:"操作成功",content:"项目已创建"})
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            this.submitting = false
          })
        }
      },
      handleModelError(){
        this.className = 'animate__animated animate__headShake'
        setTimeout(()=>{
          this.className = ''
        },500)
      }
    },
    computed:{
      ...UTIL.mapConstants(CMConstants,['business_types','business_type_colors','contract_types','contract_type_colors','status']),
      ...mapGetters('cm',['my_role']),
      amount_regions(){
        return [{
          name:"50万元以下",
          check:v=>v < 500000
        },{
          name:"50万以上",
          check:v=>v >= 500000
        },{
          name:"100万以上",
          check:v=>v >= 1000000
        },{
          name:"200万以上",
          check:v=>v >= 2000000
        },{
          name:"500万以上",
          check:v=>v >= 5000000
        }]
      },
      filteredData(){
        return this.items.filter(v=>{
          let searchText = this.filter.searchText.trim()
          if(searchText && !v.name.includes(searchText)){
            return false
          }

          if(this.filter.business_type && v.business_type != this.filter.business_type)
            return false

          if(this.filter.amount_region != undefined && !this.amount_regions[this.filter.amount_region].check(v.full_amount))
            return false
          
          if(this.filter.status && v.status != this.filter.status)
            return false


          return true
        })
      },
      columns(){
        return [ {
        type: 'text',
        key: "code",
        title: "编号",
        width: 70,
        fixed:"left",
        group: '项目',
        option: {

          align: "center",
        },
        render:CMR.renderCode
      }, {
        type: 'text',
        title: "业务类型",
        
        key: "business_type",
        group: '项目',
        width: 120,
        option: {

          align: "center",
        },
        render: (h, { row }) => {
          return h('div', { class: "flex-wrap flex-center", style: { height: "40px" } }, [h('div', {            style: {              width: "80px",
              borderRadius: "5px",
              textAlign: "center",
              background: "var(--primary)",
              color: "#fff"
            }          }, row.business_type)])
        }
      },{
        type:'text',
        title: "名称",
        tree:true,
        
        fixed:"left",
        key: "name",
        minWidth: 240,
        render:CMR.renderName(this.handleOpen,this.handleTableEvent)
      },{
        type: 'number',
        title: "合同金额",
        key: "amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => '¥ ' + UTIL.formatSalary(e),
          type: "fullAmount"
        },
      },{
        type: 'time',
        title: "签约时间",
        key: "signed_at",
        width: 120,
        option:{
          type:"date"
        }
      },{
        type: 'time',
        title: "服务开始",
        key: "started_at",
        width: 120,
        option:{
          type:"date"
        }
      },{
        type: 'time',
        title: "服务结束",
        key: "finished_at",
        width: 120,
        option:{
          type:"date"
        }
      },
				{
					key: "charger_id",
					title: "结算负责人",
					group: "管理",
					width: 120,
					type: "user",
					option: {
						getters: "session/users",
					},
				},{
        type: 'number',
        title: "履约保证金",
        key: "amount",
        width: 120,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => '¥ ' + UTIL.formatSalary(e*0.05),
          type: "fullAmount"
        },
      },{
					key: "charger_id",
					title: "是否退还",
					group: "管理",
					width: 120,
					option: {
						getters: "session/users",
					},render:(h,{row})=>{
         
          return h('div',{class:"flex-wrap flex-center"},"已退还")
        }
				},{
        type: 'number',
        title: "操作",
        key: "tool",
        minWidth:100,
        render:(h,{row})=>{
          let buttons = [
            h('Button',{props:{icon:"md-create",size:"small"},on:{click:()=>{
              this.handleEdit(row.id)
            }}}),
            h('Button',{props:{icon:"md-trash",size:"small",loading:this.deleting == row.id},on:{click:()=>{
              this.handleDelete(row.id)
            }}})
          ]
          return h('div',{class:"flex-wrap split5 flex-center"},buttons)
        }
      },	]
      }
    }
  }
</script>

<style lang="less" scoped>
.content{
  height:calc(100% - 80px);position:relative;margin-top:10px;
}
</style>