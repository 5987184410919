import { render, staticRenderFns } from "./dissolution.vue?vue&type=template&id=f58d2978&scoped=true"
import script from "./dissolution.vue?vue&type=script&lang=js"
export * from "./dissolution.vue?vue&type=script&lang=js"
import style0 from "./dissolution.vue?vue&type=style&index=0&id=f58d2978&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f58d2978",
  null
  
)

export default component.exports