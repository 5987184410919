<template>
  <div style="background:linear-gradient(to bottom right,#eeeeeeaa,#ddddddaa);height:100%;">
    <BaseEmpty>
    <div class="flex-col flex-center" @click="showAddEnterprise=true" style="cursor:pointer;color:#3af">
      <BaseIcon icon="home" size="40" style="margin-bottom:10px;" />
      <div> 创建/加入团队</div>
    </div>
    </BaseEmpty>
     <Dropdown trigger="click" transfer placement="bottom-end">
    <div class="flex-wrap flex-line" style="padding:10px;border-bottom:1px solid #ddd;">
      <Avatar shape="square" style="width:30px;height:30px;flex-shrink:0;background:#ddd;">
         <BaseIcon icon="nbgz" size="18" color="#369" />
      </Avatar>
     
      <div class="flex-col align-start" style="margin-left:10px;cursor:pointer;">
        <div style="font-size:1rem">宁波高专建设监理有限公司 <BaseIcon icon="ios-arrow-down" :size="14" /></div>
      </div>
    </div>
     <DropdownMenu slot="list">
      <div style="padding:0px 15px;margin-top:5px;">
      切换至
      </div>
        <DropdownItem>
          <div class="flex-wrap flex-line">
      <Avatar shape="square" style="width:30px;height:30px;flex-shrink:0;background:#ddd;position:relative;">
         <BaseIcon icon="md-person" size="18" color="#369" />
      </Avatar>
      <div class="flex-col align-start" style="margin-left:10px;cursor:pointer;">
        <div style="font-size:1rem">个人空间 <BaseIcon icon="ios-arrow-forward" :size="14" /></div>
      </div>
          </div>
      </DropdownItem>
     </DropdownMenu>
   </Dropdown>
<div style="padding:0 10px;">
<div class="flex-wrap flex-between pm-cell" style="margin-top:5px;padding-top:10px;border-top:1px solid #eee;">
        <div class="l-label">员工档案</div>
        <div class="l-value"><a>查看</a></div>
      </div>
      <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">部门</div>
        <div class="l-value"><a>项目管理</a></div>
      </div>
      <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">岗位</div>
        <div class="l-value"><a>软件开发</a></div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">入职时间</div>
        <div class="l-value"><a>2022-03-08</a></div>
      </div>
       <div class="flex-wrap flex-between pm-cell">
        <div class="l-label">劳动合同到期</div>
        <div class="l-value"><a>2024-03-08</a></div>
      </div>


      </div>
       <Modal v-model="showAddEnterprise" transfer title="创建/加入团队" footer-hide>
  
    </Modal> 
    </div>

   
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    computed:{
      ...mapGetters('session',['session'])
    },
    data(){
      return {
        showAddEnterprise:false
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>

