<template>
  <BaseResult state="404" desc="您访问的页面不存在">
    <div style="margin-top:5px;font-size:12px">
      您可以 <a href="#" @click="$router.go(-1)">后退</a> 或 <a href="#" @click="RouteTo('/core/dashboard')">返回首页</a>
    </div>
  </BaseResult>
</template>
<script>
import BaseResult from '@components/base/Result'
export default {
  components: { BaseResult }
}
</script>
