import {
  API
} from '@/vue-plugins/api'

const o = {
  namespaced: true,
}

o.state = {
  group: {},
  group_map: {}
}

o.getters = {
  group(state) {
    return state.group
  },
  groups(state) {
    return Object.values(state.group_map)
  }
}


o.actions = {
  load({commit,state},id){
    return new Promise((resolve)=>{
      if (state.group_map[id] && state.group_map[id].loaded && state.group_map[id].public == false) {
        commit('save', state.group_map[id])
        setTimeout(resolve,1000)
      }else{
        API.get('groups/'+id).then(res=>{
          commit('save',res.data.data)
          resolve()
        })
      }
    })
  },
  load_public({
      commit,
      state
    }, id) {
    return new Promise((resolve) => {
      if (state.group_map[id] && state.group_map[id].loaded) {
        commit('save', state.group_map[id])
        setTimeout(resolve, 1000)
      } else {
        API.get('/public/projects/' + id).then(res => {
          res.data.data.public = true
          commit('save', res.data.data)
          resolve()
        })
      }
    })
  }
}

o.mutations = {
  save(state,group){
    group.loaded = true

    state.group_map[group.id] = group
    state.group = group
  }
}

export default o
