<template>
   <div>
    <div style="width:1000px;margin:0px auto;">
    <div style="padding:20px" v-if="type && type.id">
      
     <h1><a @click="RouteTo(`/core/materials`)">返回首页</a> / {{type.name}}</h1>
     <div style="margin:10px 0;">
      <template v-for="c in (type.parents || [])">
        {{c}} /
      </template>
      {{type.desc || ''}}
      <div style="margin:10px 0;" v-if="type.files && type.files.length > 0">
        <div style="font-weight:bold;">规格参数样例</div>
        <div v-for="f in type.files">
          <div style="margin:5px;"><a @click="DownloadWithName(f.url,f.name)"><BaseIcon icon="md-document" /> {{ f.name }}</a></div>
        </div>
      </div>
     </div>
     <div style="margin:20px 0">
      <div class="flex-wrap flex-between">
        <div class="flex-wrap">
        <Input search style="width:200px" placeholder="搜索产品名称/型号/生产商" v-model="searchText" clearable />
        </div>
        <Button type="primary" icon="md-search" @click="search">搜索</Button>
      </div>
     </div>
     <div>
      <Row style="margin:5px 0;padding-bottom:5px;border-bottom:1px solid var(--border)">
        <Col :span="3" align="center">
        图示</Col>
        <Col :span="5">
        名称/型号</Col>
        <Col :span="4"  align="left">
        生产商</Col>
        <Col :span="2"  align="left">
        产地</Col>
        <Col :span="3" align="right">
        参考价</Col>
        <Col :span="3"  align="center">
        询价时间</Col>
        <Col :span="3"  align="center">
        询价方式</Col>
        <Col :span="1"  align="center">
        收藏</Col>
      </Row>
      <div style="position:relative">
        <Spin fix v-if="loading" />
        <BaseEmpty v-if="items.length == 0" />
      <template v-for="item in items">
         <Row :key="item.id" style="margin:5px 0;padding:5px 0;border-bottom:1px dashed var(--border)" align="middle">
          <Col :span="3" align="center">
        <img style="width:60px;height:60px;border-radius:5px;border:1px solid var(--border)" :src="item.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'" /></Col>
        <Col :span="5">
        <div>
        <div style="font-weight:bold"><a @click="RouteTo(`/core/materials/${item.id}/info`)">{{item.name}}</a></div>
        <div style="color:var(--subtext3)">{{item.model}}</div>
        </div>
        </Col>
        <Col :span="4"  align="left" style="text-align:left">
        <template v-if="item.producer">
        <a class="flex-wrap flex-between" @click="RouteTo(`/core/materials/${item.producer.id}/company`)">
          <div>{{item.producer.name}}
            <div style="color:var(--subtext3)">{{item.producer.address}}</div></div>  
        </a>
        </template>
        <span v-else>-</span>
        </Col>
        <Col :span="2"  align="center">
        {{item.product_area}}</Col>
        <Col :span="3"  align="">
        <BaseAmount full :value="item.last_price?item.last_price.amount:0" />
        </Col>
        <Col :span="3"  align="center">
        {{item.last_price && item.last_price.accquired_at?moment(item.last_price.accquired_at).fromNow():'-'}}</Col>
        <Col :span="3" align="center">
        {{item.last_price && item.last_price.type?item.last_price.type:'-'}}</Col>
          <Col :span="1"  align="center">
        <Button icon="md-add" @click="addToCart(item)"></Button></Col>
      </Row>
      </template>
     </div>
     </div>
     <div class="flex-wrap flex-right" style="margin-top:10px">
       <Page :total="total" :page-size="pagesize" :current="page" @on-change="handleChangePage" />
     </div>
     
    </div>
    </div>
  </div>
</template>

<script>

  export default {
    data(){
      return {
        showCart:false,
        page:1,
        pagesize:10,
        total:0,
        searchText:"",
        ACC_TYPES:["电话","聊天","询价单","其他"],
        LEVELS:["LV1","Lv2","LV3"],
        type:{
          
        },
        loading:false,
        items:[]
      }
    },
    routerOption:{
      as_object:true
    },
    computed:{
      cate(){
        return this.$route.params.id
      }
    },
    watch:{
      $route:{
        handler(v){
          console.log('route-changed')
          if(!this.timer){
            this.timer = setTimeout(() => {
              this.getBaseData()
              this.timer = null
            }, (500));
          }
        },
        immediate:true,
        deep:true
      }
    },
    mounted(){
      console.log('mounted')
     if(!this.timer){
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.getBaseData()
           this.timer = null
        }, (500));
      }
    },
    methods:{
      handleChangePage(e){
        this.page = e
        this.getData()
      },
      getBaseData(){
        console.log('getBase')
        this.$api.get(`mat/types/${this.cate}?q=full&related=files`).then(res=>{
          this.type = res.data.data
          console.log("type:",this.type)
          this.getCount()
          this.getData()
        })
      },
      search(){
        this.page = 1
        this.getData()
      },
      getData(){
        this.loading = true
        let url = `mat/products?related=files,producer,prices&type_id=${this.cate}&page=${this.page}&pagesize=${this.pagesize}`
        if(this.searchText)
          url += ('&search='+this.searchText)
        this.$api.get(url).then(res=>{
            let items = res.data.data
            items.forEach(v=>{
              if(Array.isArray(v.prices) && v.prices.length > 0){
                v.last_price = v.prices[v.prices.length - 1]
              }
            })
            this.items = res.data.data
          }).finally(()=>{
            this.loading = false
          })
      },
      getCount(){
        this.$api.get(`mat/products?q=count&type_id=${this.cate}`).then(res=>{
          this.total = res.data.data || 0
        })
      },
      addToCart(item){
        this.$store.commit("mat/add_cart",item)
        this.$Notice.success({title:"添加成功"})
      }
    }
  }
</script>

<style lang="less" scoped>
.lf-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width:180px;
  img{
    margin-bottom:10px;
  }
}

.l-cell{
  display: flex;
  align-items: flex-start;
  padding:5px 0;
  .l-label{
    font-weight: bold;
    margin-right:15px;
    min-width:80px;
    text-align: right;
  }
  .l-value-wrap{
    display: flex;

    .l-unit{
      color:var(--subtext3);
    }
  }
}
</style>