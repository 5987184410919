<template>
  <FormItem :label="label" :required="required">
    <Input type="textarea" v-model="_value" :disabled="!editable" style="width:500px" />
  </FormItem>
</template>

<script>
  export default {
    name:"长文本输入",
    icon:'changwenbenxiangsi',
        props:['value','label','required','config','editable'],
    computed:{
      _value:{
        get(){
          return this.value
        },
        set(v){
          this.value = v
          this.$emit('input',v)
        }
      }
    },
    properties:[
      {
        label:'字数限制',
        key:'maxlen',
        control:'Text',
      },
      {
        label:'宽度',
        key:'width',
        control:'Text'
      }

    ]
  }
</script>

<style lang="scss" scoped>

</style>