<template>
  <svg style="position:absolute;" :style="
    styles[placement]
  ">
    <template v-for="(_,i) in counts">
      <line :key="'la'+i"  
        :x1="horizontal?(i*100+1):(!left?15:0)" 
        :y1="horizontal?(!top?15:0):(i*100+1)" 
        :x2="horizontal?(i*100+1):(!left?30:15)" 
        :y2="horizontal?(!top?30:15):(i*100+1)" 
        style="stroke:#666;stroke-width:1" 
      />
      <g :transform="`rotate(${horizontal?0:90},${horizontal?(i*100+1+5):(left?10:15-3)},${horizontal?(top?15/2:25/2):(i*100+15)+8})`" :key="'tx'+i"  >
      <text 
       :x="horizontal?(i*100+1+5):(left?-5:-5)" 
        :y="horizontal?(top?25:0):(i*100+20)"
        style="font-size:10px;fill:#999;"
      >
        {{i * 100 - parseInt(length/200)*100 }}
      </text>
      </g>
      <g :key="'g'+i">
      <template v-for="(_,j) in sub_counts">
          <line 
          :key="'lb'+j" 
          :x1="horizontal?(i*100+10*(j+1)):(!left?25:0)" 
          :y1="horizontal?(!top?25:0):(i*100+10*(j+1))" 
          :x2="horizontal?(i*100+10*(j+1)):(!left?30:5)" 
          :y2="horizontal?(!top?30:5):(i*100+10*(j+1))" 
          style="stroke:#888;stroke-width:1" 
          />
      </template>
      </g>
    </template>
  </svg>
</template>

<script>
  export default {
    props:{
      
      placement:{
        type:String,
        default:"top"
      },
      scale:{
        type:Number,
        default:100
      },
      scale2:{
        type:Number,
        default:10
      }
    },
    computed:{
      styles(){
        return {
          top:"width:100%;height:30px;top:0px;left:0;right:0;",
          bottom:"width:100%;height:30px;bottom:0px;left:0;right:0",
          left:"width:30px;height:100%;left:0px;top:0;bottom:0;",
          right:"width:30px;height:100%;right:0px;top:0;bottom:0"
        }
      },
      length(){
        if(!this.$el)
          return 1024
        let box = this.$el.getBoundingRect()
       return this.horizontal?box.width:box.height
      },
      horizontal(){
        return this.placement == 'top' || this.placement == 'bottom'
      },
      top(){
        return this.placement == 'top'
      },
      left(){
        return this.placement == 'left'
      },
      counts(){
        if(this.scale <= 0 || this.length <= 0 || isNaN(this.length) || isNaN(this.scale))
          return []
        return new Array(Math.ceil(this.length / this.scale) || 1)
      },
      sub_counts(){
        if(this.scale2 <= 0 || this.scale <= 0|| isNaN(this.scale2) || isNaN(this.scale))
          return []
        
        return new Array((Math.ceil(this.scale / this.scale2) - 1) || 1)
      },

    }
  }
</script>

<style lang="scss" scoped>

</style>