<template>
  <Modal :value="value" footer-hide :title="`选择人员(${filteredUsers.length})`" :width="580" @on-visible-change="$emit('input',$event);value=$event">
   <div class="flex-wrap" style="width:100%;margin-bottom:10px;"><Input v-model="filterText" search clearable style="width:220px;" />
   </div>
    <div  style="width:calc(100%);height:300px;overflow-y:auto;">
     <template v-for="g in filteredGroups">
     <div :key="g.id" v-if="filterUsers(g.users).length > 0">
       <div class="flex-wrap flex-between" :style="`background:${g.color || 'var(--bg1)'};color:var(--hover-text);padding:2px 5px;`">{{g.name}} ({{filterUsers(g.users).length}})

        <div><a @click="selectGroupAll(g)">全选</a> <a @click="clearGroupAll(g)">清除</a></div>
       </div>
    <div class="flex-wrap" style="overflow-y:auto;flex-wrap:wrap;overflow-x:hidden;gap:1px;">
       <template v-for="o in filterUsers(g.users)">
         <div class="flex-wrap ugo-item-user" :class="{
           'ugo-item-user-selected':selected && selected.includes(o.id)
         }" :key="o.id" style="" @click="handleClick(o)">
           <Avatar :src="o.avatar" style="flex-shrink:0">{{o.name[0]}}</Avatar>
           <div style="margin-left:5px">
             <div>{{o.name}}</div>
             <div class="ugo-item-user-sub" style="">{{o.phone}}</div>
             </div>
         </div>
       
       </template>
       </div>
        </div>
       </template>
      
       </div>


  <div class="flex-wrap flex-between" v-if="multiple" style="width:100%;height:40px;padding:0 10px;background:var(--bg3);border-top:1px solid #ddd;" @click.stop>
   <div class="flex-wrap split5">
     <div>已选择 {{selected.length}}</div>
     <Button size="small" @click.stop="handleSelectAll">全选</Button>
     <Button size="small" @click.stop="handleClear">取消</Button>
     </div>
      <Button icon="md-checkmark" @click="handleConfirm" type="primary" size="small">确认</Button>
 </div>
  </Modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            prev:false,
      current:{},
      filterText:"",
      selected:[]
        }
    },
    props:{
        value:{
            type:Boolean,
            default:false
        },
        multiple:{
            type:Boolean,
            default:false
        },
        groups:{
          type:Array,
            default:()=>([])
        },
        extraGroups:{
          type:Array,
          default:()=>([])
        },
        included:{
          type:Array,
          default:()=>([])
        },  
        excluded:{
            type:Array,
            default:()=>([])
        }
    },
    mounted(){
        setTimeout(()=>{
      this.$store.dispatch("session/getGroupedEmployees")
    },500)
    },
    computed:{
    ...mapGetters("session",["grouped_employees","users"]),
    extraGroupObjects(){
      return this.extraGroups.map(v=>{
        return {
          name:v.name,
          color:v.color,
          id:v.id,
          users:this.users.filter(u=>v.users.includes(u.id))
        }
      })
    },
    filteredGroups(){
      let groups = this.groups
      let grouped_employees = this.grouped_employees
      if(this.extraGroupObjects.length > 0)
        grouped_employees = [...this.extraGroupObjects,...this.grouped_employees]
      if(groups && groups.length > 0){
        return grouped_employees.filter(v=>groups.includes(v.id) == true)
      }else{
        return grouped_employees
      }
    },
    filteredUsers(){
        let users = this.users.filter(v=>(!Array.isArray(this.included) || this.included.length == 0 || this.included.includes(v.id)) && this.excluded.includes(v.id) == false)
      if(this.filterText){
              return users.filter(v=>v.name && v.name.includes(this.filterText))
      }else{
        return users.filter(v=>v.name)
      }
    },
    user(){
      return this.users.find(v=>v.id == this.value) || {}
    },
    selected_users(){
      return this.selected.map(v=>this.users.find(u=>u.id == v)).filter(v=>v)
    }
  },
  methods:{
    selectGroupAll(g){
      let users = this.filterUsers(g.users)
      users.forEach(v=>{
        if(!this.selected.includes(v.id))
          this.selected.push(v.id)
      })
    },
    clearGroupAll(g){
      let users = this.filterUsers(g.users)
      this.selected = this.selected.filter(id=>users.find(u=>u.id == id) == null)
    },
    filterUsers(users){
      
      if(this.filterText){
              return users.filter(v=>v.name && v.name.includes(this.filterText)).filter(v=>(!Array.isArray(this.included) || this.included.length == 0 || this.included.includes(v.id)) && this.excluded.includes(v.id) == false)
      }else{
        return users.filter(v=>v.name).filter(v=>(!Array.isArray(this.included) || this.included.length == 0 || this.included.includes(v.id)) && this.excluded.includes(v.id) == false)
      }
    },
    handleClear(e){
      this.selected = []
    },

    handleSelectAll(){
      this.selected = this.filteredUsers.map(v=>v.id)
    },
    handleClick(o){
      if(this.multiple){
        let index = this.selected.findIndex(v=>v == o.id)
        if(index == -1)
          this.selected.push(o.id)
        else
          this.selected.splice(index,1)
      }else{
        this.selected = o.id
       this.handleConfirm()
      }
    },
    handleConfirm(){
      this.$emit('on-select',[...this.selected])
      this.value = false
      this.selected = []
      this.$emit('input',false)
    }
    }
}
</script>

<style>

</style>