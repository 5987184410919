<template>
  <div class="flex-wrap flex-line" style="height:100%;width:100%;">
    <div class="l-dep-item flex-col flex-center m-light" style="width:35%;border:1px solid var(--border)" v-if="dep && dep.id" @click="OpenDepDashboard(dep)">
      <div>{{ dep.name }}</div>
    </div>
    <Dropdown style="position:relative;" v-if="my_deps.length > 1" trigger="click" transfer >
      <div class="l-dep-item  flex-col flex-center">
      <BaseIcon icon="ios-arrow-down" size="20" />
    </div>
    <DropdownMenu slot="list" placement="bottom-right">
      <DropdownItem :name="d.id" v-for="d in my_deps" :key="d.id">
        {{ d.name }}
      </DropdownItem>
    </DropdownMenu>
    </Dropdown>
    
    <div class="container l-links align-start flex-wrap">
     <template v-for="l in links">
      <div :key="l.name" class="l-link-item flex-wrap flex-center m-light" @click="handleClick(l)" :title="l.name">
         <img style="width:22px;height:22px;" :src="l.avatar" v-if="l.avatar" />
         <BaseIcon v-else :icon="l.icon || 'link'"  :size="26" :color="l.color || 'var(--primary)'" />
         
        
      </div>
     </template>
  </div>
  </div>
 
</template>

<script>
export default {
  computed:{
    deps(){
      return this.$store.getters['session/deps']
    },
    my_deps(){
      return this.session.groups.filter(v=>v.type == 'dep' && v.top == 1)
    },
    dep(){
      if(this.my_deps && this.my_deps.length > 0){
        return this.my_deps[0]
      }else{
        return {id:1,name:'行政综合'}
      }
    },  
    session(){

      return this.$store.getters['session/session']
    },
    links(){
      return [{
        name:"宁波高专项目管理平台",
        icon:"nbgz",
        color:"#39f",
        url:`http://zzlatm.gicp.net:10000/acl/crossLogin.html?uid=${this.session.zzl_id}&phone=${this.session.phone}&platform=pmis&href=/v11/index.html`
      },{
        name:"宁波高专监理平台",
        icon:"nbgz",
        color:"orange",
        url:`http://zzlatm.gicp.net:10020/acl/crossLogin.html?uid=${this.session.zzl_id}&phone=${this.session.phone}&platform=jlis&href=/newWeb/index.html`
      }]
    }
  },
  methods:{
    handleClick(e){
      if(e.self){
        
        this.$store.commit('session/push',{icon:'md-download',size:20,base_url:e.url,name:e.name})
      
      }
      this.RouteTo(e.url,e.self!=true)
    },
    OpenDepDashboard(p){
      let url = '/core/groups/' + p.id 
      this.$store.commit("session/push_group",{id:p.id,name:p.name})
      this.RouteTo(url,  false)
    },
  }
}
</script>

<style lang="less">
.l-links{
  padding:0px 2px;
  flex-wrap:wrap;
  align-content: flex-start;
  align-items: stretch;

  .l-link-item{
    width:40px;
    padding:0 10px;
    border-radius: 10px;
    margin-right:10px;
    height:100%;
    min-height:40px;
    flex-shrink: 0;
    box-shadow: 0 0 3px 1px #33333311;
    
    
    border:1px solid var(--border);
    background:var(--bg2);
    margin-bottom:10px;
    span{
      white-space: nowrap;
    }
  }

  .l-link-item:hover{
    border-color:var(--primary);
    color:var(--primary);
    cursor: pointer;
  }

  .l-link-item:last-child{
    margin:0;
  }
}

.l-dep-item{
    border-radius: 10px;
    height:calc(100%);
   
    cursor:pointer;
    flex-shrink: 0;
    font-size:18px;
    box-shadow: 0 0 1px 1px #33333311;
    
    background:var(--bg2);
    margin-right:15px;
    span{
      white-space: nowrap;
    }

    .l-dep-item-sub{
      font-size:12px;color:var(--subtext3);
    }
  }

  .l-dep-item:active{
    position:relative;
    right:-1px;
    bottom:-1px;
  }

  .l-dep-item:hover{
    background:var(--primary);
    color:var(--hover-text);
    cursor: pointer;
    .l-dep-item-sub{
      color:var(--hover-text);
      opacity:0.8;
    }
  }
</style>