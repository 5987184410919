<template>
     <Form :model="formData" :rules="rules"  label-position="top">
        <BaseSegment title="企业信息" disabled>
        <Row :gutter="20">
          <Col :span="5">
          <FormItem label="LOGO">
           <div  style="width:100%;height:90px;border:1px solid var(--border);border-radius:5px;">
              <GFormImageClip v-model="formData.avatar" :editable="true" 
            :option="{
              uploader:'session/upload'
            }"/>
              </div>
              </FormItem>
          </Col>
          <Col :span="19">
          <Row>
            <Col :span="24">
              <FormItem required label="企业名称" prop="name">
                <Input v-model="formData.name" clearable />
              </FormItem>
            </Col>
          </Row>
          
     
          <Row :gutter="20">
            <Col :span="12">
              <FormItem label="企业类型" prop="ent_type">
                <Input  v-model="formData.ent_type" clearable />
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="注册资本(万元)" prop="registered_funds">
                <Input  v-model="formData.registered_funds" number placeholder="请输入数字" clearable />
              </FormItem>
            </Col>
          </Row>
          </Col>
        </Row>
        	
          <Row>
            <Col :span="24">
              <FormItem label="地址" prop="address">
                <Input v-model="formData.address" clearable />
              </FormItem>
            </Col>
          </Row>
             <Row>
            <Col :span="24">
              <FormItem label="简介" prop="description">
                <Input type="textarea" :rows="4" v-model="formData.description" clearable />
              </FormItem>
            </Col>
          </Row>
          </BaseSegment>
          <BaseSegment title="联系人">
           <Row>
              <BaseFlexTable v-model="formData.contacts" :columns="contactColumns" />
            </Row>
          </BaseSegment>
          <div class="flex-wrap flex-right">
          <Button type="primary" style="margin-right:10px" @click="handleSubmit">提交</Button>
						<Button @click="$emit('on-cancel')">取消</Button>
				</div>
      </Form>
</template>

<script>

  import GFormImageClip from '@/components/form/ImageClip'
  
  import FileSelector from '@/components/mat/FileSelector'
  export default {
  data(){
    return {
       formData:{},
    }
  },
   components:{GFormImageClip,FileSelector},
	methods: {
     handleSelectFile(files){
      if(files && files.length > 0)
      {
         if(!this.formData.files){
          this.$set(this.formData,'files',[files])
        }else{
          files.forEach(v=>{
            this.formData.files.push({...v})
          })
        }
      }
     
    },
    handleSubmit(){
        this.$store.dispatch("mat/addCompany",this.formData).then((data)=>{
          this.$emit('on-success',data.id)
        }).catch(this.Error)
      
    }
	},
  computed:{
     rules(){
        return {
          name:[{required:"true",message:"名称不可为空"}],
         
        }
     },
     contactColumns(){
        return [{
          name:"序",
          control:"index"
        },{
          name:"拖",
          control:"drag"
        },{
          key:"name",
          name:"姓名",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            number:true,
            clearable:true
          }
        },{
          key:"phone",
          name:"电话1",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        },{
          key:"phone2",
          name:"电话2",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        },{
          key:"phone3",
          name:"电话3",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        },{
          key:"EMAIL",
          name:"email",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        },{
          key:"note",
          name:"备注",
          control:"Input",
          span:3,
          option:{
            
            size:"small",
            clearable:true
          }
        }]
    },
  }
};
</script>