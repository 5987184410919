<style lang="less">
.l-notice{
  background:var(--border);
  border-radius:2px;
  overflow: hidden;
  color:var(--text2);
  height:calc(100% - 70px);
  position:relative;cursor:pointer;align-items:stretch;flex-wrap:nowrap !important;
}

.number{
  flex-shrink:0;width:70px;height:100%;font-size:2.3rem;font-weight:bold;font-family:impact;display:flex;align-items:center;justify-content:center;border-right:1px solid var(--border);padding-left:5px;
  color:var(--subtext2);
}

.number-active{
  animation:pulse 2s infinite;
  color:var(--primary);
}

</style>
<template>
  <div class="container">
 <div style="width:100%;height:30px;font-weight:bold;color:var(--subtext1)">
      <BaseIcon icon="md-apps" style="margin-right:5px;" />消息盒 </div>
  <div class="flex-wrap l-hover l-notice" style="height:calc(100% - 30px);background:#ffffff11;" >
    <div 
      :class="{
        'number':true,
        'number-active':unreadItems.length > 0
      }"
    >
      {{unreadItems.length}}
    </div>
    <div class="flex-wrap" style="height:100%;width:100%;flex-shrink:1;flex-grow:1;padding:0px 15px;">
    <transition name="fadeIn">
      <template v-if="loading">
        <BaseLoading />
      </template>
      <template v-else-if="error">
        <div @click="getData">加载失败:{{error}}
        <a style="color:var(--primary)"><Icon type="md-refresh"></Icon> 重试</a></div>
      </template>
      <template v-else>
        <div class="flex-wrap" style="position:relative;z-index:5;height:100%;width:100%;" v-if="unreadItems.length == 0" >
          <div  style="position:relative;text-align:left;width:100%;">
              <div style="font-size:18px;color:var(--text1);font-weight:bold;">{{greetings}}</div>
              <div style="height:calc(100% - 35px);overflow:hidden;text-overflow:ellipsis;margin-top:5px;color:var(--subtext1);">当前没有新消息</div>
            </div>
          
        </div>
        <template v-else>
            

            <div style="position:relative;padding-top:5px;height:100%;width:100%;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;" @click="open()">
              <div style="font-size:18px;" v-if="first">{{first &&first.title && fisrt.title.length > 10?first.title.slice(0,10):first.title}}</div>
              <div style="height:calc(100% - 50px);overflow:hidden;width:calc(100% - 10px);
              overflow:hidden;text-overflow:ellipsis;margin-top:5px;"><span class="l-notice-dep" v-if="first && first.dep">{{first.dep}}</span>{{first.content}}</div>
            </div>
        </template>
        </template>
    </transition>
    </div>

     <Modal :title="'[通知] '+(first?first.title:'')" :mask-closable="false" v-model="modalMessage" footer-hide width="800" @on-cancel="markReaded" :fullscreen="mobile">
      <div style="padding:20px;font-size:16px;">
      <span class="l-notice-dep" v-if="first && first.dep">{{first?first.dep:''}}</span>{{first?first.content:''}}
      <div class="flex-wrap flex-right" style="margin-top:20px">
      <Button type="primary" @click="markReaded();modalMessage=false;">关闭</Button>
      </div>
      </div>
      
    </Modal>
   
  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import loadingMixin from '@/mixins/loading'
import moment from 'moment'
moment.locale('zh-CN')
export default{
  panelInfo:{
      name:'通知面板',
      minWidthSpan:10,
      minHeightSpan:5,
    },
  mixins:[loadingMixin],
  data(){
    return {
      modalMessage:false,
      items:[]
    }
  },
  computed:{
    ...mapGetters('session',['session']),
    first(){
      return this.unreadItems.length>0?this.unreadItems[0]:null
    },
    unreadItems(){
      return this.id?this.items.filter(v=>v.title && !v.readed_at) : this.items.filter(v=>!v.readed_at && v.title)
    },
    mobile(){
      return this.$store.getters['session/mobile']
    },
    greetings(){
      let greeting = '早上好'
      let now = moment()
      if(now.hours() < 5){
        greeting = '夜深了，早点休息吧'
      }else if(now.hours() < 11){
        greeting = "早上好"
      }else if(now.hours() < 12 && now.hours() > 11){
        greeting = '中午好'
      }else if(now.hours() < 18){
        greeting = '下午好'
      }else if(now.hours() < 24){
        greeting = '晚上好，加班辛苦了'
      }
      return `${greeting}, ${this.session.name}`
    },
    id(){
      return this.$route.params.id
    }
  },
  mounted(){
   this.getData()
  },
  methods:{
    getData(){
      if(!this.startLoading())
        return
      this.$api.get('/notices?q=mine').then(res=>{
       // this.items = res.data.data
        this.items = [{id:1,title:'2023年度员工考核'}]
      })
      .catch(this.setLoadingError)
      .finally(this.endLoading)
    },
   
   
     markReaded(){
      this.$set(this.first,'readed_at',moment().format())
    },
    open(){
      if(this.first && this.first.id){
        this.modalMessage = true
      }else{
        this.RouteTo("/core/self/messages")
      }
    }
  },
}
</script>
<style lang="less" >

.l-bgicon{
  position: absolute;
  bottom:-0px;
 font-size:130px;
    color:#ddddddaa;
    opacity: 0.5;
    z-index:0;
}

.l-notice-dep{
  padding:0 5px;
  background:var(--primary);
  color:#fff;
  margin-right:5px;
}
</style>
