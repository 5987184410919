<template>
  <div 
    class="u-block"
    :class="`u-${type} u-${value[itemId] && togglable?'button':'empty'}-active`"
    :style="
      `
        height:${height}px;
        width:${computedWidth}px;
        gap:${type=='list'?gap:0}px;
        max-width:${maxWidth}px;
      `"
    @click.stop="handleEvent()"
  >
    <template v-if="type == 'icon-button'">
      <BaseIcon :icon="icon" :size="height/2.5" />
    </template>
    <template v-else-if="type == 'text-button'">
      <BaseIcon :icon="icon" :size="height/2.5" style="margin-right:5px" v-if="icon" />
      {{ name }}
    </template>
    <template v-else-if="type == 'count-button'">
      <div class="u-count-button-name" :style="`font-size:${height/6}px`" v-if="name">{{ name }}</div>
      <div class="u-count-button-value" :style="`font-size:${height/3}px`">{{ counts[itemId] }}</div>
    </template>
    <template v-else-if="type == 'tabs'">
      <div class="u-tabs-name" v-if="name"
        :style="`font-size:${height/6}px`"  
      >{{ name }}</div>
      <div class="u-tabs-list">
        <div :key="s.key" v-for="s in subs"
          class="u-tab-button"
          :style="`flex-direction:${height<60?'row':'column'}`"
          :class="{'u-tab-button-active':s.key == value[itemId]}"
          @click="value[itemId] = s.key;$emit('input',value);$emit('on-event',{type:'tab-change',key:itemId,value:s.key})"
        >
          <div class="u-tab-name" :style="`font-size:${height>70?height/6:12}px`" ><BaseIcon :icon="s.icon" /> {{ s.name }}</div>
          <div class="u-tab-count" v-if="s.countable" :style="`font-size:${height<60?12:height/5}px`">
            {{ counts[s.key] }}
          </div>
      </div>
      </div>
    </template>
    <template v-else-if="type == 'search'">
      <Input 
        :value="value[itemId]"
        search
        :placeholder="placeholder"
        :style="`height:${height}px;overflow:hidden;`"
        @input="value[itemId]=$event;$emit('input',value)" 
        @on-search="$emit('on-event',{type:'search',key:itemId})"
      />
    </template>
    <template v-else-if="type='list'">
      <ToolBarItem 
        v-for="s in subs"
        :key="s.key"
        :item-id="s.key"
        v-bind="s"
        :height="computedListItemHeight"
        :count="count"
        :gap="gap"
        @on-event="handleEvent"
        @input="$emit('input',$event)"
      />
    </template>
  </div>
</template>

<script>
export default {
    name:"ToolBarItem",
    props:{
      type:{
        type:String,
        default:"empty"
      },
      width:{
        type:Number
      },  
      height:{
        type:Number
      },  
      gap:{
        type:Number,
        default:5
      },
      ['item-id']:{
        type:String
      },
      icon:{
        type:String
      }, 
      name:{
        type:String
      },  
      count:{
        type:Number
      },
      counts:{
            type:Object,
            default:()=>({})
        },
      value:{
        type:Object,
        default:()=>({})
      },
      subs:{
        type:Array,
        default:()=>([])
      },
      placeholder:{
        type:String
      },
      rows:{
        
        type:Number,
        default:1
      },
      maxWidth:{
        type:Number,
      },
      togglable:{
        type:Boolean
      }

    },
    computed:{
      computedWidth(){
        if(this.type == 'list')
          return this.width || this.computedListItemHeight * this.cols + this.gap * (this.cols - 1)

        if(this.type == 'search')
          return 'auto'

        if(this.type == "tabs")
          return 'auto'

        if(this.type == 'text-button')
          return 'auto'

        return this.width || this.height
      },
      cols(){
        if(this.type == 'list' && this.rows)
          return Math.ceil(this.subs.length / this.rows)
      },  
      computedListItemHeight(){
        return (this.height - this.gap*(this.rows-1))/this.rows
      }
    },
    methods:{
      handleEvent(e){
        if(e){
          this.$emit('on-event',e)
        }

        if(this.type.includes('button')){
          if(this.togglable){
            this.$set(this.value,this.itemId,!this.value[this.itemId])
            this.$emit('input',this.value)
          }
          this.$emit('on-event',{type:this.itemId})
        }
      }
    }
}
</script>

<style lang="less">
.u-block{
  position: relative;
  flex-grow:0;
  flex-shrink: 0;
  border-radius: 2px;
}
.u-icon-button{
  background:var(--bg3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border:1px solid var(--border);
}

.u-icon-button:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-icon-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.u-text-button{
  background:var(--bg3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width:auto !important;
  padding:0 5px;
  border:1px solid var(--border);
}

.u-text-button:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-text-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.u-count-button{
  background:var(--bg3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  border:1px solid var(--border);

  .u-count-button-name{
    font-size:12px;
  }

  .u-count-button-value{
    font-size:25px;
  }
}

.u-count-button:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-count-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.u-button-active,.u-button-active:hover{
  background:var(--primary);
  color:var(--hover-text);
}

.u-list{
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  
  align-items: stretch;
}


.u-search{
  flex-grow: 1;
  min-width: 100px;
  .ivu-input{
    border-radius: 2px;
    height:100%;
  }
}

.u-tabs{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border:1px solid var(--border);
}
.u-tabs-name{
  padding:2px;
  text-align: center;
  
  border-bottom: 1px solid var(--border);
}

.u-tabs-list{
  display: flex;
  flex-wrap:wrap;
  flex-shrink: 0 !important;
  flex-grow: 1;
}



.u-tab-button{
  background:var(--bg3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  min-width:40px;
  padding:0 8px;
  text-align: center;
  border-right: 1px solid var(--border);

  .u-tab-name{
    text-align: center;
  }

  .u-tab-count{
    font-size:25px;
    text-align: center;
    padding:0 5px;
    background:var(--bg2);
    margin:0 5px;
    border-radius: 5px;
  }
}

.u-tab-button:last-child{
  border-right:none;
}

.u-tab-button:hover{
  background:var(--primary);
  color:var(--hover-text);
  .u-tab-count{
    background:#33333355;
    
  }
}

.u-tab-button:active{
  position: relative;
  right:-1px;
  bottom:-1px;
}

.u-tab-button-active{
  background:var(--primary);
  color:var(--hover-text);
  .u-tab-count{
    background:#33333355;
  }
}


</style>