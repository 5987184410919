<template>
    <div style="width:1000px;margin:20px auto;">
    <div class="flex-wrap flex-between" style="padding-right:10px">
    <h1><a @click="RouteTo(`/core/materials/${item.type.id}/type`)">{{item.type.name}}</a> / {{item.name}}</h1>
     <Button icon="md-add" @click="add_cart">收藏</Button>
      </div>
    <div style="padding:20px" v-if="item">
     
    
     
     <BaseSegment title="产品信息">
        <Row style="width:100%">
          <Col :span="6">
          <img style="width:100%" :src="item.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'" />
          </Col>
          <Col :span="9" :push="1">
          
          <div class="l-cell">
            <div class="l-label">类别</div>
            <div class="l-value-wrap">
              <div>
      <template v-for="c in item.cates">
        {{c}} /
      </template>
      {{item.name}}
     </div>
            </div>
          </div>
          <div class="l-cell">
            <div class="l-label">品牌</div>
            <div class="l-value-wrap" v-if="item.brand">
              <div class="l-value"><a @click="RouteTo(`/core/materials/${item.brand.id}/brand`)">{{item.brand.name}}</a></div>
              
              <Tag  size="small" style="margin-left:10px" v-if="item.brand.level">{{item.brand.level}}</Tag>
            </div>
          </div>
           <div class="l-cell">
            <div class="l-label">规格</div>
            <div class="l-value-wrap">
              <div class="l-value">{{item.specification}}</div>
            </div>
          </div>
           <div class="l-cell">
            <div class="l-label">市场参考价</div>
            <div class="l-value-wrap" v-if="item.prices && item.prices.length > 0">
              <div class="l-value"><BaseAmount full :value="item.prices[0].amount"></BaseAmount></div>
              <div class="l-unit" style="margin-left:5px"> / {{item.prices[0].unit}}</div>
              <Tag size="small" style="margin-left:10px" v-if="item.prices[0].taxrate">税率 {{item.prices[0].taxrate}}%</Tag>
              <Tag size="small" style="margin-left:3px" v-if="item.prices[0].withTran">含运费</Tag>
            </div>

          </div>
            <div class="l-cell">
            <div class="l-label">产品说明</div>
            <div class="l-value-wrap">
              <div class="l-value">{{item.note}}</div>
            </div>
          </div>

          <div class="l-cell">
            <div class="l-label">产地</div>
            <div class="l-value-wrap">
              <div class="l-value">{{item.product_area}}</div>
            </div>
          </div>
          </Col>
          <Col :span="9">
          
           <div class="l-cell">
            <div class="l-label">产品系列</div>
            <div class="l-value-wrap">
              <div class="l-value">{{item.series}}</div>
            </div>
          </div>

           <div class="l-cell">
            <div class="l-label">产品型号</div>
            <div class="l-value-wrap">
              <div class="l-value">{{item.model}}</div>
            </div>
          </div>

           <div class="l-cell">
            <div class="l-label">更新日期</div>
            <div class="l-value-wrap">
              <div class="l-value">{{moment(item.updated_at).format("LL")}}</div>
            </div>
          </div>

           <div class="l-cell">
            <div class="l-label">更新人</div>
            <div class="l-value-wrap">
              <div class="l-value"><BaseNameBoard :uid="$store.getters['session/session'].id" /></div>
            </div>
          </div>
          </Col>
          
        </Row>
     </BaseSegment>

     <BaseSegment title="报价信息">
        <Row >
          <Col :span="24">
          <Row style="font-weight:bold;border-bottom:1px solid var(--border);padding-bottom:5px;margin-bottom:5px;">
            <Col :span="1" align="center">
              序
            </Col>
            <Col :span="3" align="right">
              价格(无税)
            </Col>
            <Col :span="3" align="right">
              价格(含税)
            </Col>
            <Col :span="2" align="center">
              税率
            </Col>
             <Col :span="4" align="center">
              供应商 
            </Col>
           
            <Col :span="4" align="center">
              询价日期
            </Col>
            <Col :span="2" align="center">
              询价方式
            </Col>
            <Col :span="2" align="center">
              LV
            </Col>
             <Col :span="2" align="center">
             附件
            </Col>
          </Row>
          <template v-for="(p,i) in item.prices">
            <Row  :key="p.id" style="margin-bottom:5px;border-bottom:1px dashed var(--border)">
            <Col :span="1" align="center">
              {{i+1}}
            </Col>
            <Col :span="3" align="center">
              <BaseAmount full :value="p.notax_amount" />
            </Col>
            <Col :span="3" align="center">
              <BaseAmount full :value="p.amount" />
            </Col>
            <Col :span="2" align="center">
              {{p.taxrate}} %
            </Col>
             <Col :span="4" align="center">
              {{p.supplier?p.supplier.name:'-'}}
              <div style="color:var(--subtext3)">{{p.supplier?p.supplier.address:''}}</div>
            </Col>
           
            <Col :span="4" align="center">
              {{moment(p.accquired_at).format("YYYY-MM-DD")}}
            </Col>
            <Col :span="2"  align="center">
              {{ACC_TYPES[p.type] || p.type}}
            </Col>
            <Col :span="2" align="center">
              {{LEVELS[p.level]}}
            </Col>
             <Col :span="2" align="center">
              <a @click="previewURL=p.file;preview=true;" v-if="p.file">查看</a>
              <span v-else>-</span>
            </Col>
          </Row>
          </template>

          </Col>
          <Col :span="8">
          <!-- <img style="width:100%;height:200px;background:var(--bg1)" />
          <div class="flex-wrap flex-center" style="margin-top:10px">
          价格波动图
          </div> -->
          </Col>
        </Row>
     </BaseSegment>
    <template v-if="item.producer">
        <BaseSegment :title="'厂商信息 / '+item.producer.name">
            <Row>
              <Col :span="12">
                 <div class="l-cell">
                <div class="l-label">企业名称</div>
                <div class="l-value-wrap">
                  <a  @click="RouteTo(`/core/materials/${item.producer.id}/company`)">{{item.producer.name}}</a>
                </div>
              </div>
              <div class="l-cell">
                <div class="l-label">企业性质</div>
                <div class="l-value-wrap">
                  {{item.producer.ent_type}}
                </div>
              </div>

               <div class="l-cell">
                <div class="l-label">注册资金</div>
                <div class="l-value-wrap">
                  <BaseAmount :value="item.producer.registered_funds" style="margin-right:5px" /> <div class="l-unit">{{item.producer.registered_funds_unit}}</div>
                </div>
              </div>

               <div class="l-cell">
                <div class="l-label">地址</div>
                <div class="l-value-wrap">
                  <div class="l-value">{{item.producer.address}}</div>
                </div>
              </div>

              <div class="l-cell">
                <div class="l-label">联系人</div>
                <div class="l-value-wrap" v-if="item.producer.contacts">
                  <template v-for="c in item.producer.contacts">
                    
                  <div class="l-value" :key="c.name">{{c.name}}</div>
                  </template>
                </div>
              </div>
              </Col>
              </Row>
              <Row>
                <Col :span="12">
                 <div class="l-cell">
                <div class="l-label">公司类型</div>
                <div class="l-value-wrap">
                  <div class="l-value">{{item.producer.type}}</div>
                </div>
              </div>

              
                </Col>
              </Row>
        </BaseSegment>
    </template>
     <BaseSegment title="产品手册等其他资料">
      <div class="flex-wrap align-start">
        <template v-for="f in item.files">
          <div class="lf-item" :key='f.id'>
            <img style="width:140px;height:180px;border:1px solid var(--border);border-radius:5px" :src="f.avatar ||  'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/empty-img.png'" />
            <a class="f-name" @click="Download(f.url)">
              {{f.name}}
            </a>
          </div>
        </template>
      </div>
     </BaseSegment>
    </div>
    
    <Drawer v-model="preview" :width="800">
      <BasePreview :url="previewURL" />
    </Drawer>
    </div>
    </div>
</template>

<script>

  export default {
    data(){
      return {
        ACC_TYPES:["电话","聊天","询价单","其他"],
        preview:false,
        previewURL:"",
        showCart:false,
        LEVELS:['L1','L2','L3'],
        item:{
          cates:[],
          brand:{},
          files:[],
          producer:{
            contacts:[],
            relatedCates:[]
          },
          prices:[],

        }
      }
    },
    routerOption:{
      as_object:true
    },
    computed:{
      cate(){
        return this.$route.params.id
      },
    },
    mounted(){
      setTimeout(()=>{
        this.getData()
      },500)
    },
    methods:{
      add_cart(){
        this.$store.commit('mat/add_cart',this.item)
        this.$Notice.success({title:"添加成功"})
      },
      getData(){
        this.$api.get(`mat/products/${this.cate}?full=2`).then(res=>{
          this.item = res.data.data
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.lf-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-start;
  width:180px;
  img{
    margin-bottom:10px;
  }
}

.l-cell{
  display: flex;
  align-items: flex-start;
  padding:5px 0;
  .l-label{
    font-weight: bold;
    margin-right:15px;
    min-width:80px;
    text-align: right;
  }
  .l-value-wrap{
    display: flex;

    .l-unit{
      color:var(--subtext3);
    }
  }
}
</style>