

<template>
  <div class="pm-page" :class="{'pm-flex-page':flex}">
    <div class="pm-layout pm-layout-config">
      <BaseEmpty v-if="loading">
        <BaseLoading></BaseLoading>
      </BaseEmpty>
      <div
        class="pm-content pm-content-mobile"
        v-if="!loading && mobile"
        :style="`padding:${padding}px`"
      >
        <template v-for="p in panels">
          <div
            class="pm-panel"
            :style="`
          position:relative;
          width:100%;
          height:${(screenWidth - padding * 2) * p.h / p.w}px;
          opacity:1;
          overflow:hidden;
          margin-bottom:${gutter}px;
        `"
            :key="p.id"
          >
            <keep-alive>
              <component
                :is="p.control"
                @event="handleEvent"
                :data="p.data"
                :env="env"
                :option="p.option"
              />
            </keep-alive>
          </div>
        </template>
      </div>
      <div class="pm-fix" v-if="!loading && !mobile">
        <template v-for="p in panels.filter(p=>p.option && p.option.fixed)">
          <div
            class="pm-panel"
            :style="`
          left:${p.x * (unitx+gutter)}px;
          top:${p.y * (unity+gutter)}px;
          width:${p.w * (unitx+gutter) - gutter}px;
          height:${p.h * (unity+gutter) - gutter}px;
          opacity:1;
          zoom:${zoom||1};
          overflow:hidden;
        `"
            :key="p.id"
          >
            <keep-alive>
              <component
                :is="p.control"
                @event="handleEvent"
                :data="p.data"
                :env="env"
                :option="p.option"
              />
            </keep-alive>
          </div>
        </template>
      </div>

      <div class="pm-content" :class="flex?'pm-content-flex':''" v-if="!loading && !mobile">
        <template v-for="p in panels.filter(p=>!p.option || !p.option.fixed)">
          <div
            class="pm-panel"
            :class="{'pm-panel-flex':flex}"
            :style="(!flex?`left:${padding + p.x * (unitx+gutter) }px;
          top:${padding + p.y * (unity+gutter)}px;
          width:${p.w * (unitx+gutter) - gutter}px;
          height:${p.h * (unity+gutter) - gutter}px;
          opacity:1;
          overflow:hidden;display:${loading?'none':'block'};
          
         zoom:${zoom||1};`:`position:relative;
          width:calc(${(p.w *100/24)}% - ${gutter}px);
           overflow:hidden;display:${loading?'none':'block'};
         zoom:${zoom||1};
         margin:${gutter/2}px
        `) + ';'+(p.noStyle?'':panelStyles)+(p.styles||'')
        "
            :key="p.id"
          >
            <keep-alive>
              <component
                :is="p.control"
                @event="handleEvent"
                :data="p.data"
                :env="env"
                :option="p.option"
              />
            </keep-alive>
          </div>
        </template>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import responsableMixin from "@/mixins/responsable";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: true,
      clientWidth: 0,

      clientHeight: 0,
      unitx: 0,
      unity: 0,
      zoom: 1,
      unit: 100
    };
  },
  mixins: [responsableMixin],
  props: {
    panels: {
      type: Array,
      default: () => []
    },
    padding: {
      type: Number,
      default: 0
    },
    gutter: {
      type: Number,
      default: 10
    },
    env: {
      type: Object,
      default: () => ({})
    },
    flex: {
      type: Boolean,
      default: false
    },
    "panel-styles": {
      type: String
    }
  },
  computed: {
    mobile() {
      return this.$store.getters["session/mobile"];
    },
    screenWidth() {
      return this.$store.getters["session/screenWidth"];
    }
  },
  mounted() {
   
    this.$nextTick(e => {
      this.resetLayout();
      window.addEventListener("resize", this.resetLayout);
    });
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.resetLayout);
  },  
  activated() {
    this.$nextTick(e => {
      this.resetLayout();
    });
  },
  methods: {
    resetLayout() {
      console.log('layout:reset')
      if (this.mobile) return;
      if (this.resetLayoutTimer) {
        clearTimeout(this.resetLayoutTimer);
      }
      this.resetLayoutTimer = setTimeout(() => {
        this.loading = true;
        this.clientWidth = this.$el.clientWidth;
        this.clientHeight = this.$el.clientHeight;

        let ratio = this.clientHeight / this.clientWidth;
        this.maxColumns = "";
        this.maxRows = "";
        this.unitx =
          (this.clientWidth + this.gutter - 2 * this.padding) / 24 -
          this.gutter;
        //(this.clientHeight + this.gutter ) / 11 - this.gutter

        let unity = this.layoutScrollable
          ? (this.clientHeight / this.clientWidth) * this.unitx - this.gutter
          : (this.clientHeight + this.gutter - 2 * this.padding) /
              parseInt(24 * ratio) -
            this.gutter;

        // if(this.clientHeight - 11 * unity <= 250)
        unity =
          (this.clientHeight + this.gutter - 2 * this.padding) / 11 -
          this.gutter;

        this.unity = unity;

        this.loading = false;
       
        this.$forceUpdate();
      }, 500);
    },
    handleEvent(e) {
      this.$emit("event", e);
    },
    onResize() {
      this.resetLayout();
    }
  }
};
</script>

<style lang="less">
.pm-panel-flex {
  display: flex;

  height: auto !important;
  min-height: 200px !important;
  transition: all 0.3s;
}

.pm-panel-flex:hover {
  box-shadow: 0 0 3px 2px var(--border);
}
.pm-panel-editing-wrap:hover {
  filter: brightness(1.2);
  cursor: pointer;
}

.pm-panel-selected {
  background: #fbbd2162 !important;
}

.pm-content-mobile {
  display: block;
  height: 100%;
  overflow-y: auto;
}

.pm-content-flex {
  overflow: hidden;
  justify-content: center;
  align-content: flex-start;
}

.pm-flex-page {
  height: auto !important;
  overflow: hidden;
  .pm-layout {
    position: relative !important;
    width: 100%;
    .pm-content {
      position: relative;
    }
  }
}
</style>