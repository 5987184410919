<style lang="less">
.ugo-form{
  .ivu-form-item{
    margin:0;
    margin:10px 0;
    padding:0 10px;
  }
  .ivu-form-item-label{
    background:#eee;
    text-align: center;
    border-radius:5px;
    padding:10px;
  }

  .ivu-form-item-content{
    padding-left:10px;
  }
}
</style>

<template>
<Layout>
  <div style="height:50px;padding:0 !important;position:relative !important;border-bottom:1px solid #aaa;background:#eee;">
    
     <BaseToolbar
      class="ugo-toolbar"
          :data="tools"
          @event="handleToolEvent"
        />
  </div>

  <Layout>
    <Sider style="width:200px;background:#fff;min-height:700px;border-right:1px solid #aaa;">
      <div class="l-panel-title">内容</div>
      <BaseTree :selected="selected" manu @event="handleTreeEvent" subname="fields" :data="[form]" style="height:300px;overflow-y:auto;"  />
      
      <div style="position:absolute;bottom:0;top:200px;left:0;right:0;">
        <div class="l-panel-title">组件</div>
        <div class="flex-wrap" style="height:calc(100% - 30px);overflow-y:auto;align-items:flex-start;">
          <draggable :list="controls" :group="{ name: 'control', pull: 'clone', put: false }" :clone="handleClone" style="width:100%">
            <transition-group type="transition" name="flip-list">
              <div class="u-control-item" :key="c.control" v-for="c in controls">
                <Icon :custom="`ugoicon ugi${c.icon || 'input'}`" size="18" style="margin-right:5px" /> {{c.name || c.control}}
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </Sider>
    <Content style="background:#ddd">
     
      
      <div style="width:900px;margin:60px auto;background:#fff;position:relative;">
        <template v-if="form.html">
          <a style='font-size:14px;color:#3af;position:absolute;top:-25px;' @click="view=(view=='html'?'form':'html')">切换至 {{view=='html'?'原始表单':'HTML表单'}}</a>
        </template>
         <div class="" style="overflow:hidden;height:auto;margin:20px;background:none !important;padding:40px 0;">
      <Form :label-width="form.labelWidth || 100" class="ugo-form" inline>
        
        <template v-if="view == 'html'">
          
          <BaseForm :form="{layout:form.html,def:formDef}"
          
          v-model="formData" />
        </template>
        <template v-else>
         <draggable v-model="form.fields" @input="save" group="control" style="width:100%;min-height:150px;background:#eee;border:1px dashed #39f;padding:5px 0;display: block;" >
          
          <BaseEmpty v-if="!form.fields || form.fields.length == 0" msg="拖拽组件到此处" />
         
          <template v-for="p in form.fields">
            <div class="form-item flex-wrap flex-between" :class="selected==p?'form-item-selected':''"  :key="p.id" 
            @click="selected = p" style="border:1px solid var(--primary);margin:12px;position:relative;overflow:hidden;padding:5px;">
                    <div class="l-mask"></div>
                <div class="l-remove-btn" @click.stop="handleRemove(p)"><Icon type="md-close" /></div>
                <keep-alive>
                  <template v-if="isContainer(p.control)">
                    <template v-if="p && p.config">
                     <Row :gutter="p & p.config ? p.config.gutter:0" style="width:100%">
                          <template v-for="(p,i) in new Array(p && p.config ? p.config.cols:2)">
                            <Col :key="p" :span="p && p.config ? p.config.span : 24" >
                            <draggable tag="div" :key="p"  :list="p.subs" :group="{animation: 300,
                              group: 'control',
                              disabled: false,
                              ghostClass: 'ghost'
                            }"  style="background:#ffffffee;min-height:150px;width:100%;display: block;padding:10px 0;border:1px dashed #aaa;">
                              <div v-if="!p.subs[i] || p.subs[i].subs.length == 0">
                                拖拽至此处
                              </div>
                              <template v-else>
                                <template v-for="t in p.subs[i].subs">
                                  <component 
                                          :is="t.control" 
                                          :required="t.required"
                                          :label="t.name"
                                          :key="t,key"
                                          :config="t.config" 
                                          :editable="true" 
                                        
                                        />
                                </template>
                                  </template>
                                </draggable>
                            </Col>
                            
                          </template>
                        
                        </Row>
                        </template>
                  </template>
                  <template v-else>
                  <component 
                    :is="p.control" 
                    :required="p.required"
                    :label="p.name"
                    :fkey="p.key" 
                    :config="p.config" 
                    :editable="true" 
                  
                  />
                    </template>
                </keep-alive>
            </div>
          </template>
          
       <div v-if="form.fields.length != 0" class="drag-tip">拖拽组件到此处</div>
         
         </draggable>
         
         </template>
      </Form>
    </div>
      </div>
    </Content>
    <Sider style="width:240px;background:#fff;border-left:1px solid #aaa;">
      <div class="l-panel-title">属性</div>
      <BaseEmpty v-if="!selected || selected.name==undefined" msg="请选择组件" />
      <template v-else>
        <BaseProperty v-model="selected" :attrs="selected != form?fieldConfig:formConfig" :disbaled="{control:true}"  @input="handleChange"></BaseProperty>
        <BaseProperty v-if="selected != form"  v-model="selected.config" :attrs="getAttrConfig(selected)" @input="handleChange"></BaseProperty>
      </template>
    </Sider>
  </Layout>
  <div class="flex-wrap" style="align-items:stretch;height:24px;border-top:1px solid #aaa;background:#eee;padding:0 !important;font-size:12px;">
      <div class="flex-wrap flex-center" style="padding:0 10px;border-right:1px solid #aaa;">编辑中</div>
      <div class="flex-wrap flex-center" style="padding:0 10px;border-right:1px solid #aaa;width:calc(100% - 160px);"> 
        {{selecting_to?'请选择下一节点':''}}</div>
      
    </div>
  </Layout>
</template>

<script>
import {upperFirst,camelCase,cloneDeep} from 'lodash'
import draggable from 'vuedraggable'
import UgoXForm from './index.vue'
  const requireComponentFormControls = require.context('@/components/form/controls', true, /\.vue$/)
let Controls = []
for (const file of requireComponentFormControls.keys()) {
  const componentConfig = requireComponentFormControls(file)
  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  const componentName = upperFirst(camelCase(name))
  
  Controls.push({
    control:'Ugo'+componentName,
    name:componentConfig.default.name,
    icon:componentConfig.default.icon,
    properties:componentConfig.default.properties || [],
    initModel:componentConfig.default.initModel
  })
}
let idGlobal =1
  export default {
    props:['value'],
    data(){
      return {
        selecting_to:null,
        view:'form',
        selected:{},
        form:{},
        drag:false,
        fieldConfig:[{
          label:"名称",
          key:"name",
          control:"Text",
          editable:true
        },{
          label:"数据名",
          key:"key",
          control:"Text",
          editable:true,
          option:{
            
            placeholder:"默认为名称"
          }
        },{
          label:"组件",
          key:"control",
          control:"Text"
        },{
          label:"必填",
          key:"required",
          editbale:true,
          control:"Check"
        }],
         formConfig:[{
            label:"表单名称",
            key:"name",
            control:"Text"
          },{
            label:"表单简介",
            key:"description",
            control:"Text"
          },{
            label:"HTML表单",
            key:"html",
            control:"Text"
          },{
            label:"标签宽度",
            key:"labelWidth",
            control:"Text"
          }],
      }
    },
    computed:{
      nodes(){
        return [this.form]
      },
       tools() {
        return [{
          key: "copy",
          name: "复制",
          icon: 'md-copy'
        },{
          key: "clear",
          name: "清空",
          icon: 'md-remove'
        }]
       },
       controls(){
        return Controls
      },
      formDef(){
        let o = {}
        if(this.form.fields){
          
        this.form.fields.forEach(v=>{
          if(v.key || v.name)
            o[v.key || v.name] = {
              label:v.name,
              control:'input',
              option:{}
            }
        })
        }
        return o
      }
    },
    mounted(){
      if(this.value){
        this.form = cloneDeep(this.value)
      }else{
        this.form = {
          id:'create',
          name:"新建表单",
          fields:[]
        }
      }
      this.selected = this.form
    },
    watch:{
      value:{
        handler(v){
          if(v){
          this.form = (this.value)
          }else{
            this.form = {
              id:'create',
              name:"新建表单",
              fields:[]
            }
          }
          this.selected = this.form
          this.$forceUpdate()
        },
        immediate:true,
        deeply:true
      }
    },
    methods:{
       isContainer(control){
      const containers = ['UgoRow']
      return containers.includes(control)
    },
      handleClone({name,control}){
        let id = idGlobal++
        let obj = {
          id:'item'+id,
          name:`${name}${id}`,
          control
        }
        let controlDef = this.controls.find(v=>v.control == control)
        if(controlDef && controlDef.initModel)
          controlDef.initModel(obj)

     
        return obj
      },
      log(e){
        //this.handleChange()
        console.log("log:",e)
        
      },
      save(){
        this.value = this.form
        this.$emit('input',this.form)
      },
      handleChange(){
        this.save()
      },
      getAttrConfig(e){
        let control = this.controls.find(v=>v.control == e.control)
        if(control)
          return control.properties || []
       
      },
      handleRemove(e){
        this.form.fields.splice(this.form.fields.findIndex(v=>v == e),1)
           this.handleChange()
      },
      handleTreeEvent(e){
        if(e && e.type=='select')
          this.selected = e.data
      },
      handleToolEvent(e){
        if(e=='clear'){
          this.form.fields = []
          this.handleChange()
        }
      },
    },
    components:{UgoXForm,draggable}
  }
</script>

<style lang="less">
.u-control-item{
  width:calc(100% - 10px);
  height:35px;
  display: flex;
  align-items: center;
  border:1px solid #ddd;
  padding:0 5px;
  margin:2.5px 5px;
  overflow: hidden;
  font-size:12px;

}

.u-control-item:hover{
  background:#3399ff33;
  cursor: pointer;
}
.l-mask{
  position: absolute;
  z-index:10;
  left:0;
  top:0;
  bottom:0;
  right:0;
  opacity: 0.1;
}

.l-remove-btn{
   position: absolute;
  z-index:12;
 
  top:0;
  width:20px;
  height:20px;
  right:0;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.l-remove-btn:hover{
  i{
  color:var(--primary) !important;
  }
}

.form-item{
  position: relative;
  .l-mask{
    background:var(--primary);
  }
}

.form-item-selected{
  border-color:var(--warning) !important;

  .l-mask{
    background-color:var(--warning);
  }
  
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.drag-tip{
  display: flex;
  align-items: center;justify-content: center;
  margin:5px;
  color:#aaa;
}

.l-panel-title{
height:25px;padding:4px 5px;background:#eee;font-size:10px;
border-bottom:1px solid #aaa;
}
</style>