<template>
  <div class="container">
    <template v-if="loading">
      <Spin fix>
      <BaseLoading style="margin:0;margin-bottom:10px;height:20px" />
      加载中
    </Spin>
    </template> 
    <template  v-else>
      <div style="font-size:10px;color:var(--subtext3);margin-bottom:5px;">{{ flowId }}</div>
    <div class="flex-wrap flex-between flow-header">
      <div class="flex-wrap">
      <div class="icon-wrap">
      <BaseIcon :icon="flowData.icon" :size="30" />
      </div>
      <div>
        <div class="title">{{flowData.name}}</div>
        <div class="sub">{{flowData.group}}</div>
      </div>
      </div>
      <div class="flex-wrap split10">
        <div>管理</div>
        <div>编辑</div>
      </div>
    </div>
    <BaseBoardSubTitle name="填写表单" style="margin:10px" />
    <SForm ref="form" :form="flowData.form" style="margin:20px;backgrond:var(--bg2)" />
    <template v-if="flowData.formCreateFlow">
    <BaseBoardSubTitle name="配置流程" style="margin:10px" />
    <SForm ref="flow" :form="flowData.formCreateFlow"  style="margin:10px;backgrond:var(--bg2)" />
  </template>
    <div class="flex-wrap flex-right">
    <Button long type="primary" size="large" style="width:200px" @click="handleSubmit">提交</Button>
    <Button long size="large" style="width:120px;margin-left:20px;" @click="handleSaveAsDraft">保存草稿</Button>
     <Button long size="large" style="width:120px;margin-left:20px;" @click="handleCancel">取消</Button>
    </div>
  </template>
  </div>
</template>

<script>
import flow from './core'
import SForm from  '../form/instance'
export default {
  data(){
    return {
      loading:true,
      flowData:{
        name:'发布文稿',
        group:"行政",
        icon:'md-alert',
        form:{
          fields:[
           
            {
              key:'name',
              control:"Input",
              required:true,
              name:"文章标题",
              option:{
                long:true,
                placeholder:"请输入文章标题",
                style:'width:300px',
                clearable:true,
                maxlen:64
              },
          },
          {
              key:'type',
              control:"BaseSelect",
              required:true,
              name:"文章类型",
              option:{
                valueType:'object',
                style:'width:200px',
                long:true,
                options:[{
                  id:'notice',
                  name:'通知公告'
                },{
                  id:'public-file',
                  name:'公文发布'
                },{
                  id:'policy',
                  name:'政策解读'
                },{
                  id:'knowledge',
                  name:'技术文章'
                }]
              },
          },
          {
              key:'code',
              control:"Input",
              name:"公文编号",
              option:{
                placeholder:"仅公文填写",
                long:true,
                clearable:true,
                maxlen:64
              },
          },
            {
              key:'content',
              required:true,
              name:"通知正文",
              control:"BaseMarkdownEditor",
          },
            {
              key:'files',
              name:"上传附件",
              control:"BaseFileUpload"
            }

          ]
        }
      },
      instData:{}
    }
  }, 
  components:{SForm},
  mounted(){
    
  },
  watch:{
    ['flow-id']:{
      handler(val){
        this.loadingFlow(val)
      },
      immediate:true
    }
  },
  props:{
    'flow-id':{
      type:String
    }
  },  
  methods:{
    loadingFlow(val){
      this.loading = true
      setTimeout(()=>{
        this.loading = false
      },500)
    },  
    handleSubmit(){
      this.$refs.form.submit(item=>{
        console.log('submit:',item)
        this.$api.post('/articles',item).then(res=>{
          this.$bus.$emit('article-'+item.t)
          this.$emit('cancel')
        }).catch(e=>{
          this.$emit('error',e)
        })
      })
    },
    handleSave(){
      this.$refs.form.submit(item=>{
        if(!item.id){
          item.draft = true
          this.$api.post('/articles',item).then(res=>{
            let updateInfo = res.data.data
            item.id = updateInfo.id
            this.$Notice.success({title:"操作成功",desc:"草稿已保存，你可以在我的草稿中找到它"})
          }).catch(e=>{
            this.$emit('error',e)
          })
        }else{
          this.$api.patch('/articles/'+item.id).then(res=>{
          }).catch(e=>{
            this.$emit('error',e)
          })
        }
        
      })
    },
    handleCancel(){
      this.$emit('cancel')
    },
    handleSaveAsDraft(){
      
    }
  }
}
</script>
<style lang="less">
.flow-header{
  padding:10px;
  background:var(--bg2);
  color:var(--text2);
  .icon-wrap{
    width:50px;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:var(--primary);
    color:var(--hover-text);
    border-radius: 10px;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .title{
    font-size:16px;
    
  }
  .sub{
    font-size:12px;
    color:var(--subtext2);
  }
}
</style>