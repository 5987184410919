<template>
  <div class="in-app-client" :style="`${collapseRight?`right:${rightWidth || 400}px;`:'right:0;'};${collapseLeft?`left:${leftWidth || 260}px`:'left:0'};top:${top||42}px`">
    <Spin fix v-if="loading"
      >
      加载中
        <BaseLoading style="margin:0" />
        
    </Spin>
    <BaseEmpty style="height:100%;flex-shrink:0;background:var(--bg3)" v-else-if="empty"></BaseEmpty>
    <template v-else>
      <slot></slot>
    </template>
    
  </div>
</template>

<script>
  export default {
    name:"client",
    props:['collapse-left','collapse-right','top','loading','empty','left-width','right-width']
  }
</script>

<style lang="less" scoped>
.in-app-client{
  position:absolute;
  right:0;
  bottom:0;
  top:50px;
  left:0;
  background:var(--bg2);
  color:var(--text2);
  overflow-y:auto;
  transition:all 0.3s;z-index:100
}
</style>