<template>
  <div class="container" style="overflow-y:auto">
    <div class="flex-wrap  align-start" style="flex-wrap:wrap">
        <div class="study-class" :key="item.id" v-for="item in items">
            <img :src="item.avatar || (item.project && item.project.avatar) || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/misc/px.jpg'" />
            <div class="study-project-name" v-if="item.project">{{ item.project.name }}</div>
            <div class="study-class-name">{{ item.name }}</div>

        </div>
    </div>

  </div>
</template>

<script>
export default {
    data(){
        return {
            loading:false,
            items:[]
        }
    },
    
}
</script>

<style lang="less">
.study-class{
    width:200px;
    flex-grow: 1;
    margin:10px;
    img{
        width:200px;
        height:140px;
    }

    .study-project-name{
        color:var(--subtext3);
        text-align: center;
        font-size:10px;
    }

    .study-class-name{
        width:100%;
        text-align: center;
    }
}

</style>