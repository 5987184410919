import TaskHelper from '../helper/task'
import {API} from '@/vue-plugins/api'
const o = {
  namespaced:true
}

o.state = {
  ready:false,
  tasks:[],
  computedTasks:[],
  taskTree:[],
  history:[],
  historyIndex:0,
  selected_id:null
}

o.getters = {
  tasks(state){
    return state.tasks
  },
  computedTasks(state){
    return state.computedTasks
  },
  taskTree(state){
    return state.computedTasks.filter(v=>!v.parent_id)
  },
  is_ready(state){
    return state.ready
  },
  root(state){
    return state.tasks[0]
  },
  selected(state) {
    return state.tasks.find(v=>v.id == state.selected_id) || {}
  },
  can_undo(state){
    return state.history.length > state.historyIndex
  },
  can_redo(state){
    return state.historyIndex > 0
  },
  actions(state){
    let actions = Object.keys(TaskHelper.actions).map(action=>{
      let v = TaskHelper.actions[action]
      if(v.tool)
        return {
          action,
          ...v.tool
        }
      else
        return null
    }).filter(v=>v)
    
    if(state.selected_id)
      return actions
    else
      return []
  }
}

o.actions = {
  load({commit},id){
    if(!id)
      return
    return new Promise((resolve)=>{
       if (!isNaN(id)) {
        setTimeout(()=>{
          commit('init')
          resolve()
        },1000)
       }else{
        API.get("/tasks/"+id).then(res=>{
          commit('load',res.data.data)
          resolve()
        })
       }
    })
   
  },
  save(){

  },
  
}

o.mutations = {
  do(state,{
    action,
    data
  }){
    console.log("do",action)
    let actionCaller = TaskHelper.actions[action]
    if (!actionCaller)
       throw new Error("action is not exist")
    let historyData = actionCaller.do(state,data)

    if (state.historyIndex > 0) {
      state.history = state.history.slice(0, -state.historyIndex)
      state.historyIndex = 0
    }

    o.mutations.compute(state)

    state.history.push({
      action,
      data:historyData
    })
  },
  undo(state) {
    if (history.length <= historyIndex) 
      throw new Error("can not undo anything")
    let historyNode = state.history[historyIndex]
    historyIndex--
    let action = TaskHelper.actions[historyNode.action]
    if(!action)
      throw new Error("action is not exist")
    action.undo(state,historyNode.data)
  },
  redo(state) {
    if(historyNode == 0)
      throw new Error("historyNode is 0,can not redo anything")
    let historyNode = state.history[historyIndex+1]
    historyIndex++
    let action = TaskHelper.actions[historyNode.action]
    if (!action)
      throw new Error("action is not exist")
    action.do(state, historyNode.data)
  },
  select(state,id){
    console.log('select',id)
    state.selected_id = id
  },
  init(state){
    let task = TaskHelper.init_model()
    state.tasks = [task]
    state.computedTasks = TaskHelper.compute(state.tasks)
    state.ready = true
  },
  load(state,tasks){
    state.tasks = tasks
    state.computedTasks = TaskHelper.compute(state.tasks)
    state.ready = true
  },
  compute(state){
    state.computedTasks = TaskHelper.compute(state.tasks)
  }
}

export default o
