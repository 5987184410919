<template>
    <div class="dnd-panel">
        <template v-for="item in items">
        <div class="dnd-item" @mousedown="onDragStart(item.key)" :key="item.key">
            <BaseIcon custom-class="iflofont" custom-prefix='iflo-' :icon="item.icon" :size="30" color="var(--text1)" />
            <div class="no-select">{{item.name}}</div>
        </div>
      </template>
    </div>
  </template>
  
  <script>
  export default {
    computed:{
        items(){
            return [{
                key:'start_node',
                name:'开始',
                icon:'start',

            },{
                key:'approval_node',
                name:'审批',
                icon:'approval',

            },{
                key:'finish_node',
                name:'结束',
                icon:'finish',
            },{
                key:'timer_node',
                name:'定时',
                icon:'timer',

            },{
                key:'repeat_node',
                name:'重复',
                icon:'repeat',

            },{
                key:'event_node',
                name:'事件',
                icon:'event',

            }]
        }
    },
    methods: {
      onDragStart(type) {
        this.$emit('drag-start', type);
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
  .dnd-panel {
    width: 80px;
    border: 3px solid var(--border);
    padding: 10px;
    background:var(--bg1);
    border-radius:10px;
    box-shadow: 2px 2px 3px 3px var(--border);
  }
  
  .dnd-item {
    cursor: pointer;
    padding: 5px;
    margin-bottom: 10px;
    
    color:var(--text1);
    text-align: center;
  }

  .dnd-item:hover{
    i{
        transition: color 0.5s;
        color:var(--active) !important;
    }
    color:var(--active);
  }
  </style>
  