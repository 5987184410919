<template>
    <Container >
        <h2 style="padding:10px 20px">合同风险预警管理</h2>
      <div class="flex-wrap" 
        style="padding:10px 20px;height:100px;gap:10px;width:100%;">
        <div class="flex-wrap" style="padding:5px;gap:5px;background:var(--border);border-radius:5px;">
        <template v-for="(s,i) in states">
          <div 
            class="tab-card m-light"
            :class="{
              'tab-card-active':i == filter_mode
            }"
            @click="filter_mode = i"
            :key="s.name">
            <div class="count" :style="`color:${s.color}`">
                {{s.count}}
              
            </div>
            <div class="name">{{s.name}}</div>
          </div>
          
        </template>
        </div>
        <div class="flex-wrap" style="padding:5px;gap:5px;background:var(--border);border-radius:5px;">
        <template v-for="(s,i) in types">
          <div 
            class="tab-card m-light"
            :class="{
              'tab-card-active':s.name == filter_type
            }"
            @click="filter_type = (filter_type == s.name?null:s.name)"
            :key="s.name">
            <div class="count" :style="`color:${s.count?'var(--text3)':'var(--subtext3)'}`">
                {{s.count || 0}}
              
            </div>
            <div class="name" :style="`color:${s.color}`">{{s.name}}</div>
          </div>
          
        </template></div>
      </div>
      <div class="flex-wrap" style="margin:0 20px">
        <Input search placeholder="输入风险关键字搜索" style="width:200px" />
        <Select placeholder="选择项目" style="margin-left:10px;width:260px;"></Select>
        <Select placeholder="选择项目" style="margin-left:10px;width:260px;"></Select>
      </div>
      <div style="padding:0 20px;height:calc(100% - 200px);width:100%;position:relative;flex-shrink:0;flex-grow:1;">
        <BaseTable ref="table" :columns="cols" :data="filteredItems" full />

      </div>

    </Container>
  </template>
  <script>
    import Container from '@/components/app/container'
    import Toolbar from '@/components/app/toolbar'
    import moment from 'moment'
  export default {
    routerOption: {
    },
    components:{Container,Toolbar},
    data(){
      return {
        filter_mode:0,
        filter_type:null,
        types:[{
          name:'一般风险',
          color:'var(--text3)',
          count:4,
        },{
          name:'中等风险',
          count:4,
          color:'var(--warning)'
        },{
          name:"高危风险",
          color:"var(--error)",
          count:6
        },],
        states:[{
          name:'进行中',
          new_count:3,
          color:'var(--error)',
          count:14
        },{
          name:'起草阶段',
          new_count:3,
          color:'var(--error)',
          count:0
        },{
          name:"履约阶段",
          count:13,
          new_count:2
        },{
          name:"履约阶段",
          count:0,
          new_count:2
        },{
          name:"结算阶段",
          count:1
        },{
          name:"已闭合",
          count:34
        }],
        items:[
  {
    "id": 1,
    "type": "市政监理",
    "name": "镇海区箭港湖ZH07-05-18地块环境综合整治工程",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "收款逾期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4a831d-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 2,
    "type": "市政监理",
    "name": "杭州市富阳区富春街道石塔坞渣土消纳场建设项目",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4aaa04-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 3,
    "type": "市政监理",
    "name": "2023年暑期华泰剑桥幼儿园维修工程",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "收款逾期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4a34b4-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 4,
    "type": "市政监理",
    "name": "集士港镇中学扩建工程（一期）",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "02662f42-d7d1-11eb-8764-9b4ff46eae27",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 5,
    "type": "市政监理",
    "name": "镇海区箭港湖ZH07-05-18地块环境综合整治工程",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4a0da6-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  }

,{
    "id": 1,
    "type": "市政监理",
    "name": "东部新城市民广场景观工程（图书馆、城展馆交界区域）施工",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "收款逾期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4aa9f0-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 2,
    "type": "市政监理",
    "name": "集士港镇联丰路人行道改造",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4a34be-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 3,
    "type": "项目管理",
    "name": "潘火王家弄安置小区",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "收款逾期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "eed4a0dbc-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 4,
    "type": "市政监理",
    "name": "后庙河等7条河道疏浚工程",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed49e6bd-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 5,
    "type": "项目管理",
    "name": "高新区贵驷安置小区",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4aa9e7-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },{
    "id": 1,
    "type": "项目管理",
    "name": "咸祥广场管理",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "收款逾期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4aa9f0-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 2,
    "type": "房建监理",
    "name": "北仑中心区E地块项目（三期）",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "ed4a82ef-3b83-11eb-8e1e-c15d5c7db744",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 3,
    "type": "市政监理",
    "name": "镇海区箭港湖ZH07-05-18地块环境综合整治工程",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "收款逾期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "02662f42-d7d1-11eb-8764-9b4ff46eae27",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 4,
    "type": "市政监理",
    "name": "镇海区箭港湖ZH07-05-18地块环境综合整治工程",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "02662f42-d7d1-11eb-8764-9b4ff46eae27",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  },
  {
    "id": 5,
    "type": "市政监理",
    "name": "镇海区箭港湖ZH07-05-18地块环境综合整治工程",
    "预警级别": "高",
    "风险阶段": "履约阶段",
    "风险描述": "项目延期",
    "处理措施": "立即与甲方开会讨论调整合同条件，确保合同条款能够全面覆盖新增工作范围及相应的预算和时间表调整",
    "风险状态": "已受控",
    "created_by": "02662f42-d7d1-11eb-8764-9b4ff46eae27",
    "updated_at": "2024-04-12 12:00:00",
    "created_at": "2024-03-22 12:00:00"
  }]
      }
    },
    mounted(){
    setTimeout(()=>{
        this.$refs.table.calcTableHeight()
    },500)
    },
    computed: {
      filteredItems(){
        if(this.filter_type !== null)
          return this.items.filter(v=>v.type == this.filter_type)
        else
          return this.items
      },
      cols(){
        return [{
          title:"序号",
          width:60,
          type:"index"
        },{
          title:"类型",
          width:120,
          key:"type",
          render:(h,{row})=>{
            const colormap = {
              '合同审阅':'var(--text3)',
              '合同变更':'var(--warning)',
              '合同结算':'var(--success)'
            }
            return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[row.type] || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row.type)
          }
        },{
          title:"项目名称",
          minWidth:140,
          key:"name",
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:'lianjieliu'},style:"margin-right:3px;color:var(--primary)"})
            let name = h('a',row.name)
            return h('div',{class:"flex-wrap",style:"text-align:left"},[icon,name])
          }
        },{
          title:"等级",
          width:120,
          key:"type",
          render:(h,{row})=>{
            const colormap = {
              '低':'var(--primary)',
              '中':'var(--warning)',
              '高':'var(--error)'
            }
            return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);border-color:${colormap[row['预警级别']] || 'var(--text3)'};width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row['预警级别'])
          }
        },{
          title:"风险阶段",
          width:120,
          key:"风险阶段",
          render:(h,{row})=>{
            return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);width:80px;margin:0 auto;text-align:center;padding:2px;padding-right:7px;border-radius:5px;`},row['风险阶段'] || '-')
          }
        },{
          title:"风险描述",
          minWidth:140,
          key:"风险描述",
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:'md-alert'},style:"margin-right:3px;color:var(--primary)"})
            let name = h('a',row.风险描述)
            return h('div',{class:"flex-wrap",style:"text-align:left"},[icon,name])
          }
        },
        {
          title:"风险状态",
          width:120,
          key:"风险状态",
          render:(h,{row})=>{
            const colormap = {
                '已排除':'var(--success)',
                '已受控':'var(--primary)'
            }
            return h('div',{
              style:`background:var(--border);border-left:5px solid var(--border);width:80px;margin:0 auto;text-align:center;padding:2px;border-color:${colormap[row['风险状态']]};padding-right:7px;border-radius:5px;`},row['风险状态'] || '未处理')
          }
        },
        {
          title:"处理措施",
          minWidth:140,
          key:"处理措施",
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:'md-alert'},style:"margin-right:3px;color:var(--primary)"})
            let name = h('a',row.处理措施)
            return h('div',{class:"flex-wrap",style:"text-align:left"},[icon,name])
          }
        },{
          title:"责任人",
          width:120,
          type:'user',
          key:"created_by"
        },{
          title:"操作",
          width:120,
          render:(h,{row})=>{
            let buttons = [h('Button',{props:{size:"small"}},"处理")]
            return h('div',{class:"flex-wrap flex-center"},buttons)
          }
        }]
      }
        
    },
    methods:{
     
    }
  }
  </script>
  
  <style lang="less">
    .tab-card{
      padding:5px 10px;
      height:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background:var(--bg3);
      border-right:none;
      cursor: pointer;
      border-radius: 5px;
      .count{
        font-size:30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      
    }


    .tab-card:hover{
      background:var(--primary);
      color:var(--hover-text);
      .count,.name{
        color:var(--hover-text) !important;
      }
    }

    .tab-card-active{
      background:var(--primary);
      color:var(--hover-text);
      .count,.name{
        color:var(--hover-text) !important;
      }
    }
  </style>