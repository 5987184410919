<style lang="less" >

.ugo-item-user{
  width:116px;height:38px;padding:0 10px;background:#fff;
  cursor: pointer;

}

.ugo-item-user:hover{
  background:gold;
  transition: all 0.3s;
}

.ugo-item-user-selected{
  background:gold;
  color:#fff;
}

.ivu-select-dropdown{
  max-height:400px;
}
</style>
<template>
  <div class="l-full flex-wrap" style="color:#aaa;align-items:flex-start;width:100%;position:relative;">
    <div>
    {{config?config.label:''}} 
    </div>
    <Dropdown trigger="custom" :visible="showDropdown" transfer placement="bottom-start" style="width:100%" @on-clickoutside="handleClickOut">
      <div @click="showDropdown=true" style="border:1px solid #ddd;width:100%;padding:0 5px;border-radius:3px;" :style="selected && selected.length > 0?'':''">
        <template v-if="selected.length == 0">
          请选择
        </template>
        <template v-else>
         
          <template v-for="u in selected">
            <Avatar :key="u.id" size="20" :src="(users.find(v=>v.id == u) || {name:u}).avatar">{{(users.find(v=>v.id == u) || {name:u}).name[0]}}</Avatar> {{(users.find(v=>v.id == u) || {name:u}).name}}
          </template>
        </template>
        
        </div>
      <DropdownMenu slot="list">
      <div class="flex-wrap" style="max-width:600px;background:#eee;position:relative;max-height:300px;overflow:hidden;">
        <template v-if="config.groups">
          <template v-if="prev">
            <div @click="current=prev;prev=null">Back</div>
          </template>
          <template v-if="current.groups">
            <template v-for="o in current.groups">
              <div :key="o.key">
                <Avatar shape="rect" :src="o.avatar">{{o.name}}</Avatar>
              </div>
            </template>
          </template>
          <template v-if="current.users">
            <template v-for="o in current.groups">
              <div :key="o.key">
                <Avatar shape="rect" :src="o.avatar">{{o.name}}</Avatar>{{o.name}}
              </div>
            </template>
          </template>
        </template>
        <template v-else>
          <div class="flex-wrap" style="width:100%"><Input v-model="filterText" search clearable style="margin:5px;width:calc(100%);flex-wrap:wrap;" /></div>
           
           <div class="flex-wrap" style="max-height:140px;overflow-y:auto">
              <template v-for="o in users">
                <div class="flex-wrap ugo-item-user" :class="{
                  'ugo-item-user-selected':selected && selected.includes(o.id)
                }" :key="o.key" style="" @click="handleClick(o)">
                  <Avatar :src="o.avatar">{{o.name[0]}}</Avatar>
                  <div style="margin-left:5px">{{o.name}}</div>
                </div>
              
              </template>
              </div>
            </template>
     
       
          </div>
         <div class="flex-wrap flex-between" v-if="multiple" style="width:100%;height:40px;padding:0 10px;background:#fff;border-top:1px solid #ddd;">
          <div class="flex-wrap split5">
            <div>已选择 {{selected.length}}</div>
            <Button>全选</Button>
            <Button>取消</Button>
            </div>
             <Button icon="md-checkmark" @click="handleConfirm" type="primary" size="small">确认</Button>
        </div>
      </DropdownMenu>
    </Dropdown>
      
  </div>
</template>

<script>
export default {
  name:"用户选择",
  icon:'xialaliebiao',
  data(){
    return {
      prev:false,
      current:{},
      showDropdown:false,
      filterText:"",
      selected:[]
    }
  },
  mounted(){
    if(this.config && this.config.groups)
      this.current  = {groups:this.config.groups}
    if(this.value)
      this.selected = this.multiple?[...this.value]:(this.value?[this.value]:[])
  
  },
  props:['fkey','config','value','editable'],
  computed:{
    users(){
      if(this.filterText){
        return this.config.users.filter(v=>v.name && v.name.includes(this.filterText))
      }else{
        return this.config.users.filter(v=>v.name)
      }
    },
    multiple(){
      return this.config.multiple
    }
  },
  methods:{
    handleClickOut(e){
      if(!e.path || !e.path.find(v=>v.className=='ivu-dropdown-menu'))
        this.showDropdown = false
    },
    handleClick(o){
      if(this.multiple){
        let index = this.selected.findIndex(v=>v == o.id)
        if(index == -1)
          this.selected.push(o.id)
        else
          this.selected.splice(index,1)
        
      }else{
        this.selected = [o.id]
        this.$emit('input',o.id)
        this.showDropdown = false
      }
    },
    handleConfirm(){
      this.$emit('input',this.selected)
      this.showDropdown = false
    }
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>