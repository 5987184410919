<template>
  <div style="position:fixed;right:10px;bottom:10px;z-index:1000;">
    <div class="l-color-box" v-for="c in themeColors"  :key="c"  >
      <div :style="`width:15px;height:15px;margin-right:10px;flex-shrink:0;border-radius:50%;background:var(--${c})`"></div>
      {{c}}
      
    </div>

  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    computed:{
      ...mapGetters('session',['themeColors'])
    }
  }
</script>

<style lang="less">
.l-color-box{
  width:80px;
  height:20px;
  text-shadow: 1px 1px 1px #fff;
  padding:0 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
</style>