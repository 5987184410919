<template>
  <div class="container flex-wrap flex-line">
    <div style="flex-shrink:0;width:120px;height:100%;border-right:1px solid var(--border);overflow-y:auto;"
    >
    
       <BaseMenu :menus="menus" :actived="actived" @on-click="handleOpen" >
      </BaseMenu>
    </div>
    <div style="width:calc(100% - 120px);height:100%;position:relative;">
      <div class="flex-warp flex-col flex-center" style="position:absolute;left:0;right:0;bottom:0;top:0;"> <BaseLoading style="margin-bottom:2px;" />
            模块加载中</div>
        <router-view style="position:relative;z-index:1;background:var(--bg2);">
         
        </router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import responsableMixin from '@/mixins/responsable'
export default {
  routerOption: {
    as_default: true,
    as_frame:true
  },
  mixins:[responsableMixin],
  mounted(){
    let key = localStorage.getItem("cm-route-key")
    let routeKey = key
    if(!key || !this.menus.find(v=>v.key == key)){
     routeKey = this.menus[0].key
    } 
    if(routeKey != this.actived)
      this.RouteTo(routeKey)
  },
  computed: {
    ...mapGetters('cm', ['permission']),
    sm(){
      return this.w < 1200
    },
    menus(){
      const items = [
        {
          name:"数据总览",
          key:"dashboard",
          icon:"md-speedometer"
        },
        {
          name:"统计报表",
          key:"report",
          icon:"table"
        },
        {
          name:"项目创建",
          key:"contract_old",
          icon:"md-add"
        },{
          name:"项目分配",
          key:"project",
          icon:"jianzhu"
        },{
          name:"项目督办",
          key:"check",
          icon:"mail"
        },
        // {
        //   name:"项目监控",
        //   key:"analysis",
        //   icon:"area-chart"
        // },
        {
          name:"人员分配",
          key:"employee_mg",
          icon:"supervisor"
        },
        // {
        //   name:"人力投入",
        //   key:"employee",
        //   icon:"supervisor"
        // },
        {
          name:'开票管理',
          key:"bill",
          icon:"money"
        },{
          name:'问题反馈',
          key:"debug",
          icon:"info"
        },{
          name:'操作记录',
          key:"log",
          icon:"wancheng",
        },{
          name:"角色权限",
          key:"role",
          icon:"role"
        },{
          name:"系统管理",
          key:"admin",
          icon:"quanxianliebiao"
        },{
          name:"等待删除",
          key:"todel",
          icon:"md-trash"
        },]
        if(this.permission && this.permission.permissions){
          
        return items.filter(v=>this.permission.permissions.find(p=>((p.target_id == v.key || p.target_id == 'all') && p.target_type == 'cm-mod') || (p.target_type == 'all')))
        }else{
          return []
        }
      },
      actived(){
        return this.$route.path.slice(this.$route.path.lastIndexOf('/')+1)
      },
      
  },methods:{
    handleOpen(e){
      this.RouteTo(e)
      localStorage.setItem('cm-route-key',e)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>