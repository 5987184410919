<template>
  <div class="container cm-bill">
    <div class="flex-line l-filter-box">
       <div class="cm-tab":class="{'cm-tab-active':filter_mode=='project'}" @click="filter_mode = 'project'">项目({{projects.length}})</div>
       <div class="cm-tab":class="{'cm-tab-active':filter_mode=='recent'}" @click="filter_mode = 'recent'">近期录入</div>
      <div class="cm-tab" :class="{'cm-tab-active':filter_mode=='todo'}" @click="filter_mode = 'todo'">未审核({{bills.filter(v=>!v.verified_by).length}})</div>
      <div class="cm-tab" :class="{'cm-tab-active':filter_mode=='error'}" @click="filter_mode = 'error'">数据异常({{bills.filter(v=>v.error).length}})</div>
     
      <Input v-model="searchText" style="margin-left:20px" placeholder="查找项目及开票信息(项目编号/名称/负责人)" clearable search />
    </div>
     <div style="height:calc(100% - 70px);margin-top:20px;background:var(--bg3);color:var(--text3);position:relative;" v-if="filter_mode=='project'">
      <BaseEmpty v-if="projects.length == 0">
      暂无开票信息
      </BaseEmpty>
      <div v-else style="height:100%;overflow-x:hidden;overflow-y:auto;">
        <template v-for="p in projects.slice(0,10)">
        <Row class="bill-item" :key="p.id" :gutter="20" align="middle" @click.native="handleOpen(p)"  >
          <Col :span="6">
           <div >{{p.type}} | {{p.status}} </div>
           <div style="font-size:16px;color:var(--primary)">{{p.name}}</div>
          </Col>
           <Col :span="2">
            <div class="flex-col  align-end">
               <div>负责人 </div>
               <div style="font-size:16px">
               <BaseNameBoard :uid="p.charger_id"></BaseNameBoard></div>
               
            </div>
          </Col>
          <Col :span="2">
            <div class="flex-col align-end">
            合同总额(调整后)
            <BaseAmount full :value="p.amount" style="font-size:16px" />
            </div>
          </Col>
          <Col :span="2">
            <div class="flex-col align-end">
            已开票
            <BaseAmount full :value="p.billed_amount" style="font-size:16px" />
            </div>
          </Col>
         
          <Col :span="1">
            <div class="flex-col align-start">
            开票比例
            <Progress :percent="p.amount ? parseInt(p.billed_amount *1000 / p.amount)/10 : 0" status="active" style="width:100px" />
            </div>
          </Col>
           <Col :span="2">
            <div class="flex-col align-end">
            合同余额
            <BaseAmount full :value="p.amount - p.billed_amount" style="font-size:16px" />
            </div>
          </Col>
          <Col :span="2">
            <div class="flex-col align-end">
            已到账
            <BaseAmount full :value="p.transfer_amount" style="font-size:16px" />
            </div>
          </Col>
          <Col :span="1">
            <div class="flex-col align-start">
            到账比例
            <Progress :percent="p.billed_amount ? parseInt(p.transfer_amount *1000 / p.billed_amount)/10 : 0" status="active" style="width:100px" />
            </div>
          </Col>
<Col :span="2">
            <div class="flex-col  align-end">
              本月开票
              <BaseAmount full :value="p.transfer_amount" style="font-size:16px" />
            </div>
          </Col>
          
          <Col :span="2">
            <div class="flex-col  align-end">
              本季度开票
              <BaseAmount full :value="p.transfer_amount" style="font-size:16px" />
            </div>
          </Col>
          <Col :span="2">
            <div class="flex-col  align-end">
              本年开票
              <BaseAmount full :value="p.transfer_amount" style="font-size:16px" />
            </div>
          </Col>
        </Row>
     </template>
    </div>
    </div>
    <div style="height:calc(100% - 70px);margin-top:20px;background:var(--bg3);color:var(--text3);position:relative;" v-else>
      <BaseEmpty v-if="bills.length == 0">
      暂无开票信息
      </BaseEmpty>
      <div v-else style="height:100%;overflow-x:hidden;overflow-y:auto;">
        <template v-for="b in recentBills">
        <Row class="bill-item" :key="b.id" :gutter="20" align="middle" @click.native="handleOpen(b.project)"  >
          <Col :span="11">
           <div >{{b.projectName}} </div>
           <div style="font-size:16px;color:var(--primary)">{{b.conditionName}} {{b.note}}</div>
          </Col>
         
          <Col :span="4">
            <div class="flex-col align-end">
            收费金额
            <BaseAmount full :value="b.amount" style="font-size:16px" />
            </div>
          </Col>
          <Col :span="3">
            <div class="flex-col  align-end">
               <div>开票时间 </div>
               <div style="font-size:16px">{{moment(b.billed_at).format("YYYY/MM/DD")}}</div>
               
            </div>
          </Col>
<Col :span="3">
            <div class="flex-col  align-end">
               <div>录入人 </div>
               <div style="font-size:16px">
               <BaseNameBoard :uid="b.created_by"></BaseNameBoard></div>
               
            </div>
          </Col>
          
          <Col :span="3">
            <div class="flex-col align-end">
              <template v-if="b.verified_at">
               <div>审核人 </div>
              <BaseNameBoard :uid="b.verified_by"></BaseNameBoard>
              </template>
              <div style="font-size:16px;line-height:40px;color:var(--error);" v-else>未审核</div>
             
            </div>
          </Col>
        </Row>
        </template>
      </div>
    </div>

    <Modal v-model="showBill" footer-hide :title="project.name" :width="1400">
      <div class="flex-wrap align-start" style="padding:0 15px"  v-if="project.id">
        <div style="flex-shrink:0;width:400px;margin-right:20px;">
          <BaseSegment title="项目概况">
          <a slot="extra" style="font-weight:normal;font-size:14px;" @click="RouteTo('/groups/'+project.id+'/cm')">项目详情 <BaseIcon icon="md-arrow-forward"></BaseIcon></a>
          <CmProjectInfo :value="project"></CmProjectInfo>
          </BaseSegment>
          <BaseSegment title="合约条款">
          <CmConditions :value="project.conditions" />

        </BaseSegment>
         <BaseSegment title="重要事项">
          <CmMemos :value="project.memos" />
        </BaseSegment>
         <BaseSegment title="收费计划">
         <CmPayplans :value="project.payplans" />
        </BaseSegment>
        </div>
          <div style="flex-grow:1">
        
        <template  v-if="project.bills">
         <BaseSegment :title="`已开票据 (${project.bills.length})`">
         <div slot="extra">
          <Button icon="md-add" @click="showEditor=true;current={}">新增</Button>
         </div>
         <CmBills :value="project.bills" @event="handleEvent" />
         
        </BaseSegment>
      </template>
      <template  v-if="project.transfers">
        <BaseSegment :title="`到账记录 (${project.transfers.length})`">
         <div slot="extra">
          <Button icon="md-add" @click="showEditor=true;current={}">新增</Button>
         </div>
         <CmTransfers :value="project.transfers" @event="handleEvent" />
         
        </BaseSegment>
        </template>
      </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import moment from 'moment'
  import CmBills from '@/components/cm/Bills'
  import IconButton from '@/components/app/IconButton'
  import CmProjectInfo from '@/components/cm/ProjectInfo'
  import CmConditions from '@/components/cm/Conditions'
  import CmPayplans from '@/components/cm/Payplans'
  import CmMemos from '@/components/cm/Memos'
  import CmTransfers from '@/components/cm/Transfers'
  export default {
    components:{
      CmBills,
      IconButton,
      CmProjectInfo,
      CmConditions,
      CmMemos,
      CmPayplans,
      CmTransfers
    },
    data(){
      return {
        searchText:"",
        showBill:false,
        project:{},
        current_bill:{},
        selected:{},
        current:{},
        showEditor:false,
        filter_mode:"recent"
      }
    },
    computed:{
      ...mapGetters("cm",["projects","bills",'conditions','transfers']),
      recentBills(){
        let recentBills = [...this.bills]
        let searchText = this.searchText.trim()
         recentBills.forEach(v=>{
          let project = this.projects.find(p=>p.id == v.contract_id)
          if(project){
          v.project = project
           v.projectName = project.name
          }
          else{
            v.projectName = `项目不存在(${v.contract_id})`
            v.error = true
          }
          
          if(!moment(v.created_at).isValid)
            v.created_at = moment(2020,1,1)

          let condition = this.conditions.find(c=>c.id == v.condition_id)
          if(condition)
            v.conditionName = condition.detail
          else{
            if(v.condition_id == '10086'){
              v.conditionName = '特殊条款'
            }else{
              
            v.conditionName = `合约条款未找到(${v.condition_id})`
             v.error = true
            }
          }
        })
        if(searchText)
          recentBills = recentBills.filter(v=>{
            if(v.projectName && v.projectName.includes(searchText))
              return true
            if(v.conditionName && v.conditionName.includes(searchText))
              return true
            if(v.note && v.note.includes(searchText))
              return true
            return false
        })
        recentBills.sort((a,b)=>moment(a.created_at).isBefore(moment(b.created_at))?1:-1)
        if(this.filter_mode == 'error')
          recentBills = recentBills.filter(v=>v.error)
        else if(this.filter_mode == 'todo')
          recentBills = recentBills.filter(v=>!v.verified_at)
        recentBills = recentBills.slice(0,20)

        return recentBills
      }
    },
    methods:{
      addBill(){
        this.showBillEditor = true
        this.formData = {}
      },
      handleOpen(p){
        this.project = p
         this.showBill=true
      },
      handleEvent({type,data}){
        if(type == 'edit'){
          this.current = data
          this.showEditor = true
        }else if(type == 'delete'){
          this.Confirm(`确定删除此条记录(${data.conditionName}),关联的到账记录也会删除?`,()=>{

          })
        }else if(type == 'select'){
          this.selected = data
        }
      }
    }
  }
</script>

<style lang="less">
.l-filter-box{
  height:50px;
  .ivu-icon{
      color:var(--text2);
      font-size:25px;
      margin:10px;
    }
  .ivu-input{
    height:50px;
    border-radius: 0;
    font-size:16px;
    background:none;
    border-color:var(--border);
    color:var(--text2);
    
  }
}


.bill-item{
  border-bottom:1px solid var(--border);
  padding:10px;
}

.bill-item:hover{
  cursor: pointer;
  border-bottom:1px solid var(--primary);
}

.cm-tab{
  background:var(--bg3);
  color:var(--text3);
  height:100%;
  margin-right:0px;
  border-right:1px solid var(--border);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:16px;
  text-align: center;
  padding:0 15px;
  cursor: pointer;
}

.cm-tab:hover{
  background:#def;
}

.cm-tab-active,.cm-tab-active:hover{
  background:var(--bg1);
  color:var(--text1);
}
</style>