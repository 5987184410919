<template>
  <BaseLayout 
    :panels="session.employeeInfo ? panels:personal_panels" 
    :gutter="5" 
    :padding="10" 
    style="background:linear-gradient(to right,var(--border),var(--bg3));"
  >
    <div style="position:absolute;left:0;width:calc(21% + 8px);background:var(--bg1);bottom:0;top:0;border-left:1px solid var(--border);background-size:cover;border-right:3px solid var(--border);"
    :style="this.windowWidth < 1400?'width:calc(40% + 3px)':''"
    ></div>
    <div v-if="this.windowWidth > 1400" style="position:absolute;right:0;width:calc(25% + 12px);background:var(--bg2) linear-gradient(to right,var(--border),var(--bg2));bottom:0;top:0;background-blend-mode:multiply;background-size:cover;border-left:3px solid var(--border);"
    :style="this.windowWidth < 1400?'width:calc(40% + 3px)':''"
    ></div>
    <!-- <MileStone v-if="showMileStone" @cancel="showMileStone=false"/> -->
  </BaseLayout>
</template>

<script>
  import MileStone from '@/components/milestone'
  export default {
    components:{MileStone},
    routerOption:{
      as_default:true
    },
    data(){
      return {
        showMileStone:true
      }
    },
    computed:{
       mobile(){
        return this.$store.getters['session/mobile']
      },
      windowWidth(){
        return this.$store.getters['session/windowWidth']
      },
      session(){
        return this.$store.getters['session/session']
      },
      personal_panels(){
        return [{
          x:0,y:0,w:5,h:2,control:"PanelWeather"
        },
          {
          x:0,y:2,w:5,h:1.5,control:"PanelNotice"
        },
        {
          x:18,y:0,w:6,h:4.5,control:"PanelCalendar"
        },
        {
          x:0,y:3.5,w:5,h:4,control:"PanelAccelerators"
        },
        {
          x:5,w:13,y:0,h:11,control:"PanelMemo"
        },
        {
          x:18,y:4.5,w:6,h:6.5,control:"PanelTasks"
        },]
      },
      panels(){
        if(this.windowWidth < 600){
           return [
           {
          x:0,y:0,w:24,h:2,control:"PanelWeather"
          },
            {
            x:0,y:2,w:24,h:1,control:"PanelNotice"
          },
          {
            x:0,y:3,w:24,h:3.5,control:"PanelCalendar"
          },
          {
            x:0,y:6.5,w:24,h:4.5,control:"PanelAccelerators"
          },
          {
            x:0,y:11,w:24,h:5,control:"PanelGroups"
          },
          {
            x:0,y:16,w:24,h:1.5,control:"PanelLinks"
          },
          {
            x:0,y:17.5,w:24,h:4.5,control:"PanelTasks"
          },
        // {
        //   x:6,y:0,w:12,h:7,control:"PanelCalendar"
        // }
        ]
        }else if(this.windowWidth < 1400){
          return [
           {
          x:9.8,y:0,w:14.2,h:0.3,control:"PanelWeatherStatus"
          },
          {
            x:0.2,y:0,w:9.2,h:1,control:"PanelCaption"
          },
            {
            x:0.2,y:1,w:9.2,h:1.3,control:"PanelNotice"
          },
          {
            x:0.2,y:2.4,w:9.2,h:4.5,control:"PanelAccelerators"
          },
          
          {
            x:0.2,y:6.9,w:9.2,h:4.1,control:"PanelGroups"
          },
        
          {
            x:9.8,y:4,w:14.2,h:4.5,control:"PanelStudy"
          },
          {
          x:9.8,y:0.5,w:14.2,h:3,control:"PanelCNotices",data:{
             key:"notice",
            title:"通知公告",
            icon:"md-notifications"
          }
        },
        
        
        // {
        //   x:6,y:0,w:12,h:7,control:"PanelCalendar"
        // }
        ]
        }else{
          return [
        //    {
        //   x:0,y:2.5,w:6,h:0.7,control:"PanelLinks"
        // },
        {
          x:5.1,y:0,w:18.7,h:0.3,control:"PanelWeatherStatus"
        },
          {
          x:0,y:0.8,w:5,h:1.3,control:"PanelNotice"
        },
        {
          x:18,y:0.35,w:6,h:3,control:"PanelCalendar",data:{
            hideDate:true
          }
        },
        {
          x:0,y:2.1,w:5,h:4,control:"PanelAccelerators"
        },
        {
          x:0,y:0,w:5,h:0.8,control:"PanelCaption"
        },
        {
           x:5.2,y:0.35,w:6,h:2.65,control:"PanelCNotices",data:{
             key:"notice",
            title:"通知公告",
            icon:"md-notifications"
          }
        },
        {
           x:5.2,y:3.4,w:6,h:3.1,control:"PanelCRules",data:{
             key:"notice",
            title:"通知公告",
            icon:"md-notifications"
          }
        },
        {
           x:11.6,y:3.4,w:6,h:3.1,control:"PanelCExp",data:{
             key:"notice",
            title:"通知公告",
            icon:"md-notifications"
          }
        },
        {
          x:11.6,y:0.35,w:6,h:3,control:"PanelCActivity"
        },
        {
          x:5.2,y:6.5,w:12.5,h:4.5,control:"PanelCompanyActivity"
        },
        {
          x:18,y:6.35,w:6,h:4.65,control:"PanelStudy"
        },
        // {
        //   x:18,y:5.7,w:6,h:4.2,control:"PanelMeetingRooms"
        // },
        //   {
        //   x:5.1,y:11.5,w:6.4,h:3.3,control:"PanelDownload"
        // },
        //   {
        //   x:5.1,y:7.5,w:6.4,h:4,control:"PanelCNotices",data:{
        //     key:"recomend",
        //     title:"文章推荐"
        //   }
        // },
        {
          x:0,y:6.1,w:5,h:4.9,control:"PanelGroups"
        },
       
        {
          x:18,y:3.35,w:6,h:3,control:"PanelFlows"
        },
       
        // {
        //   x:17,y:0,w:7,h:11,control:"PanelMemo"
        // },
       
        
        // {
        //   x:6,y:0,w:12,h:7,control:"PanelCalendar"
        // }
        ]
        }
        
      }
    }
  }
</script>
