<template>
<div class="container" style="overflow-y:auto;height:100%;">
  <div class="ugo-page" style="width:1000px;margin:40px auto;background:var(--bg2);border-radius:10px;border:1px solid var(--border);overflow:hidden;">
    
    <BaseEmpty v-if="loading">
    <BaseLoading />
    </BaseEmpty>
    <div v-else>
      
      <div class="flex-wrap flex-line align-start" style="align-items:stretch">
      <div style="flex-grow:1;padding:40px;">
        <h2 class="flex-wrap"><Avatar :src="user.avatar" :size="40" style="margin-right:20px">{{user.name ? user.name[0]:''}}
      
      </Avatar>
      <div><div>{{user.name}}<span style='font-size:18px;margin-left:5px;'>的动态</span></div>
      <div class="flex-wrap" style="font-weight:normal;font-size:12px;margin-top:5px;">
        <div class="flex-wrap flex-center" style="background:var(--primary);color:var(--hover-text);border-radius:50%;width:16px;height:16px;margin-right:3px"><BaseIcon icon="organization"  size="10" /></div>{{(deps.find(d=>d.id == employee.main_dep) || {}).name}}
        <div class="flex-wrap flex-center" style="background:var(--primary);color:var(--hover-text);border-radius:50%;width:16px;height:16px;margin-right:3px;margin-left:30px;"><BaseIcon icon="phone"  size="10" /></div>{{user.phone}}</div>
      </div></h2>
      
    <Divider style="margin:15px 0" />
        <BaseBoardSubTitle name="员工信息（仅内部查看）" style="margin-bottom:10px" />
        <div style="height:200px">
      <div class="flex-wrap">
        <div style="margin-right:20px">入职时间</div>
        <div>{{employee.joinwork_time ? moment(employee.joinwork_time).format("YYYY-MM-DD"): '-'}}</div>
        
        </div>
        
        </div>
        <BaseBoardSubTitle name="个人动态" style="margin-bottom:10px" />
        <div style="margin-bottom:15px;color:var(--subtext3);height:100px;">无</div>

        
        <template v-if="in_projects.length > 0">
      <BaseBoardSubTitle name="当前项目 (进行中)" style="margin-bottom:10px" />
      <div class="flex-wrap">
        <div v-for="p in in_projects" :key="p.id" class="flex-col justify-start" style="width:220px;height:220px">
          <img :src="p.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/files/ref/2023-08-14/6566349d-6768-461f-844e-d9b9c450db0a.webp'" style="width:200px;height:140px;border-radius:5px;box-shadow:1px 1px 2px 3px var(--border)" />
          <div style="font-size:12px;margin:10px;">
          {{p.name}}</div>
        </div>
      </div>
        </template>

      <template v-if="out_projects.length > 0">
      <BaseBoardSubTitle name="历史项目 (已竣工)" style="margin-bottom:10px" />
      <div class="flex-wrap" style="flex-wrap:wrap">
        <div v-for="p in out_projects" :key="p.id" class="flex-col justify-start" style="width:220px;height:220px">
          <img :src="p.avatar || 'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/files/ref/2023-08-14/6566349d-6768-461f-844e-d9b9c450db0a.webp'" style="width:200px;height:140px;border-radius:5px;box-shadow:1px 1px 2px 3px var(--border)" />
          <div style="font-size:12px;margin:10px;">
          {{p.name}}</div>
        </div>
      </div>
      </template>
      </div>
      <div class="flex-col" style="margin-left:40px;width:200px;background:var(--bg1);padding:40px;color:var(--hover-text);">
        <img :src="employee.photo || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/icon/employee.png'" style="width:140px;border-radius:5px;box-shadow:1px 1px 2px 1px var(--border)" />

        <div class="flex-col" style="margin-top:55px">
          <div>成就系统</div>
          <div style="font-size:30px;color:orange;"> (开发中)</div>
        </div>

        <div class="flex-col" style="margin-top:55px">
          <div>勋章墙</div>
          <div style="font-size:30px;color:orange;">
            (开发中)
          </div>
        </div>
      </div>
        </div>

    </div>

  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
  export default {
    data(){
      return {
        user:{},
        loading:true
      }
    },
    routerOption:{
      as_object:true,
      as_frame:true
    },
    computed:{
      ...mapGetters("session",['deps']),
      id(){
        return this.$route.params.id
      },
      in_projects(){
        if(Array.isArray(this.user.projects))
          return this.user.projects.filter(v=>['准备中','进行中'].includes(v.status))
        else
          return []
      },
      out_projects(){
        if(Array.isArray(this.user.projects))
          return this.user.projects.filter(v=>['准备中','进行中'].includes(v.status) == false)
        else
          return []
      },
      employee(){
        return this.user.employeeInfo || {}
      }
    },
    metaInfo:{
      title:"员工动态"
    },
    mounted(){
      this.$store.dispatch("session/getDeps")
      this.$store.dispatch("session/getUsers")
      setTimeout(()=>{
        this.getData()
      },2000)
    },
    methods:{
      getData(){
        this.user = this.$store.getters["session/users"].find(v=>v.id == this.id)
        this.$api.get("oa/employees/"+this.id).then(res=>{
          let user = res.data.data

          if(user.projects){
            user.projects.forEach(v=>{
              if(v.images){
                v.images = JSON.parse(v.images)
                if(Array.isArray(v.images) && v.images.length > 0){
                  v.avatar = v.images[0]
                }
              }
            })
          }

          this.user = user
        }).finally(()=>{
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>