<template>
    <Container class="flex-col align-start">
      <h2 style="margin:10px 20px">用印申请
        <div style="font-size:12px;color:var(--subtext3);font-weight:bold;">
            本模块为【用印申请】流程模块
        </div>
      </h2>
      <div class="flex-wrap" 
        style="padding:10px 20px;height:100px;gap:10px;width:100%;">
        <div class="flex-wrap" style="padding:5px;gap:5px;background:var(--border);border-radius:5px;">
        <template v-for="(s,i) in states">
          <div 
            class="tab-card m-light"
            :class="{
              'tab-card-active':i == filter_mode
            }"
            @click="filter_mode = i"
            :key="s.name">
            <div class="count" :style="`color:${s.color}`">
                {{s.count}}
              
            </div>
            <div class="name">{{s.name}}</div>
          </div>
          
        </template>
        </div>
       
      </div>
      <div class="flex-wrap flex-between" style="margin:10px 20px;width:calc(100% - 40px);">
      <div class="flex-wrap">
        <Input search placeholder="输入名称搜索" /> <Button icon="md-refresh" style="margin-left:10px" @click="getData()"></Button>
      </div><div><Button icon="md-add">发起流程</Button></div>
      </div>
      <div style="padding:0 20px;height:calc(100% - 40px);width:100%;">
        <BaseTable ref="table" :columns="cols" :data="items" :loading="loading" :count="312" />

      </div>
    </Container>
  </template>
  <script>
    import Container from '@/components/app/container'
    import Toolbar from '@/components/app/toolbar'
    import moment from 'moment'
  export default {
    components:{Container,Toolbar},
    data(){
      return {
        filter_mode:0,
        filter_type:null,
        page:1,
        pagesize:20,
        loading:false,
        states:[{
          name:'我的流程',
          new_count:3,
          color:'var(--orange)',
          count:7
        },{
          name:'待审批',
          new_count:3,
          color:'var(--primary)',
          count:4
        },{
          name:"已通过",
          count:123,
          color:'var(--success)',
          new_count:2
        },{
          name:"与我相关",
          count:243
        }],
        items:[]
      }
    },
    mounted(){
      this.getData()
    },
    computed: {
      filteredItems(){
        if(this.filter_type !== null)
          return this.items.filter(v=>v.type == this.filter_type)
        else
          return this.items
      },
      cols(){
        return [{
          title:"序号",
          width:60,
          type:"index"
        },{
          title:"用印日期",
          width:120,
          render:(h,{row})=>{
            return h('div',{style:"color:var(--success)"},row.signed_at?moment(row.signed_at).format("L"):'-')
          }
        },{
          title:"使用内容",
          width:120,
          render:(h,{row})=>{
            return h('div',{style:"color:var(--success)"},'合同签订')
          }
        },{
          title:"合同名称",
          minWidth:140,
          key:"name",
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:'contract'},style:"margin-right:3px;color:var(--primary)"})
            let name = h('a',row.name)
            return h('div',{class:"flex-wrap"},[icon,name])
          }
        },{
          title:"对方单位",
          width:240,
          key:"name",
          render:(h,{row})=>{
            let name = h('a',row.partA)
            return h('div',{class:"flex-wrap"},[name])
          }
        },{
          title:"合同金额",
          width:140,
          key:"name",
          render:(h,{row})=>{
            return h('BaseAmount',{props:{value:row.amount}})
          }
        },{
          title:"合同文件",
          width:120,
          key:"name",
          sortable:false,
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:'md-download'},style:'margin-right:3px'})
            return h('a',[icon,'下载'])
          }
        },{
          title:"申请人",
          width:120,
          type:'user',
          key:"created_by"
        },{
          title:"审批人",
          width:120,
          type:'user',
          key:"checked_by"
        },{
        title:"审核意见",
        width:120,
        type:'user',
        key:"result",
        render:(h,{row})=>{
            
          return h('span',{style:{color:row.passed?'var(--success)':'var(--error)'}},[row.passed])
        }
      },{
          title:"流程",
          width:120,
          render:(h,{row})=>{
            let icon = h("BaseIcon",{props:{icon:"lianjieliu"},style:"margin-right:3px"})
            let buttons = [h('Button',{props:{size:"small"}},[icon,"审批记录"])]
            return h('div',{class:"flex-wrap flex-center"},buttons)
          }
        }]
      }
        
    },
    methods:{
        getData(){
        if(this.timer){
          clearTimeout(this.timer)
          this.loading = true
          
        }
        this.timer = setTimeout(()=>{
          const base_url = `contracts-m/seal_approval_flows`
          let url = base_url
        
          this.$api.get(url).then(res=>{
            this.items = res.data.data
          }).catch(e=>{
            this.Error(e)
          }).finally(()=>{
            setTimeout(()=>{
              
            this.loading= false
            },300)
          })
        },100)
       
       
      },
    }
  }
  </script>
  
  <style lang="less">
    .tab-card{
      padding:5px 10px;
      height:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background:var(--bg3);
      border-right:none;
      cursor: pointer;
      border-radius: 5px;
      .count{
        font-size:30px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      
    }


    .tab-card:hover{
      background:var(--primary);
      color:var(--hover-text);
      .count,.name{
        color:var(--hover-text) !important;
      }
    }

    .tab-card-active{
      background:var(--primary);
      color:var(--hover-text);
      .count,.name{
        color:var(--hover-text) !important;
      }
    }
  </style>