<template>
    <router-view></router-view>
</template>

<script>
  export default {
    name:"itasks",
    routerOption:{
      as_frame:true,
      as_object:true
    }
  }
</script>