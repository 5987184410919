<template>
  <FormItem :label="label" :required="required">
    <Upload>
       <Button icon="ios-cloud-upload-outline">上传文件</Button>
    </Upload>
  </FormItem>
</template>

<script>
  export default {
    name:"文件",
    icon:'fujian',
    properties:[],
    props:['label','value','required']
    
  }
</script>

<style lang="scss" scoped>

</style>