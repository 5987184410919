<template>
	<div class="container l-cnotices m-light">
		<div
      class="flex-wrap flex-between m-panel-caption"
			
		>
			<BaseIcon :icon="data.icon || 'nbgz'" size="20"  class="icon"/> 
      <BaseIcon :icon="data.icon || 'nbgz'" size="50" color="var(--border)" class="b-icon" /> 
      {{ data.title || "通知公告" }}
      <BaseIcon icon="ios-arrow-forward" @click="RouteToApp" size="20" color="var(--subtext3)" style="cursor:pointer"  class="l-hoverable" />
		</div>
        <div class="flex-wrap" style="height:40px;padding:0 10px;" v-if="!data.success">
            <Button  size="small" icon="md-add"></Button>
        </div>
		<BaseLoading v-if="loading"> </BaseLoading>
    <BaseEmpty v-else-if="filteredItems.length == 0" msg="这个板块还没有内容上传" />
		<template v-else>
            <Row align="middle" style="padding:5px 0;background:var(--bg2);border-bottom:1px solid var(--border)">
                <Col :span="2" align="center">优先级</Col>
                
                <Col :span="2" align="left">负责人</Col>
                <Col :span="8" align="left">工作内容</Col>
                
                <Col :span="4" align="center">备注</Col>
                <Col :span="2" align="center">计划开始</Col>
                <Col :span="2" align="center">计划完成</Col>
                <Col :span="2" align="center">状态</Col>
                <Col :span="2" align="center">操作</Col>
            </Row>
			<template v-for="item in filteredItems">
                
                <Row align="middle" :key="'notice_' + item.id" style="padding:3px 0;border-bottom:1px dashed var(--border)" :class="`task-state-${item.state}`">
                    <Col :span="2" align="center"><div class="flex-wrap flex-center l-code" v-if="priorities[item.priority]" :style="`width:40px;text-align:center;background:${priorities[item.priority || 0].color};color:var(--hover-text);margin:0 10px`">{{priorities[item.priority || 0].name}}</div></Col>
                     
                <Col :span="2" align="center"><BaseNameBoard :uid="item.charger" /></Col>
                    <Col :span="8" align="left"><a>{{ item.name }}</a></Col>
                
              
               
                <Col :span="4" align="center">{{ item.note }}</Col>
                <Col :span="2" align="center"><div :style="`background:var(--border);color:var(--text3);width:60px;border-radius:3px;font-size:12px;font-weight:bold;`">
						{{ moment(item.date || item.created_at).format("MM/DD") }}
					</div></Col>
                <Col :span="2" align="center"><div :style="`background:var(--border);color:var(--text3);width:60px;border-radius:3px;font-size:12px;font-weight:bold;`">
						{{ moment(item.date || item.created_at).format("MM/DD") }}
					</div></Col>
                <Col :span="2" align="center"><div :style="`background:#00000033;color:var(--hover-text);width:60px;border-radius:3px;`">{{states[item.state]}}</div> </Col>
                <Col :span="2" align="center">
                <div class="flex-wrap flex-center split5">
                    <Button type="success"  icon="md-checkmark" size="small" style="border-radius:50%;"></Button>
                    <Button type="primary"  icon="md-create" size="small" style="border-radius:50%;"></Button>
                    <Button type="error" icon="md-remove" size="small" style="border-radius:50%;"></Button>
                    </div></Col>
            </Row>
            <div :key="item.id" v-if="item.last_event" style="padding:3px 30px;padding-left:17%;color:var(--success);font-size:10px;">
                <BaseIcon icon="md-return-right" style="margin-right:5px;" />{{ moment(item.last_event.created_at).fromNow() }} : {{item.last_event.detail}} 
            </div>
            </template>
		</template>
	</div>
</template>
  
  <script>

export default {
	data() {
		return {
			loading: false,
			items: [{
                id:1,
                name:"确定食堂设计图纸，特别是包厢设计",
                started_at:"2023/3/6",
                plan_finished_at:"2023/5/6",
                contacts:"行政部、余姚支行",
                priority:0,
                contacts2:"地标院、厨具深化单位",
                note:"燃气铺管前须完成",
                charger:'ed4a5bf7-3b83-11eb-8e1e-c15d5c7db744',
                state:2,
                last_event:{
                    detail:'已跟支行提出建议,预计5月中旬提交',
                    created_at:'2023-08-13'
                }
            },{
                id:2,
                name:"确定8至11层细水雾厂家，由厂家出深化图纸",
                started_at:"2023/3/6",
                plan_finished_at:"2023/4/21",
                contacts:"运营部",
                priority:0,
                contacts2:"总包单位、生产厂家考察",
                note:"设备品牌待确定",
                charger:'ed4a5bf7-3b83-11eb-8e1e-c15d5c7db744',
                state:0,
                last_event:{
                    detail:'细水雾图纸已完成',
                    created_at:'2023/8/1'
                }
            },{
                id:3,
                name:"泛光照明方案备案",
                started_at:"2023/3/6",
                plan_finished_at:"2023/4/21",
                contacts:"",
                priority:0,
                contacts2:"规划局、路灯所",
                note:"设备品牌待确定",
                charger:'ed4a5bf7-3b83-11eb-8e1e-c15d5c7db744',
                state:1,
                last_event:{
                    detail:'已沟通对接，方案可行',
                    created_at:'2023/8/1'
                }
            },{
                id:4,
                name:"地上原安装图纸与精装修图纸不一致，需明确按照按哪套图纸施工",
                started_at:"",
                plan_finished_at:"2023/7/11",
                contacts:"基建办",
                priority:2,
                contacts2:"",
                note:"设备品牌待确定",
                charger:'ed4a5bf7-3b83-11eb-8e1e-c15d5c7db744',
                state:1,
                last_event:{
                    detail:'平面布局确认中',
                    created_at:'2023/8/1'
                }
            }],
		};
	},
	created() {
		this.getData();
		if(this.data.key){
			
		this.$bus.$on('article-'+this.data.key,()=>{
			console.log('on-emit')
		this.getData()
		})
		
		}
	},
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
    computed:{
        filteredItems(){
            if(this.data.success){
                return this.items.filter(v=>v.state == 1)
            }else{
                return this.items.filter(v=>v.state !== 1)
            }
        },  
        priorities(){
        return [{
          name:'普通',
          color:'var(--bg1)'
        },{
          name:'重要',
          color:'var(--primary)'
        },{
          name:'紧急',
          color:'var(--error)'
        }]
      },
      states(){
        return ['进行中','已完成','已逾期']
      },
      stateColors(){
        return ['var(--primary)','var(--success)','var(--error)']
      }
    },  
	methods: {
		getData() {
			// if (this.items.length == 0) {
			// 	setTimeout(() => {
			// 		this.loading = true;
			// 		this.$api
			// 			.get(`articles?t=${this.data.key || "notice"}`)
			// 			.then((res) => {
			// 				this.items = res.data.data;
			// 			})
			// 			.finally(() => {
			// 				this.loading = false;
			// 			});
			// 	}, 500);
			// }
		},
		RouteToApp(){
			let base_url = "/core/tasks"
			let url = base_url + "?t="+(this.data.key || 'notice')
			this.$store.commit("session/push", {
				name: '文章库',
				icon: "md-bookmarks",
				url,
				base_url,
			});
			this.RouteTo(url, false);
		},
		handleOpen(item) {
			let url = "/core/articles/" + item.id;
			this.$store.commit("session/push", {
				id: item.id,
				name: item.name,
				icon: "md-document",
				url,
				base_url:url,
			});
			this.RouteTo(url, false);
		},
	},
};
</script>
<style lang="less">
.task-state-1{
    background:linear-gradient(to right,#ffffff00,#ffffff00,#ffffff00,#ffffff00,#ffffff00,var(--success));
}
.task-state-0{
    background:linear-gradient(to right,#ffffff00,#ffffff00,#ffffff00,#ffffff00,#ffffff00,var(--primary));
}
.task-state-2{
    background:linear-gradient(to right,#ffffff00,#ffffff00,#ffffff00,#ffffff00,#ffffff00,var(--error));
}
</style>
  