

<template>
  <App class="pm-editor" :loading="loading" :ready="is_ready">
    <AppBar>
      <div class="flex-wrap" style="color:#333">
        正在编辑 {{ task.name || '新考核' }}
      </div>
      <div class="flex-wrap">
        <IconButton icon="md-undo" :disabled="!can_undo" />
        <IconButton icon="md-redo" :disabled="!can_redo" />

        <template v-if="actions.length > 0">
          <Divider type="vertical" />
          <template v-for="a in actions">
            <IconButton
              :key="a.name"
              :icon="a.icon"
              @on-click="$store.commit('task/do',{action:a.action})"
            />
          </template>
        </template>
        <Divider type="vertical" />
        <IconTabs :items="views" :current="view" @on-select="view=$event" />
      </div>
      <div class="flex-wrap split5">
        <Button  @click="RouteTo('dashboard')">预览</Button>
       
        <Button type="primary" @click="RouteTo('dashboard')">保存并上传</Button>
      </div>
    </AppBar>
    
    <AppRight :collapse="!is_ready || !showHelper">
      {{selected}}
      <BaseEmpty v-if="!selected.help" />
      <div class="pm-help" v-html="selected.help" style="padding:10px;white-space:pre-wrap;"></div>
    </AppRight>

    <AppClient
      :collapse-left="is_ready && showMenu"
      :collapse-right="is_ready &&showHelper"
      v-if="is_ready"
    >
        <AppraisalEditorCore />
    </AppClient>
  </App>
</template>
    
    <script>
import responsableMixin from "@/mixins/responsable";
import App from "@/components/app/app";
import AppBar from "@/components/app/bar";
import AppMask from "@/components/app/mask";
import TaskLeft from "@/components/task/editor/left";
import AppRight from "@/components/app/right";
import AppAvatar from "@/components/app/avatar";
import AppClient from "@/components/app/client";
import IconButton from "@/components/app/IconButton";
import IconTabs from "@/components/app/IconTabs";
import TaskMenu from "@/components/task/app/bar/TaskMenu";
import TaskFileName from "@/components/task/app/bar/TaskFileName";
import AppraisalEditorCore from '@/components/gojs/AppraisalEditor'
export default {
  components: {
    App,
    AppBar,
    AppMask,
    TaskLeft,
    AppRight,
    AppClient,
    AppAvatar,
    IconButton,
    IconTabs,
    TaskMenu,
    TaskFileName,
    AppraisalEditorCore
  },
  data() {
    return {
      loading: false,
      is_ready:true,
      showMenu: true,
      selected:{},
      actions:[],
      view: "task",
      task: {
        name: "新任务"
      },
      showHelper: true
    };
  },
  mixins: [responsableMixin],
  watch: {
    $route: {
      handler(v) {
        this.getData(this.$route.params.id);
      },
      immediate: true,
      deep: true
    }
  },
  activated() {
    this.$nextTick(e => {
      this.calcLayout();
    });
  },
  routerOption: {
    as_object: true
  },
  computed: {
    views() {
      const _views = [
        {
          key: "task",
          name: "执行",
          icon: "md-create"
        },
        {
          key: "flow",
          name: "流程",
          icon: "lianjieliu"
        },
        {
          key: "gantte",
          name: "管理",
          icon: "xiangmu"
        },
        {
          key: "file",
          name: "资料",
          icon: "folder"
        },
        {
          key: "share",
          name: "分享",
          icon: "share1"
        }
      ];
      let o = _views;
    

      return o;
    },

    admin() {
      return this.role == "admin";
    },
    permissions() {
      if (this.role == "admin") {
        return true;
      } else return {};
    },
    can_undo(){
        return false
    },
    can_redo(){
        return false
    },
  },
  methods: {
    getData(id) {
      if (this.threadGetData) return;
      this.threadGetData = setTimeout(() => {
        this.loading = true;
        this.$api
          .get(`oa/appraisals/${id}`)
          .then(res => {
            this.task = res.data.data;
          })
          .finally(() => {
            this.loading = false;
            delete this.threadGetData;
          });
      }, 500);
    }
  }
};
</script>
    
    <style lang="less">
.pm-panel-flex {
  display: flex;
}
.pm-panel-editing-wrap:hover {
  filter: brightness(1.2);
  cursor: pointer;
}

.pm-panel-selected {
  background: #fbbd2162 !important;
}
</style>