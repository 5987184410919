<template>
  <div class="container flex-line align-start m-theme" :class="{[theme_class]:true,
  'client-mobile':mobile}" style="align-items:stretch"
  >
    <app-bar v-if="!mobile" />
    <div style="height:100%;width:100%;position:relative;overflow:hidden;">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
    <!-- <BaseColorHelper /> -->
    <!-- <app-bar-mobile v-if="mobile" /> -->
  </div>
</template>

<script>
  import AppBar from '@/components/appbar'
  import AppBarMobile from '@/components/appbar-mobile'

  export default {
    routerOption:{
      as_frame:true
    },
    components:{AppBar,AppBarMobile},
    
    computed:{
      theme_class(){
        return 'm-'+this.$store.getters['session/theme']
      },
      mobile(){
        return this.$store.getters['session/mobile']
      }
    },
    mounted(){
       this.$store.commit('session/resize')
    },
    methods:{
      
    },
    created(){
      this.$bus.$once('401',()=>{
        this.$store.commit('session/save_login_url',this.$route.path)
        
        this.RouteTo('/')
      })

      this.$bus.$once('login',()=>{
        console.log("logined")
        this.$store.dispatch('session/getUsers')
      })

      var that = this
      if(this.resizeTimer)
        clearTimeout(this.resizeTimer)

      window.addEventListener('resize',(e)=>{
        that.resizeTimer = setTimeout(()=>{
          that.$bus.$emit('resize',{})
          that.$store.commit('session/resize',e)
          that.$forceUpdate()
        })
      })

      if(this.mobile){
        document.body.addEventListener('touchmove',(e)=>{e.preventDefault()},false)
      }
    }
  }
</script>

<style lang="less">
.client-mobile{
  flex-direction: column;
  
}
</style>