
<template>
  <transition name="fade">
    <template v-if="initing">
      <div class="l-loading">
        <div style="font-size:10px">{{error || "系统初始化中"}}</div>
      <div style="font-size:30px">若本页面停留过长，建议更换浏览器进行访问 </div>
      <div><a href="http://zzlatm.gicp.net:10000/public/upload/20190916/20190916_5d7f2f83da6e8.exe">Google Chrome</a></div>
      
      </div>
    </template>
    <template v-else>
      <router-view />
    </template>
  </transition>
</template>

<script>
  export default {
    name: 'App',
    data(){
      return {
        initing:false,
        error:""
      }
    },
    metaInfo: {
      title: '首页',
      titleTemplate: '%s | 爱高专ㆍ爱生活',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    watch:{
      $route:{
        handler(v){
          this.$store.commit("session/update",v.path)
        }
      }
    },
    created(){
      this.$bus.$on('401',e=>{
        console.log('401')
        this.$store.commit('session/logout')
      })
    }
  }
</script>

<style lang="less">
  @import "../public/css/styles.less";
  @import "../public/css/theme.less";
  @import '~animate.css';
  .l-loading{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:#346;
    color:#fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
</style>


