<template>
  <div class="flex-wrap flex-center split5">
    <template v-if="!editable && !value">
      -
    </template>
    <template v-for="i in [1,2,3]">
      <BaseIcon icon="star" :key="i" class="u-star" :class="('u-star-'+i)+ (value==i?(' u-star-selected u-star-selected-'+i):'')" @click="handleSelect(i)" v-if="editable || value==i" />
    </template>
  </div>
</template>

<script>
  export default {
    props:{
      value:{
        type:Number,
        default:0
      },
      editable:{
        type:Boolean,
        default:false
      }
    },
    methods:{
      handleSelect(i){
        if(!this.editable)
          return

        if(this.value == i){
           this.$emit('input',0)
        }else{
          this.$emit('input',i)
          this.$forceUpdate()
        }
       
      }
    }
  }
</script>

<style lang="less">
.u-star{

}


.u-star-selected:before,.u-star:hover:before{
  cursor: pointer;
  content:'\e620'
}

.u-star-1:hover,.u-star-selected-1:before{
  color:rgb(39, 118, 198) !important;
}

.u-star-2:hover,.u-star-selected-2:before{
  color:orange !important;
}

.u-star-3:hover,.u-star-selected-3:before{
  color:darkred !important;
}
</style>