<template>
    <Modal :value="value" footer-hide title="选择项目" :width="580" @on-visible-change="$emit('input',$event);value=$event">
     <div class="flex-wrap" style="width:100%;margin-bottom:10px;"><Input v-model="filterText" search clearable style="width:220px;"  size="small" />
     <ButtonGroup style="margin-left:5px">
        <Button v-for="s in status_list" size="small" :type="status==s?'primary':'default'" :key="s" @click="status==s?(status=null):(status = s)">{{s}}</Button>
     </ButtonGroup>
     </div>
      <div  style="width:calc(100%);height:300px;overflow-y:auto;">
        <div class="flex-wrap" style="overflow-y:auto;flex-wrap:wrap;overflow-x:hidden;gap:1px;">
        <template v-for="o in filteredGroups">
           <div class="flex-wrap ugo-item-user" :class="{
             'ugo-item-user-selected':selected && selected.includes(o.id)
           }" :key="o.id" style="width:100%;height:auto;border-bottom:1px solid var(--border);padding:5px 0;" @click="handleClick(o)">
             <div style="margin-left:5px">
               <div>{{o.name}}</div>
               <div style="color:var(--subtext2);font-size:12px">{{o.type}}</div>
               </div>
           </div>
         
         </template>
         </div>
        
         </div>
  
  
    <div class="flex-wrap flex-between" v-if="multiple" style="width:100%;height:40px;padding:0 10px;background:var(--bg3);border-top:1px solid #ddd;" @click.stop>
     <div class="flex-wrap split5">
       <div>已选择 {{selected.length}}</div>
       <Button size="small" @click.stop="handleSelectAll">全选</Button>
       <Button size="small" @click.stop="handleClear">取消</Button>
       </div>
        <Button icon="md-checkmark" @click="handleConfirm" type="primary" size="small">确认</Button>
   </div>
    </Modal>
  </template>
  
  <script>
  import {mapGetters} from 'vuex'
  export default {
      data(){
          return {
              prev:false,
        current:{},
        filterText:"",
        selected:[],
        status:'进行中',
        status_list:['准备中','进行中','已竣工','已结束','已关闭']
          }
      },
      props:{
          value:{
              type:Boolean,
              default:false
          },
          as_object:{
            type:Boolean,
            default:true
          },
          multiple:{
              type:Boolean,
              default:false
          },
          top_dep_id:{
            type:String | Number,
          },
          included:{
            type:Array,
            default:()=>([])
          },  
          excluded:{
              type:Array,
              default:()=>([])
          }
      },
      mounted(){
          setTimeout(()=>{
        this.$store.dispatch("session/getProjects")
      },500)
      },
      computed:{
      ...mapGetters("session",["projects"]),
      filteredGroups(){
        let items = this.projects
        if(Array.isArray(this.excluded) && this.included.length > 0){
            items = items.filter(d=>this.included.includes(d.id))
        }
        if(Array.isArray(this.excluded) && this.excluded.length > 0){
            items = items.filter(d=>this.excluded.includes(d.id) == false)
        }
        if(this.top_dep_id){
            items = items.filter(d=>d.top_dep_ids && d.top_dep_ids.includes(this.top_dep_id))
        }
        if(this.filterText)
            items = items.filter(d=>d.name && d.name.includes(this.filterText.trim()))

        if(this.status){
            items = items.filter(d=>d.status == this.status)
        }
        return items
      },
      item(){
        return this.projects.find(v=>v.id == this.value) || {}
      },
      selected_items(){
        return this.selected.map(v=>this.projects.find(u=>u.id == v)).filter(v=>v)
      }
    },
    methods:{
      handleClear(e){
        this.selected = []
      },
  
      handleSelectAll(){
        this.selected = this.filteredGroups.map(v=>v.id)
      },
      handleClick(o){
        if(this.multiple){
          let index = this.selected.findIndex(v=>v == o.id)
          if(index == -1)
            this.selected.push(o.id)
          else
            this.selected.splice(index,1)
        }else{
          this.selected = o.id
         this.handleConfirm()
        }
      },
      handleConfirm(){
        if(this.as_object)
            this.$emit('on-select',this.selected.map(id=>({...this.projects.find(d=>d.id == id)})))
        else
            this.$emit('on-select',[...this.selected])
        this.value = false
        this.selected = []
        this.$emit('input',false)
      }
      }
  }
  </script>
  
  <style>
  
  </style>