<style lang="less">
.bp-title {
  font-size: 16px;
  margin: 5px 0;
  font-weight: bold;
}

.pd-condition {
  margin: 4px 0;
  border-bottom:1px dashed var(--border);
  flex-wrap: nowrap;
}

.u-index {
  padding: 2px;
  margin: 5px;
  min-width: 20px;
  text-align: center;
  font-size: 10px;
  font-weight: normal;
}

.cm-list-item{
  padding:5px;
  border:1px dashed var(--border);
  border-bottom:none;
  border-color:var(--border);
  overflow:hidden !important;
  position: relative;
  transition: all 0.3s;
  .u-index{
    background:var(--bg2);
    color:var(--text2);
    white-space: nowrap;
    text-align: center;
    margin-right:5px;
    font-size:10px;
    font-weight:normal;
   width:60px;
   max-width:60px;
   flex-grow: 0;
   flex-shrink:0;
    
  }
}

.cm-list-item{
  border-bottom:1px dashed var(--border);
  border-left:2px solid var(--primary);
}

.cm-list-item:hover{
  filter:brightness(1.1);
  box-shadow: 1px 1px 5px var(--subtext3);
  cursor: pointer;
  .u-index{
    color:var(--primary);
    background:var(--bg3);
  }
}

.cm-list-item-finished{
  border-left:2px solid var(--success);
  background:var(--bg2);
}

.cm-list-item-error{
  border-left:2px solid var(--error);
  background:var(--bg2);
}
</style>
<template>
  <BaseSegment title="收费计划" desc="项目的收费节点、计划、实际开票情况" style="margin:0">
  <div slot="title-append" v-if="isProjectFinished" icon="md-checkmark" color="var(--success)"
                style="position:absolute;left:160px;top:-5px;padding:5px;border:3px solid var(--success);border-radius:50%;opacity:0.5;font-weight:bold;color:var(--success);transform:rotateZ(-25deg);padding:10px;filter:grayscale(1)" 
                :size="30"
              >项目已结束</div>
  <div class="flex-wrap split10" slot="extra">
    <BaseBoardButton icon="md-create" name="收费条款" desc="需要审核" @on-click="handleEditCondition" />
      	<BaseBoardButton icon="md-list" name="收费条款" :desc="moment(data.condition_updated_at).fromNow()" @on-click="showCondition=true" />
        	<BaseBoardButton icon="md-create" name="收费计划" :desc="moment(data.billplan_updated_at).fromNow()" @on-click="handleEdit" />
          <BaseBoardButton icon="md-list" name="开票记录" :desc="moment(data.bill_updated_at).fromNow()" @on-click="showBills=true" />
          	<!-- <BaseBoardButton icon="download" name="导出" desc="收费数据" @on-click="exportToExcel" /> -->
    </div>
    <div class="flex-wrap flex-left split10" style="border:1px solid var(--border);margin:0 10px;padding:10px;">
      <BaseNumboard name="签约总额" v-if="data.adjust_amount > 1" align="flex-start" :value="formatSalaryObject(data.signed_amount).value" :unit="formatSalaryObject(data.signed_amount).unit"  reverse />
      <BaseNumboard :name="`合同总额${data.adjust_amount?'(调)':''}`" align="flex-start" :value="formatSalaryObject(data.amount).value"  :unit="formatSalaryObject(data.amount).unit"  reverse />
      <BaseBoardDivider />
      
      <BaseNumboard name="已开票" align="flex-start" :value="formatSalaryObject(data.bill_amount).value" unit="万" reverse />
      <BaseProgressBoard name="开票比例" align="flex-start" :value="data.bill_percent" />
      <BaseBoardDivider />
      <BaseNumboard name="已到账" align="flex-start" :value="formatSalaryObject(data.trans_amount).value" reverse  :unit="formatSalaryObject(data.trans_amount).unit" />
       <BaseProgressBoard name="到账比例"  align="flex-start"  :value="data.trans_percent" />
     
    </div>
   
   
    <Row :gutter="10" style="padding:0 10px;">
      <Col :span="8" v-if="false">
      <div style="">
         <BaseBoardSubTitle name="收费条款" />
         <BaseEmpty v-if="!data.conditions || data.conditions.length == 0" msg="暂无收费条款">
      </BaseEmpty>
      <template v-for="g in pay_conditions_groups">
        <div :key="'p_g_'+g.name" style="margin-bottom:10px">
          <div style="color:var(--primary);margin:5px 0;">{{g.name}}</div>
          <div>
            <template v-for="(b,index) in g.conditions">
              <div class="flex-wrap flex-between flex-line cm-list-item" :key="'pay_c_'+b.id">
                <div style="color:var(--text3);font-size:14px;font-weight:bold;flex-grow:1;width:100%;flex-shrink:1;" class="flex-wrap"><span class="u-index" style="">{{b.business_type.slice(0,2)}}{{index+1}}</span>
                    {{b.detail}} </div>
                <div style="font-weight:bold;color:var(--primary);width:140px;text-align:right;flex-shink:0;flex-grow:0;margin-left:10px;"> ￥{{formatSalary(b.amount)}}
                
                </div>
                <div style="width:40px;flex-shrink:0;margin-left:5px;text-align:right;color:var(--bg1);">{{b.percent}}%</div>

              </div>
            </template>
          </div>
        </div>
      </template>
        
      </div>
      </Col>
      <Col :span="24">
      <BaseEmpty v-if="!data.bill_plans || data.bill_plans.length == 0" msg="暂无收费计划">
      </BaseEmpty>
      <template v-else>
        <div style="">
          
          <template v-for="(g,i) in plan_years_groups">
            <div :key="`condition_group_${g.id}`">
            <div style="padding:10px 10px;background:var(--bg3);border-bottom:1px dashed var(--primary);cursor:pointer;" :style="`border-color:${g.status_color} !important`"  class="flex-wrap flex-between" >
              <div>
                <div style="font-size:16px"> <BaseIcon icon="ios-arrow-down" :style="`transition:all 0.3s;`" :color="open_index==i?'var(--primary)':''" /> {{g.name}} <span style="background:var(--primary);font-size:10px;padding:1px 3px;color:var(--hover-text);margin-left:10px;" v-if="moment().year() == g.name"> 今年</span></div>
                </div>
                <div class="flex-row align-end">
                <div class="flex-wrap split10">
               
                 
                 <div class="flex-wrap flex-right split10" style="width:480px;color:var(--subtext3);">
                 
                  <div class="flex-wrap" style="padding:0px 5px;background:var(--bg2);margin-right:20px;border:1px solid var(--border)">
                  实际已完成<BaseAmount :value="g.completed" style="width:90px;border-left:1px solid var(--border);margin-left:5px;" /> </div>  
                  <div class="flex-wrap" style="padding:2px 5px;background:var(--bg2);margin-right:20px;border:1px solid var(--border)">
                计划完成<BaseAmount :value="g.amount" style="width:90px;border-left:1px solid var(--border);margin-left:5px;"  />
                </div>
                </div>
                
                <Progress size="small" hide-info :percent="g.percent" status="active" style="width:50px;" :stroke-width="5"></Progress>
                </div>
                
                </div>
            </div>
            <template v-for="(b,index) in g.items">
            <div class="cm-list-item flex-between flex-line" :class="{'cm-list-item-finished':b.finished_at,'cm-list-item-error':!b.finished_at && moment(b.plan_finished_at).isBefore(moment())}" :key="'bp_'+b.id" @click="handleEditPlan(b)" >
              
            <div class="flex-wrap flex-between " style="flex-grow:1;min-width:200px;">
              <div style="color:var(--text3);font-size:15px;font-weight:bold;" class="flex-wrap"><div class="flex-wrap" style="margin-left:20px;" :style="`color:var(--${!b.finished_at && moment(b.plan_finished_at).isBefore(moment())?'error':(b.finished_at?'success':'primary')})`">
                <BaseIcon icon="date" size="15"></BaseIcon> <div style="font-size:14px;margin:0 5px;font-weight:normal;width:80px;flex-shrink:0;">{{b.finished_at && moment(b.finished_at).isValid()?moment(b.finished_at).format("MM/DD"):(b.plan_finished_at?moment(b.plan_finished_at).format("MM/DD"):'无计划时间')}}</div>
              </div>
                
              <div>
              <div :key="b.id" style="font-size:14px;margin:2px 10px;font-weight:bold;color:var(--text3);flex-grow:1;" v-if="b.condition_id"> {{pay_conditions.find(p=>p.id == b.condition_id)?pay_conditions.find(p=>p.id == b.condition_id).detail:b.condition_id}} </div>
             <div :key="'vbin_'+b.id" style="font-size:12px;margin:2px 10px;color:var(--subtext3)"><span style="color:var(--primary)">{{pay_conditions.find(p=>p.id == b.condition_id)?pay_conditions.find(p=>p.id == b.condition_id).business_type:data.type}}</span>  {{b.note}} </div>
             </div>
             </div>
            </div>
            <div class="flex-wrap" >
               
             <div style="font-size:14px;padding:1px 5px;border:1px solid var(--error);margin-left:10px;font-size:10px;border-radius:5px;color:var(--error)" v-if="!b.finished_at && moment(b.plan_finished_at).isBefore(moment())">
                  已逾期
                </div>
                <div v-else-if="b.finished_at" icon="md-checkmark" color="var(--success)"
                style="border:1px solid var(--success);border-radius:5px;font-weight:bold;padding:1px 5px;color:var(--success);font-size:10px;margin-left:10px;" 
              >已完成</div>

              <div v-else style="border:1px solid var(--primary);border-radius:5px;font-weight:bold;padding:1px 5px;color:var(--primary);font-size:10px;margin-left:10px;" >
                计划中
              </div>
              <div style="color:var(--primary);font-size:14px;width:140px;text-align:right;flex-shrink:0;"><BaseAmount :value="b.amount"></BaseAmount></div>
              <div>
              </div>
              </div>
              
              

            </div>
            
          </template>
            </div>
          </template>
          
        </div>
      </template>
      </Col>
      <!-- <Col :span="8">
      
     
      </Col> -->
    </Row>

    <Modal v-model="showBillPlan" transfer title="收费计划" footer-hide>
      <div style="padding:0px;">
        <div class="flex-wrap flex-between  cm-list-item">
          <div style="color:var(--bg1);font-size:16px;max-width:calc(100% - 100px)" class="flex-wrap">
            <div style="color:var(--text3);margin:0 5px;">
              计划收款 {{formData.plan_finished_at?moment(formData.plan_finished_at).format("YYYY/MM/DD"):"/ 无计划时间"}}
               <div style="font-size:14px;margin-top:5px;text-align:left;"> {{formData.name}} </div>
              </div>
            
          </div>
          <div style="font-weight:bold;color:var(--primary);font-size:18px;"> ￥{{formatFullSalary(formData.amount)}}
               <div v-if="formData.finished_at" style="margin-left:5px;color:darkgreen;font-size:14px;text-align:right">{{formData.finished_at?' 已完成':''}}</div>
            <div style="color:darkred;font-size:14px;" v-else-if="!formData.finished_at && formData.plan_finished_at && moment(formData.plan_finished_at).isBefore(moment())">
              已逾期
            </div>


          </div>

        </div>
      

      <div  style="margin-top:10px;padding:10px;background:var(--bg3);font-size:16px;">
        实际收款时间
        <div class="flex-wrap flex-between" style="margin-top:10px">
          <DatePicker v-model="formData.finished_at" clearable transfer placeholder="选择日期" />
          <Button type="primary" @click="handleSavePlan">提交</Button>
        </div>
      </div>
        
      </div>
    </Modal>

    <Modal v-model="showEditor" title="编辑收费计划" footer-hide fullscreen>
      <Row :gutter="20" style="min-height:100px;height:calc(100%);overflow-y:auto;" >
        <Col :span="6">
        <BaseSegment title="合约条款">
          
        <div class="flex-wrap flex-center split10" style="font-size:20px;margin-bottom:20px;">
            <BaseNumboard name="签约金额" :value="formatFullSalary(data.signed_amount)" reverse />
            <BaseNumboard name="调整金额" :value="formatFullSalary(data.adjust_amount)" reverse />
            <BaseNumboard name="合同总额" :value="formatFullSalary(data.amount)" reverse />
        </div>
        <BaseBoardSubTitle name="收费节点" />
        <BaseEmpty 
          v-if="pay_conditions.length == 0 && special_conditions.length == 0"
        >
          合约条款还未录入，请联系业务部门
        </BaseEmpty>

        <template v-if="pay_conditions.length > 0">

          <div style="padding:1px 0;">
            <draggable :list="pay_conditions" :group="{
             
                name:'contract',pull:'clone',put: false
             
  
            }" animation="300" :clone="handleClone">
              <transition-group type="transition">
                <div :key="'pca_'+c.id" v-for="(c,i) in pay_conditions" class="cm-list-item flex-wrap">
                  <div class="u-index">节点 {{i+1}}</div>
                  <div class="u-index" v-if="data.splited">{{c.business_type}}</div>
                  <div style="flex-shrink: 1;flex-grow:1;max-width:calc(100% - 50px);padding:5px">
                    <div><span style="color:#39f;font-weight:bold;">{{formatFullSalary(c.amount)}}</span> ({{c.percent}}%)</div>
                    <div style="color:#333;font-size:16px;  "> {{c.detail}}</div>

                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </template>
        </BaseSegment>
        <BaseSegment title="特殊条款" v-if="special_conditions.length > 0">
        <div style="padding:2px;background:#ccc">
            <draggable :list="special_conditions" :group="{
             
                name:'contract',pull:'clone',put: false
             
  
            }" animation="300" :clone="handleClone">
              <transition-group type="transition">
                <div :key="'specail_'+c.id" v-for="(c,i) in special_conditions" class="cm-list-item flex-wrap">
                  <div class="u-index">特殊 {{i+1}}</div>
                  <div style="flex-shrink: 1;flex-grow:1;max-width:calc(100% - 50px);padding:5px">
                    <div><span style="color:#39f;font-weight:bold;">{{formatSalary(c.amount)}}</span> ({{c.percent}}%)</div>
                    <div style="color:#333;font-size:10px;  "> {{c.detail}}</div>

                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </BaseSegment>
        </Col>
        <Col :span="13">
        <BaseSegment title="收费计划" :disabled="true">
        <div class="flex-wrap split10" slot="extra">
				<BaseBoardButton icon="save" type="primary" name="保存本次修改" @on-click="handleSavePlans" />
        <BaseBoardButton icon="md-close" name="取消保存" @on-click="showEditor=false" />
</div>
         <div class="flex-wrap flex-center split10" style="font-size:20px;margin-bottom:10px;" v-if="bill_plans && bill_plans.length > 0">
          <BaseNumboard name="计划总额" :value="formatFullSalary(plan_sum)"  reverse />
          <BaseNumboard name="已完成" :value="formatFullSalary(plan_finished_sum)" reverse />
          <BaseNumboard name="剩余" :value="formatFullSalary(plan_unfinished_sum)"  reverse />
          <BaseProgressBoard name="占比" :value="plan_finished_percent" reverse />
          </div>
          <div style="padding:10px 0" v-if="errors.length > 0">
            <BaseBoardSubTitle name="存在以下错误"  />
            <div style="padding:10px 25px;background:var(--bg2);font-weight:bold;color:var(--subtext3)" v-for="(e,i) in errors" :key="e">{{i+1}} - <span style="color:var(--error)">{{e}}</span>
            <div>
              {{errors_descriptions[e] || ''}}
            </div>
            </div>

          </div>
          <BaseEmpty v-if="!bill_plans || bill_plans.length == 0">
            <div class="flex-col flex-center">
              <img
                src="https://inbgz-1257839135.cos.ap-shanghai.myqcloud.com/assets/undraw_team_up_re_84ok.svg"
                style="
                  width: 150px;
                  height: 150px;
                  margin-bottom: 10px;
                  filter: brightness(0.8);
                  opacity: 0.89;
                  margin-top: 50px;
                "
              />
              <p style="color: var(--bg1); font-size: 25px">当前项目未创建收费计划</p>
              <p style="color: var(--bg1); font-size: 12p; margin-bottom: 10px">
                快速创建收费计划, </p>
              <p style="font-size: 15px">
                <a @click="createPlan" style="color: var(--primary)" href="#">
                  <Icon type="md-add" size="20"></Icon> 点击创建
                </a>
              </p>
            </div>
          </BaseEmpty>
          <div style="min-height:100px;margin-top:20px;" v-else>
            <BaseBoardSubTitle name="计划内容" />
            <BaseAlert type="success" style="margin:0 25px;opacity:0.7">tips: 可以从合约收费节点拖动创建以及拖动排序哦</BaseAlert>
            <draggable v-model="bill_plans" group="contract" style="min-height:100%;padding:10px 20px;padding-bottom:50px;" animation="300" filter=".undraggable">
                <div :key="'bill_p'+c.id" v-for="(c,i) in bill_plans" class="pd-condition flex-wrap" style="position:relative;margin-top:30px;border:1px solid var(--border);background:var(--bg3);">
                 
                  <div class="u-index" style="position:absolute;left:-6px;background:var(--bg2);color:var(--hover-text);padding-left:5px;padding-top:0;font-size:12px;font-weight:bold;padding-bottom:0;top:-24px;" :style="`background:var(--${c.finished_at?'success':(moment(c.plan_finished_at).isBefore(moment())?'error':'primary')})`">{{i+1}} - 收款计划
                    <BaseIcon class="hover-highlight" icon="md-copy" :size="15"  color="var(--hover-text)" style="border-left:1px solid var(--border);padding:0 2px;cursor:pointer" @click="bill_plans.splice(i+1,0,{...c,id:('id'+c.id)})" />
                    <BaseIcon class="hover-highlight"  :size="15" icon="md-trash" color="var(--hover-text)" style="border-left:1px solid  var(--border);padding:0 2px;cursor:pointer" @click="handleDeletePlan(i)" />
                  </div>

                  <div class="flex-wrap flex-between" style="flex-shrink: 1;flex-grow:1;max-width:calc(100%);padding:5px;">
                   
                    <div class="flex-wrap split10" style="font-weight:normal;padding:10px;">
                      <div>

                        <div style="font-size:10px">节点</div>
                        <div style="color:#333;font-size:10px;  "> <Select style="width:200px" @pointerdown.stop.native :rows="1" v-model="c.condition_id" placeholder="请输入">
                            <Option v-for="(c,i) in pay_conditions" :key="'pay_con_'+c.id" :value="c.id">{{i+1}} - {{c.detail}}</Option>
                            <Option value="其他">其他</Option>
                          </Select></div>
                      </div>

                      <div>

                        <div style="font-size:10px">备注</div>
                        <div style="color:#333;font-size:10px;  "> <Input   @pointerdown.stop.native style="width:200px" :rows="1" v-model="c.note" placeholder="请输入" /></div>

                      </div>
                      <div>
                        <div style="font-size:10px;color:#888">
                          计划时间
                        </div>
                        <BaseDate @pointerdown.stop.native transfer editable v-model="c.plan_finished_at" style="width:140px" clearable />
                      </div>
                      <div>
                        <div style="font-size:10px;color:#888">
                          完成时间
                        </div>
                        <BaseDate @pointerdown.stop.native transfer editable v-model="c.finished_at" style="width:140px" clearable />
                      </div>
                    </div>
                    <div class="flex-col" style="width:160px;margin-left:10px;align-items:flex-end;padding:5px;white-space:nowrap;">
                      <div style="font-size:10px;color:#888">计划金额 ({{(c.amount * 100 / data.amount).toFixed(1)}} %)</div>
                      <span style="font-weight:normal;font-size:12px;">
                        <span style="color:#39f;font-weight:bold;font-size:14px;"><Input @pointerdown.stop.native number v-model="c.amount" placeholder="计划金额" /></span> 
                      </span>

                    </div>
                    <div style="width:50px">
                    <BaseIcon v-if="c.finished_at" icon="md-checkmark" color="var(--success)"
                style="padding:5px;border:3px solid var(--success);border-radius:50%;opacity:0.5;font-weight:bold;margin:0 10px;" 
                :size="20"
              ></BaseIcon></div>
                  </div>
                </div>
                <div class="flex-wrap hover-lighter" @click="handleAdd()" style="margin:10px 0;padding:10px;background:var(--bg2);width:200px;color:var(--text3)">
                <BaseIcon icon="add"  style="padding:5px;border:3px solid var(--text3);border-radius:50%;opacity:0.5;font-weight:bold;margin:0 10px;" />
                新增计划
                </div>
              <BaseAlert type="success" style="margin-top:5px;opacity:0.6">可直接将收费节点拖拽至此处哦</BaseAlert>
            </draggable>
          </div>

        </div>
        </BaseSegment>
          

        </Col>
        <Col :span="5">
         <BaseSegment title="开票数据">
            <div class="flex-wrap flex-center split5" style="font-size:20px;margin-bottom:10px;">
          <BaseNumboard name="已开票" :value="formatFullSalary(data.bill_amount)" reverse  />
          <BaseNumboard name="尚可开" :value="formatFullSalary(data.unbill_amount)" reverse   />
          <BaseNumboard name="到账比例" :value="data.trans_percent" reverse  unit="%" />
           <BaseNumboard name="未到账" :value="formatFullSalary(data.untrans_amount)" reverse   />
          </div>
        <div style="padding:1px 0;min-height:100px;margin-top:20px;">
           <BaseBoardSubTitle name="开票记录" />
            <BaseEmpty v-if="!data.bills || data.bills.length == 0" />
          <draggable :list="data.bills" :group="{
             
                name:'bill',pull:'clone',put: false
             
  
            }" animation="300" :clone="handleClone" style="min-height:100%">
            <transition-group type="transition">
              <div :key="'bill_'+c.id" v-for="(c,i) in data.bills" class="cm-list-item">
                <div class="flex-wrap flex-between" style="width:100%;padding-right:5px">
                  <div class="flex-wrap" style="">
                    <div class="u-index" style="padding:0 5px">开票 {{i+1}}</div>
                    <div style="font-size:12px">{{moment(c.billed_at).format("YYYY/MM/DD")}}</div>
                  </div>
                  <div style="font-size:14px;font-weight:bold;color:#36f">
                    {{formatFullSalary(c.amount)}}</div>
                </div>
                <div :key="'pay_c'+c.id" style="width:100%;padding:0 10px;padding-bottom:8px;font-size:14px;margin-left:60px;">
            <div style="font-weight:bold">{{c.condition_id && c.condition_id != '10086'?(pay_conditions.find(v=>v.id == c.condition_id) || {}).detail:'无对应节点'}}</div>
            <span v-if="c && c.note">备注: {{c.note}}</span>
          </div>
          <div style="padding-left:30px;border-top:2px dashed var(--border);padding-top:10px;">
            <div class="flex-wrap flex-between">
              到账情况
  
             <span>  <span  v-if="c.transferred_date" style="color:var(--subtext3);font-size:14px;font-weight:bold;">{{moment(c.transferred_date).format("YYYY/MM/DD")}}</span> </span>
              </div>
            
            
            <Progress :percent="c.transferred_percent" status="active" />
          </div>
              </div>
            </transition-group>
          </draggable>

        </div>
        </BaseSegment>
        </Col>

      </Row>
    </Modal>

    <Drawer v-model="showError" title="错误">
       <div style="padding:10px" v-if="errors.length > 0">
      <BaseBoardSubTitle name="存在以下错误"  />
      <div style="padding:10px 25px;background:var(--bg2);font-weight:bold;color:var(--subtext3)" v-for="(e,i) in errors" :key="'error_'+e">{{i+1}} - <span style="color:var(--error)">{{e}}</span>
      <div style="margin:5px">
              {{errors_descriptions[e] || ''}}
            </div>
      </div>

    </div>
    </Drawer>

    <Drawer v-model="showCondition" title="合约收费条款" footer-hide width="800">
      <div style="padding:0 10px">
        <div class="flex-wrap split10" style="margin:0 -5px;margin-bottom:10px;background:var(--bg3);padding:10px;">
         <BaseNumboard name="签约金额" align="flex-start" :value="formatSalaryObject(data.signed_amount).value" :unit="formatSalaryObject(data.signed_amount).unit" reverse />
         <BaseNumboard name="调整金额" align="flex-start" :value="formatSalaryObject(data.adjust_amount).value" :unit="formatSalaryObject(data.adjust_amount).unit" reverse />
         <BaseNumboard name="合同总额" align="flex-start" :value="formatSalaryObject(data.amount).value" :unit="formatSalaryObject(data.amount).unit" reverse />
         <BaseNumboard name="扣除额（外委/联合体)" align="flex-start" :value="formatSalaryObject(data.amount).value" :unit="formatSalaryObject(data.amount).unit" reverse />
         <BaseNumboard name="拟开票总额" align="flex-start" :value="formatSalaryObject(data.amount).value" :unit="formatSalaryObject(data.amount).unit" reverse />
         </div>
        
        <BaseBoardSubTitle name="服务内容拆分"  />
        <ProjectConditionGroup v-model="data" />
        <BaseBoardSubTitle name="收费节点"  style="margin-top:20px"  />
        <ProjectCondition v-model="data" />

        <BaseBoardSubTitle name="合同调整记录"  />
        
      </div>
    </Drawer>
    <Drawer v-model="showConditionEditor" title="编辑-合约条款" footer-hide width="800" :styles="{padding:'20px 40px'}">
      <div class="flex-wrap split10" style="margin:0 -5px;margin-bottom:10px;background:var(--bg3);padding:10px;">
         <BaseNumboard name="签约金额" align="flex-start" :value="formatSalaryObject(model.signed_amount).value" :unit="formatSalaryObject(model.signed_amount).unit" reverse />
         <BaseNumboard name="调整金额" align="flex-start" :value="formatSalaryObject(model.adjust_amount).value" :unit="formatSalaryObject(model.adjust_amount).unit" reverse />
         <BaseNumboard name="合同总额" align="flex-start" :value="formatSalaryObject(model.amount).value" :unit="formatSalaryObject(data.amount).unit" reverse />
         <BaseNumboard name="扣除额（外委/联合体)" v-if="model.splited" align="flex-start" :value="formatSalaryObject(model.deduct_amount).value" :unit="formatSalaryObject(model.deduct_amount).unit" reverse />
         <BaseNumboard name="拟开票总额" align="flex-start"  v-if="model.splited" :value="formatSalaryObject(model.in_bill_amount).value" :unit="formatSalaryObject(model.in_bill_amount).unit" reverse />
         </div>
         <Checkbox v-model="model.splited">服务内容拆分</Checkbox>
        <template v-if="model.splited">
      <BaseBoardSubTitle name="服务内容拆分" />
      <ConditionGroupEditor :value="model" />
      </template>

      <BaseBoardSubTitle name="收费条款" />
      <ConditionEditor :value="model" />

      <BaseBoardSubTitle name="调整记录" />
      <AdjustEditor :value="model" />
      <div class="flex-wrap flex-right split10" style="margin-top:10px;padding-top:10px;border-top:2px dashed var(--border)">
        <Button type="primary" @click="handleSaveCondition">提交</Button>
        <Button>取消</Button>
      </div>
    </Drawer>

    <Drawer v-model="showBills" title="开票记录" width="400">
       <div style="">
         <BaseBoardSubTitle name="开票记录" />
        <template v-for="(c,i) in data.bills">
           <div class="cm-list-item" :key="'dbill_'+c.id">
          <div style="color:var(--bg1);font-size:14px;font-weight:bold;margin-bottom:2px;" class="flex-wrap flex-between">
            <div class="flex-wrap split10">
              <span class="u-index">开票 {{i+1}}</span>
              <div style="font-size:14px;color:var(--success);margin:0 5px;">{{moment(c.billed_at).format("YYYY/MM/DD")}}</div>
            </div>

            <div style="font-weight:bold;color:var(--primary);font-size:18px">
              {{formatSalary(c.amount)}}</div>
            </div>
          <div :key="'pay_c'+c.id" style="width:100%;padding:0 10px;padding-bottom:8px;font-size:14px;">
            <div style="font-weight:bold"><span style="margin-right:10px;padding:2px 5px;font-weight:normal;" v-if="pay_conditions.find(v=>v.id == c.condition_id)">{{(pay_conditions.find(v=>v.id == c.condition_id) || {}).order}} | {{(pay_conditions.find(v=>v.id == c.condition_id) || {}).business_type}}</span>{{c.condition_id && c.condition_id != '10086'?(pay_conditions.find(v=>v.id == c.condition_id) || {}).detail:'无对应节点'}}</div>
            <span v-if="c && c.note">备注: {{c.note}}</span>
          </div>
          <div style="overflow:hidden;padding:5px;background:var(--bg2);margin-top:5px;border-radius:5px;padding-left:30px;">
            <div class="flex-wrap flex-between">
              到账情况
              <Progress style="width:100px" :percent="c.transferred_percent" status="active" />
             <span>  <span  v-if="c.transferred_date" style="color:var(--subtext3);font-size:14px;font-weight:bold;">{{moment(c.transferred_date).format("YYYY/MM/DD")}}</span> </span>
              </div>
            
            
            
          </div>
          </div> 
        </template>
      </div>

    </Drawer>

  </BaseSegment>
</template>

<script>
import { cloneDeep, pick } from 'lodash'
import moment from 'moment'
moment.locale('zh-CN')
import Draggable from 'vuedraggable'
import UTIL from '@/utils'
import {fix, percent} from '@/utils/calc'
import ProjectCondition from '@/components/cm/Conditions'
import ProjectConditionGroup from '@/components/cm/ConditionGroups'
import ConditionEditor from '../EditorConditions'
import ConditionGroupEditor from '../EditorConditionSplit'
import AdjustEditor from '../EditorAdjustRecords'
export default {
  data() {
    return {
      showBillPlan: false,
      showEditor: false,
      showCondition: false,
      bill_plans: [],
      open_index:0,
      formData: {},
      model:{},
      showBills:false,
      showError:false,
      showConditionEditor:false,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.idGlobal = 1
    setTimeout(()=>{
      let index = this.plan_years_groups.findIndex(v=>v.name == moment().year())
      this.open_index = index
    },500)
  },
  components:{Draggable,ProjectCondition,ProjectConditionGroup,ConditionEditor,ConditionGroupEditor,AdjustEditor},
  computed: {
    plan_years_groups(){
       let g = {}
       if(!Array.isArray(this.data.billplans))
        return []
      this.data.bill_plans.forEach(c=>{
        
        let type = c.finished_at?(moment(c.finished_at).format("YYYY")):(c.plan_finished_at?moment(c.plan_finished_at).format("YYYY"):'未计划')
        if(g[type]){
          g[type].items.push(c)
        }else{
          g[type] = {
            name:type,
            items:[c]
          }
        }
      })
      let groups =  Object.values(g)
      groups.forEach(v=>{
        v.amount = v.items.reduce((acc,item)=>acc+item.amount,0)
        v.completed = v.items.filter(v=>v.finished_at).reduce((acc,item)=>acc+item.amount,0)
        v.percent = parseInt(v.completed * 100 / v.amount)
        v.status_color = 'var(--primary)'
        if(Math.abs(v.completed - v.amount) <= 0.1)
          v.status_color = 'var(--success)'
        else if(v.year != '未计划' && parseInt(v.year) < moment().year())
          v.status_color = 'var(--error)'
      })
      return groups
    },
    pay_conditions_groups(){
      let g = {}
      this.pay_conditions.forEach(c=>{
        if(g[c.business_type]){
          g[c.business_type].conditions.push(c)
        }else{
          g[c.business_type] = {
            name:c.business_type,
            conditions:[c]
          }
        }
      })
      return Object.values(g)
    },
    isProjectFinished() {
			return (
				this.data.status == "已结束"
			);
		},
    id(){
      return this.$route.params.id
    },
    errors_descriptions(){
      return {
        "计划金额与合同总额不符":"我们要求收费计划的总额与合同总额一致, 请检查收费计划是否创建完整",
        "已完成的计划金额与开票金额不符":"已标记为完成的计划总额与已开票的总额不一致，请进行修改，如果开票存在多笔一起开或者多笔收费的情况，需要如实对计划指标进行拆分，并将已开票部分标记为完成"
      }
    },
    errors(){
      let plan_sum = this.data.plan_amount
      let plan_finished = this.data.plan_finished_amount
      if(this.showEditor){
        plan_sum = this.plan_sum
        plan_finished = this.plan_finished_sum
      }

      let e = []
      if(plan_sum <= this.data.amount - 0.1 || plan_sum >= this.data.amount + 0.1){
        e.push(`计划金额与合同总额不符`)
      }else if(plan_finished + 0.1 < this.data.bill_amount || plan_finished -0.1 > this.data.bill_amount){
        e.push(`已完成的计划金额与开票金额不符`)
      }

      return e
    },

    lastBill() {
      if (!Array.isArray(this.data.bills))
        return {}
      if (this.data.bills && this.data.bills.length > 0)
        return this.data.bills[this.data.bills.length - 1]
    },
    nextPlan() {
      if (!Array.isArray(this.data.bill_plans))
        return {}
      for (let i = 0; i < this.data.bill_plans.length; i++)
        if (moment(this.data.bill_plans[i].plan_finished_at).isAfter(moment()))
          return this.data.bill_plans[i]
    },
    plan_sum() {
      return fix(this.bill_plans.reduce((c, a) => c + a.amount, 0),2)
    },
    plan_finished_sum(){
      return fix(this.bill_plans.filter(v=>v.finished_at).reduce((c, a) => c + a.amount, 0),2)
    },
    plan_unfinished_sum(){
      return fix(this.plan_sum - this.plan_finished_sum,2)
    },
    plan_finished_percent(){
      return percent(this.plan_finished_sum,this.plan_sum)
    },
    pay_conditions() {
      return this.data.conditions || []
    },
    special_conditions() {
      return this.data.conditions ? this.data.conditions.filter(v => v.type == '特殊条款') : []
    }
  },
  methods: {
    handleEditCondition(e){
      this.model = cloneDeep(pick(this.data,['signed_amount','amount','condition_groups','splited','id','conditions','contract_adjusts','adjust_amount']))
      this.showConditionEditor=true
    },
    handleSaveCondition(){
      let shrinked_model = UTIL.compare_patch_object(this.data,this.model)
      
      this.$api.patch("cm/projects/"+this.model.id,shrinked_model).then(res=>{
        this.$emit('on-update')
        this.showConditionEditor = false
        this.model = {}
      })
    }, 
    formatSalaryObject(e){
      return UTIL.formatSalaryObject(e)
    },
    formatFullSalary(e){
      return UTIL.formatSalarySemicolon(e)
    },
    formatSalary(e){
      return UTIL.formatSalary(e)
    },
    createPlan(){
      this.bill_plans = this.pay_conditions.map(this.handleClone)
    },
    handleSavePlans() {
      let patched = UTIL.compare_patched(this.data.bill_plans, this.bill_plans, ['amount', 'condition_id', 'note', 'plan_finished_at','finished_at'])
      patched.create.forEach(v=>{
        delete v.id
      })
      
      if(patched.empty){
         this.showEditor = false
        this.bill_plans = []
         return
      }

      this.$api.patch('/projects/'+this.id+'?q=billplan',{bill_plans:patched}).then(res=>{
        this.showEditor = false
        this.bill_plans = []
         this.$emit("on-update")
      }).catch(e=>{
        this.Error(e?e.message:'错误')
      })
    },
    handleSavePlan(){
      this.$api.patch('/projects/'+this.id+'?q=billplan',{bill_plans:{
        update:[{id:this.formData.id,finished_at:this.formData.finished_at || null}]
      }}).then(res=>{
        //this.data.bill_plans = res.data.data
       
        this.$emit("on-update")
        this.showEditor = false
        this.showBillPlan = false
        this.bill_plans = []
      }).catch(e=>{
        this.Error(e?e.message:'错误')
      })
    },
    handleEdit() {
      this.bill_plans = cloneDeep(this.data.bill_plans)
      this.showEditor = true
    },
    handleEditPlan(plan) {
      this.formData = { ...plan }
      if(!this.formData.finished_at){
        this.formData.finished_at = this.formData.plan_finished_at || moment().format()
      }
      this.showBillPlan = true
    },
    handleDeletePlan(i) {
       this.bill_plans.splice(i, 1)

    },
    handleAdd(){
      this.bill_plans.push(this.handleClone({}))
    },
    handleClone({ id, amount, detail }) {
      this.idGlobal++
      let obj = {
        id: 'id'+this.idGlobal,
        condition_id: id,
        amount:amount || 0
      }
      return obj

    },
    exportToExcel(){

    }
  }
}
</script>
<style lang="less">

</style>
